<vex-page-layout>
    <div *ngIf="isLoading" class="fixed w-full h-full inset-0 flex items-center justify-center z-50" style="background-color: rgba(0, 0, 0, 0.5);">
        <mat-spinner [diameter]="100"></mat-spinner>
    </div>

    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>

<!--        <div class='w-full flex flex-col sm:flex-row justify-between px-gutter'>-->
<!--            <div>-->
<!--                <h1 class='title mt-0 mb-1'>{{ entityName }}</h1>-->
<!--                <vex-breadcrumbs [crumbs]="[entityName]"></vex-breadcrumbs>-->
<!--            </div>-->
<!--        </div>-->
    </vex-page-layout-header>
    <vex-page-layout-content class='-mt-6'>

        <div class='card overflow-auto -mt-16' *hasPermission="[ENUM_PERMISSION.READ_FM_REF_PURCHASE_SUPPLIER]">
            <base-data-table-header
                [label]='entityName'
                [loading]='loading'
                [showSynchronization]="true"
                [showArchives]="canShowArchivePermission"
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                (onShowArchived)="onShowArchived($event)"
                (onSynchronization)="onSynchronization($event)"
                (onCreate)='createItem()'
                [canImport]='true'
                (onImport)='importItem()'
                [popupFilter]='true'
                (clearPopupFilter)='clearPopupFilter()'
                (validatePopupFilter)='validatePopupFilter()'
                [countPopupFilter]='countPopupFilter'
                [popupWidth]='700'
                headerPopupTitle='Filtres avancés'
                [contentPopupTemplate]='contentPopupTemplate'
                [createPermission]="[ENUM_PERMISSION.ADD_FM_REF_PURCHASE_SUPPLIER]"
                [importPermission]="[ENUM_PERMISSION.IMPORT_FM_REF_PURCHASE_SUPPLIER]"
            ></base-data-table-header>

            <!-- PREVIOUS PERMISSION
            [deletePermission]="[ENUM_PERMISSION.DELETE_FM_BF_SUPPLIER]"
            -->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [customColumnTemplate]="templateRef"
                [routerLinkDetailRow]='routerLinkDetailRow'
                [feisColumns]='feisColumns'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                (onArchiveItem)='archiveItem($event)'
                [deletePermission]="[ENUM_PERMISSION.INPUT_FM_REF_PURCHASE_SUPPLIER]"
            ></base-data-table>
            <ng-template #templateRef let-column let-row="element">
                <mat-chip-list *ngIf="column === 'families'" >
                    <mat-chip *ngFor="let family of row.famillies" style="min-height:18px; font-size: 12px;"  >
                        {{ family?.category?.label ?? family?.label  }}
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm' >
        <base-form-input
            label="Famille d'achat"
            type="autocomplete"
            controlName="category"
            displayProperty='link'
            [items]="families"
        ></base-form-input>
        <base-form-input
                label="Type"
                type="multiple"
                controlName="supplierType"
                [items]="popupType"
        ></base-form-input>
    </form>
</ng-template>
