import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from 'src/app/core/base/base.service';
import {Observable} from 'rxjs';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {SupplierContactModel} from '../../../../core/models/supplier-contact.model';
import {ActivatedRoute} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SupplierContactService extends BaseService<SupplierContactModel>{
    defaultSort = 'createdAt';
    baseUrlApi: string;

    constructor(public http: HttpClient,
                private route: ActivatedRoute) {
        super(http, 'supplier')
    }
    downloadExcelTemplate(): Observable<ObjectApiInterface<SupplierContactModel>> {
        return this.http.get<ObjectApiInterface<SupplierContactModel>>(`${this.baseUrlApi}/download-excel-template-contacts`);
    }
}