import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ENUM_API_URL, ENUM_BASKET_TYPE_ENDPOINT} from "./api-enum";

@Injectable()
export class RequestToLargeInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.body) {
            // Filter Establishment Validators
            if (request.body?.establishment?.validators) {
                delete request.body.establishment.validators;
            }

            if (request.body?.establishment?.analytics) {
                delete request.body.establishment.analytics;
            }

            // if (request.body?.genericEngagement?.engagementCategory?.categories){
            //     delete  request.body.genericEngagement.engagementCategory.categories;
            // }

            this.requestManyToLargeForBasketType(request);
            this.requestManyToLargeForUpdateContract(request);


            if (request.body?.supplier?.clientNumbers) {
                request.body.supplier.clientNumbers.map((clientNumber: any) => {
                    if (clientNumber?.establishment?.validators) {
                        delete clientNumber.establishment.validators;
                    }
                    if (clientNumber?.establishment?.analytics) {
                        delete clientNumber.establishment.analytics;
                    }
                });
            }

            return next.handle(request.clone({body: request.body}));
        }

        return next.handle(request);
    }

    requestManyToLargeForBasketType(request: HttpRequest<any>) {
        if (request.url.startsWith(ENUM_API_URL.BASKET_TYPE_URL)) {
            if (request.body?.contract || request.url?.includes(ENUM_BASKET_TYPE_ENDPOINT.UPDATE_BASKET_PRODUCT) || request.url?.includes(ENUM_BASKET_TYPE_ENDPOINT.ADD_BASKET_PRODUCT)) {
                console.log('request.body?.contract', request.body?.contract);
                delete request.body?.contract;
            }
        }
    }

    requestManyToLargeForUpdateContract(request: HttpRequest<any>) {
        if (request.url.startsWith(ENUM_API_URL.CONTRACT_UPDATE_URL)) {
            delete request.body?.genericEngagement?.engagementCategory?.categories;
        }
    }
}
