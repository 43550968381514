import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {MatColumnDef, MatTable} from '@angular/material/table';
import icClose from '@iconify/icons-ic/twotone-close';
import icDone from '@iconify/icons-ic/twotone-done';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icBaselineContentCopy from '@iconify/icons-ic/baseline-content-copy';
import icAdd from '@iconify/icons-ic/add';
import icStar from '@iconify/icons-ic/twotone-star';
import icRemove from '@iconify/icons-ic/remove';
import {toTitleCase} from 'codelyzer/util/utils';
import {DateFormat, DateTimeFormat} from '../../../helpers/date.helper';
import {ClipboardService} from 'ngx-clipboard';
import {SnackbarService} from '../../../services/snackbar.service';
import {NotificationStatusEnum} from '../../../enums/notification-status.enum';
import {SelectionModel} from '@angular/cdk/collections';
import {EquipmentModel} from '../../../models/equipment.model';
import {AuthService} from '../../../services/auth.service';
import icArchive from '@iconify/icons-ic/twotone-archive';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CrudModeEnum} from '../../enum/crud-mode.enum';
import {
    ContractBasketCreateUpdateDeleteComponent
} from '../../../../pages/application/management/contract/modals/contract-basket-create-update-delete/contract-basket-create-update-delete.component';
import icEmail from '@iconify/icons-ic/twotone-email';
import {element} from "protractor";
import moment from "moment/moment";

@Component({
    selector: 'base-data-table-column',
    styleUrls: ['data-table-column-base.component.scss'],
    templateUrl: 'data-table-column-base.component.html',
})

export class DataTableColumnBaseComponent implements OnInit, OnChanges {

    @Input() label: string;
    @Input() column: string;
    @Input() width: string;
    @Input() propertyDisplay: string;
    @Input() titleCase = false;
    @Input() upperCase = false;
    @Input() type = 'text';
    @Input() customColHeader = false;
    @Input() isPredik = false;
    @Input() showExpired = false;

    @Input() additionalDisplay: string;
    @Input() previousDisplay: string;
    @Input() customDisplay: string;
    @Input() value: any;
    @Input() status: any;
    @Input() clearSelectedRow = false;
    @Input() selectAllDisabled?: boolean;
    @Input() selectedRows: Array<any> = [];
    @Input() canSelectMultiple = true;
    @Input() canUpdate = true;
    @Input() canDelete = true;
    @Input() canArchive = false;
    @Input() canFavorite = false;
    @Input() attentionRequieredTooltip = '';
    @Input() checkboxTooltip = '';
    @Input() customColumnTemplate: TemplateRef<any>;
    @Input() customColumnHeaderTemplate: TemplateRef<any>;
    @Input() actionsTemplate: TemplateRef<any>;
    @Output() onSelectItem = new EventEmitter();
    @Output() onUpdateItem = new EventEmitter();
    @Output() onDeleteItem = new EventEmitter();
    @Output() onArchiveItem = new EventEmitter();
    @Output() onFavoriteItem = new EventEmitter();
    @Output() onClickRow = new EventEmitter();
    @Output() onValueChange = new EventEmitter();
    @Output() onHoverItem = new EventEmitter();
    @Output() onSelectRow = new EventEmitter();
    @Input() routerLinkDetailRow: string;
    @Input() showSort = true;
    @Input() dataSource: any;
    @Input() canSort = true;
    @Input() isOrderBasket = false;
    @Input() customExtraHtml: string;
    customHtml: SafeHtml;
    @Input() maxDecimal = 5;
    @Input() actionText: string;
    @Input() isProductPrestation: boolean;
    icClose = icClose;
    icDone = icDone;
    icDelete = icDelete;
    icStar = icStar;
    icEdit = icEdit;
    icMoreVert = icMoreVert;
    icBaselineContentCopy = icBaselineContentCopy;
    icAdd = icAdd;
    icRemove = icRemove;
    icArchive = icArchive;
    dateFormat = DateFormat;
    dateTimeFormat = DateTimeFormat;
    _specialColumn = ['isActive', 'actions', 'selection', 'favorite'];
    _selectedItem;
    _notificationStatus = NotificationStatusEnum;
    selection: SelectionModel<EquipmentModel>;
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;
    // technicalIdentifier
    canSeeDetails = true;

    @Input() canSelectCheckBoxRows = true;

    protected readonly icEmail = icEmail;


    @Input() isExpiredOrderContractLine = false;

    constructor(
        @Optional() public table: MatTable<any>,
        private cdRef: ChangeDetectorRef,
        private clipboardApi: ClipboardService,
        private snackbarService: SnackbarService,
        private authService: AuthService,
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private router: Router
    ) {
    }

    @Input() clickEl: (element) => void = (e) => {
        console.log(e);
    }

    ngOnInit() {
        if (!this.maxDecimal) { this.maxDecimal = 5; }
        if (this.isOrderBasket) {
            const allItemsHaveSameCode = this.dataSource.data?.every(item =>
                item.genericProduct?.genericAccountingAccount?.code === this.dataSource.
                    data?.[0]?.genericProduct?.genericAccountingAccount?.code
            );
            this.selectAllDisabled = !allItemsHaveSameCode;
        }
        this.customHtml = this.sanitizer.bypassSecurityTrustHtml(this.customExtraHtml);
        if (this.canSort === null || this.canSort === undefined) { this.canSort = true; }
        this.selection = new SelectionModel<EquipmentModel>(this.canSelectMultiple, []);
        // this.actionPermission = this.deletePermission
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }

        if (this.selectedRows.length > 0) {
            // foreach item to display in the table check if it is selected or not

            this.dataSource.filteredData?.forEach((item) => {
                if (this.selectedRows.find(x => x._id === item._id)) {
                    this.selection.toggle(item);
                }
            });

        }
        this.setActionsRules();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.clearSelectedRow) {
            this.selection?.clear();
            // :TODO: FIX THIS TIMEOUT
            setTimeout(() => this.onSelectRow.emit(this.selection.selected), 0);
            this.clearSelectedRow = false;
        }

        if (this.selectedRows && this.dataSource) {
            this.dataSource.filteredData?.forEach((item) => {
                if (this.selectedRows.find(x => x._id === item._id)) {
                    this.selection.toggle(item);
                }
            });
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;

        // filter disabled rows dataTableSelectDisabled = true
        const numRows = this.dataSource.data.filter(x => !x.dataTableSelectDisabled && x.isActive !== false).length;
        return numSelected === numRows;
    }

    // check if the value is a number
    isNumber(value: any): boolean {
        return typeof value === 'number' && !isNaN(value);
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {

        console.log('toggleAllRows', this.selection.selected);

        if (this.isAllSelected()) {
            this.selection.clear();
            this.onSelectRow.emit(this.selection.selected);
            return;
        }

        // select all rows except the ones that are disabled dataTableSelectDisabled = true
        this.dataSource.data.forEach((row) => {
            if (!row.dataTableSelectDisabled && row.isActive !== false) {
                this.selection.select(row);
            }
        });

        this.onSelectRow.emit(this.selection.selected);
    }

    toggleRow(event, element) {
        event ? this.selection.toggle(element) : null;
        if (this.isOrderBasket) { this.syncDatatableCheckboxes(element); }
        this.onSelectRow.emit(this.selection.selected);
    }

    toggleFavorite(element) {
        element.isMain = !element.isMain;
        element.favorite = !element.favorite;
        this.favoriteItem(element);
        this.onSelectRow.emit(element);
    }

    isFavorite(element) {
        return element.isMain === true || element.favorite;
    }

    setActionsRules() {
        if (this.canUpdate === undefined) { this.canUpdate = true; }
        if (this.canDelete === undefined) { this.canDelete = false; }
        if (this.canArchive === undefined) { this.canArchive = false; }
    }
    showLabelTva(element) {
        return element.labelTva ?? '-';
    }

    display(element) {
        if (!element) {
            return '-';
        }

        let display = element;

        if (!this.propertyDisplay) {
            display = element[this.column];
        } else {
            this.propertyDisplay.split('.').forEach(prop => display = display[prop] ?? '-');
        }

        // Info for developers : you need to choose between titleCase and upperCase otherwise it will throw an error
        if (this.titleCase && this.upperCase) {
            const error = new Error(`titleCase and upperCase cannot be used together in column "${this.column}"`);
            console.error(error);
            throw error;
        }

        if (this.titleCase) {
            display = toTitleCase(display);
        }

        if (this.upperCase) {
            display = display.toUpperCase();
        }

        return display ?? '-';
    }

    selectItem(element) {
        this._selectedItem = element;
        this.onSelectItem.emit(element);
    }

    favoriteItem(element) {
        this._selectedItem = element;
        this.onFavoriteItem.emit(this._selectedItem);
    }

    updateItem() {
        this.onUpdateItem.emit(this._selectedItem);
    }

    deleteItem(element?) {
        this.onDeleteItem.emit( this._selectedItem ?? element);
    }

    // ici pour gérer la route;
    routerLink(element: any): Array<string> {
        if (this.type === 'technicalIdentifier') {
            return;
        }
        const finalRoute = [];
        if (this.routerLinkDetailRow) {
            this.routerLinkDetailRow.split('/').filter(x => x).forEach((route, i) => {
                if (route === ':id') {
                    finalRoute.push(element._id);
                } else {
                    finalRoute.push(route);
                }
            });
        }
        if (this.routerLinkDetailRow && this.routerLinkDetailRow[0] === '/') {
            finalRoute.unshift('/');
        }
        return finalRoute;
    }

    clickRow(element: any, event?: any) {
        if (this.type === 'technicalIdentifier') {
            this.clipboardApi.copyFromContent(element.technicalIdentifier);
            this.snackbarService.copyToClipboard(element.technicalIdentifier);
        } else {
            this.onClickRow.emit(element);
        }
    }

    // createPrestIntervention(element: any) {
    //     console.log('createPrestIntervention', element);
    //  //   this.onClickRow.emit(element);
    //
    //
    //     this.dialog.open(ContractPrestInterventionBasketCreateUpdateDeleteComponent, {
    //     }).afterClosed().subscribe(res => {
    //     });
    // }


    // TODO REFACTO




    round(element) {
        if (!element && element !== 0) { return; }
        return Math.round(element * 100) / 100;
    }

    catalogueChange(action: string, element: any, event: any = null) {
        if (action === 'add') {
            element.quantity++;
            this.onValueChange.emit({action, element});
        }

        if (action === 'remove') {
            if (element.quantity - 1 >= 0) {
                element.quantity--;
                this.onValueChange.emit({action, element});
            }
        }

        if (action === 'update') {
            element.quantity = +event.target.value;
            if (element.quantity < 0) {
                event.target.value = 1;
                element.quantity = 1;
            }
            this.onValueChange.emit({action, element});
        }
    }

    elementIsTrue(element): boolean {
        this.column.split('.').forEach(x => {
            if (element) { element = element[x]; }
        });
        return element === true;
    }

    elementIsNotNull(element): boolean {
        this.column.split('.').forEach(x => {
            if (element) { element = element[x]; }
        });
        return element !== null && element !== undefined;
    }

    elementNbContractCatalogueIsTrue(element): boolean {
        this.column.split('.').forEach(x => element = element[x]);
        return element > 0;
    }

    archiveItem() {
        this.onArchiveItem.emit(this._selectedItem);
    }

    hoverItem(element, isHover: boolean) {
        if (isHover) {
            this.onHoverItem.emit(element);
        }
        if (!isHover){
            this.onHoverItem.emit(isHover);
        }
    }

    canAccess(): boolean {
        return this.canSeeDetails;
    }

    contractExpiringSoon(contract) {
        if(contract){
            const endDate = contract?.denunciationDate ? contract?.denunciationDate : contract?.endValidityDate;
            const currentDate = moment();
            const endValidityDate = moment(endDate);
            const daysBeforeExpiration = endValidityDate.diff(currentDate, 'days');
            return daysBeforeExpiration >= 0 && daysBeforeExpiration <= 7;
        } else {
            return false;
        }
    }

    private syncDatatableCheckboxes(element) {
        this.dataSource.data = this.dataSource.data.map(item => {
            if (item.genericProduct?.genericAccountingAccount?.code !== element.genericProduct?.genericAccountingAccount?.code) {
                item.dataTableSelectDisabled = true;
            } else {
                item.dataTableSelectDisabled = false;
            }
            return item;
        });
    }

    public checkIfAllWaiting(element: any): boolean {
        return element?.statusPrestationLineIsWaiting;
    }

}
