import { Injectable } from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { validate } from 'codelyzer/walkerFactory/walkerFn';
import { GedTypeEnum } from '../../../../../core/enums/ged-type.enum';
import {IDocumentIndexField, IDocumentsQueryResult} from "../../../../../core/ui/ged-ui/interface/docuware.interface";

export interface DocuwareConnection {
    url: string;
    username: string;
    password: string;
}

export interface FileCabinet {
    AddIndexEntriesInUpperCase: boolean;
    Color: string;
    Default: boolean;
    DocumentAuditingEnabled: boolean;
    HasFullTextSupport: boolean;
    Id: string;
    IsBasket: boolean;
    Name: string;
}

@Injectable({
    providedIn: 'root'
})
export class GedService {
    errorMessage: BehaviorSubject<string>;
    public errorMessage$: Observable<string>;
    baseUrlApi = `/api/ged`;
    type;

    constructor(private http: HttpClient) {
        this.errorMessage = new BehaviorSubject<string>(null);
        this.errorMessage$ = this.errorMessage.asObservable();
    }

    testConnection(): Observable<any> {
        return this.http.get(`${this.baseUrlApi}/testConnection`);
    }

    allDocumentsByPredikId(id: string) {
        return this.http.get<any>(`${this.baseUrlApi}/allDocumentsByPredikId/${id}`);
    }

    downloadDocument(documentId: string) {
        return this.http.get(`${this.baseUrlApi}/download/${documentId}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    uploadDocument(document, data, loading: boolean): any {
        const formParams = new FormData();
        formParams.append('file', document._file, document.originalFileName);
        const body = {...data, ...{type: this.type}};
        formParams.append('data', JSON.stringify(body));
        // return this.http.post(`${this.baseUrlApi}/upload`, formParams, {
        //     reportProgress: true
        // });
        const req = new HttpRequest('POST', `${this.baseUrlApi}/upload`, formParams, {
            reportProgress: true
        });

        this.http.request(req)
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    document.progress.next(percentDone);
                } else if (event instanceof HttpResponse) {
                    document.progress.next(101);
                }
            },
            error => {
                document.progress.next(500);
                document.status = 'ERROR';
            });
        return document;
    }

    deleteDocument(id: string) {
        return this.http.delete(`${this.baseUrlApi}/delete/${id}`);
    }

    setUrl(url: string) {
        this.baseUrlApi = url;
    }

    setType(gedType: GedTypeEnum) {
        this.type = gedType;
    }

    mapToDataSource(value: IDocumentsQueryResult) {
        const convertToObject = (arr) => {
            return arr.Fields.reduce(
                (obj, item) => ({
                    ...obj,
                    // tslint:disable-next-line:radix
                    [item.FieldName]: item.Item
                }),
                {}
            );
        };
        return value.Items.map(m => {
            const dateToConvert = m.Fields.filter(x => x.ItemElementName === 'Date' && x.IsNull === false).map(x => {
                // tslint:disable-next-line:radix
                x.Item = new Date(parseInt( x.Item.substr(6)));
                return x;
            });
            m.Fields.find(x => x.FieldName === 'DWPAGECOUNT').Item = m.TotalPages;
            return convertToObject(m);
        });
    }

    checkDocumentIsNotEmpty(id: string, type: string) {
        return this.http.get(`${this.baseUrlApi}/ged/checkDocumentIsNotEmpty/${id}/${type}`);
    }
}

export interface DocumentElement {
    id: string;
    DCWDOCID?: number;
    originalFileName: string;
    dateUpload: string;
    size: number;
    contentType: string;
    status: string;
    _file: File;
    progress: BehaviorSubject<number>;
    uploadError: string;
    errorType: string;
    type?: string;
}

