import {Component, OnDestroy, OnInit} from '@angular/core';
import {EstablishmentService} from '../../establishment.service';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import {PageEvent} from '@angular/material/paginator';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {BuildingModel} from "../../../../../../core/models/building.model";
import {DataTableBase} from "../../../../../../core/base/components/data-table-base/data-table-base";
import {BuildingService} from "../../../building/building.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {EstablishmentModel} from "../../../../../../core/models/establishment.model";
import {Sort} from "@angular/material/sort";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'vex-establishment-details-activite',
    templateUrl: './establishment-details-buildings.component.html',
    styleUrls: ['./establishment-details-buildings.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class EstablishmentDetailsBuildingsComponent extends DataTableBase<EstablishmentModel> implements OnInit, OnDestroy {

    displayedColumns = ['actions'];
    count = 0;
    sortField: string;
    sortOrder: string;
    pageIndex: number;
    pageSize: number;
    search: string;
    feisColumns: Array<FeiColumn> = [
        {label: 'Identifiant technique', column: 'technicalIdentifier', propertyDisplay: null},
        {label: 'Libellé', column: 'label', propertyDisplay: null},
    ]
    loading: boolean = false;
    entityName = 'Bâtiments';
    displayPostePercentWarning: boolean = false;
    ENUM_PERMISSIONS = ENUM_PERMISSIONS;
    filters: any = {};
    routerLinkBuilding = '/management/building/:id';
    protected _building = new BehaviorSubject<BuildingModel[]>(null);
    readonly building$ = this._building.asObservable();
    private subscriptions = new Subscription();

    constructor(
        public service: EstablishmentService,
        public buildingService: BuildingService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {
        const sub = this.service.entity$.subscribe((entity) => {
            this.loading = true;
            this.filters = {
                establishmentId: entity._id,
            }

            this.buildingService.findAll(this.pageIndex, null, this.sortField, this.sortOrder, null, this.filters).subscribe(res => {
                this.dataSource = new MatTableDataSource<any>(res.data);
                this.count = res.totalData;
                this.loading = false;
            })
        })

        this.subscriptions.add(sub);
    }

    pageEvent(event: PageEvent): void {
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.initData();
    }

    sortEvent(event: Sort): void {
        this.sortField = event.active;
        this.sortOrder = event.direction;
        this.initData();
    }
}
