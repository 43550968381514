import {Component, OnInit} from '@angular/core';
import icDelete from '@iconify/icons-ic/twotone-delete';
import {BehaviorSubject, Observable} from 'rxjs';
import {DateFormat} from '../../../../../../core/helpers/date.helper';
import {MatDialog} from '@angular/material/dialog';
import {SnackbarService} from '../../../../../../core/services/snackbar.service';
import {ContractService} from '../../contract.service';
import {
    ContractModel,
    ContractPerimeterModel,
    RecurringBasketModel
} from '../../../../../../core/models/contract.model';
import {recurrenceEnumTranslationOccurence} from '../../../../../../core/enums/recurrence.enum';
import {
    ContractBasketCreateUpdateDeleteComponent
} from '../../modals/contract-basket-create-update-delete/contract-basket-create-update-delete.component';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import icClose from '@iconify/icons-ic/twotone-close';
import icMinus from '@iconify/icons-ic/twotone-minus';
import icPlus from '@iconify/icons-ic/twotone-plus';
import {ProductModel} from '../../../../../../core/models/product.model';
import {PrestationModel} from '../../../../../../core/models/prestation.model';
import {ContractBaseComponent} from '../contract-base/contract-base.component';
import {BaseBasketItemModel} from '../../../../../../core/base/models/base-basket-item.model';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {ProductService} from '../../../product/product.service';
import {PrestationService} from '../../../prestation/prestation.service';
import {ContractStatusEnum} from '../../../../../../core/enums/contract-status.enum';
import {DialogConfirmComponent} from "../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component";
import {TicketModel} from "../../../../../../core/models/ticket.model";
import {OutOfCatalogStatusEnum} from "../../../../../../core/enums/ticketStatusEnum";
import moment from "moment";
import {EstablishmentService} from "../../../establishment/establishment.service";
import {BuildingService} from "../../../building/building.service";
import {EquipmentService} from "../../../equipment/equipment.service";
import {GenericEngagementDataPatrimonyEnum} from "../../../../../../core/enums/generic-engagement-data-patrimony.enum";
import {EnergyIdService} from "../../../energy-id/energy-id.service";
import {
    ContractBasketValidateComponent
} from "../../modals/contract-basket-validate/contract-basket-validate.component";
import {log10} from "chart.js/helpers";


@Component({
    selector: 'vex-contract-details-basket-contract',
    templateUrl: './contract-details-basket-contract.component.html',
    styleUrls: ['./contract-details-basket-contract.component.scss']
})
export class ContractDetailsBasketContractComponent extends ContractBaseComponent implements OnInit {

    reccuringBaskets: RecurringBasketModel[];
    currentRecurringBasket: RecurringBasketModel;

    // Boolean
    hasArticle = false;
    prestationEmpty = false;
    hasPrestation = false;
    articleEmpty = false;
    displayRecurringBasketBanner = false;
    confirmationDelayDate = null;
    contractStatusEnum = ContractStatusEnum;

    count = 0;
    column: string;

    selected = 0;
    icDelete = icDelete;
    dateFormat = DateFormat;

    _basket: BehaviorSubject<Array<BaseBasketItemModel>> = new BehaviorSubject<Array<BaseBasketItemModel>>(null);
    basket$: Observable<any> = this._basket.asObservable();

    isContractBasketLineSelected = false;
    entity: any;
    contract: ContractModel;
    perimeter: ContractPerimeterModel[] = [];

    tooltip = false;

    protected readonly DateFormat = DateFormat;


    recurringBasketEstablishmentLabel: string;
    recurringBasketBuildingLabel: string;
    recurringBasketEquipmentLabel: string;
    recurringBasketIdEnergyLabel: string;


    tabs = ['Articles', 'Prestations'];

    // DataTable
    feisColumnsArticle: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label'},
        {label: 'Fabricant', column: 'manufacturer'},
        {label: 'Prix', column: 'priceHT', type: 'number', additionalDisplay: ' €'},
        {label: 'Quantité', column: 'quantity', type: 'custom'},
        {label: 'Action', column: 'addToCart', type: 'custom'},
    ];
    dataSourceArticle: MatTableDataSource<ProductModel>;
    displayedColumnsArticle = this.feisColumnsArticle.map((feiColumn: FeiColumn) => feiColumn.column);

    feisColumnsPrestation: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label'},
        {label: 'Fabricant', column: 'manufacturer'},
        {label: 'Prix', column: 'priceHT', type: 'number', additionalDisplay: ' €'},
        {label: 'Quantité', column: 'quantity', type: 'custom'},
        {label: 'Action', column: 'addToCart', type: 'custom'},
    ];
    dataSourcePrestation: MatTableDataSource<PrestationModel>;
    displayedColumnsPrestation = this.feisColumnsPrestation.map((feiColumn: FeiColumn) => feiColumn.column);

    loading = false;
    contractId: string;


    isContractColumnQuantity = true;
    totalBasketAmount = 0;
    public iconPlus = icPlus;
    public iconMinus = icMinus;
    public iconClose = icClose;
    public iconDelete = icDelete;

    ContractStatusEnum = ContractStatusEnum;
    GenericEngagementDataPatrimonyEnum = GenericEngagementDataPatrimonyEnum;


    constructor(
        public contractService: ContractService,
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        public productService: ProductService,
        public prestationService: PrestationService,
        public establishmentService: EstablishmentService,
        public buildingService: BuildingService,
        public equipmentService: EquipmentService,
        public energyIdService: EnergyIdService,
    ) {
        super(
            contractService, productService, prestationService
        );
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {

        super.initData();

        const sub1 = this.contractService.reccuringBaskets$.subscribe((reccuringBaskets) => {
            this.reccuringBaskets = reccuringBaskets;

            // Restaurer la sélection basée sur un identifiant unique.
            if (this.currentRecurringBasket?._id) {
                this.currentRecurringBasket = this.reccuringBaskets.find(basket => basket._id === this.currentRecurringBasket?._id);
            } else {
                this.currentRecurringBasket = null;
            }

            this.initRecurringBasketBanner(this.contract, this.reccuringBaskets);

            // if (reccuringBaskets.length > 0) {
            //     this.contractService.selectRecurringBasket(reccuringBaskets[0]._id); // à changer piru renvoyer u nresultat vide au début // probleme here
            // }else {
            //     this.currentRecurringBasket = null;
            //     this._basket.next(null);
            // }

        });

        const sub = this.contractService.currentRecurringBasket$.subscribe((currentBasket: RecurringBasketModel) => {

            if (currentBasket && !currentBasket?.baskets) {
                currentBasket.baskets = [];
            }

            this.currentRecurringBasket = currentBasket;
            this.currentRecurringBasket.baskets = this.contractService.mapBasket(this.contract, currentBasket);
            this._basket.next(this.currentRecurringBasket.baskets);
            this.perimeter = this.currentRecurringBasket.perimeter;

            this.currentRecurringBasket.totalHT = this.currentRecurringBasket.baskets.reduce((acc, product) => {
                if (product.priceHT !== undefined && product.quantity !== undefined) {
                    acc += product.priceHT * product.quantity;
                }
                return acc;
            }, 0);

            if (this.currentRecurringBasket.confirmationDelay > 0) {
                this.confirmationDelayDate = moment(this.currentRecurringBasket.nextRecurrence).subtract(this.currentRecurringBasket.confirmationDelay, 'days').toDate();
            }

            if (this.currentRecurringBasket.prestInterventionId) {
                // TODO : A revoir
                // console.log('this.currentRecurringBasket.prestInterventionId', this.currentRecurringBasket);
                // this.currentRecurringBasket.prestIntervention = this.currentRecurringBasket.find((x) => x._id === this.currentRecurringBasket.prestInterventionId);

                const sub1 = this.establishmentService.findById(currentBasket.perimeter[0].establishmentId).subscribe((data: any) => {
                    this.recurringBasketEstablishmentLabel = data.data.label;
                });
                this.subscription.add(sub1);

                if (this.contract.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.BUILDING) {
                    const sub2 = this.buildingService.findById(currentBasket.perimeter[0].buildingId).subscribe((data: any) => {
                        this.recurringBasketBuildingLabel = data.data.label;
                    });
                    this.subscription.add(sub2);

                }

                if (this.contract.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.EQUIPMENT) {
                    const sub3 = this.equipmentService.findById(currentBasket.perimeter[0]?.equipmentId).subscribe((data: any) => {
                        this.recurringBasketEquipmentLabel = data?.data?.reference;
                    });
                    this.subscription.add(sub3);
                }

                if (this.contract.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.ID_ENERGIE) {
                    const sub4 = this.energyIdService.findById(currentBasket.perimeter[0]?.energyIdId).subscribe((data: any) => {
                        this.recurringBasketIdEnergyLabel = data?.data?.energyIdId;
                    });
                    this.subscription.add(sub4);
                }


                console.log(this.contract.genericEngagement.dataPatrimony, 'generic engagement')

            } else {

                this.currentRecurringBasket.products?.map((item) => {
                    const product = item.item as ProductModel;

                    if (product) {
                        item.label = product.label;
                        item.priceHT = product.priceHT;
                    }
                    return product;
                });

                this.currentRecurringBasket.prestations?.map((item) => {
                    const prestation = item.item as PrestationModel;
                    if (prestation) {
                        item.label = prestation.label;
                        item.priceHT = prestation.priceHT;
                    }
                    return prestation;

                });
            }
        });
        this.subscription.add(sub);
        this.currentRecurringBasket = null;
        this.subscription.add(sub1);
    }

    selectRecurringBasket(reccuringBasket: RecurringBasketModel) {
        // if selected basket is the same as the current basket, do nothing
        if (this.currentRecurringBasket?._id === reccuringBasket?._id) {
            return;
        }
        this.contractService.selectRecurringBasket(reccuringBasket?._id);
    }

    createRecurringBasket() {
        let confirmationNeeded = false;

        this.dialog.open(ContractBasketCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Create,
                contract: this.contract
            }

        }).afterClosed().subscribe(res => {
            confirmationNeeded = res.data.confirmationNeeded;

            if (!confirmationNeeded) {
                const latestRecurringBasket = res.data.contract.recurringBasket[res.data.contract.recurringBasket.length - 1];
                this.selectRecurringBasket(latestRecurringBasket);
            }

            if (confirmationNeeded) {
                this.dialog.open(DialogConfirmComponent, {
                    data: {
                        description: 'Le délai de confirmation est être antérieure à la date actuelle. Le panier sera automatiquement créé à la validation du panier.',
                        cancelText: 'Annuler',
                        title: 'Confirmer la création du panier',
                        validText: 'Confirmer',
                        call$: this.contractService.confirmCreateRecurringBasket(this.contract._id)
                    }
                }).afterClosed().subscribe(res => {
                    const latestRecurringBasket = res.data.contract.recurringBasket[res.data.contract.recurringBasket.length - 1];
                    this.selectRecurringBasket(latestRecurringBasket);
                });
            }
        });

    }

    initRecurringBasketBanner(entity: ContractModel, recurringBaskets: RecurringBasketModel[]) {
        this.displayRecurringBasketBanner = (entity?.status === ContractStatusEnum.DRAFT) && recurringBaskets?.length > 0;
    }

    async deleteRecurringBasket(reccuringBasket: RecurringBasketModel) {

        const entity = await this.contractService.entity;
        if (reccuringBasket) {
            const sub = this.contractService.removeRecurringBasket(entity._id, reccuringBasket._id).subscribe(
                remove => this.snackbarService.success('Le panier récurrent à été supprimé.')
            );
            this.subscription.add(sub);
        }
        this.isContractBasketLineSelected = false;
    }

    editRecurrence() {

        // check if currentRecurringBasket is editable

        if (this.contract.status !== ContractStatusEnum.DRAFT && !this.currentRecurringBasket.manualPlanification && this.currentRecurringBasket.prestInterventionId) {
            this.snackbarService.danger('Vous ne pouvez pas modifier un panier récurrent logique, lorsque le contrat est actif.')
            return
        }

        this.dialog.open(ContractBasketCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Update,
                contract: this.contract,
                currentRecurringBasket: this.currentRecurringBasket
            }
        }).afterClosed().subscribe(res => {

            if (res.data.confirmationNeeded) {
                this.dialog.open(DialogConfirmComponent, {
                    data: {
                        description: 'Le délai de confirmation est être antérieure à la date actuelle. Le panier sera automatiquement créé à la validation du panier.',
                        cancelText: 'Annuler',
                        title: 'Confirmer la création du panier',
                        validText: 'Confirmer',
                        call$: this.contractService.confirmUpdateRecurringBasket(this.contract._id, this.currentRecurringBasket._id)
                    }
                }).afterClosed().subscribe(res => {
                });
            }
        });
    }

    async validatesRecurringBasket(value: boolean = true) {

        this.contractService.validateRecurringBasket(this.contract._id, this.currentRecurringBasket._id, value).subscribe(res => {
            if (value && res) {
                this.snackbarService.success('Le panier contrat a bien été créé');
            }
        });

        // this.dialog.open(ContractBasketValidateComponent, {
        //     width: '400px',
        //     data: {
        //         contract: this.contract,
        //         currentRecurringBasket: this.currentRecurringBasket
        //     }
        // }).afterClosed().subscribe(res => {
        //     if (res) {
        //         const optionUrgencyDegree = res.option.urgencyDegree;
        //         this.contractService.validateRecurringBasket(this.contract._id, this.currentRecurringBasket._id, value, optionUrgencyDegree).subscribe(res => {
        //             if (value && res) {
        //                 this.snackbarService.success('Le panier contrat a bien été créé');
        //             }
        //         });
        //     }
        // });
    }

    async addToBasket(selectedEl: any, selectedRecurringBasket: RecurringBasketModel) {
        this.contractService.addToBasket(this.contract._id, selectedRecurringBasket._id, this.entityType, selectedEl).subscribe();
    }

    async onUpdateItemQuantityFromBasketEvent(event: any) {


        switch (event.action) {
            case 'plus':
                event.item.quantity++;
                break;
            case 'minus':
                event.item.quantity--;
                break;
        }

        this.contractService.updateBasketQuantity(this.contract._id, this.currentRecurringBasket._id, event.item).subscribe();

    }

    async onRemoveItemFromBasketEvent(item: any) {

        const sub = this.contractService.removeFromBasket(this.contract._id, this.currentRecurringBasket._id, item).subscribe();
        this.subscription.add(sub);
    }

    updateCatalogueQuantity(element, direction: string) {

        if (this.entityType === 'products') {
            this.dataSourceArticle = new MatTableDataSource(this.dataSourceArticle.data.map((entity: any) => {
                if (entity.quantity === undefined || NaN) {
                    entity.quantity = 1;
                }
                this.isContractColumnQuantity = false;
                if (entity._id === element._id) {
                    if (direction === 'minus' && entity.quantity === 1) {
                        return entity;
                    }
                    if (direction === 'change') {
                        return entity;
                    }
                    if (direction === 'plus' && entity.quantity === 9999) {
                        return entity;
                    }
                    direction === 'plus' ? entity.quantity++ : entity.quantity--;
                }
                return entity;

            }));
        }

        if (this.entityType === 'prestations') {
            this.dataSourcePrestation = new MatTableDataSource(this.dataSourcePrestation.data.map((entity: any) => {
                if (entity.quantity === undefined || NaN) {
                    entity.quantity = 1;
                }
                this.isContractColumnQuantity = false;
                if (entity._id === element._id) {
                    if (direction === 'minus' && entity.quantity === 1) {
                        return entity;
                    }
                    if (direction === 'change') {
                        return entity;
                    }
                    if (direction === 'plus' && entity.quantity === 9999) {
                        return entity;
                    }
                    direction === 'plus' ? entity.quantity++ : entity.quantity--;
                }
                return entity;

            }));
        }
    }

    showTooltip(state: boolean) {
        this.tooltip = state;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        super.ngOnDestroy();
    }


    getOccurenceText(recurringBasket: RecurringBasketModel) {
        return recurrenceEnumTranslationOccurence(recurringBasket);
    }

    isBasketSelected(reccuringBasket): boolean {
        return reccuringBasket._id === this.currentRecurringBasket?._id;
    }

}
