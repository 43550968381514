<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
<!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
<!--            <div>-->
<!--                <h1 class='title mt-0 mb-1'>{{entityName}}</h1>-->
<!--                <vex-breadcrumbs [crumbs]="[entityName]"></vex-breadcrumbs>-->
<!--            </div>-->
<!--        </div>-->
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>
            <!-- PREVIOUS PERMISSION
            [createPermission]="[ENUM_PERMISSION.CREATE_HM_BUILDING]"
            [importPermission]="[ENUM_PERMISSION.IMPORT_HM_BUILDING]"
            -->
            <base-data-table-header
                [label]='entityName'
                [loading]='loading'
                [showArchives]="canShowArchivePermission"
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                (onShowArchived)="onShowArchived($event)"
                (onCreate)='createItem()'
                canImport='true'
                (onImport)='importItems()'
                [popupFilter]='false'
                (clearPopupFilter)='clearPopupFilter()'
                (validatePopupFilter)='validatePopupFilter()'
                [countPopupFilter]='countPopupFilter'
                headerPopupTitle='Filtres avancés'
                [contentPopupTemplate]='contentPopupTemplate'
                [createPermission]="[ENUM_PERMISSION.ADD_BUILDING]"
                [importPermission]="[ENUM_PERMISSION.IMPORT_BUILDING]"
            ></base-data-table-header>

            <!-- PREVIOUS PERMISSION
            [deletePermission]="[ENUM_PERMISSION.DELETE_HM_BUILDING]"
            -->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
                [routerLinkDetailRow]='routerLinkDetailRow'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                (onArchiveItem)='archiveItem($event)'
                [deletePermission]="[ENUM_PERMISSION.INPUT_BUILDING]"
            ></base-data-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm'>
        <base-form-input
            label='Etablissement'
            type='autocomplete'
            [items$]='establishment$'
            (autocompleteChange)='establishmentAutoCompleteChange($event)'
            displayProperty='label'
            controlName='establishmentId'
        >
        </base-form-input>
<!--        TODO: améliorer le zéro-->
        <base-form-input
                label="Nombre d'étages"
                type='number'
                controlName='stages'
        >
        </base-form-input>
    </form>
</ng-template>
