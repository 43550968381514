import {Component, Input, OnInit} from '@angular/core';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {Observable} from 'rxjs';
import {BaseBasketItemModel} from "../../../../models/base-basket-item.model";

@Component({
  selector: 'catalog-basket-minified-base',
  templateUrl: './catalog-basket-minified-base.component.html',
  styleUrls: ['./catalog-basket-minified-base.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ],

  styles: [`
    .number-no-arrows::-webkit-outer-spin-button,
    .number-no-arrows::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        text-align: center;
    }
  `]
})
export class CatalogBasketMinifiedBaseComponent implements OnInit {

  @Input() items$: Observable<any | BaseBasketItemModel> = new Observable<any>();


  totalBasketAmount = 0.00;
  totalQuantity = 0;

  constructor() {
  }

  ngOnInit() {

    this.items$.subscribe(items => {

      if (!items) {
        return;
      }

      this.totalBasketAmount = items.reduce((acc, item) => acc + item.priceHT * item.quantity, 0).toFixed(3);
      this.totalQuantity = items.reduce((acc, item) => acc + item.quantity, 0);
    });
  }

}
