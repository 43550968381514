import {SupplierModel} from './supplier.model';
import {ValidityModel} from '../base/models/validity.model';
import {ContractType} from '../enums/contract-type.enum';
import {UserProfileModel} from './user-profile.model';
import {EstablishmentModel} from './establishment.model';
import {QuoteModel} from "./quote-model";
import {SupplierContactModel} from "./supplier-contact.model";
import {SavingClauseReasonModel} from "./saving-clause-reason.model";
import {BasketPrestation, BasketProduct} from './ticket.model';
import {RecurrenceEnum} from '../enums/recurrence.enum';
import {MonthEnum} from "../enums/month.enum";
import {GenericEngagementModel} from "./generic-engagement.model";
import {GenericPaymentConditionModel} from "./generic-payment-condition.model";
import {
    ContractDeliveryCostEnum,
    ContractDeliveryTimeEnum,
    ContractTypeOfRevisionEnum,
    ContractValidityEnum
} from "../enums/contract.enum";
import {GenericAdditionalCostModel} from "./generic-additional-cost.model";
import {ProductModel} from "./product.model";
import {PrestationModel} from "./prestation.model";
import {ContractSustainabilityPriceEnum} from "../enums/contract-sustainability-price.enum";
import {ContractStatusEnum} from "../enums/contract-status.enum";
import {BaseBasketItemModel} from "../base/models/base-basket-item.model";
import {RecurrencePrestationTypeEnum} from "../enums/recurrence-prestation-type.enum";


export const defaultBasket: RecurringBasketModel = {
    products: [],
    prestations: [],
    baskets: [],
    label: '',
    _id: '',
    recurrence: null,
    repeat: 0,
    activated: [],
    startDate: new Date(),
    endDate: new Date(),
    month: null,
    totalHT: 0
};


export class ContractModel {
    _id: string;
    isArchive?: boolean;
    isDelete?: boolean;
    technicalIdentifier: string;
    label: string;
    genericEngagement?: GenericEngagementModel;
    genericEngagementId?: string;
    object: string;
    supplier: SupplierModel;
    supplierContractReference: string;
    supplierContact: SupplierContactModel;
    validity: ContractValidityEnum;
    startValidityDate: Date;
    endValidityDate: Date;
    signatureDate: Date;
    renewalValidity: number;
    minNotice: number;
    paymentCondition: GenericPaymentConditionModel;
    nbDay: number;
    creationStep: number;
    // productIds?: ProductModel[];
    // prestationIds?: PrestationModel[];
    // products: ProductModel[];
    // prestations: PrestationModel[];
    // perimeter Fields
    perimeter: ContractPerimeterModel[];
    status?: ContractStatusEnum;
    displayStatus?: string;
    denunciationDate?: Date;
    noticeDate?: Date;
    renewalDate?: Date;
    renewalNb?: number;
    noticePeriod?: number;
    engagementContact?: UserProfileModel;
    quoteId?: string;


    // CGA Fields
    sustainabilityAndPriceTrans: ContractSustainabilityAndPriceTransModel;
    priceRevision: RecurringBasketModel;
    additionalCosts: ContractAdditionalCostsModel[];
    deliveryCost: ContractDeliveryCostModel;
    specialConditions: string;
    deliveryTime: ContractDeliveryTimeModel;


    //old fields
    quote: QuoteModel;
    contractType: ContractType;
    referenceInterne: string;
    referenceSupplier: string;
    amountHT: number;
    closeDate: Date;
    origin: ContractModel;
    priorNoticeDuration: ValidityModel;
    clientContact: UserProfileModel;
    dueDate: Date;
    savingClauseRef: string;
    savingClauseReason: SavingClauseReasonModel;
    parent: string;
    children: string;
    version: number;
    isValid: boolean;
    establishments: Array<EstablishmentModel>;
    customExtraHtml: string;
    isAttentionRequired: boolean;
    isRealyValidated?: boolean;
    basket: ContractBasketModel;
    recurringBasket: Array<RecurringBasketModel>;
    isFullPerimeter?: boolean;

}

export class RecurringBasketModel {
    _id?: string;
    label: string;
    totalHT: number;
    recurrence: RecurrenceEnum;
    nextRecurrence?: Date;
    totalRecurrence?: number;
    repeat: number;
    activated: number[];
    month: string;
    startDate?: Date;
    endDate?: Date;
    products: Array<BasketProduct>;
    prestation?: PrestationModel;
    prestations: Array<BasketPrestation>;
    establishment?: EstablishmentModel;
    isActivated?: boolean;
    isIntervention?: boolean;
    isAnniversaryDate?: boolean;
    nbIntervention?: number;
    // countIntervention?: number;
    confirmationDelay?: number;
    prestInterventionId?: string;
    prestIntervention?: PrestationModel;
    baskets: BaseBasketItemModel[];
    isNotSendBcToSupplier?: boolean;
    isFullPerimeter?: boolean;
    perimeter?: Array<ContractPerimeterModel>;
    manualPlanification?: Date[];
    recurrencePrestationType?: RecurrencePrestationTypeEnum;
}

export class ContractSustainabilityAndPriceTransModel {
    sustainabilityPrice: ContractSustainabilityPriceEnum;
    typeOfRevision: ContractTypeOfRevisionEnum;
    globalRevision: boolean;
    revisionAmount: number;
    revisionForm: string;
}


export class ContractAdditionalCostsModel {
    additionalCost: GenericAdditionalCostModel;
    numValueHT: number;
    numValueTTC: number;
    type: string;
    additionalInformation: string;
}

export class ContractDeliveryCostModel {
    deliveryCost: ContractDeliveryCostEnum;
    fromValue: number;
    packageValue: number;
}

export class ContractDeliveryTimeModel {
    standardTheoreticalDeliveryValue: number;
    urgentTheoreticalDeliveryValue: number;
    // standardTheoreticalDeliveryFinalizedDate: Date;
    // urgentTheoreticalDeliverFinalizedDate: Date;
    theoreticalDeliveryFinalizedDate: Date;
}

export class ContractBasketModel {
    _id?: string;
    products: BasketProduct[];
    prestations: BasketPrestation[];
}


export class ContractPerimeterModel {

    constructor(establishmentId: string, buildingId?: string, equipmentId?: string, energyIdId?: string) {
        this.establishmentId = establishmentId;
        this.buildingId = buildingId || null;
        this.equipmentId = equipmentId || null;
        this.energyIdId = energyIdId || null;
    }


    establishmentId: string;
    buildingId: string;
    equipmentId: string;
    energyIdId: string;
}
