import {Injectable} from '@angular/core';
import {NotificationModel} from 'src/app/core/models/notification.model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from 'src/app/core/base/base.service';
import {ObjectApiInterface} from '../../../base/interfaces/object-api.interface';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseService<NotificationModel> {
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'notification');
    }

    // You may provide multiple ids. If so, they need to be comma-separated.
    deleteNotifications(ids: string) {
        return this.http.delete(`${this.baseUrlApi}/delete/${ids}`);
    }

    read(notification: NotificationModel) {
        return this.http.put(`${this.baseUrlApi}/read/${notification._id}`, {});
    }
}


