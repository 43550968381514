import {BaseService} from '../../../../core/base/base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EnergyIdModel} from '../../../../core/models/energy-id.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {ContractModel, ContractPerimeterModel} from '../../../../core/models/contract.model';
import {map} from 'rxjs/operators';
import {addWarning} from '@angular-devkit/build-angular/src/utils/webpack-diagnostics';
import {GenericEngagementDataPatrimonyEnum} from '../../../../core/enums/generic-engagement-data-patrimony.enum';

@Injectable({
    providedIn: 'root'
})
export class EnergyIdService extends BaseService<EnergyIdModel>{
    defaultSort = 'createdAt';

    constructor(
        public http: HttpClient,
    ) {
        super(http, 'energy-id');
    }

    findAllWithFullRelation(
        page?: number,
        perPage?: number,
        sortField: string = this.defaultSort,
        sortOrder: string = 'desc',
        search?: string,
        filters?: EnergyIdModel | any
    ): Observable<ObjectApiInterface<EnergyIdModel[]>> {
        return this.http.get<ObjectApiInterface<EnergyIdModel[]>>(`${this.baseUrlApi}/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }


    update(entity: EnergyIdModel): Observable<ObjectApiInterface<EnergyIdModel>> {
        return this.http.put<ObjectApiInterface<EnergyIdModel>>(`${this.baseUrlApi}/update/${entity._id}`, entity).pipe(map(x => {
            // this._entity.next(x.data);
            return x;
        }));
    }

    create(entity: EnergyIdModel): Observable<ObjectApiInterface<EnergyIdModel>> {
        console.log('create', entity);
        return this.http.post<ObjectApiInterface<EnergyIdModel>>(`${this.baseUrlApi}/create`, entity).pipe(map(x => {
            // this._entity.next(x.data);
            return x;
        }));
    }

    importItems(file: File): Observable<ObjectApiInterface<EnergyIdModel[]>> {
        return this.http.post<ObjectApiInterface<EnergyIdModel[]>>(`${this.baseUrlApi}/import`, file).pipe(map(x => {
            // this._entity.next(x.data);
            return x;
        }));
    }

    findPerimetersByEstablishments(entity: ContractModel, data: string[]) {
        return this.http.post<ObjectApiInterface<EnergyIdModel[]>>(`${this.baseUrlApi}/find-by-establishments/${entity._id}`,
            {data}).pipe(map(x => {
            return x.data;
        }));
    }

    transformEnergyIdData(data, perimeters?: ContractPerimeterModel[]) {
        const transformedData = [];

        for (const energyId of data) {
            if (energyId.establishments) {
                for (const building of energyId.buildings) {
                    for (const establishment of building.establishments) {
                        if (!perimeters || perimeters.some(perimeter => establishment._id === perimeter.establishmentId)) {
                            transformedData.push({
                                _id: energyId._id,
                                label: energyId.energyIdId,
                                genericLabel: energyId.genericEnergyId.fieldLabel,
                                genericId: energyId.genericEnergyId._id,
                                establishmentId: establishment._id,
                                establishmentLabel: establishment.label,
                                buildingId: building._id,
                                buildingLabel: building.label,
                            });
                        }
                    }
                }
            }
        }

        transformedData.sort((a, b) => a.genericLabel.localeCompare(b.genericLabel));
        return transformedData;
    }

}
