<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
<!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
<!--            <div>-->
<!--                <h1 class='title mt-0 mb-1'>{{entityName}}</h1>-->
<!--                <vex-breadcrumbs [crumbs]="[entityName]"></vex-breadcrumbs>-->
<!--            </div>-->
<!--        </div>-->
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>
            <!-- PREVIOUS PERMISSION
            [createPermission]="[ENUM_PERMISSION.CREATE_HM_EQUIPMENT]"
            [importPermission]="[ENUM_PERMISSION.IMPORT_HM_EQUIPMENT]"
            -->
            <base-data-table-header
                [label]="entityName"
                [loading]='loading'
                [showArchives]="canShowArchivePermission"
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                (onShowArchived)="onShowArchived($event)"
                (onCreate)='createItem()'
                canImport='true'
                (onImport)='importItems()'
                [popupFilter]='true'
                (clearPopupFilter)='clearPopupFilter()'
                (validatePopupFilter)='validatePopupFilter()'
                [countPopupFilter]='countPopupFilter'
                headerPopupTitle='Filtres avancés'
                [contentPopupTemplate]='contentPopupTemplate'
                [createPermission]="[ENUM_PERMISSION.ADD_EQUIPMENT]"
                [importPermission]="[ENUM_PERMISSION.IMPORT_EQUIPMENT]"
            ></base-data-table-header>

            <!-- PREVIOUS PERMISSION
            [deletePermission]="[ENUM_PERMISSION.DELETE_HM_EQUIPMENT]"
            -->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
                [routerLinkDetailRow]='routerLinkDetailRow'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                (onArchiveItem)='archiveItem($event)'
                [deletePermission]="[ENUM_PERMISSION.INPUT_EQUIPMENT]"
            ></base-data-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm'>
        <base-form-input
            label='Etablissement'
            type='autocomplete'
            [items$]='establishment$'
            (autocompleteChange)='establishmentAutocompleteChange($event)'
            displayProperty='label'
            controlName='establishment'
        >
        </base-form-input>
        <base-form-input
            label='Bâtiment'
            type='autocomplete'
            [items$]='building$'
            (autocompleteChange)='buildingAutocompleteChange($event)'
            displayProperty='label'
            controlName='building'
        >
        </base-form-input>
        <base-form-input
            label='Date de mise en service'
            controlName='commissioningDate'
            type='date'
        >
        </base-form-input>
    </form>
</ng-template>
