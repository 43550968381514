import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormModalBaseComponent} from '../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {EnergyIdService} from '../energy-id.service';
import {EstablishmentService} from '../../establishment/establishment.service';
import {EstablishmentModel} from '../../../../../core/models/establishment.model';
import {ObjectApiInterface} from '../../../../../core/base/interfaces/object-api.interface';
import {BuildingService} from '../../building/building.service';
import {BuildingModel} from '../../../../../core/models/building.model';
import {Observable} from 'rxjs';
import {ResponseTypeEnum} from '../../../../../core/base/enum/response-type.enum';
import {CrudModeEnum} from '../../../../../core/base/enum/crud-mode.enum';
import {EnergyIdModel} from '../../../../../core/models/energy-id.model';
import {map} from 'rxjs/operators';
import {GenericEnergyIdService} from '../../../settings/generic-energy-id/generic-energy-id.service';
import {GenericEnergyIdModel} from '../../../../../core/models/generic-energy-id.model';
import {GenericEnergyIdFormatEnum, GenericEnergyIdRestrictEnum} from '../../../../../core/enums/generic-energy-id.enum';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {SnackbarService} from '../../../../../core/services/snackbar.service';
import {ENUM_PERMISSIONS} from '../../../../../core/enums/permission.enum';
import {AuthService} from '../../../../../core/services/auth.service';

@Component({
    selector: 'vex-energy-id-create-update-delete',
    templateUrl: './energy-id-create-update-delete.component.html',
    styleUrls: ['./energy-id-create-update-delete.component.scss']
})
export class EnergyIdCreateUpdateDeleteComponent extends FormModalBaseComponent<EnergyIdModel> implements OnInit {
    genericEnergyIds$: Observable<GenericEnergyIdModel[]>;
    buildings$: Observable<BuildingModel[]>;
    defaultCustomFieldLabel = 'Choisissez d\'abord un type d\'énergie';
    customFieldLabel = this.defaultCustomFieldLabel;
    energyIdIdCustomError = null;
    isIDEnergyAlreayExist = false;
    itemCtrl = new FormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    isDuplicate = false;

    establishmentsOfBuilging: EstablishmentModel[] = [];
    favoriteBuildings: BuildingModel[] = [];
    uniqueEstablishmentsSet = new Set<EstablishmentModel>();
    isRequireBuilding = false;
    @ViewChild('itemInput') itemInputBuilding: ElementRef<HTMLInputElement>;

    canDownloadTemplateModel = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<EnergyIdModel>>,
                private genericEnergyIdService: GenericEnergyIdService,
                public service: EnergyIdService,
                public establishmentService: EstablishmentService,
                public buildingService: BuildingService,
                private snackBarService: SnackbarService,
                private authService: AuthService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        this.checkPermission();
        super.ngOnInit();
        this._initForm();
        this._initData();
    }

    beforeCreateItem() {
        this.defaults.buildings = this.form.get('buildings').value;
        if (this.defaults.buildings.length <= 0) {
            this.isRequireBuilding = true;
        }
    }

    beforeUpdateItem() {
        super.beforeUpdateItem();
        this.defaults._id = this.data.defaults._id;
        if (this.defaults.buildings.length <= 0) {
            this.isRequireBuilding = true;
        }
    }

    createItem(): void {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeCreateItem();

        const result = this._parseData(this.defaults);
        if (this.form.valid) {
            const sub = this.service.create(this._parseData(this.defaults)).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, result.message);
                }, error => {
                    console.log(error, 'error');
                    if (error.error.statusCode === 5501) {
                        this.isIDEnergyAlreayExist = true;
                        this.energyIdIdCustomError = `Cet ID Energie existe déjà pour ce type d\'énergie`;
                    }

                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            console.log(this.form, 'form');
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem(): void {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeUpdateItem();

        if (this.isUpdateMode() && this.establishmentsOfBuilging.length <= 0) {
            this.data.establishments = this.defaults.establishments?.map(establishment => establishment._id) ?? null;
            this.establishmentsOfBuilging = this.defaults.establishments;
            this.establishmentsOfBuilging.length === 0 ? this.establishmentsOfBuilging = this.data.establishments : null;
        }

        if (this.form.valid && this.form.get('buildings').value.length > 0) {
            const sub = this.service.update(this._parseData(this.defaults)).subscribe(
                result => {
                    this.afterUpdateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Update, result.message);
                }, error => {

                    if (error.error.statusCode === 5501) {
                        this.isIDEnergyAlreayExist = true;
                        this.energyIdIdCustomError = `Cet ID Energie existe déjà pour ce type d\'énergie`;
                    }

                    this.afterUpdateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }


    importItems() {

        const isImporting = true;
        const importStartTime = Date.now();

        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.service.importItems(this.importDefaults).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });

            setTimeout(() => {
                if (isImporting && !sub.closed) {
                    const importDuration = Date.now() - importStartTime;
                    if (importDuration >= 2000) {
                        this.snackbarService.warning('L\'importation peut prendre quelques secondes. Veuillez patienter.', 15000);
                    }
                }
            }, 2000);

            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    onFormInvalid() {
        super.onFormInvalid();

    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    private _initForm() {
        if (this.isCreateMode()) {
            this.form = new FormGroup({
                genericEnergyId: new FormControl(null, Validators.required),
                energyIdId: new FormControl(null, Validators.required),
                buildings: new FormControl([], Validators.minLength(1)),
                establishments: new FormControl([]),
                favoriteBuilding: new FormControl(null, Validators.required)
            });
        }
        if (this.isUpdateMode()) {
            this.form = new FormGroup({
                genericEnergyId: new FormControl(this.defaults.genericEnergyId ? this.defaults.genericEnergyId : null, Validators.required),
                energyIdId: new FormControl(this.defaults.energyIdId ? this.defaults.energyIdId : null, Validators.required),
                buildings: new FormControl(this.defaults.buildings ? this.defaults.buildings : [], Validators.minLength(1)),
                establishments: new FormControl(this.defaults.establishments ? this.defaults.establishments : [], Validators.minLength(1)),
                favoriteBuilding: new FormControl(this.defaults.favoriteBuilding ? this.defaults.favoriteBuilding : null, Validators.required)
            });

            this.itemCtrl.setValue(this.defaults.buildings);
            this.form.get('genericEnergyId').valueChanges.subscribe(val => {
                this.form.get('energyIdId').setValue(null);
            });

            this.favoriteBuildings = this.form.get('buildings').value;

        }

        // we disable energyIdId by default because it will be enabled when genericEnergyId is chosen
        if (this.isCreateMode()) {
            this.getFormControl('energyIdId').disable({
                onlySelf: true,
                emitEvent: false
            });
        }

        if (this.isUpdateMode()) {
            this.getFormControl('energyIdId').enable({
                onlySelf: true,
                emitEvent: false
            });
        }


        // Trigger events based on changes of form
        this.subscription.add(
            this.form.valueChanges.subscribe(val => {
                const genericEnergyId: GenericEnergyIdModel = val?.genericEnergyId;
                this.isIDEnergyAlreayExist = false;

                if (genericEnergyId && this.getFormControl('genericEnergyId')?.valid) {
                    const energyIdIdFormControl: AbstractControl = this.getFormControl('energyIdId');

                    // Set custom field label depending on chosen generic energy id
                    this.customFieldLabel = val.genericEnergyId.fieldLabel;

                    // Then set the validators depending on the generic energy id chosen
                    const validators: ValidatorFn[] = [];

                    if (genericEnergyId.format !== GenericEnergyIdFormatEnum.NO_RESTRICTION) {
                        this.energyIdIdCustomError = null;

                        switch (genericEnergyId.restrict) {

                            case GenericEnergyIdRestrictEnum.FIXED:
                                validators.push(Validators.minLength(genericEnergyId.charactersNumber));
                                validators.push(Validators.maxLength(genericEnergyId.charactersNumber));
                                validators.push(Validators.required);
                                this.energyIdIdCustomError = `Le format de l'identifiant doit être de ${genericEnergyId.charactersNumber} caractères`;
                                break;
                            case GenericEnergyIdRestrictEnum.MAX:
                                validators.push(Validators.maxLength(genericEnergyId.charactersNumber));
                                validators.push(Validators.required);
                                this.energyIdIdCustomError = `Le format de l'identifiant doit être de maximum ${genericEnergyId.charactersNumber} caractères`;
                                break;
                        }

                        switch (genericEnergyId.format) {
                            case GenericEnergyIdFormatEnum.ALPHANUMERIC:
                                console.log('ALPHANUMERIC');
                                validators.push(Validators.pattern('^[a-zA-Z0-9]+$'));
                                this.energyIdIdCustomError += '\nL\'identifiant doit être alphanumérique';
                                break;
                            case GenericEnergyIdFormatEnum.NUMERIC:
                                console.log('NUMERIC');
                                validators.push(Validators.pattern('^[0-9]+$'));
                                this.energyIdIdCustomError += '\nL\'identifiant doit être numérique';
                                break;
                        }

                        energyIdIdFormControl.setValidators(validators);
                        energyIdIdFormControl.updateValueAndValidity({
                            onlySelf: true,
                            emitEvent: false
                        });
                    }

                    // Finally we enable the field
                    energyIdIdFormControl.enable({
                        onlySelf: true,
                        emitEvent: false
                    });
                } else {
                    // If for some reason the generic energy id is unselected, we make sure that energyIdId is reset
                    const energyIdIdFormControl: AbstractControl = this.getFormControl('energyIdId');

                    // First we disable it
                    energyIdIdFormControl.disable({
                        onlySelf: true,
                        emitEvent: false
                    });

                    // Then we reset its label, validators and value
                    this.customFieldLabel = this.defaultCustomFieldLabel;
                    energyIdIdFormControl.clearValidators();
                    energyIdIdFormControl.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false
                    });
                    energyIdIdFormControl.setValue(null, {
                        onlySelf: true,
                        emitEvent: false
                    });
                }
            })
        );
    }

    getFormControl(controlName: string): AbstractControl {
        return this.form.get(controlName);
    }

    private _initData() {
        this.genericEnergyIds$ = this.genericEnergyIdService.findAll().pipe(
            map((x: ObjectApiInterface<GenericEnergyIdModel[]>) => x.data)
        );

        this.buildings$ = this.buildingService.findAll(
            null,
            null,
            'label',
            'asc',
            null,
            {
                onlyMine: true,
                excludeItemsWithouEstablishments: true,
                excludeIds: this.form.get('buildings').value?.map(building => building._id.toString())
            }
        ).pipe(
            map((x: ObjectApiInterface<BuildingModel[]>) => x.data)
        );
    }

    private _parseData(defaults: EnergyIdModel) {


        // this.establishmentsOfBuilging?.forEach(establishment => {
        //     this.uniqueEstablishmentsSet.add(establishment);
        // });

        const data: any = {
            genericEnergyId: defaults.genericEnergyId._id,
            energyIdId: defaults.energyIdId,
            buildings: defaults.buildings?.map(building => building._id) ?? null,
            //   establishments: Array.from(this.uniqueEstablishmentsSet).map(establishment => establishment._id) ?? null
            establishments: defaults.establishments?.map(establishment => establishment._id) ?? null,
            favoriteBuilding: defaults.favoriteBuilding
        };

        console.log(data, 'data');

        this.isUpdateMode() ? data._id = defaults._id : null;
        return data;
    }

    genericEnergyIdsAutoCompleteChange(state: any) {
        this.genericEnergyIds$ = this.genericEnergyIdService.findAll(null,
            null,
            null,
            null,
            state
        ).pipe(map((x: ObjectApiInterface<GenericEnergyIdModel[]>) => x.data));
    }


    // mat chips for buildings

    async remove(item: any) {

        if (this.isCreateMode()) {

            const index = this.form.get('buildings').value.indexOf(item);
            if (index >= 0) {
                this.form.get('buildings').value.splice(index, 1);
                //  this.form.get('establishments').value.splice(index, 1);

                if (item.establishments.length > 1) {
                    this.form.get('establishments').value.splice(index, item.establishments.length);
                } else {
                    this.form.get('establishments').value.splice(index, 1);
                }
            }

        }

        if (this.isUpdateMode()) {
            const index = this.form.get('buildings').value.indexOf(item);

            if (index >= 0) {
                this.form.get('buildings').value.splice(index, 1);
            }

            const totalEstablishementsArrayByBuildings = [];
            const buildingArray = this.form.get('buildings').value;

            buildingArray.map(building => {
                totalEstablishementsArrayByBuildings.push(building.establishments.map(x => x._id));
            });

            const establishementsArrayIdsOfItem = item.establishments.map(establishment => establishment._id);

            this.form.get(('establishments')).value.forEach(establishment => {
                if (establishementsArrayIdsOfItem.includes(establishment._id) && !totalEstablishementsArrayByBuildings.flat().some(id => establishementsArrayIdsOfItem.includes(id))) {
                    console.log('Oui suppression OK');
                    const indexEstablishment = this.form.get(('establishments')).value.indexOf(establishment);
                    this.form.get(('establishments')).value.splice(indexEstablishment, 1);
                } else {
                    console.log('non pas de suppression');
                }
            });

        }

        const establishmentFormControl = this.getFormControl('establishments');
        establishmentFormControl.setValue(this.form.get(('establishments')).value);
        establishmentFormControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false
        });

        this.form.get('favoriteBuilding').setValue(null);
        this.form.get('favoriteBuilding').updateValueAndValidity({
            onlySelf: true,
            emitEvent: false
        });

    }

// async remove(building: BuildingModel) {
//
//     const buildingArray = this.form.get('buildings').value;
//     const buildingArrayIds = buildingArray.map(building => building._id);
//     let totalEstablishments = [];
//
//     await this.buildingService.findAll(null, null, null, null, null, {getBuildingsIds: buildingArrayIds}).subscribe(x => {
//         x.data.map(establishment => {
//             totalEstablishments = establishment.establishments.map(establishment => establishment._id);
//             console.log(totalEstablishments, "ttoal establishements des builings ")
//
//
//         });
//     });
//
//     const establishmentsArray = this.form.get('establishments').value;
//     const establishementsArrayIds = establishmentsArray.map(establishment => establishment._id);
//     console.log(establishementsArrayIds, "establishmentsArrayIds des establissmetnd dans lecham petablissemtn ")
//
//
//     const index = buildingArray.indexOf(building);
//     console.log(building, 'gerto')
//     if (index >= 0) {
//         buildingArray.splice(index, 1);
//     }
//
//
//
//     // là il est sencé m'en renvoyer le nomrbe de batiment
//
//     // ensuite je recueprer à chacun leur etablissemtn
//
//     // je ceck cette list totale detablissement,, si j'en supprime un et quil ya u nduplicat ce dernier n'est pas supprimé
//     // sinon sil ets unique et quil est supprimé alors il est suprimé poru de bon dans l'itnerface
//
//
//     const establishmentFormControl = this.getFormControl('establishments');
//     establishmentFormControl.setValue(this.establishmentsOfBuilging);
//     establishmentFormControl.updateValueAndValidity({
//         onlySelf: true,
//         emitEvent: false
//     });
// }

    onKeyDown(event
                  :
                  KeyboardEvent
    ):
        void {
        if (event.key === 'Enter'
        ) {
            event.preventDefault();
        }
    }

    onAutocompleteInput(event
                            :
                            Event
    ):
        void {
        const inputValue = (event?.target as HTMLInputElement)?.value;
        this.buildings$ = this.buildingService.findAll(null, null, 'label', 'asc', inputValue, {
            onlyMine: true,
            excludeItemsWithouEstablishments: true,
            excludeIds: this.form.get('buildings').value?.map(building => building._id.toString())
        }).pipe(
            map((x: ObjectApiInterface<BuildingModel[]>) => x.data)
        );
    }

    selected(event
                 :
                 MatAutocompleteSelectedEvent
    ):
        void {
        if (this.isUpdateMode()
        ) {
            this.establishmentsOfBuilging = this.form.get('establishments').value;
        }

        this.isDuplicate = false;
        const selectedBuilding = event.option.value;
        const buildingArray = this.form.get('buildings').value;


        const duplicateIndex = buildingArray.findIndex(building => building._id === selectedBuilding._id);
        const EstblishmentIndexNull = selectedBuilding.establishments.length === 0;

        if (duplicateIndex !== -1) {
            buildingArray[duplicateIndex] = selectedBuilding;
            this.isDuplicate = true;
        }
        if (EstblishmentIndexNull) {
            this.snackBarService.danger('Ce bâtiment ne contient aucun établissement');
        }
        if (duplicateIndex === -1 && !EstblishmentIndexNull) {
            buildingArray.push(selectedBuilding);
        }

        this.itemInputBuilding.nativeElement.value = '';
        this.itemCtrl.setValue(null);

// code prévu pour vérifier et eliminer les duplicates au moment de l'ajout :

//   let isEstablishmentAlreadyExist = false;
//     const selectedBuildingsEstablishmentIds = selectedBuilding.establishments.map(establishment => establishment._id);
//
// this.establishmentsOfBuilging.forEach(establishment => {
//     if (selectedBuildingsEstablishmentIds.includes(establishment._id)) {
//
//         console.log("duplicat d'etablissement pour :", establishment.label);
//         isEstablishmentAlreadyExist = true;
//     }
// });
//
//    if (!isEstablishmentAlreadyExist) {
// selectedBuilding.establishments.forEach(establishment => {
//     this.establishmentsOfBuilging.push(establishment);
// });
//     }


        selectedBuilding?.establishments.forEach(establishment => {
            if (!this.isDuplicate) {
                this.establishmentsOfBuilging.push(establishment);
            }

        });


        const establishmentFormControl = this.getFormControl('establishments');
        establishmentFormControl.setValue(this.establishmentsOfBuilging);

        establishmentFormControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false
        });

        this.isRequireBuilding = false;
        this.favoriteBuildings = buildingArray;

        this.onAutocompleteInput(null);
    }

    checkPermission() {
        this.subscription.add(
            this.authService.getCurrentUserPermissions$().subscribe(permissions => {
                if (permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_ENERGY_ID)) {
                    this.canDownloadTemplateModel = true;
                }

            })
        );
    }

    genericBuildingsAutoCompleteChange(state: any) {
        this.buildings$ = this.buildingService.findAll(null,
            null,
            null,
            null,
            state
        ).pipe(map((x: ObjectApiInterface<BuildingModel[]>) => x.data));
    }
}
