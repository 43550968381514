export enum ContractStatusEnum {
    DRAFT = 'DRAFT',
    VALIDATED = 'VALIDATED',
    EXPIRED = 'EXPIRED',
}


export class ContractStatusTranslation {
    static translate(status: ContractStatusEnum): string {
        switch (status) {
            case ContractStatusEnum.DRAFT:
                return 'Brouillon';
            case ContractStatusEnum.VALIDATED:
                return 'Actif';
            case ContractStatusEnum.EXPIRED:
                return 'Echu';
            default:
                return null;
        }
    }
}

export const contractStatusTranslation = (status: ContractStatusEnum) => {
    switch (status) {
        case ContractStatusEnum.DRAFT.toString():
            return 'Brouillon';
            break;
        case ContractStatusEnum.VALIDATED.toString():
            return 'Actif';
        case ContractStatusEnum.EXPIRED:
            return 'Echu';
            break;
        default:
            return null;
    }
};

export const contractStatusTranslationObject = (status: ContractStatusEnum) => {
    return {
        value: status,
        label: ContractStatusTranslation.translate(status)
    };
};
