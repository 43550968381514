import {Component, Inject, LOCALE_ID, OnInit, Renderer2} from '@angular/core';
import {ConfigService} from '../@vex/services/config.service';
import {Settings} from 'luxon';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {NavigationService} from '../@vex/services/navigation.service';
import {LayoutService} from '../@vex/services/layout.service';
import {ActivatedRoute} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SplashScreenService} from '../@vex/services/splash-screen.service';
import {Style, StyleService} from '../@vex/services/style.service';
import {ConfigName} from '../@vex/interfaces/config-name.model';
import {AuthService} from './core/services/auth.service';
import {
    budgetReport,
    businessModulesManagement,
    clientSettings,
    //documentItems,
    financialManagement,
    functionalManagement,
    genericAdministration,
    heritageManagement,
    internalRessourcesManagement,
    itemsAdmin,
    itemsCore,
    predikSettingsTenant,
    qualityReport,
    ticketHome
} from './core/utils/nav-items';
import {UserProfileMode} from './core/enums/user-profile.mode.enum';
import {Observable} from 'rxjs';
import {UserProfileModel} from './core/models/user-profile.model';
import moment from "moment";
import {ENUM_PERMISSIONS} from "./core/enums/permission.enum";
import {CustomerGroupService} from "./pages/application/settings/customer-group/customer-group.service";
import {CustomerGroupModel} from "./core/models/customer-group.model";
import {NavigationItem} from "../@vex/interfaces/navigation-item.interface";


@Component({
    selector: 'vex-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'predik360';

    user$: Observable<UserProfileModel>;
    UserProfileMode = UserProfileMode;
    tenant: CustomerGroupModel;
    financialManagementWithGed: NavigationItem[] = financialManagement;

    //documentItemsWithGed: NavigationItem[] = documentItems;

    constructor(private configService: ConfigService,
                private styleService: StyleService,
                private renderer: Renderer2,
                private platform: Platform,
                @Inject(DOCUMENT) private document: Document,
                @Inject(LOCALE_ID) private localeId: string,
                private layoutService: LayoutService,
                private route: ActivatedRoute,
                private navigationService: NavigationService,
                private splashScreenService: SplashScreenService,
                private customerGroupService: CustomerGroupService,
                private authService: AuthService,
    ) {
        Settings.defaultLocale = this.localeId;

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        /**
         * Customize the template to your needs with the ConfigService
         * Example:
         *  this.configService.updateConfig({
         *    sidenav: {
         *      title: 'Custom App',
         *      imageUrl: '//placehold.it/100x100',
         *      showCollapsePin: false
         *    },
         *    showConfigButton: false,
         *    footer: {
         *      visible: false
         *    }
         *  });
         */

        /**
         * Config Related Subscriptions
         * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
         * Example: example.com/?layout=apollo&style=default
         */


        this.route.queryParamMap.pipe(
            map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
        ).subscribe(isRtl => {
            this.document.body.dir = isRtl ? 'rtl' : 'ltr';
            this.configService.updateConfig({
                rtl: isRtl
            });
        });

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('layout'))
        ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('style'))
        ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

        this.user$ = this.authService.currentUserValue$();
        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('layout'))
        ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));


    }

    recursiveFilter(parent, mergedUserPermissions) {
        if (parent.children) {
            parent.children = parent.children.filter(x => (x.permission && mergedUserPermissions.includes(x.permission)) || !x.permission).map(child => {
                return this.recursiveFilter(child, mergedUserPermissions);
            });
        }

        return parent;
    }

    recursiveFilterEmptyChildren(nav) {
        if (nav.children) {
            nav.children = nav.children.filter(x => !x.children || (x.children && x.children.length !== 0)).map(child => {
                return this.recursiveFilterEmptyChildren(child);
            });
        }
        return nav;
    }


    async ngOnInit() {
        await this.authService.updateLoggedInStatus();
        // ENERGISME URL SET UP
        const functionalManagementWithEnergisme = functionalManagement.map(f => {
            // @ts-ignore
            f.children?.find(c => c.label === 'Performance Energétique')?.children.find(c => c.label === 'Gestion des énergies').route = () => window.open(this.configService.env.energismeUrl, '_blank');
            return f;
        });

        const businessModulesWithEnergisme = businessModulesManagement.map(f => {
            // @ts-ignore
            f.children?.find(c => c.label === 'Performance Energétique')?.children.find(c => c.label === 'Gestion des énergies').route = () => window.open(this.configService.env.energismeUrl, '_blank');
            return f;
        });

        // GED URL SET UP


        this.authService.currentUserCoreValue().subscribe();
        this.customerGroupService.getCurrent().subscribe(tenant => {
            this.tenant = tenant.data;
            this.financialManagementWithGed = financialManagement.map(f => {
                // @ts-ignore
                f.children?.find(c => c.label === 'Achats')?.children.find(c => c.label === 'GED').route = () => window.open(this.tenant.SSOUrl, '_blank');
                return f;
            });
            // this.documentItemsWithGed = documentItems.map(f => {
            //     // @ts-ignore
            //     f.children?.find(c => c.label === 'Accéder aux documents').route = () => window.open(this.tenant.SSOUrl, '_blank');
            //     return f;
            // });
        });

        this.authService.currentUser.subscribe(user => {
                //clear this.navigationService.items
                this.navigationService.items = [];

                if (user) {
                    let permissions = new Set();
                    const currentDate = moment().toDate();
                    const userPermission = user.roles?.filter(role => ((moment(role.start).startOf('day').toDate() < currentDate) && (moment(role.end).endOf('day').toDate() > currentDate)) || (!role.start && !role.end)).map(x => x._id.permissions.map((permission) => permission.permission));
                    userPermission?.forEach(p => {
                        p?.forEach(x => {
                            permissions.add(x);
                        });
                    });


                    const mergedUserPermissions = [...permissions];
                    if (user.mode === UserProfileMode.CORE) {
                        this.navigationService.items = itemsCore;
                    } else {
                        clientSettings[0]?.children.push(...predikSettingsTenant[0].children);


                        // if environnement variable APPLICATION_ENVIRONMENT is set to prod or prep fiilter all italic subheading or children
                        let arrayOfNavItems = [itemsAdmin, ticketHome, clientSettings, heritageManagement, internalRessourcesManagement, this.financialManagementWithGed, qualityReport, budgetReport];

                        if (this.configService.env.applicationEnvironment === 'production' || this.configService.env.applicationEnvironment === 'prep' || this.configService.env.applicationEnvironment === 'formation') {

                            arrayOfNavItems = arrayOfNavItems.filter(x => x[0].italic === false || !x[0].italic);

                            arrayOfNavItems.map(navItem => {
                                navItem[0].children = navItem[0].children?.filter(x => x.italic === false || !x.italic);
                            });

                        }


                        // const arrayOfNavItems = [itemsAdmin, ticketHome, clientSettings, heritageManagement, this.financialManagementWithGed, qualityReport, this.documentItemsWithGed];

                        arrayOfNavItems.forEach(navItem => {
                            let items = navItem.filter(x => (x.permission && mergedUserPermissions.includes(x.permission)) || !x.permission).map(settings => {

                                return this.recursiveFilterEmptyChildren(this.recursiveFilter(settings, mergedUserPermissions));


                                // foreach filteredNav if children is empty remove children

                                // if (settings.children) {
                                //     settings.children = settings.children.filter(x => x.permission && mergedUserPermissions.includes(x.permission)).map(child => {
                                //         if (child.children) {
                                //             child.children = child.children.filter(x => x.permission && mergedUserPermissions.includes(x.permission)).map(subChild => {
                                //                 if (subChild.children) {
                                //                     subChild.children = subChild.children.filter(x => x.permission && mergedUserPermissions.includes(x.permission));
                                //                 }
                                //                 return subChild;
                                //             });
                                //         }
                                //         return child;
                                //     })
                                // }
                                // return settings;
                            });


                            if (user.isPredik) {
                                items = items.map(item => {
                                    if (item.label === 'Paramétrage Client') {
                                        if (mergedUserPermissions.includes(genericAdministration.permission)) {
                                            item.children.push(genericAdministration);
                                        }
                                    }
                                    return item;
                                });
                            }

                            // If user is not predik, we remove here all italic subheading or children - temporarily (PRED-1478 - Rob)
                            if (!user.isPredik) {
                                items = items.map(item => {
                                    if (item.children) {
                                        item.children = item.children.filter(child => !child.italic);
                                    }
                                    return item;
                                });
                            }
                            if (!user.isPredik) {
                                items = items.map(item => {
                                    if (item.children) {
                                        item.children = item.children.filter(child => !child.italic);
                                    }
                                    return item;
                                });
                            }

                            this.navigationService.items.push(...items);

                            // remove duplicate in all this.navigationService.items[].children
                            this.navigationService.items = this.navigationService.items.map(item => {
                                if (item.children) {
                                    item.children = item.children.filter((thing, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.label === thing.label
                                            ))
                                    );
                                }
                                return item;

                            });

                            //-------------------------- WORKAROUND --------------------------
                            // TODO: Check on many permission !
                            // WE CAN FIND BETTER WAY
                            this.navigationService.items = this.navigationService.items.filter(x => {
                                if ((x.type === 'subheading') && (!x.children || x.children.length === 0)) return false;
                                if (x.permissions && (x.permissions.length > 0)) return this.checkManyPermission(mergedUserPermissions as any, x.permissions);
                                return true;
                            });
                        });
                    }
                }
            }
        );
    }

    checkManyPermission(permissions: ENUM_PERMISSIONS[], permissionToCheck: ENUM_PERMISSIONS[]): boolean {
        for (const permission of permissionToCheck) {
            if (permissions.includes(permission)) return true;
        }
        return false;
    }
}
