<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
<!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
<!--            <div>-->
<!--                <h1 class='title mt-0 mb-1'>Articles</h1>-->
<!--                <vex-breadcrumbs [crumbs]="['Gestions','Articles']"></vex-breadcrumbs>-->
<!--            </div>-->
<!--        </div>-->
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>
            <!-- PREVIOUS PERMISSION
            [createPermission]="[ENUM_PERMISSION.CREATE_FM_BF_PRODUCT]"
            [importPermission]="[ENUM_PERMISSION.IMPORT_FM_BF_PRODUCT]"
            -->

<!--            PRED-1278 : juste en readOnly : Ne peut plus modifier ajouter ou importer -->

            <base-data-table-header
                label='Articles'
                [loading]='loading'
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                (onCreate)='createItem()'
                [canImport]='false'
                (onImport)='importItems()'
                [canCreate]='false'
                [showArchives]="canShowArchive"
                (onShowArchived)="onShowArchived($event)"
                [showExpires]="false"
                (onShowExpired)="onShowExpired($event)"
                [createPermission]="[ENUM_PERMISSION.ADD_FM_REF_PURCHASE]"
                [importPermission]="[ENUM_PERMISSION.IMPORT_FM_REF_PURCHASE]"
            ></base-data-table-header>

            <!-- PREVIOUS PERMISSION
            [deletePermission]="[ENUM_PERMISSION.DELETE_FM_BF_PRODUCT]"
            [updatePermission]="[ENUM_PERMISSION.UPDATE_FM_BF_PRODUCT_INFO]"
            -->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                (onArchiveItem)="archiveItem($event)"
                (onRowClick)='rowClickItem($event)'
                [customColumnTemplate]="templateRef"
                [deletePermission]="[ENUM_PERMISSION.DELETE_FM_REF_PURCHASE]"
                [updatePermission]="[ENUM_PERMISSION.UPDATE_FM_REF_PURCHASE_INFO]"
                [actionText]='actionText'
                [isProductPrestation]="true"
            ></base-data-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm'>
        <base-form-input
            label='Fournisseur'
            type='autocomplete'
            [items$]='suppliers$'
            displayProperty='label'
            controlName='supplier'
            (autocompleteChange)='supplierAutocompleteChange($event)'
        >
        </base-form-input>

        <base-form-input
            label='Article générique'
            type='autocomplete'
            [items$]='genericProducts$'
            displayProperty='label'
            controlName='genericProduct'
            (autocompleteChange)='genericProductAutocompleteChange($event)'
        >
        </base-form-input>

    </form>
</ng-template>


<ng-template #templateRef let-column let-row="element" let-status="status">

<!--  <ng-container *ngIf='type === "booleanQuote" && showSort' [matColumnDef]='column'>-->
<!--    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell mat-sort-header>-->
<!--      {{ label }}-->
<!--    </th>-->
<!--    <td *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"-->
<!--        [routerLink]='canAccess() ? routerLink(element) : null'-->
<!--        class="text-secondary font-medium"-->
<!--        mat-cell>-->

<!--      <button *ngIf="elementIsTrue(element)"-->
<!--              matTooltip="Devis"-->
<!--              matTooltipClass="example-tooltip-uppercase" c-->
<!--      >-->
<!--        <mat-icon>description</mat-icon>-->
<!--      </button>-->

<!--      <button *ngIf="!elementIsTrue(element)"-->
<!--              [matTooltip]="element?.contract?.technicalIdentifier"-->
<!--              matTooltipClass="example-tooltip-uppercase"-->
<!--      >-->
<!--        <mat-icon color="primary">assignment</mat-icon>-->
<!--      </button>-->
<!--    </td>-->
<!--  </ng-container>-->

  <div *ngIf="column === 'quoteId'" class="relative flex items-center" >

          <button [matTooltip]="row.quoteId ? row?.quote?.technicalIdentifier : row?.contract?.technicalIdentifier"
                  (click)="redirectToElement(row)"
                  matTooltipClass="example-tooltip-uppercase"
          >
            <mat-icon color="{{row.quoteId ? 'secondary' : 'primary' }}">{{row.quoteId ? 'description' : 'assignment' }}</mat-icon>
          </button>
  </div>

    <div *ngIf="column === 'label'" class="relative flex items-center" (mouseenter)="showComment = row._id" (mouseleave)="showComment = null">
        <span>{{ row.label }}</span>
        <div [hidden]="showComment !== row._id" class="absolute bg-white z-10 top-0 translate-y-5">
            <div *ngIf="row.comment" class="p-2 bg-gray-100 border rounded shadow-lg max-w-3xl overflow-auto" [innerHTML]="row.comment"></div>
            <div *ngIf="!row.comment" class="p-2 bg-gray-100 border rounded shadow-lg">Aucune description</div>
        </div>
    </div>

    <div *ngIf="column === 'tva'" class="relative flex items-center" (mouseenter)="showComment = row._id" (mouseleave)="showComment = null">
        <span>{{ getTvaRate(row.tva) }}</span>
    </div>

    <div *ngIf="column === 'reference'" class="relative flex items-center" (mouseenter)="showPreviousReference = row._id" (mouseleave)="showPreviousReference = null">
        <span>{{ row.reference }}</span>
        <div [hidden]="showPreviousReference !== row._id" class="absolute bg-white z-10 top-0 translate-y-5">
            <div *ngIf="row.previousReference" class="p-2 bg-gray-100 border rounded shadow-lg max-w-3xl overflow-auto" [innerHTML]="row.previousReference"></div>
        </div>
    </div>

</ng-template>
