import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounce, Subject, Subscription } from 'rxjs';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import icDone from '@iconify/icons-ic/twotone-done';
import icSearch from '@iconify/icons-ic/twotone-search';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icAdd from '@iconify/icons-ic/twotone-add';
import icClose from '@iconify/icons-ic/twotone-close';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icEdit from '@iconify/icons-ic/twotone-edit';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BaseService } from '../../base.service';
import { FeiColumn } from '../../interfaces/fei-column.interface';
import { ENUM_PERMISSIONS } from '../../../enums/permission.enum';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    template: '',
    animations: [
        fadeInUp400ms,
        stagger40ms
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'standard'
            } as MatFormFieldDefaultOptions
        }
    ]
})

export class DataTableBase<T> implements OnInit, OnDestroy {
    ENUM_PERMISSION = ENUM_PERMISSIONS;
    icFilterList = icFilterList;
    icAdd = icAdd;
    icClose = icClose;
    icDone = icDone;
    icMoreVert = icMoreVert;
    icDelete = icDelete;
    icEdit = icEdit;
    icSearch = icSearch;
    searchCtrl = new FormControl();
    loading = false;
    dataSource = new MatTableDataSource<T>();
    sortField: string;
    sortOrder: string;
    pageSize = 10;
    count = 0;
    displayedColumns = ['actions'];
    feisColumns: Array<FeiColumn>;
    routerLinkDetailRow = ':id';
    selectedItem: T;
    isArchive = false;
    isExpired = false;
    service: BaseService<T>;
    isLoading = false;
    filters: T | any = {};
    popupForm: FormGroup = new FormGroup({});
    countPopupFilter = 0;
    protected subscription = new Subscription();

    private searchSubject: Subject<string> = new Subject();
    private searchSubscription: any;
    pageIndex = 1;
    search = '';
    constructor() {
    }

    ngOnInit() {
        this.searchSubscription = this.searchSubject.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchTerm => {
            this.pageIndex = 1;
            this.search = searchTerm;
            this.initData();
        });
        this.initData();
    }

    initData() {
        this.loading = true;
        const items = this.service
            .findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, this.filters)
            .subscribe(res => {
            if (!this.dataSource) {
                this.dataSource = new MatTableDataSource<T>(res.data);
            } else {
                this.dataSource.data = res.data;
            }
            this.count = res.totalData;
            this.loading = false;
        });

        this.subscription.add(items);
    }

    pageEvent(event: PageEvent): void {
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.initData();
    }

    sortEvent(event: Sort): void {
        const propertyDisplay = this.feisColumns.find(x => x.column === event.active);
        this.sortField = propertyDisplay ? propertyDisplay.propertyDisplay : event.active;
        this.sortField = propertyDisplay.propertyDisplay ? propertyDisplay.propertyDisplay : event.active;
        this.sortOrder = event.direction;
        this.initData();
    }

    ngOnDestroy(): void {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        this.subscription.unsubscribe();
    }

    clearTextFilter() {
        this.search = '';
        this.initData();
    }

    onSearchChange(event) {
        this.searchSubject.next(event);
    }

    onShowArchived(event) {
        this.isArchive = event;
        this.filters = {...this.filters, isArchive: event};
        this.initData();
    }

    onShowExpired(event) {
        this.isExpired = event;
        this.filters = {...this.filters, isExpired: event};
        this.initData();
    }


    onSynchronization(event) {
        this.isLoading = true; // Affichez le loader

        const items = this.service.synchronizeDocuware().subscribe(
            res => {
                // Réponse réussie

                this.isLoading = false; // Masquez le loader
            },
            err => {
                // Gérer les erreurs

                // this.snackbarService.danger('Erreur lors de la synchronisation !');
                this.isLoading = false; // Masquez le loader
            }
        );

        this.subscription.add(items);
    }

    createItem(): void {
        //create logic
    }

    updateItem(element: T): void {
        //update logic
    }

    deleteItem(element: T): void {
        //delete logic
    }

    archiveItem(element: T): void {
        //archive logic
    }

    clearPopupFilter(): void {
        //clear popup filter logic
        this.popupForm.reset();
        if (Object.keys(this.filters).length > 0) {
            this.filters = {};
            this.countPopupFilter = 0;
            this.initData();
        }
    }

    beforeValidatePopupFilter(): void {
        //remap filters objet before send request
    }

    validatePopupFilter(): void {
        //validator popup filter logic
        this.filters = {...this.popupForm.value, isArchive: this.isArchive};
        this.beforeValidatePopupFilter();
        this.countPopupFilter = Object.entries(this.popupForm.value).filter((entrie: any) => {
            if (entrie[1] && typeof entrie[1] === 'object' && entrie[1].start !== null) {
                return entrie[1];
            }
            if (entrie[1] && typeof entrie[1] !== 'object') {
                return entrie[1];
            }
        }).length;
        // this.pageIndex = 1;
        this.initData();
    }

    // selectItem(element: T) {
    //     this.selectedItem = element;
    // }
}
