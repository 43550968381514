<ng-container *ngIf="isCreateMode() || isUpdateMode() || isReadMode()">
    <div class="flex flex-row">

        <form (ngSubmit)="save()" [formGroup]="form">
            <base-form-header [mode]='mode' createLabel="Ajouter une prestation au contrat"
                              [updateLabel]='form?.get("reference")?.value'></base-form-header>

            <div class="flex">
                <mat-dialog-content fxLayout="column">
                    <base-form-input
                            label='Prestation Générique'
                            type='autocomplete'
                            [items$]='genericPrestations$'
                            [disabled]='isReadMode()'
                            (autocompleteChange)='genericPrestationAutocompleteChange($event)'
                            displayProperty='label'
                            controlName='genericPrestation'>
                    </base-form-input>

                    <base-form-input
                            [disabled]='isReadMode()'
                            label='Référence fournisseur'
                            controlName='reference'>
                    </base-form-input>
                    <base-form-input
                            [disabled]='isReadMode()'
                            label='Réf. précédente'
                            controlName='previousReference'>
                    </base-form-input>
                    <base-form-input
                            [disabled]='isReadMode()'
                            label='Libellé'
                            controlName='label'>
                    </base-form-input>
                    <base-form-input
                            label='Taux TVA'
                            type='autocomplete'
                            [items$]='tva$'
                            [disabled]='isReadMode()'
                            (autocompleteChange)="tvaAutocompleteChange($event)"
                            displayProperty="genericTva.label, genericTva.rateList"
                            [displayPropertyObject]="getTvaRate.bind(this)"
                            controlName='tva'>
                    </base-form-input>

                    <base-form-input
                            *ngIf="isUpdateMode()"
                            label="Compte comptable"
                            type="select"
                            [items]="accountingAccounts"
                            displayProperty="display"
                            controlName="accountingAccount">
                    </base-form-input>

                    <base-form-input
                            *ngIf="isUpdateMode()"
                            label='Compte comptable TVA'
                            [disabled]='isReadMode()'
                            type='select'
                            [items]='tvaAccountingAccounts'
                            displayProperty='display'
                            controlName='tvaAccountingAccount'>
                    </base-form-input>

                    <base-form-input
                            label="Intervention nécessaire"
                            type='checkbox'
                            controlName='interventionRequired'
                            [disabled]='isReadMode()'
                    >
                    </base-form-input>

                    <div *ngIf="isInterventionRequired" class="flex flex-col sm:flex-row">

                        <base-form-input
                                         label="Unité d'achat"
                                         controlName='purchaseUnit'
                                         type="select"
                                         [items]="purchaseUnit"
                                         class='w-1/2 sm:mr-3'
                        >
                        </base-form-input>

                        <base-form-input [disabled]="isReadMode()"
                                         [label]="purchaseValueLabel"
                                         type="number"
                                         controlName="purchaseValue"
                                         class='w-1/2'
                                         [minNumber]="1"
                        >
                        </base-form-input>

                    </div>


                    <div class='items-center flex-row w-full mb-5'
                         *ngIf="isInterventionRequired">

                        <div class='pr-2'>
                            <p>Type d'intervention:</p>
                        </div>

                        <mat-chip-list aria-label="prestation selection">
                            <ng-container *ngFor='let typeOfIntervention of typesOfIntervention'>
                                <mat-chip [selected]="isSelected(typeOfIntervention)"
                                          (click)="selectInterventionType(typeOfIntervention)">
                                    {{ typeOfIntervention.name === 'Fixe' ? 'A planifier' : 'Récurrence automatique' }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>

                    <base-form-input
                            *ngIf="isPackageAvailable"
                            label="Forfait"
                            type='checkbox'
                            controlName='isPackageService'
                            [disabled]='isReadMode()'
                    >
                    </base-form-input>

<!--                    <details-line-base-->
<!--                            *ngIf='isReadMode()'-->
<!--                            hint="Disponible au catalogue"-->
<!--                            type="boolean"-->
<!--                            [label]='!!form?.get("isAvailable").value'-->
<!--                    >-->
<!--                    </details-line-base>-->
<!--                    <details-line-base-->
<!--                            *ngIf='isReadMode()'-->
<!--                            hint="Intervention nécessaire"-->
<!--                            type="boolean"-->
<!--                            [label]='!!form?.get("interventionRequired").value'-->
<!--                    >-->
<!--                    </details-line-base>-->

                    <div *ngIf="isWithPackage">
                        <base-form-input
                                label='Nombre de prestation au forfait'
                                type='number'
                                controlName='purchasePackageValue'
                        >
                        </base-form-input>
                        <div class="flex flex-col sm:flex-row">
                            <base-form-input
                                    class='w-1/2 sm:mr-3'
                                    label="Prix du forfait HT"
                                    type='number'
                                    controlName='packagePriceHT'
                            >
                            </base-form-input>

                            <base-form-input
                                    [disabled]='true'
                                    class='w-1/2'
                                    label='Prix du forfait TTC'
                                    type='number'
                                    controlName='packagePriceTTC'>
                            </base-form-input>
                        </div>
                    </div>

                    <div class="flex flex-col sm:flex-row">
                        <base-form-input
                                class='w-1/2 sm:mr-3'
                                [disabled]='isReadMode()'
                                label="Prix par prestation HT"
                                type='number'
                                controlName='priceHT'
                        >
                        </base-form-input>
                        <base-form-input
                                class='w-1/2'
                                [disabled]='true'
                                label='Prix par prestation TTC'
                                type='number'
                                controlName='priceTTC'>
                        </base-form-input>
                    </div>


                    <div class="flex flex-col sm:flex-row" *ngIf="isInterventionRequired">
                        <base-form-input class='w-1/2 sm:mr-3'
                                         [disabled]='isReadMode()'
                                         [label]="priceHTPerUnitLabel"
                                         type='number'
                                         controlName='priceHTPerUnit'
                        >
                        </base-form-input>
                        <base-form-input class='w-1/2'
                                         [disabled]='true'
                                         [label]="priceTTCPerUnitLabel"
                                         type='number'
                                         controlName='priceTTCPerUnit'>
                        </base-form-input>
                    </div>

                    <base-form-input class='flex-1'
                                     label='Quantité minimum de commande'
                                     type='number'
                                     controlName='minQuantity'
                                     [minNumber]="1"
                    >
                    </base-form-input>

                    <base-form-input
                            label="Catalogue public du fournisseur"
                            controlName="generalSupplierCatalog"
                            invalidPatternMessage='Le champs doit être rempli avec une URL'
                    >
                    </base-form-input>

                    <base-form-input
                            label="Fiche méthode"
                            controlName="technicalSheet"
                            invalidPatternMessage='Le champs doit être rempli avec une URL'
                    >
                    </base-form-input>

                    <base-form-input
                            label="Protocole de sécurité"
                            controlName="safetyUsageProtocol"
                            invalidPatternMessage='Le champs doit être rempli avec une URL'
                    >
                    </base-form-input>

                    <ng-container *ngIf="isRevisionPrestationAmount">
                        <base-form-input
                                label='Condition de révision'
                                type='number'
                                controlName='revisionPrestationAmount'
                        >
                        </base-form-input>
                    </ng-container>

<!--                    <base-form-input-->
<!--                            *ngIf="isWithPackage"-->
<!--                            label='Etablissement gestionnaire'-->
<!--                            type='autocomplete'-->
<!--                            [items$]='establishments$'-->
<!--                            [disabled]='isReadMode()'-->
<!--                            (autocompleteChange)='establishmentAutocompleteChange($event)'-->
<!--                            displayProperty='label'-->
<!--                            controlName='mainEstablishment'>-->
<!--                    </base-form-input>-->

                    <div class="pb-2 text-input">
                        <p>Informations complémentaires</p>
                    </div>
                    <quill-editor
                            placeholder='Veuillez saisir les informations complémentaires ici'
                            [modules]="quillConfig"
                            [formControl]='form?.controls?.comment'
                    >
                    </quill-editor>


                    <base-form-input
                            *ngIf='!isReadMode() && isPackageAvailable'
                            label="Disponible au catalogue"
                            type='checkbox'
                            controlName='isAvailable'
                            [disabled]='isReadMode() && canUpdateAvailableCatalogPermission'
                    >
                    </base-form-input>

                    <base-form-input
                            class="sm:mr-3"
                            label="Tout le périmètre"
                            type="checkbox"
                            controlName="isFullPerimeter">
                    </base-form-input>

                </mat-dialog-content>
                <div *ngIf="!form.get('isFullPerimeter').value" class="ml-10 w-1/2"
                     style="min-width: 500px; max-height: 800px; overflow-y: auto;">
                    <!--            <vex-tree-checkbox-->
                    <!--                    *ngIf="node?.length > 0"-->
                    <!--                    [node]="node"-->
                    <!--                    [readMode]="false"-->
                    <!--                    (onSelectedItem)="onSelectItem($event)"-->
                    <!--                    (onUnSelectedItem)="onUnSelectItem($event)"-->
                    <!--            ></vex-tree-checkbox>-->

                    <vex-contract-details-user-perimeter
                            [entity]="contract"
                            [parentItems]="perimeter"
                            [isCompressed]="true"
                            (selectedItemsChange)="onSelectedItemsChange($event)"
                            (displayedItemsList)="onSelectedItemsChange($event)"
                            [prestationToPLan]="false"
                            class=''>
                    </vex-contract-details-user-perimeter>

                    <!--            <vex-contract-details-user-perimeter *ngIf="(isEnergyId || isEquipment || isBuilding)"-->
                    <!--                                                 [existingItemsList]="existingItemsList"-->
                    <!--                                                 [entity] = "entity"-->
                    <!--                                                 [selectedItemsFromChild] ="selectedItemsFromChild"-->
                    <!--                                                 (selectedItemsChange)="onSelectedItemsChange($event)"-->
                    <!--                                                 class='w-2/4 card overflow-auto p-6'>-->
                    <!--            </vex-contract-details-user-perimeter>-->

                </div>

            </div>


            <mat-dialog-actions align="end">
                <base-form-input
                        type='submit'
                        [mode]='mode'
                        [loading]='loading'
                        entityLabel='PRESTATION'
                        (onCreate)='createItem()'
                        (onUpdate)='updateItem()'
                >
                </base-form-input>
            </mat-dialog-actions>
        </form>
    </div>

</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [loading]='loading'
            [label]='data.name'
            (onClose)='close()'
            (onDelete)='deleteItem()'
    >
    </form-modal-delete-base>
</ng-container>

<ng-container *ngIf='isImportMode()'>
    <vex-form-import-base
            [modalTitle]='"Importer des prestations"'
            [entityLabel]='"PRESTATIONS"'
            [form]='importForm'
            [service]='service'
            [loading]='loading'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            (close)='close()'
            [customExport]='true'
            (customExportItems)='exportPrestationContractTemplate()'
            [canDownloadTemplateModel]="canDownloadTemplateModel"
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver la prestation"
            defaultMessage='Êtes-vous sûr de vouloir archiver cette prestation ?'
    >
    </form-modal-delete-base>
</ng-container>
