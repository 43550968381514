import { Injectable } from '@angular/core';
import { EquipmentActivityModel, EquipmentModel, LocationModel } from 'src/app/core/models/equipment.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BuildingModel } from '../../../../core/models/building.model';
import { BuildingService } from '../building/building.service';
import { map } from 'rxjs/operators';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { BuildingActivityModel } from '../../../../core/models/building-activity.model';
import { ContractModel } from '../../../../core/models/contract.model';

@Injectable({
    providedIn: 'root'
})
export class EquipmentService extends BaseService<EquipmentModel>{
    defaultSort = 'createdAt';

    protected _location = new BehaviorSubject<LocationModel[]>(null);
    readonly location$ = this._location.asObservable();
    protected _building = new BehaviorSubject<BuildingModel[]>(null);
    readonly building$ = this._building.asObservable();

    constructor(
        public http: HttpClient,
        public buildingService: BuildingService
    ) {
        super(http, 'equipment');
    }

    findById(id: string): Observable<ObjectApiInterface<EquipmentModel>> {
        return super.findById(id)
            .pipe(map((x: ObjectApiInterface<EquipmentModel>) => {
                if (x.data.contactIntern) {
                    x.data.contactIntern.fullName = `${x.data.contactIntern?.firstName} ${x.data.contactIntern?.lastName}`;
                }
                return x;
            }));
    }

    update(entity: any): Observable<ObjectApiInterface<EquipmentModel>> {
        return super.update(entity)
            .pipe(map((x: ObjectApiInterface<EquipmentModel>) => {
                if (x.data.contactIntern) {
                    x.data.contactIntern.fullName = `${x.data.contactIntern?.firstName} ${x.data.contactIntern?.lastName}`;
                }
                return x;
            }));
    }

    getGenericEquipmentRelation(_id): Observable<ObjectApiInterface<EquipmentModel[]>> {
        return this.http.get<ObjectApiInterface<EquipmentModel[]>>(`${this.baseUrlApi}/getGenericEquipmentRelation/${_id}`);
    }

    getBuilding(establishmentId: Array<string>, listOfBuildingId: Array<string>, search = ''){
        return this.buildingService.findAll(null, null, null, 'desc', search, {establishmentId, buildingsIds: listOfBuildingId}).pipe(map(res => {
            this._building.next(res.data);
            return res;
        }));
    }

    getEstablishmentRelation(_id): Observable<ObjectApiInterface<EquipmentModel[]>> {
        return this.http.get<ObjectApiInterface<EquipmentModel[]>>(`${this.baseUrlApi}/getEstablishmentRelation/${_id}`);
    }

    createLocation(equipmentId, location) {
        return this.http.post<ObjectApiInterface<EquipmentModel>>(`${this.baseUrlApi}/${equipmentId}/createLocation`, location)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    updateLocation(id: string, location: any) {
        return this.http.put<ObjectApiInterface<LocationModel>>(`${this.baseUrlApi}/${id}/updateLocation`, location)
            .pipe(map(x => {
                const index = this._entity.value.locations.findIndex(y => y.technicalIdentifier === x.data.technicalIdentifier);
                this._entity.value.locations[index] = x.data;
                return x;
            }));
    }

    deleteLocation(equipmentId: string, locationId: any) {
        return this.http.delete<ObjectApiInterface<EquipmentModel>>(`${this.baseUrlApi}/${equipmentId}/deleteLocation/${locationId}`)
            .pipe(map((x: ObjectApiInterface<EquipmentModel> ) => {
                this._entity.next(x.data);
                return x;
            }));
    }

    createAmortization(nbPeriod: number, nbValue: number, startDate: Date) {
        const period = nbPeriod;
        const value = nbValue;

        const endDate = new Date(startDate.getFullYear() + period, startDate.getMonth(), startDate.getDate());
        let nextStartDate = new Date(startDate.getFullYear() + 1, 0, 1);
        let nextEndDate: Date;
        if (startDate.getMonth() === 11 && startDate.getDate() === 31) {
            nextEndDate = new Date(startDate.getFullYear() + 1, 11, 31);
        } else {
            nextEndDate = new Date(startDate.getFullYear(), 11, 31);
        }

        const taux = (100 / period) / 100;
        const startRestMonth = ((12 - (startDate.getMonth() + 1)) * 30) + (30 - (startDate.getDate() - 1));
        const endRestMonth = (startDate.getMonth() * 30) + (startDate.getDate() - 1);

        console.log(startRestMonth);
        console.log(endRestMonth);

        const amortization = [];
        let accumulatedAmount: any = 0;
        let index = period;

        if (startRestMonth > 0) {
            index = period - 1;
            accumulatedAmount = ((value * taux) * (startRestMonth / 360));
            amortization.push({
                nbOfDay: startRestMonth,
                startDate,
                endDate: nextEndDate,
                accumulatedAmount: +accumulatedAmount.toFixed(2),
                amortizationAmount: +((value * taux) * (startRestMonth / 360)).toFixed(2),
                netValue: (nbValue - accumulatedAmount).toFixed(2)
            });
            nextEndDate = new Date(nextEndDate.getFullYear() + 1, 11, 31);
        }

        for (let i = 0; i < index; i++) {
            accumulatedAmount += ((value * taux) * (360 / 360));
            amortization.push({
                nbOfDay: 365,
                startDate: nextStartDate,
                endDate: nextEndDate,
                accumulatedAmount: +accumulatedAmount.toFixed(2),
                amortizationAmount: +((value * taux) * (360 / 360)).toFixed(2),
                netValue: (nbValue - accumulatedAmount).toFixed(2)
            });

            // add one year to startDate
            nextStartDate = new Date(nextStartDate.getFullYear() + 1, 0, 1);
            nextEndDate = new Date(nextEndDate.getFullYear() + 1, 11, 31);
        }

        if (endRestMonth !== 360) {
            accumulatedAmount += ((value * taux) * (endRestMonth / 360));
            amortization.push({
                nbOfDay: endRestMonth,
                startDate: nextStartDate,
                endDate,
                accumulatedAmount: +accumulatedAmount.toFixed(2),
                amortizationAmount: +((value * taux) * (endRestMonth / 360)).toFixed(2),
                netValue: (nbValue - accumulatedAmount).toFixed(2)
            });
        }

        return amortization;
    }

    createActivity(id: string, activity: any) {
        return this.http.post<ObjectApiInterface<EquipmentActivityModel>>(`${this.baseUrlApi}/${id}/createActivity`, activity)
            .pipe(map(x => {
                this._entity.value.activity.push(x.data);
                return x;
            }));
    }


    getEquipmentDetails(equipmentId: string): Observable<ObjectApiInterface<any>> {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/equipment-details/${equipmentId}`).pipe(map(res => {
            return res;
        }));
    }

    updateActivity(id: string, activity: any) {
        return this.http.put<ObjectApiInterface<EquipmentActivityModel>>(`${this.baseUrlApi}/${id}/updateActivity`, activity)
            .pipe(map(x => {
                const index = this._entity.value.activity.findIndex(y => y._id === x.data._id);
                this._entity.value.activity[index] = x.data;
                return x;
            }));
    }

    deleteActivity(id: string, activityId: any) {
        return this.http.delete<ObjectApiInterface<BuildingActivityModel>>(`${this.baseUrlApi}/${id}/deleteActivity/${activityId}`)
            .pipe(map(x => {
                this._entity.value.activity = this._entity.value.activity.filter(y => y._id !== x.data._id);
                return x;
            }));
    }

    addGenerics(data: any) {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/addGenerics`, data)
            .pipe(map(x => {
                // this._entity.next(x.data);
                return x;
            }));
    }

    findPerimetersByEstablishments(entity: ContractModel, data: string[]) {
        return this.http.post<ObjectApiInterface<EquipmentModel[]>>(`${this.baseUrlApi}/find-by-establishments/${entity._id}`, {data}).pipe(map(x => {
            return x.data;
        }));
    }

    transformEquipmentData(data: EquipmentModel[]){
        const transformedData = [];

        for (const equipment of data) {

            if (equipment.establishment) {
                const item = {
                    _id: equipment._id,
                    equipmentId: equipment._id,
                    label: equipment.reference,
                    genericLabel: equipment.genericEquipment.label,
                    genericId: equipment.genericEquipment._id,
                    establishmentId: equipment.establishment._id,
                    establishmentLabel: equipment.establishment.label,
                };

                if (!equipment.locations || equipment.locations.length === 0) {
                    transformedData.push({ ...item, buildingId: null, buildingLabel: null });
                } else {
                    for (const location of equipment.locations) {
                        const transformedItem = { ...item, buildingId: location.building._id, buildingLabel: location.building.label };
                        transformedData.push(transformedItem);
                    }
                }
            }
        }

        transformedData.sort((a, b) => {
            // place buildingId null at bottom of the list
            if (a.buildingId === null && b.buildingId !== null) return 1;
            if (a.buildingId !== null && b.buildingId === null) return -1;

            return a.label.localeCompare(b.label);
        });

        return transformedData;
    }
}


