<ng-container>

    <ng-container *ngIf="!prestationToPLan; else prestationTemplate">
        <div class='flex flex-row px-gutter border-b py-4 mb-6'>
            <h2 class='title m-0'>Elements intégrés au contrat</h2>
        </div>
    </ng-container>

    <ng-template #prestationTemplate>
        <div class='flex flex-row px-gutter border-b py-4 mb-6'>
            <h2 class='title m-0'>Elements intégrés à la prestation</h2>
        </div>
    </ng-template>

    <ng-container *ngIf="!prestationToPLan else prestationRadioTemplate">

        <ng-container *ngIf="!readMode; else readOnly">
            <div *ngFor="let item of displayedItemsList">
                <mat-checkbox
                        [value]="item"
                        [checked]="item?.checked"
                        [disabled]="isCheckboxDisabled"
                        (change)="toggleSelectionItem($event.checked, item)"
                >
                    {{ item.displayLabel }}
                </mat-checkbox>
            </div>
        </ng-container>

    </ng-container>

    <ng-template #prestationRadioTemplate>
        <div *ngFor="let item of displayedItemsList">
            <mat-radio-button
                    [value]="item"
                    [checked]="item?.checked"
                    [disabled]="item?.isPlanned"
                    (change)="toggleSelectionItemRadioButton(item)"
            >
                {{ item.displayLabel }}
            </mat-radio-button>
        </div>
    </ng-template>

    <ng-template #readOnly>
        <div *ngFor="let item of displayedItemsList"
             class="leading-relaxed">
            {{ item.displayLabel }}
        </div>
    </ng-template>

</ng-container>