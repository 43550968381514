import { Component, Inject, OnInit } from '@angular/core';
import { FormModalBaseComponent } from '../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { ContractModel, ContractPerimeterModel, RecurringBasketModel } from '../../../../../../core/models/contract.model';
import { Observable } from 'rxjs';
import { EstablishmentModel } from '../../../../../../core/models/establishment.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '../../../../../../core/services/snackbar.service';
import { RecurrenceEnum, recurrenceEnumTranslation } from '../../../../../../core/enums/recurrence.enum';
import moment from 'moment/moment';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DayEnum, dayEnumTranslation } from '../../../../../../core/enums/day.enum';
import { ContractService } from '../../contract.service';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { MonthEnum, monthEnumTranslation } from '../../../../../../core/enums/month.enum';
import { InputTypeInterface } from '../../../../../../core/base/interfaces/input-type.interface';
import { PrestationService } from '../../../prestation/prestation.service';
import { RecurrencePrestationTypeEnum } from '../../../../../../core/enums/recurrence-prestation-type.enum';
import {PrestationModel} from "../../../../../../core/models/prestation.model";
import {InterventionTypeEnum} from "../../../../../../core/enums/intervention.enum";

@Component({
  selector: 'vex-contract-basket-create-update-delete',
  templateUrl: './contract-basket-create-update-delete.component.html',
  styleUrls: ['./contract-basket-create-update-delete.component.scss'],
})

export class ContractBasketCreateUpdateDeleteComponent extends FormModalBaseComponent<ContractModel> implements OnInit {

  startDate: Date;
  endDate: Date;
  endStartDate: Date;
  autoCompleteValue: Array<any>;
  autoCompleteType: typeof InputTypeInterface;
  autoCompleteIsActive = false;
  autocompleteMonth = false;
  autoCompleteDisplayProperty: string = null;

  // TODO FAIRE UN COMPOSANT RECURRENCE
  monthEnum = Object.values(MonthEnum).map(month => monthEnumTranslation(month));
  dayEnum = Object.values(DayEnum).map(day => dayEnumTranslation(day));
  recurrenceEnum = Object.values(RecurrenceEnum).map(recurrence => recurrenceEnumTranslation(recurrence));

  displayRepeat = true;
  repeatLabel: string;

  establishment$: Observable<EstablishmentModel[]>;
  canCreateMode: boolean;
  canUpdateMode: boolean;
  readonly RecurrenceEnum = RecurrenceEnum;

  contract: ContractModel;
  prestation: PrestationModel;
  currentRecurringBasket: RecurringBasketModel;
  prestInterventionId: string;
  prestInterventionRequired: boolean;
  prestLabel: string;
  isRequired: boolean;
  perimeter: ContractPerimeterModel[] = [];
  isReccurencePrestation: boolean = false;
  isManualPlanification: boolean = false;
  typesOfRecurrences = [
    { name: 'LOGIC' },
    { name: 'MANUAL' },
  ];
  selectedTypeOfRecurrence: { name: string } | undefined;
  canUpdatePerimeter: boolean = true;
  protected readonly MonthEnum = MonthEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<FormModalBaseComponent<ContractModel>>,
    public service: ContractService,
    public snackbarService: SnackbarService,
    private prestationService: PrestationService,
    private formBuilder: FormBuilder,
  ) {
    super(data, dialogRef, service);
    this.data = data;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
    this.initForm();

    // By default, the checkbox is set up to 'true'
    this.form.get('isNotSendBcToSupplier').setValue(true, { emitEvent: false });
    this.form.get('isNotSendBcToSupplier').valueChanges.subscribe(x => {
      if (x) {
        this.form.get('isNotSendBcToSupplier').setValue(true, { emitEvent: false });
      }
      if (!x) {
        this.form.get('isNotSendBcToSupplier').setValue(false, { emitEvent: false });
      }
    });

    if (this.prestInterventionId) {
      this.prestationService.findById(this.prestInterventionId).subscribe(prestation => {
        if (prestation.data.interventionType === InterventionTypeEnum.RECURRENCE) {
          this.isReccurencePrestation = true;
          this.form.controls.isFullPerimeter.setValue(false);
          this.prestation = prestation.data;
        }
      });
    }

  }

  isCreateMode(): boolean {
    return super.isCreateMode();
  }

  isUpdateMode(): boolean {
    return super.isUpdateMode();
  }

  async initData() {

    this.contract = this.data.contract;
    this.mode = this.data.mode;
    this.prestInterventionId = this.data.prestInterventionId;
    this.prestInterventionRequired = this.data.prestInterventionRequired;
    this.prestLabel = this.data.prestLabel;

    if (this.mode === CrudModeEnum.Create) {
      if (this.prestInterventionId) {
        this.startDate = null;
      } else {
        this.startDate = moment().startOf('day').toDate();
      }
      this.endDate = moment(this.contract.endValidityDate).startOf('day').toDate();
      this.endStartDate = moment().startOf('day').toDate();
    }

    if (this.mode === CrudModeEnum.Update) {
      this.currentRecurringBasket = this.data.currentRecurringBasket;
      this.perimeter = this.currentRecurringBasket.perimeter;
      this.startDate = moment(this.contract.startValidityDate).startOf('day').toDate();
      this.endDate = moment(this.contract.endValidityDate).startOf('day').toDate();
    }

    // init date locker
    // start date is today

  }


  async initForm() {
    this.form = new FormGroup({
      label: new FormControl(this.currentRecurringBasket?.label || this.prestLabel || '', [Validators.required]),
      startDate: new FormControl(this.currentRecurringBasket?.startDate || null),
      endDate: new FormControl(this.currentRecurringBasket?.endDate || null),
      recurrence: new FormControl(this.recurrenceEnum.find(x => x.value === this.currentRecurringBasket?.recurrence) || null),
      repeat: new FormControl(this.currentRecurringBasket?.repeat || null),
      activated: new FormControl(this.currentRecurringBasket?.activated || null),
      month: new FormControl(this.monthEnum.find(x => x.value === this.currentRecurringBasket?.month) || null),
      nbIntervention: new FormControl(this.currentRecurringBasket?.nbIntervention || 0, [Validators.min(0)]),
      confirmationDelay: new FormControl(this.currentRecurringBasket?.confirmationDelay || 0, [Validators.min(0)]),
      isNotSendBcToSupplier: new FormControl(this.currentRecurringBasket?.isNotSendBcToSupplier || false),
      isFullPerimeter: new FormControl(this.currentRecurringBasket?.isFullPerimeter ?? true),
      manualPlanification: new FormArray([]),
      recurrencePrestationType: new FormControl(this.currentRecurringBasket?.recurrencePrestationType || null),
    });

    if (this.isCreateMode()) {
      this.setRepeat(RecurrenceEnum.Daily);
      this.form.controls.startDate.setValue(moment().startOf('day').toDate());
      this.form.controls.endDate.setValue(moment(this.contract.endValidityDate).startOf('day').toDate());

      this.selectedTypeOfRecurrence = this.typesOfRecurrences[0];
      this.form.controls.recurrencePrestationType.setValue(RecurrencePrestationTypeEnum.LOGIC);
    }


    this.form.controls.startDate.valueChanges.subscribe(x => {
      this.endStartDate = moment(x).startOf('day').toDate();

    });

    if (this.currentRecurringBasket?.recurrence === RecurrenceEnum.Daily) {
      this.form.controls.activated.disable();
    }

    this.form.controls.recurrence.valueChanges.subscribe(x => {
      this.setForm(x);
    });

    if (this.isUpdateMode()) {
      this.setRepeat(this.currentRecurringBasket?.recurrence);

      const manualPlanificationValues = this.currentRecurringBasket?.manualPlanification || [];
      // Ajouter chaque date dans le FormArray
      manualPlanificationValues.forEach(date => {
        (this.form.get('manualPlanification') as FormArray).push(
          this.formBuilder.control(new Date(date), Validators.required),
        );
      });

      if (this.currentRecurringBasket.manualPlanification?.length > 0) {
        this.selectedTypeOfRecurrence = this.typesOfRecurrences[1];
        this.form.controls.recurrencePrestationType.setValue(RecurrencePrestationTypeEnum.MANUAL);
        this.isManualPlanification = true;
        this.addDateField();
      } else {
        this.selectedTypeOfRecurrence = this.typesOfRecurrences[0];
        this.form.controls.recurrencePrestationType.setValue(RecurrencePrestationTypeEnum.LOGIC);
        this.isManualPlanification = false;
      }

      const sub = await this.prestationService.findById(this.data.currentRecurringBasket.prestInterventionId).subscribe(prestation => {
        if (prestation.data.interventionType === InterventionTypeEnum.RECURRENCE) {

            this.form.controls.isFullPerimeter.setValue(null);
            this.form.controls.isFullPerimeter.disable();
            this.form.controls.isFullPerimeter.updateValueAndValidity();
            this.canUpdatePerimeter = false;
        }
      });

      this.subscription.add(sub);


    }
  }

  dateFilter(date: Date): boolean {
    return date >= moment(this.defaults.startValidityDate ?? new Date()).startOf('day').toDate();
  }

  setForm(x) {
    if (x?.value === RecurrenceEnum.Daily) {
      this.form.controls.activated.disable();
      this.autoCompleteIsActive = false;
      this.autocompleteMonth = false;

      // this.form.controls.repeat.clearValidators();
      this.displayRepeat = false;
    } else if (x?.value === RecurrenceEnum.Yearly) {
      this.autocompleteMonth = true;
      this.form.controls.activated.enable();
      this.displayRepeat = true;
    } else {

      this.form.controls.activated.enable();
      this.displayRepeat = true;
      this.autocompleteMonth = false;

    }

    this.form.controls.repeat.setValue(null);
    this.form.controls.activated.setValue(null);
    this.form.controls.month.setValue(null);
    if (x) {
      this.setRepeat(x.value);
    }
  }

  createItem() {
    this.loading = true;
    if (this.form.valid) {
      const data = this.parseData(this.form.getRawValue());
      data.prestInterventionId = this.prestInterventionId;
      data.perimeter = this.perimeter;

      const sub = this.service.createRecurringBasket(this.contract._id, data).subscribe(
        result => {

          if (!this.prestInterventionId) {
            if (data.activated && (data.activated.includes(29) || data.activated.includes(30) || data.activated.includes(31))) {
              this.snackbarService.warning('Ajouter des références à votre panier pour le finaliser. Certains mois comptent moins de ' + data.activated[0] + ' jours, pour ces mois l’occurrence tombera le dernier jour du mois', 10000);
            }

            if (!result.data.confirmationNeeded){
              this.snackbarService.warning('Ajouter des références à votre panier pour le finaliser');
            }
          }
          this.loading = false;
          this.close(result);
        }, error => {
            if (error?.error?.statusCode === 5502) {
              this.snackbarService.danger(error.error.message);
            } else {
              this.snackbarService.warning('Une erreur est survenue lors de la création du panier contrat');
            }
          this.loading = false;
        },
      );
      this.subscription.add(sub);
    } else {
      this.onFormInvalid();
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }


  updateItem(): void {
    this.loading = true;
    if (this.form.valid) {
      const data = this.parseData(this.form.getRawValue());
      data.perimeter = this.perimeter;

      const sub = this.service.updateRecurringBasket(this.contract._id, this.currentRecurringBasket._id, data).subscribe(
        result => {

          if (!result.data.confirmationNeeded){
            this.snackbarService.success('Le panier contrat a bien été modifié');
          }

          this.loading = false;
          this.close(result);
        }, error => {
          if (error?.error?.statusCode === 5510) {
            this.snackbarService.danger(error.error.message);
          } else if (error?.error?.statusCode === 5502){
            this.snackbarService.danger(error.error.message);
          } else {
            this.snackbarService.warning('Une erreur est survenue lors de la modification du panier contrat');
          }
          this.loading = false;
        },
      );
      this.subscription.add(sub);
    } else {
      this.onFormInvalid();
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  parseData(data: any) {
    if (data.recurrence) {
      data.recurrence = data.recurrence.value;
    }

    if (data.recurrence === RecurrenceEnum.Weekly) {
      data.activated = data.activated?.map((value) => {
        return Object.values(DayEnum).findIndex((y: any) => y === value.value);
      });
    }
    if (!Array.isArray(data.activated)) {
      data.activated = [data.activated];
    }

    if (data.month) {
      data.month = data.month.value;
    }

    if (this.isManualPlanification) {
      // i want set the value of field at null
      this.form.get('endDate').setValue(null);
      this.form.get('startDate').setValue(null);
      this.form.get('recurrence').setValue(null);
      this.form.get('repeat').setValue(null);

      // if (data.manualPlanification.length <= 0) {
      //     this.snackbarService.danger('Il faut ajouter au moins une date de récurrence manuelle');
      //     this.loading = false;
      //     return;
      // }
    }

    if (!this.isManualPlanification) {
      const manualPlanificationArray = this.form.get('manualPlanification') as FormArray;

      this.form.controls.manualPlanification.clearValidators();
      manualPlanificationArray.clear();
      manualPlanificationArray.disable();
      this.form.controls.manualPlanification.updateValueAndValidity();
      data.manualPlanification = null;
    }

    return data;
  }

  onSelectedItemsChange(selectedItems: []) {
    this.perimeter = selectedItems;
  }

  setRepeat(value: RecurrenceEnum) {
    switch (value) {
      case RecurrenceEnum.Weekly:
        this.repeatLabel = 'Semaine(s)';
        this.autoCompleteValue = this.dayEnum;
        this.autoCompleteType = 'multiple';
        this.autoCompleteDisplayProperty = 'label';
        this.autoCompleteIsActive = true;

        if (this.isUpdateMode()) {
          // get index of day enum
          this.form.controls.repeat.setValue(this.currentRecurringBasket?.repeat);
          this.form.controls.activated.setValue(this.currentRecurringBasket?.activated?.map(x => this.dayEnum[x])?.filter(x => x !== undefined) || null);
        }

        break;
      case RecurrenceEnum.Monthly:
        this.repeatLabel = 'Mois';
        this.autoCompleteValue = Array.from({ length: 31 }, (_, index) => index + 1);
        this.autoCompleteDisplayProperty = null;
        this.autoCompleteType = 'autocomplete';
        this.autoCompleteIsActive = true;

        if (this.isUpdateMode()) {
          // get index of day enum
          this.form.controls.repeat.setValue(this.currentRecurringBasket?.repeat);
          this.form.controls.activated.setValue(this.currentRecurringBasket?.activated[0]);
        }

        break;
      case RecurrenceEnum.Yearly:
        this.repeatLabel = 'Année(s)';
        this.autoCompleteValue = Array.from({ length: 31 }, (_, index) => index + 1);
        this.autoCompleteDisplayProperty = null;
        this.autoCompleteType = 'autocomplete';
        this.autoCompleteIsActive = true;
        this.autocompleteMonth = true;

        if (this.isUpdateMode()) {
          // get index of day enum
          this.form.controls.repeat.setValue(this.currentRecurringBasket?.repeat);
          this.form.controls.activated.setValue(this.currentRecurringBasket?.activated);
          this.form.controls.month.setValue(this.monthEnum.find(x => x.value === this.currentRecurringBasket?.month));
        }

        break;
      default:
        this.repeatLabel = 'Jour(s)';
    }
  }

  isSelected(typeOfRecurrence: { name: string }): boolean {
    return this.selectedTypeOfRecurrence === typeOfRecurrence;
  }

  selectPrestation(typeOfRecurrence: { name: string }): void {
    this.selectedTypeOfRecurrence = typeOfRecurrence;

    if (typeOfRecurrence.name === 'MANUAL') {
      this.isManualPlanification = true;

      //    this.form.controls.startDate.setValue(null);
      this.form.controls.startDate.clearValidators();
      this.form.controls.startDate.updateValueAndValidity();

      //    this.form.controls.endDate.setValue(null);
      this.form.controls.endDate.clearValidators();
      this.form.controls.endDate.updateValueAndValidity();

      // If the user selects the manual planification, we add a date field by default only if this createMode

      // if (this.form.controls.manualPlanification.value.length === 0) {
      //     console.log(this.form.controls.manualPlanification.value)
      //     this.addDateField();
      // }

      if (!this.form.controls.manualPlanification.value.includes(null)) {
        this.addDateField();
      }

      this.form.controls.recurrencePrestationType.setValue(RecurrencePrestationTypeEnum.MANUAL);

    }
    if (typeOfRecurrence.name === 'LOGIC') {
      this.isManualPlanification = false;
      // If the user selects the manual planification, we remove all date fields

      const manualPlanificationArray = this.form.get('manualPlanification') as FormArray;
      if (this.isCreateMode()) {
        manualPlanificationArray.clear();
        manualPlanificationArray?.setValue(null);

        manualPlanificationArray.clearValidators();
        manualPlanificationArray.updateValueAndValidity();
      }

      if (manualPlanificationArray.at(manualPlanificationArray.length - 1).value === null) {
        manualPlanificationArray.removeAt(manualPlanificationArray.length - 1);
      }

      this.form.controls.recurrencePrestationType.setValue(RecurrencePrestationTypeEnum.LOGIC);
    }
  }


  checkIfDateDisabled(date): boolean {
    // disabled if contract is not draft and date is before today
    return this.contract.status !== 'DRAFT' && date.value < moment().startOf('day').toDate();
  }


  addDateField() {
    if (this.contract.status !== 'DRAFT') {
      return;
    }

    const manualPlanificationArray = this.form.get('manualPlanification') as FormArray;
    // Vérifier si le dernier champ date est vide avant d'ajouter un nouveau champ
    const lastDateControl = manualPlanificationArray.at(manualPlanificationArray.length - 1);
    if (lastDateControl?.value !== null && lastDateControl?.value !== '') {
      manualPlanificationArray.push(this.formBuilder.control(null, Validators.required));
    }

  }

  removeDateField(index: number): void {
    const manualPlanificationArray = this.form.get('manualPlanification') as FormArray;

    if (index === 0 && manualPlanificationArray.length === 1) {
      this.snackbarService.danger('Il faut une date de récurrence manuelle au minimum');
      this.form.get('manualPlanification').setErrors({ required: true });
    } else {
      manualPlanificationArray.removeAt(index);
    }
  }

}
