import { NavigationItem } from '../../../@vex/interfaces/navigation-item.interface';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icContactSupport from '@iconify/icons-ic/twotone-contact-support';
import icCollectionsBookmark from '@iconify/icons-ic/twotone-collections-bookmark';
import icBookmark from '@iconify/icons-ic/twotone-bookmark';
import icBook from '@iconify/icons-ic/twotone-book';
import outlineSupervisedUserCircle from '@iconify/icons-ic/outline-supervised-user-circle';
import faBuilding from '@iconify/icons-fa-solid/building';
import faTicket from '@iconify/icons-fa-solid/ticket-alt';
import faPiggyBank from '@iconify/icons-fa-solid/piggy-bank';
import faPercent from '@iconify/icons-fa-solid/percent';
import faBoxes from '@iconify/icons-fa-solid/boxes';
import faBoxOpen from '@iconify/icons-fa-solid/box-open';
import faContract from '@iconify/icons-fa-solid/file-contract';
import faWrench from '@iconify/icons-fa-solid/wrench';
import faWallet from '@iconify/icons-fa-solid/wallet';
import faMoneyBill from '@iconify/icons-fa-solid/money-bill';
import faPeopleArrow from '@iconify/icons-fa-solid/people-arrows';
import faUtensils from '@iconify/icons-fa-solid/utensils';
import icPrint from '@iconify/icons-ic/print';
import icIncandescent from '@iconify/icons-ic/wb-incandescent';
import icShoppingCart from '@iconify/icons-ic/shopping-cart';
import icGavel from '@iconify/icons-ic/gavel';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import { ENUM_PERMISSIONS } from '../enums/permission.enum';
import icShoppingBasket from '@iconify/icons-ic/outline-shopping-basket';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';

export const itemsCore: NavigationItem[] = [
  // {
  //     type: 'link',
  //     label: 'Tableau de bord',
  //     route: '/dashboard',
  //     icon: icLayers
  // },
  {
    type: 'subheading',
    label: 'Paramétrage PREDIK360',
    children: [
      {
        type: 'dropdown',
        label: 'Eléments contractuels',
        icon: icCollectionsBookmark,
        children: [
          {
            type: 'link',
            label: 'Motif d\'avenant',
            route: '/settings/savingClauseReason',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Objet de l\'intervention',
            route: '/settings/interventionObject',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Type d\'intervention',
            route: '/settings/interventionType',
            icon: icCollectionsBookmark,
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'Engagements',
        icon: icCollectionsBookmark,
        children: [

          {
            type: 'link',
            label: 'Categorie d\'engagement générique',
            route: '/settings/genericEngagementCategory',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Engagement générique',
            route: '/settings/genericEngagement',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Conditions règlements générique',
            route: '/settings/genericPaymentCondition',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Frais annexes générique',
            route: '/settings/genericAdditionalCost',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'TVA générique',
            route: '/settings/genericTVA',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Type de frais annexes générique',
            route: '/settings/genericTypeAdditionalCost',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Motif générique',
            route: '/settings/genericMotif',
            icon: icCollectionsBookmark,
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'Equipement',
        icon: icPrint,
        children: [
          {
            type: 'link',
            label: 'Type d\'équipement',
            route: '/settings/equipmentType',
            icon: icCollectionsBookmark,
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'Etablissement',
        icon: icCollectionsBookmark,
        children: [
          {
            type: 'link',
            label: 'Poste collaborateur',
            route: '/settings/employeePosition',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Type d\'acteur',
            route: '/settings/contactType',
            icon: icCollectionsBookmark,
          },
          // {
          //   type: 'link',
          //   label: 'Type de contrat RH',
          //   route: '/settings/contractRhType',
          //   icon: icCollectionsBookmark,
          // },
        ],
      },
      {
        type: 'link',
        label: 'Postes de dépenses',
        route: '/settings/category',
        icon: icCollectionsBookmark,
      },
      {
        type: 'dropdown',
        label: 'Objets génériques',
        icon: icCollectionsBookmark,
        children: [
          {
            type: 'link',
            label: 'Caractéristique générique',
            route: '/settings/genericCharacteristic',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Equipement générique',
            route: '/settings/genericEquipment',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Prestation générique',
            route: '/settings/genericPrestation',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Article générique',
            route: '/settings/genericProduct',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Plan comptable générique',
            route: '/settings/genericAccountingAccount',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Bâtiment générique',
            route: '/settings/genericBuilding',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Énergie générique',
            route: '/settings/generic-energy-id',
            icon: icCollectionsBookmark,
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'Salles',
        icon: icCollectionsBookmark,
        children: [
          {
            type: 'link',
            label: 'Catégorie de salle générique',
            route: '/settings/roomCategory',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Salle générique',
            route: '/settings/genericRoom',
            icon: icCollectionsBookmark,
          },
          {
            type: 'link',
            label: 'Type de sol',
            route: '/settings/floorType',
            icon: icCollectionsBookmark,
          },
        ],
      },
      {
        type: 'link',
        label: 'Type d\'activité',
        route: '/settings/activityType',
        icon: icCollectionsBookmark,
      },
      {
        type: 'link',
        label: 'Typologie des résidents',
        route: '/settings/publicType',
        icon: icCollectionsBookmark,
      },
    ],
  },
];

export const ticketHome: NavigationItem[] = [
  {
    type: 'link',
    label: 'Mes demandes',
    icon: faTicket,
    route: '/administrative/ticket-home',

    permissions: [
        // DS-PRED-1253

      // ENUM_PERMISSIONS.CREATE_TICKET_REQUIREMENT,
      // ENUM_PERMISSIONS.READ_TICKET_REQUIREMENT_INFO,
      // ENUM_PERMISSIONS.ADD_TICKET_REQUIREMENT_INFO,
      // ENUM_PERMISSIONS.VALIDATE_TICKET_REQUIREMENT_INFO,
      // ENUM_PERMISSIONS.INPUT_TICKET_REQUIREMENT_INFO_REFUSE,
      // ENUM_PERMISSIONS.INPUT_TICKET_REQUIREMENT_INFO,
      // ENUM_PERMISSIONS.CANCEL_TICKET_REQUIREMENT_INFO,
      // ENUM_PERMISSIONS.READ_TICKET_REQUIREMENT,
      // ENUM_PERMISSIONS.ACCESS_TICKET_REQUIREMENT,
      // ENUM_PERMISSIONS.READ_TICKET_REQUIREMENT_CATALOG,
      // ENUM_PERMISSIONS.SEARCH_TICKET_REQUIREMENT_CATALOG,
      // ENUM_PERMISSIONS.INPUT_TICKET_REQUIREMENT_CATALOG_FILTER,
      // ENUM_PERMISSIONS.ADD_TICKET_REQUIREMENT_CATALOG,
      // ENUM_PERMISSIONS.UPDATE_TICKET_REQUIREMENT_CATALOG,
      // ENUM_PERMISSIONS.DELETE_TICKET_REQUIREMENT_CATALOG,
      // ENUM_PERMISSIONS.INPUT_TICKET_REQUIREMENT_CATALOG,
      // ENUM_PERMISSIONS.READ_TICKET_REQUIREMENT_BASKET_TYPE,
      // ENUM_PERMISSIONS.ADD_TICKET_REQUIREMENT_BASKET_TYPE,
      // ENUM_PERMISSIONS.READ_TICKET_REQUIREMENT_BASKET,
      // ENUM_PERMISSIONS.UPDATE_TICKET_REQUIREMENT_BASKET,
      // ENUM_PERMISSIONS.DELETE_TICKET_REQUIREMENT_BASKET,
      // ENUM_PERMISSIONS.INPUT_TICKET_REQUIREMENT_BASKET_FINALISE,
      // ENUM_PERMISSIONS.VALIDATE_TICKET_REQUIREMENT_BASKET,
      // ENUM_PERMISSIONS.INPUT_TICKET_REQUIREMENT_BASKET,
      // ENUM_PERMISSIONS.READ_TICKET_REQUIREMENT_DOCUMENT,
      // ENUM_PERMISSIONS.UPLOAD_TICKET_REQUIREMENT_DOCUMENT,
      // ENUM_PERMISSIONS.DOWNLOAD_TICKET_REQUIREMENT_DOCUMENT,
      // ENUM_PERMISSIONS.DELETE_TICKET_REQUIREMENT_DOCUMENT,
      // ENUM_PERMISSIONS.CREATE_TICKET_INCIDENT,
      // ENUM_PERMISSIONS.READ_TICKET_INCIDENT_DESCRIPTION,
      // ENUM_PERMISSIONS.INPUT_TICKET_INCIDENT_DESCRIPTION,
      // ENUM_PERMISSIONS.UPDATE_TICKET_INCIDENT_DESCRIPTION,
      // ENUM_PERMISSIONS.SAVE_TICKET_INCIDENT_DESCRIPTION,
      // ENUM_PERMISSIONS.INPUT_TICKET_INCIDENT_DESCRIPTION_TRANSMIT,
      // ENUM_PERMISSIONS.READ_TICKET_INCIDENT_DOCUMENT,
      // ENUM_PERMISSIONS.UPLOAD_TICKET_INCIDENT_DOCUMENT,
      // ENUM_PERMISSIONS.DOWNLOAD_TICKET_INCIDENT_DOCUMENT,
      // ENUM_PERMISSIONS.DELETE_TICKET_INCIDENT_DOCUMENT,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET,
      // ENUM_PERMISSIONS.ACCESS_TICKET_TICKET,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_ARCHIVE,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET_ARCHIVE,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET_TICKET,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_TICKET,
      // ENUM_PERMISSIONS.ACCESS_TICKET_TICKET_TICKET,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_TICKET_ARCHIVE,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET_TICKET_ARCHIVE,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET_TICKET_IN_PROGRESS,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_TICKET_IN_PROGRESS,
      // ENUM_PERMISSIONS.ACCESS_TICKET_TICKET_TICKET_IN_PROGRESS,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_ARCHIVE,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET_TICKET_IN_PROGRESS_ARCHIVE,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED,
      // ENUM_PERMISSIONS.ACCESS_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_DUPLICATE,
      // ENUM_PERMISSIONS.INPUT_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED_ARCHIVE,
      // ENUM_PERMISSIONS.READ_TICKET_TICKET_TICKET_IN_PROGRESS_FINALIZED_ARCHIVE
    ],
  },

];

export const itemsAdmin: NavigationItem[] = [
  {
    type: 'link',
    label: 'Tableau de bord',
    route: '/dashboard',
    icon: icLayers,
    permissions: [ENUM_PERMISSIONS.READ_DASHBOARD, ENUM_PERMISSIONS.INPUT_DASHBOARD, ENUM_PERMISSIONS.DELETE_DASHBOARD],
  },
];
export const itemsClient: NavigationItem[] = [
  // {
  //     type: 'link',
  //     label: 'Dashboard',
  //     route: '/',
  //     icon: icLayers
  // },
  {
    type: 'subheading',
    label: 'Apps',
    children: [
      {
        type: 'dropdown',
        label: 'Help Center',
        icon: icContactSupport,
        children: [
          {
            type: 'link',
            label: 'Getting Started',
            route: '/apps/help-center/getting-started',
          },
          {
            type: 'link',
            label: 'Pricing & Plans',
            route: '/apps/help-center/pricing',
          },
          {
            type: 'link',
            label: 'FAQ',
            route: '/apps/help-center/faq',
          },
          {
            type: 'link',
            label: 'Guides',
            route: '/apps/help-center/guides',
          },
        ],
      },
    ],
  },
];

export const clientSettings: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Paramétrage Client',
    permission: ENUM_PERMISSIONS.READ_TP,
    // icon: icCollectionsBookmark,
    children: [
      {
        type: 'link',
        label: 'Etablissements',
        route: '/management/establishment',
        icon: faBuilding,
        permission: ENUM_PERMISSIONS.READ_CP_ESTABLISHMENT,
      },
      {
        type: 'link',
        label: 'Contacts internes',
        route: '/management/user-profile',
        icon: outlineSupervisedUserCircle,
        permission: ENUM_PERMISSIONS.READ_CP_INTERNAL_CONTACT,
      },
      {
        type: 'link',
        label: 'Favoris',
        icon: icShoppingBasket,
        route: '/administrative/basket-type',
        permission: ENUM_PERMISSIONS.READ_CP,
      },
      {
        type: 'link',
        label: 'TVA',
        route: '/settings/tva',
        icon: faPercent,
        // icon: icCollectionsBookmark,
        permission: ENUM_PERMISSIONS.READ_CP_TVA,
      },
    ],
  },
];

export const genericAdministration: NavigationItem = {
  type: 'dropdown',
  label: 'Administration générique',
  icon: icVerifiedUser,
  permission: ENUM_PERMISSIONS.READ_CP_ADMIN_GENERIC,
  children: [
    {
      type: 'link',
      label: 'Références sans générique',
      route: '/settings/generic-administration-reference-without-generic',
    },
    {
      type: 'link',
      label: 'Références à valider',
      route: '/settings/generic-administration-reference-to-validate',
    },
  ],
};

export const heritageManagement: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Pilotage du patrimoine',
    // icon: icCollectionsBookmark,
    children: [
      {
        type: 'link',
        label: 'Bâtiments',
        route: '/management/building',
        icon: faBuilding,
        permission: ENUM_PERMISSIONS.READ_BUILDING,
      },
      {
        type: 'link',
        label: 'Equipements',
        route: '/management/equipment',
        icon: icPrint,
        permission: ENUM_PERMISSIONS.READ_EQUIPMENT,
      },
      {
        type: 'link',
        label: 'ID Énergie',
        route: '/management/energy-id',
        icon: icOfflineBolt,
        permission: ENUM_PERMISSIONS.READ_ENERGY_ID,
      },
    ],
  },

];

export const internalRessourcesManagement: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Pilotage des ressources internes',
    italic: true,
    // icon: icCollectionsBookmark,
    // permission: ENUM_PERMISSIONS., // en attente
    children: [
      {
        type: 'link',
        label: 'Stock articles',
        route: 'ressource/stock',
        icon: faBoxOpen,
        italic: true,
      },
      {
        type: 'dropdown',
        label: 'Ressources humaines disponibles',
        icon: faPeopleArrow,
        italic: true,
        // permission: ENUM_PERMISSIONS., // en attente
        children: [
          {
            type: 'link',
            label: 'Intervenants internes qualifiés',
            route: '/ressource/hr/contributor',
            icon: icCollectionsBookmark,
            italic: true,
            // permission: ENUM_PERMISSIONS.RH // en attente
          },
          {
            type: 'link',
            label: 'Catalogue prestations internes',
            route: '/ressource/hr/catalog',
            icon: icCollectionsBookmark,
            italic: true,
            // permission: ENUM_PERMISSIONS.RH_INTERNAL_SERVICE // en attente
          },
          {
            type: 'link',
            label: 'Planning disponibilités ressources internes',
            route: '/ressource/hr/planing',
            icon: icCollectionsBookmark,
            italic: true,
            // permission: ENUM_PERMISSIONS.RH_INTERNAL_RESOURCE // en attente
          },
        ],
      },
    ],
  },

];

export const financialManagement: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Pilotage Des Achats',
    // permission: ENUM_PERMISSIONS.READ_FM, //n'existe plus
    children: [
      {
        type: 'dropdown',
        label: 'Processus achats',
        icon: icShoppingCart,
        // permission: ENUM_PERMISSIONS.READ_FM_PURCHASE, //n'existe plus
        children: [
          {
            type: 'link',
            label: 'Finaliser une commande sous engagement',
            route: '/achat/order-management',
            icon: icBook,
            // permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_ORDER
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_FINALIZE_ORDER,
          },
          {
            type: 'link',
            label: 'Gérer une commande',
            route: '/achat/order',
            icon: icBook,
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_ORDER_RECAP,
          },
          {
            type: 'link',
            label: 'Suivre une demande de devis',
            route: '/achat/quote',
            icon: icBook,
            // permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_QUOTE_REQUEST
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_QUOTE,
          },
          {
            type: 'link',
            label: 'Réceptionner une commande',
            route: '/administrative/reception-product',
            icon: icBook,
            // permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_RECEPTION
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_RECEPTION,
          },

          {
            type: 'link',
            label: 'Gérer une intervention externe',
            route: '/technique/externalIntervention',
            icon: icBookmark,
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_EXTERNAL_INTERVENTION,
          },

          // Menu gestion des interventions internes masqué pour le moment -> carte PREDIK 1200;
          // (Juste masqué en front mais accessible --> internalIntervention)
          // {
          //     type: 'link',
          //     label: 'Gérer une intervention interne',
          //     route: '/technique/internalIntervention',
          //     icon: icBookmark,
          //     italic: true,
          //     permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_INTERN_INTERVENTION
          // }
          // {
          //     type: 'link',
          //     label: 'Réceptionner une intervention',
          //     route: '/administrative/reception-intervention',
          //     icon: icBook,
          //     permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_RECEPTION_INTERVENTION
          // },
          {
            type: 'link',
            label: 'Rapprocher une facture (GED)',
            route: '/administrative/bill',
            icon: icBook,
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_INVOICE,
          },
          {
            type: 'link',
            label: 'Régulariser une facture',
            route: '/achat/orderRegularization',
            icon: icBook,
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_INVOICE_REG,
          },

          {
            type: 'link',
            label: 'Export comptable des écritures',
            route: '/administrative/accounting-export',
            icon: icBookmark,
            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_PROCESS_EXPORT_INVOICE_EXPORT,
          },


        ],
        // icon: icCollectionsBookmark,
      },
      {
        type: 'dropdown',
        label: 'Référentiels achats',
        icon: faBoxes,
        // permission: ENUM_PERMISSIONS.READ_FM_BF_SUPPLIER, //n'existe plus
        children: [
          {
            type: 'link',
            label: 'Base fournisseurs référencés',
            route: '/management/supplier',
            icon: icCollectionsBookmark,
            permission: ENUM_PERMISSIONS.READ_FM_REF_PURCHASE_SUPPLIER,
          },
          {
            type: 'link',
            label: 'Catalogue articles',
            route: '/management/product',
            icon: icCollectionsBookmark,
            permission: ENUM_PERMISSIONS.READ_FM_REF_PURCHASE,
          },
          {
            type: 'link',
            label: 'Catalogue prestations',
            route: '/management/prestation',
            icon: icCollectionsBookmark,
            permission: ENUM_PERMISSIONS.READ_FM_REF_PURCHASE_SERVICE,
          },
          {
            type: 'link',
            label: 'Engagements',
            route: '/management/engagement',
            icon: faContract,
            permission: ENUM_PERMISSIONS.READ_FM_REF_PURCHASE_COMMITMENT,
          },
        ],
      },
      // ENGAGEMENT REFONTE
      //{
      //    type: 'dropdown',
      //    label: 'Engagements',
      //    icon: faContract,
      //    children: [
      //        {
      //            type: 'link',
      //            label: 'Contrat',
      //            route: '/management/contract',
      //            icon: icCollectionsBookmark,
      //            permission: ENUM_PERMISSIONS.READ_FM_CONTRACT
      //        },
      //        {
      //            type: 'link',
      //            label: 'Offre Commerciale',
      //            route: '/management/commercialOffer',
      //            icon: icCollectionsBookmark,
      //            permission: ENUM_PERMISSIONS.READ_FM_PURCHASE_OFFER
      //        },
      //    ]
      //},
      // {
      //     type: 'link',
      //     label: 'Gestion des contrats',
      //     route: '/management/contract',
      //     icon: icCollectionsBookmark,
      //     permission: ENUM_PERMISSIONS.READ_FM_CONTRACT
      // },
      //PRED-544 temporary disabled until the stocks module is ready
      //{
      //    type: 'dropdown',
      //    label: 'Ressources internes',
      //    icon: icCollectionsBookmark,
      //    children: [
      //        {
      //            type: 'link',
      //            label: 'Stocks',
      //            route: '/administrative/store',
      //            icon: icCollectionsBookmark
      //        },
      //    ]
      //}
    ],
  },
];

export var functionalManagement: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Pilotage fonctionnel',
    //TODO NEXISTE PLUS
    // permission: ENUM_PERMISSIONS.READ_MM,
    // icon: icCollectionsBookmark,
    children: [
      {
        type: 'dropdown',
        label: 'Gestion interventions',
        icon: faWrench,
        //TODO NEXISTE PLUS
        // permission: ENUM_PERMISSIONS.READ_MM_PLANNING,
        children: [
          {
            type: 'link',
            label: 'Planning',
            route: '/technique/planning',
            icon: icCollectionsBookmark,
            //TODO NEXISTE PLUS
            // permission: ENUM_PERMISSIONS.READ_MM_PLANNING
          },
          {
            type: 'link',
            label: 'Intervention sur devis',
            route: '/technique/correctiveIntervention',
            icon: icCollectionsBookmark,
            //TODO NEXISTE PLUS
            // permission: ENUM_PERMISSIONS.READ_MM_IC
          },
          {
            type: 'dropdown',
            label: 'Intervention sous contrat',
            icon: icCollectionsBookmark,
            // TODO NEXISTE PLUS
            // permission: ENUM_PERMISSIONS.READ_MM_IP_PLANNING,
            children: [
              {
                type: 'link',
                label: 'Planification',
                route: '/technique/preventiveInterventionPlanification',
                //TODO NEXISTE PLUS
                // permission: ENUM_PERMISSIONS.READ_MM_IP_PLANNING
              },
              {
                type: 'link',
                label: 'Interventions',
                route: '/technique/preventiveIntervention',
                //TODO NEXISTE PLUS
                // permission: ENUM_PERMISSIONS.READ_MM_IP_INTERVENTION
              },
            ],
          },
          {
            type: 'link',
            label: 'Commission de sécurité',
            route: '/technique/securityCommission',
            icon: icCollectionsBookmark,
            //TODO NEXISTE PLUS
            // permission: ENUM_PERMISSIONS.READ_MM_CS
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'Performance Energétique',
        icon: icIncandescent,
        // permission: ENUM_PERMISSIONS.,
        children: [
          {
            type: 'link',
            label: 'Gestion des énergies',
            routerLinkActiveOptions: { exact: true },
            // route: () => window.open(environment.energismeUrl, '_blank'),
            route: () => 'toto',
            icon: icCollectionsBookmark,
            // permission: ENUM_PERMISSIONS.ACTIVITY_TYPE_CREATE,
          },
        ],
      },
    ],
  },
];

export const predikSettingsTenant: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Paramétrage PREDIK360',
    children: [
      // {
      //     type: 'link',
      //     label: 'Type d\'engagement',
      //     route: '/settings/engagementType',
      //     icon: faHandsHelping,
      //     // icon: icCollectionsBookmark,
      //     permission: ENUM_PERMISSIONS.READ_CP_TYPE_OF_COMMITMENT
      // },
      // {
      //     type: 'link',
      //     label: 'CPOM',
      //     route: '/settings/cpom',
      //     icon: icCollectionsBookmark,
      //     // icon: icCollectionsBookmark,
      //     permission: ENUM_PERMISSIONS.READ_CP_CPOM,
      // },
      {
        type: 'link',
        label: 'TVA',
        route: '/settings/tva',
        icon: faPercent,
        // icon: icCollectionsBookmark,
        permission: ENUM_PERMISSIONS.READ_CP_TVA,
      },
    ],
  },
];

export const qualityReport: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Suivi qualité',
    //permission: ENUM_PERMISSIONS.READ_QF_LITIGATION, //n'existe plus
    children: [
      // {
      //     type: 'link',
      //     label: 'Ticket incident équipements',
      //     icon: faTicket,
      //     route: '/administrative/ticket-equipment',
      //     permission: ENUM_PERMISSIONS.READ_INCIDENT_EQUIPMENT_FOLLOW_TICKET
      // },
      {
        type: 'link',
        label: 'Ticket incident prestations stratégiques',
        icon: faTicket,
        route: '/administrative/ticket-prestation',
        italic: true,
        // permission: ENUM_PERMISSIONS.INPUT_INCIDENT_STRATEGIC //en attente
      },
      {
        type: 'link',
        label: 'Gestion litiges',
        route: '/administrative/litigation',
        icon: icGavel,
        permission: ENUM_PERMISSIONS.READ_LITIGATION,
      },
    ],
  },
];

export const budgetReport: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Pilotage budgétaire',
    italic: true,
    // permission: ENUM_PERMISSIONS.READ_QF_LITIGATION,
    children: [
      {
        type: 'link',
        label: 'Consolidation / ventilation des dépenses',
        icon: faMoneyBill,
        route: '/budget/renforcement',
        italic: true,
        // permission: ENUM_PERMISSIONS.PB_BM_CONSOLIDATION, //en attente
      },
      {
        type: 'link',
        label: 'Construction budgétaire',
        icon: faWallet,
        route: '/budget/building',
        italic: true,
        // permission: ENUM_PERMISSIONS.PB_BM_BUDGET //en attente
      },
      {
        type: 'link',
        label: 'Suivi budgétaire',
        route: '/budget/followup',
        icon: faPiggyBank,
        italic: true,
        // permission: ENUM_PERMISSIONS.PB_BM_BUDGET_FOLLOW //en attente
      },
    ],
  },
];

// export let documentItems: NavigationItem[] = [
//     {
//         type: 'subheading',
//         label: 'BIBLIOTHEQUE DOCUMENTAIRE',
//         children: [
//             {
//                 type: 'link',
//                 label: 'Accéder aux documents',
//                 route: () => window.open('https://predik.docuware.cloud/DocuWare/Platform/WebClient', '_blank'),
//                 icon: icBook,
//                 permission: ENUM_PERMISSIONS.READ_BD_DOCUMENT
//             }
//         ]
//     }
//
// ];

export let businessModulesManagement: NavigationItem[] = [
  {
    type: 'subheading',
    label: 'Modules métier',
    children: [
      {
        type: 'dropdown',
        label: 'Performance Energétique',
        icon: icIncandescent,
        // permission: ENUM_PERMISSIONS.,
        children: [
          {
            type: 'link',
            label: 'Gestion des énergies',
            routerLinkActiveOptions: { exact: true },
            // route: () => window.open(environment.energismeUrl, '_blank'),
            route: () => 'toto',
            icon: icCollectionsBookmark,
            permission: ENUM_PERMISSIONS.READ_MM_ENERGY_PERFORMANCE_ENERGY,
          },
        ],
      },
      {
        type: 'link',
        label: 'Fonction restauration',
        route: '/restore',
        icon: faUtensils,
        italic: true,
        // permission: ENUM_PERMISSIONS.RESTORATION_FUNCTION, // TODO: replace with true permission
        //permission: ENUM_PERMISSIONS.RESTORATION_FUNCTION, //en attente
      },
    ],
  },

];

