    <div class="card w-full p-4">
        <div class="flex items-center mb-4">
            <span class="font-semibold text-lg text-gray-700">Recherche par mot clé</span>
        </div>
        <div class="mb-4">
            <div class="flex flex-row items-center w-full">
                <label for="generic-search" class="block text-gray-700 text-sm font-bold mb-2 mr-4" style="min-width: 100px;">Au générique</label>
                <div class="w-full flex border border-gray-300 text-gray-700 rounded overflow-hidden">
                    <input type="text" id="generic-search" name="generic-search" placeholder="Mot clé..."  [formControl]="genericControl"   [matAutocomplete]="groupAuto" class="p-2 w-full focus:outline-none" />
                    <!-- display button only if genericControl has value -->
                    <button *ngIf="genericControl.value" (click)="clearSelection()" class="p-2 text-gray-500 focus:outline-none">
                        &#x2715; <!-- Vous pouvez remplacer par une icône -->
                    </button>
                </div>
            </div>

        </div>
        <div class="mb-4">
            <div class="flex flex-row items-center w-full">
                <label for="catalogue-search" class="block text-gray-700 text-sm font-bold mb-2 mr-4" style="min-width: 100px;">Au catalogue</label>
                <div class="w-full flex border border-gray-300 rounded overflow-hidden">
                    <input type="text" id="catalogue-search" name="catalogue-search" placeholder="Mot clé..." class="p-2 w-full focus:outline-none" [formControl]="search" />
                </div>
            </div>
        </div>
    </div>

        <mat-autocomplete #groupAuto="matAutocomplete" panelWidth="auto" [displayWith]="displayGeneric" (optionSelected)="emitSearchData()">
            <mat-optgroup *ngFor="let generic of filteredGenerics | async" [label]="generic.type">
                <mat-option *ngFor="let item of generic.items" [value]="item">
                    {{item.label}}
                </mat-option>
            </mat-optgroup>
        </mat-autocomplete>
