import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductModel} from 'src/app/core/models/product.model';
import {ProductService} from '../product.service';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {Observable} from 'rxjs';
import {GenericProductModel} from '../../../../../core/models/generic-product.model';
import {GenericProductService} from '../../../settings/generic-product/generic-product.service';
import {map} from 'rxjs/operators';
import {FileSaverService} from 'ngx-filesaver';
import {SupplierModel} from '../../../../../core/models/supplier.model';
import {SupplierService} from '../../supplier/supplier.service';
import {TvaModel} from '../../../../../core/models/tva.model';
import {TvaService} from '../../../settings/tva/tva.service';
import {GenericCharacteristicModel} from '../../../../../core/models/generic-characteristic.model';
import {GenericCharacteristicService} from '../../../settings/generic-characteristic/generic-characteristic.service';
import {CategoryModel} from '../../../../../core/models/category.model';
import {CategoryService} from '../../../settings/category/category.service';
import {CustomerGroupComptableLine} from '../../../../../core/models/customer-group.model';
import {CustomerGroupService} from '../../../settings/customer-group/customer-group.service';
import {ENUM_PERMISSIONS} from '../../../../../core/enums/permission.enum';
import {AuthService} from '../../../../../core/services/auth.service';
import { quillConfig } from '../../../../../core/config/quill.config';


@Component({
    selector: 'vex-product-create-update',
    templateUrl: './product-create-update-delete.component.html',
    styleUrls: ['./product-create-update-delete.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/_quill.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductCreateUpdateDeleteComponent extends FormModalBaseComponent<ProductModel> implements OnInit {
    public readonly quillConfig = quillConfig;

    constructor(@Inject(MAT_DIALOG_DATA)
                public data: any,
                public dialogRef: MatDialogRef<FormModalBaseComponent<ProductModel>>,
                public service: ProductService,
                private genericProductService: GenericProductService,
                private genericCharacteristicService: GenericCharacteristicService,
                private supplierService: SupplierService,
                private tvaService: TvaService,
                private customerGroupService: CustomerGroupService,
                private fileSaver: FileSaverService,
                public categoryService: CategoryService,
                private authService: AuthService
    ) {
        super(data, dialogRef, service);
    }



    genericProduct$: Observable<GenericProductModel[]>;
    suppliers$: Observable<SupplierModel[]>;
    tva$: Observable<TvaModel[]>;
    genericCharacteristics$: Observable<GenericCharacteristicModel[]>;
    genericCharacteristics: GenericCharacteristicModel[];
    categories$: Observable<CategoryModel[]>;
    tvaAccountingAccounts: CustomerGroupComptableLine[];
    accountingAccounts: CustomerGroupComptableLine[];

    updateLabel: string;
    createLabel: string;

    purchaseUnit = ['Article', 'Colis', 'Palette'];

    canDownloadTemplateModel = false;
    canStockablePermission = false;
    canAvailableCatalogPermission = false;

    urlPattern: RegExp = /^(https?:\/\/[^\s/$.?#].[^\s]*|www\.[a-zA-Z0-9-]+\.[a-z]{2,})$/i;

    protected readonly ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    ngOnInit() {
        super.ngOnInit();
        this.createLabel = 'Ajouter un article';
        this.updateLabel = this.defaults?.label;

        // this.genericProduct$ = this.genricProductService.findAll().pipe(map(d => d.data));
        // this.suppliers$ = this.supplierService.findAll().pipe(map(d => d.data));
        // this.tva$ = this.tvaService.findAll().pipe(map(d => d.data));
        // this.categories$ = this.categoryService.findAll().pipe(map(d => d.data));
        this.genericProductAutocompleteChange();
        this.supplierAutocompleteChange();
        this.comptableLineAutocompleteChange();
        this.tvaAutocompleteChange();


        this.genericCharacteristics$ = this.genericCharacteristicService.findAll().pipe(
            map(d => d.data));

        if (this.defaults.tva) {
            this.tvaAccountingAccounts = this.defaults.tva.accountingAccounts;
            this.tvaAccountingAccounts.map(x => x.display = x.label + ' - ' + x.accountNumber);
            this.defaults.tvaAccountingAccount = this.tvaAccountingAccounts
                .find(x => x.accountNumber === this.defaults.tvaAccountingAccount?.accountNumber);
        }

        if (this.data?.specialMode === 'genericCharacteristic') {
            this.createLabel = 'Ajouter une caractéristique générique';
            this.updateLabel = this.defaults['characteristic']?.characteristic?.label;
            this.form = new FormGroup({
                _id: new FormControl(this.defaults["characteristic"]?._id),
                characteristic: new FormControl(this.defaults["characteristic"]?.characteristic, [Validators.required]),
                disableCharacteristicLabel: new FormControl(this.defaults["characteristic"]?.characteristic?.label),
                disableCharacteristicUnit: new FormControl(this.defaults["characteristic"]?.characteristic?.unit),
                value: new FormControl(this.defaults["characteristic"]?.value, [Validators.required])
            });
        } else {
            this.form = new FormGroup({
                _id: new FormControl(this.defaults?._id),
                genericProduct: new FormControl(this.defaults?.genericProduct),
                label: new FormControl(this.defaults?.label, [Validators.required]),
                reference: new FormControl(this.defaults?.reference, []),
                priceHT: new FormControl(this.defaults?.priceHT, [Validators.required]),
                priceTTC: new FormControl({value: this.defaults?.priceTTC, disabled: true}),
                previousReference: new FormControl(this.defaults?.previousReference),
                supplier: new FormControl(this.data?.supplier ? this.data?.supplier : this.defaults.supplier ? this.defaults.supplier : null, [Validators.required]),
                // manufacturer: new FormControl(this.defaults?.manufacturer),
                model: new FormControl(this.defaults?.model, []),
                tva: new FormControl(this.defaults?.tva, [Validators.required]),

                // tvaAccountingAccount: new FormControl({
                //     value: this.defaults?.tvaAccountingAccount,
                //     disabled: !this.defaults.tva
                // }),
                // accountingAccount: new FormControl({
                //     value: this.defaults?.accountingAccount,
                //     disabled: !this.defaults.tva
                // }),
                purchaseUnit: new FormControl(this.defaults?.purchaseUnit, [Validators.required]),
                conditioningUnit: new FormControl(this.defaults?.conditioningUnit),
                // conditioningQuantity: new FormControl(this.defaults?.conditioningQuantity, [Validators.required, Validators.max(9999)]),
                // perishable: new FormControl(this.defaults?.perishable ?? false),
                // stockable: new FormControl(this.defaults?.stockable),
                isAvailable: new FormControl(this.defaults?.isAvailable),
                comment: new FormControl(this.defaults ? this.defaults.comment : null),
                quoteId: new FormControl(this.data?.id ? this.data?.id : this.defaults.quoteId ? this.defaults.quoteId : null),
                // category: new FormControl(
                //     this.isReadMode() ? this.defaults?.category?.link : this.defaults?.category,
                //     [Validators.required]),
                generalSupplierCatalog : new FormControl(this.defaults ? this.defaults.generalSupplierCatalog : null, [Validators.pattern(this.urlPattern)]),
                technicalSheet : new FormControl(this.defaults ? this.defaults.technicalSheet : null, [Validators.pattern(this.urlPattern)]),
                safetyUsageProtocol : new FormControl(this.defaults ? this.defaults.safetyUsageProtocol : null, [Validators.pattern(this.urlPattern)]),
            });

            if (this.isCreateMode()) {
                this.form.get('isAvailable').setValue(true);
                this.form.get('stockable').setValue(true);
            }
        }

        this.subscription.add(
            this.form.controls.tva.valueChanges.subscribe((e) => {
                if (e) {
                    // this.form.controls.priceTTC.enable();
                    this.calculatePriceTTC();
                    this.tvaAccountingAccounts = e?.accountingAccounts?.map(x => {
                        x.display = x.accountNumber + ' - ' + x.label;
                        return x;
                    });

                    if (this.tvaAccountingAccounts?.length > 0) {
                        this.form.controls.tvaAccountingAccount.enable();
                    } else {
                        this.form.controls.tvaAccountingAccount.disable();
                    }
                }
            })
        );

        this.subscription.add(
            this.form.controls.priceHT.valueChanges.subscribe(e => {
                this.calculatePriceTTC();

            })
        );


        // this.form.controls.priceTTC.valueChanges.subscribe(e => {
        //     if (e) {
        //         const tva = this.form.getRawValue()?.tva?.historical[this.form.getRawValue()?.tva?.historical.length - 1].taux;
        //         this.form.controls.priceHT.disable();
        //         let number = e / (1 + (tva / 100));
        //         this.form.controls.priceHT.setValue(Math.round(number * 100) / 100);
        //     } else {
        //         this.form.controls.priceHT.enable();
        //         this.form.controls.priceHT.setValue(null);
        //     }
        // })

        this.authService.getCurrentUserPermissions$().subscribe( permissions => {
            this.canDownloadTemplateModel = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_FM_REF_PURCHASE);
            this.canStockablePermission = permissions.includes(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_INFO_STOCKABLE);
            this.canAvailableCatalogPermission = permissions.includes(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_INFO);
        });
    }
    hasPermission() {
        return this.authService.isReferentMetier();
    }

    isPredik()
    {
        return this.authService.isPredik();
    }

    beforeCreateItem() {
        // @ts-ignore
        this.defaults.tva = this.defaults.tva?._id;
        // @ts-ignore
        // this.defaults.category = this.defaults.category?._id;
    }

    calculatePriceTTC() {
        const tva = this.form.getRawValue()?.tva?.taux;
        const number = this.form.getRawValue()?.priceHT * (1 + (tva / 100));
        this.form.controls.priceTTC.setValue(Math.round(number * 100) / 100);
    }

    createItem() {
        if (this.data?.specialMode === 'genericCharacteristic') {
            this.loading = true;
            this.defaults = {...this.form.getRawValue().characteristic};
            if (this.form.valid) {
                const sub = this.service.addCharacteristic(this.data.product._id, this.defaults).subscribe(
                    result => {
                        this.afterCreateItem(result, null);
                    }, error => {
                        this.afterCreateItem(null, error);
                        this.setErrorsMessages(error.error.errors);
                        this.loading = false;
                    }
                );
                this.subscription.add(sub);
            } else {
                this.onFormInvalid();
                this.form.markAllAsTouched();
                this.loading = false;
            }
        } else {
            super.createItem();
        }
    }

    updateItem() {
        if (this.data?.specialMode === 'genericCharacteristic') {
            this.loading = true;
            const data = this.form.getRawValue();
            delete data.disableCharacteristicLabel;
            delete data.disableCharacteristicUnit;
            if (this.form.valid) {
                const sub = this.service.updateCharacteristic(this.data.product._id, this.defaults["characteristic"]?._id, data).subscribe(
                    result => {
                        this.afterUpdateItem(result, null);
                    }, error => {
                        this.afterUpdateItem(null, error);
                        this.setErrorsMessages(error.error.errors);
                        this.loading = false;
                    }
                );
                this.subscription.add(sub);
            } else {
                this.onFormInvalid();
                this.form.markAllAsTouched();
                this.loading = false;
            }
        } else {
            super.updateItem();
        }
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    beforeUpdateItem() {
        // @ts-ignore
        this.defaults.tva = this.defaults.tva?._id;
        // @ts-ignore
        // this.defaults.category = this.defaults.category?._id;
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    deleteItem() {
        if (this.data?.specialMode === 'genericCharacteristic') {
            this.loading = true;
            const sub = this.service.deleteCharacteristic(this.data.product._id, this.defaults["characteristic"]._id).subscribe(
                result => {
                    this.afterDeleteItem(result, null);
                }, error => {
                    this.afterDeleteItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            super.deleteItem();
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    categoriesChange(state: any = '') {
        this.categories$ = this.categoryService.findAll(null, null, null, null, state).pipe(map(d => d.data));
    }


    comptableLineAutocompleteChange(state = '') {
        this.customerGroupService.findFilteredComptableLines(state, this.defaults).subscribe(res => {
                this.accountingAccounts = res.data.map(x => {
                    x.display = x.label + ' - ' + x.accountNumber;
                    return x;
                });

            if (this.defaults?.accountingAccount && this.form.controls.accountingAccount) {
                const accountingAccount = this.accountingAccounts.find(x => x.accountNumber === this.defaults?.accountingAccount?.accountNumber);
                this.form.controls.accountingAccount.setValue(accountingAccount);
            }
        });
    }

    genericProductAutocompleteChange(state: any = '') {

        if (this.defaults.contractId) {
            this.genericProduct$ = this.genericProductService.findAllGenItemByGenEngagement([this.defaults.contractId], state);

        }else {
            this.genericProduct$ = this.genericProductService.findAll(null, null, 'label', 'asc', state).pipe(map(d => d.data));
        }

    }



    supplierAutocompleteChange(state: any = '') {
        this.suppliers$ = this.supplierService.findAll(null, null, 'label', 'asc', state).pipe(map(d => d.data));
    }

    tvaAutocompleteChange(state?: any) {
        this.tva$ = this.tvaService.findAll(null, null, 'label', 'asc', state).pipe(map(d => d.data));
    }

}
