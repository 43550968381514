import { Component, Inject, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ActivityModel } from '../../../../../../core/models/activity.model';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EstablishmentService } from '../../establishment.service';
import { PublicTypeModel } from '../../../../../../core/models/public-type.model';
import { AddressModel } from '../../../../../../core/models/address.model';
import { ErrorApi } from '../../../../../../core/models/api/error-api';
import { ActivityTypeModel } from '../../../../../../core/models/activity-type.model';
import { ActivityTypeService } from '../../../../settings/activity-type/activity-type.service';
import { map } from 'rxjs/operators';
import { PublicTypeService } from '../../../../settings/public-type/public-type.service';

@Component({
  selector: 'vex-establishment-activity-create-update-delete',
  templateUrl: './establishment-activity-create-update-delete.component.html',
  styleUrls: ['./establishment-activity-create-update-delete.component.scss']
})
export class EstablishmentActivityCreateUpdateDeleteComponent implements OnInit {

  protected subscription = new Subscription();
  defaults: ActivityModel;
  mode: CrudModeEnum = CrudModeEnum.Create;
  form: FormGroup;
  loading: boolean = false;
  establishmentId: string;
  activityTypeItem: Observable<ActivityTypeModel[]>;
  publicTypeItem: Observable<PublicTypeModel[]>;
  addressItem$: Observable<AddressModel[]>;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public dialogRef: MatDialogRef<ActivityModel>,
      private service: EstablishmentService,
      private activityTypeService: ActivityTypeService,
      private publicTypeService: PublicTypeService
  ) {
    if (this.data) {
      this.defaults = this.data?.defaults ?? {} as ActivityModel;
      this.mode = this.data.mode;
      this.establishmentId = this.data?.establishment?._id;
    }
  }

  ngOnInit(): void {
    this.addressItem$ = this.service.addresses$.pipe(map(x => {
      return x.data.map(y => {
        y.address = `${y.street}, ${y.city}`;
        return y;
      });
    }));
    this.autocompleteActivityTypeChange();
    this.autocompletePublicTypeChange();
    this.form = new FormGroup({
      _id: new FormControl(this.defaults?._id),
      label: new FormControl(this.defaults?.label, [Validators.required]),
      activityType: new FormControl(this.defaults?.activityType, [Validators.required]),
      publicType: new FormControl(this.defaults?.publicType, [Validators.required]),
      percent: new FormControl(this.defaults?.percent, [Validators.required]),
      finess: new FormControl(this.defaults?.finess),
      address: new FormControl(this.defaults?.address),
      nbOfPermanantBed: new FormControl(this.defaults?.nbOfPermanantBed),
      nbOfTemporaryBed: new FormControl(this.defaults?.nbOfTemporaryBed),
      accomodationCapacity : new FormControl(this.defaults?.accomodationCapacity)
    })
  }

  save(): void {
    if (this.isCreateMode()) {
      this.createItem();
    } else if (this.isUpdateMode()) {
      this.updateItem();
    }
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }

  isDeleteMode() {
    return this.mode === CrudModeEnum.Delete;
  }

  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  createItem() {
    this.loading = true;
    this.defaults = this.form.value;
    if (this.form.valid) {
      const sub = this.service.createActivity(this.establishmentId, this.defaults).subscribe(
          res => {
            this.close(res);
            this.loading = false;
          },
          err => {
            this.setErrorsMessages(err.error.errors);
            this.loading = false;
          }
      )
      this.subscription.add(sub);
    } else {
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  updateItem() {
    this.loading = true;
    this.defaults = this.form.value;
    if (this.form.valid) {
      const sub = this.service.updateActivity(this.establishmentId, this.defaults).subscribe(
          result => {
            this.close(result);
            this.loading = false;
          }, error => {
            this.setErrorsMessages(error.error.errors);
            this.loading = false;
          });
      this.subscription.add(sub);
    } else {
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = {messages: []};
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }

  deleteItem() {
    this.loading = true;
    const sub = this.service.deleteActivity(this.establishmentId, this.defaults?._id).subscribe(
        result => {
          this.close(result)
          this.loading = false;
        }, error => {
          this.setErrorsMessages(error.error.errors);
          this.loading = false;
        }
    );
    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  autocompleteActivityTypeChange(search: string = '') {
    this.activityTypeItem = this.activityTypeService.findAll(null, null, null, null, search).pipe(map(x => x.data));
  }

  autocompletePublicTypeChange(search: string = '') {
    this.publicTypeItem = this.publicTypeService.findAll(null ,null, null, null, search).pipe(map(x => x.data));
  }
}
