export enum OrderStatus {
  DRAFT = 'Précommande',
  PENDING = 'En validation',
  ACCEPTED = 'Validée',
  DECLINED = 'Rejetée',
  COMPLIANT = 'Réception conforme',
  PARTIAL = 'Réception partielle',
  NOCOMPLIANT = 'Réception non conforme',
  REGULARIZATION = 'Régularisation',
  EXPIRED = 'Expirée',
}
