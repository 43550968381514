import {Pipe, PipeTransform} from "@angular/core";
import {RecurringBasketModel} from "../models/contract.model";
import {DayEnum, DayTranslation} from "./day.enum";
import {MonthEnum, MonthTranslation} from "./month.enum";

export enum RecurrenceEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
}

export class RecurrenceTranslation {
    static translate(status: RecurrenceEnum): string {
        switch (status) {
            case RecurrenceEnum.Weekly:
                return 'Hebdomadaire';

            case RecurrenceEnum.Daily:
                return 'Quotidienne';

            case RecurrenceEnum.Monthly:
                return 'Mensuelle';

            case RecurrenceEnum.Yearly:
                return 'Annuelle';

            default:
                return null;
        }
    }

    static translateOccurence(recurrence, repeat, activated, month) {
        switch (recurrence) {
            case RecurrenceEnum.Daily:
                return "Tous les " + repeat + " jours";

            case RecurrenceEnum.Weekly:
                return "Toutes les " + repeat + " semaine" + (repeat > 1 ? "s" : "") + " le " + activated.map(index => DayTranslation.translate(DayEnum[Object.keys(DayEnum)[index]])).join(", ");


             case RecurrenceEnum.Monthly:
                 return `Tous les ${repeat === 1 ? '' : repeat} mois le ${activated.map(index => index === 31 ? 'dernier jour' : index + 'ème jour').join(", ")}`;

             case RecurrenceEnum.Yearly:
                 return "Tous les " + repeat + " an" + (repeat > 1 ? "s" : "") + " le " + activated.map(index => index === 31 ? 'dernier jour' : index + 'ème jour').join(", ") + " de " + MonthTranslation.translate(month);
            default:
                return null;
        }
    }
}

@Pipe({name: 'recurrenceTranslation', pure: false})
export class RecurrenceTranslationPipe implements PipeTransform {
    transform(status: RecurrenceEnum): string {
        return RecurrenceTranslation.translate(status);
    }
}

export const recurrenceEnumTranslation = (recurrence: RecurrenceEnum) => {
    return {
        value: recurrence,
        label: RecurrenceTranslation.translate(recurrence)
    };
};

export const recurrenceEnumTranslationOccurence = (recuringBasket: RecurringBasketModel) => RecurrenceTranslation.translateOccurence(recuringBasket.recurrence, recuringBasket.repeat, recuringBasket.activated, recuringBasket.month)

