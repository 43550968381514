<div class='p-4 overflow-x-auto' style='min-height: 1px; background-color: var(--background-card);'>

        <ng-container *ngTemplateOutlet="selectionActionTemplate; context: {$implicit: selectedEl}"></ng-container>

        <table
                (matSortChange)='sortEvent($event)'
                *ngIf='showSort'
                [dataSource]='dataSource'
                [matSortActive]='sortField'
                [matSortDirection]='sortOrder'
                class='w-full shadow-none'
                mat-table
                matSort
                matSortActive='dateUpload'
                matSortDirection='desc'
                matSortDisableClear
                matSortStart='desc'
                multiTemplateDataRows
        >
            <base-data-table-column
                    (onArchiveItem)='archiveItem($event)'
                    (onClickRow)='rowClick($event)'
                    (onDeleteItem)='deleteItem($event)'
                    (onSelectItem)='selectItem($event)'
                    (onSelectRow)='selectedRow($event)'
                    (onUpdateItem)='updateItem($event)'
                    (onFavoriteItem)='favoriteItem($event)'
                    (onValueChange)='valueChange($event)'
                    (onHoverItem)='hoverItem($event)'
                    *ngFor='let feiColumn of feisColumns' [actionsTemplate]="actionsTemplate"
                    [additionalDisplay]='feiColumn.additionalDisplay'
                    [attentionRequieredTooltip]="attentionRequiredTooltip"
                    [canArchive]='feiColumn.canArchive'
                    [canDelete]='feiColumn.canDelete'
                    [canSelectMultiple]="canSelectMultiple"
                    [canSort]='feiColumn.canSort'
                    [canUpdate]='feiColumn.canUpdate'
                    [canFavorite]='feiColumn.canFavorite'
                    [checkboxTooltip]="feiColumn.checkboxTooltip"
                    [clearSelectedRow]='clearSelectedEl'
                    [clickEl]="feiColumn.click"
                    [column]='feiColumn.column'
                    [customColHeader]='feiColumn.customColHeader'
                    [customColumnHeaderTemplate]='customColumnHeaderTemplate'
                    [customColumnTemplate]="customColumnTemplate"
                    [customDisplay]='feiColumn.customDisplay'
                    [customExtraHtml]="feiColumn.customExtraHtml"
                    [dataSource]='dataSource'
                    [isOrderBasket]='isOrderBasket'
                    [isPredik]='feiColumn.isPredik'
                    [label]='feiColumn.label'
                    [maxDecimal]="feiColumn.maxDecimal"
                    [previousDisplay]='feiColumn.previousDisplay'
                    [propertyDisplay]='feiColumn.propertyDisplay'
                    [routerLinkDetailRow]='routerLinkDetailRow'
                    [selectAllDisabled]="selectAllDisabled"
                    [selectedRows]="selectedRows"
                    [status]='feiColumn.status'
                    [titleCase]='feiColumn.titleCase'
                    [type]='feiColumn.type'
                    [upperCase]='feiColumn.upperCase'
                    [value]='feiColumn.value'
                    [width]='feiColumn.width'
                    [canSelectCheckBoxRows]="canSelectCheckBoxRows"
                    [isExpiredOrderContractLine]="isExpiredOrderContractLine"
                    [actionText]="actionText"
                    [isProductPrestation]='isProductPrestation'
            >
            </base-data-table-column>

            <ng-container *ngIf='accordeonRef' matColumnDef="expandedDetail">
                <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
                    <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                         class="example-element-detail w-full flex-auto">
                        <ng-container *ngTemplateOutlet="accordeonRef; context: {value: element}"
                                      class='w-full flex-auto'></ng-container>
                    </div>
                </td>
            </ng-container>

            <tr *matHeaderRowDef='displayedColumns' mat-header-row></tr>
            <tr (click)="expandedElement = expandedElement === element ? null : element"
                *matRowDef='let element; columns: displayedColumns; let i = index'
                class='hover:bg-hover trans-ease-out cursor-pointer text-secondary font-medium custom-tr'
                mat-row
                [ngStyle]="generateRowStyle(element)"
            >
            </tr>
            <ng-container *ngIf='accordeonRef'>
                <tr *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row" mat-row></tr>
            </ng-container>
        </table>
        <table
                *ngIf='!showSort'
                [dataSource]='dataSource'
                class='w-full'
                mat-table
                multiTemplateDataRows
        >

            <!--            &lt;!&ndash; Checkbox Column &ndash;&gt;-->
            <!--            <ng-container matColumnDef="select">-->
            <!--                <th mat-header-cell *matHeaderCellDef>-->
            <!--                    <mat-checkbox (change)="$event ? toggleAllRows() : null"-->
            <!--                                  [checked]="selection.hasValue() && isAllSelected()"-->
            <!--                                  [indeterminate]="selection.hasValue() && !isAllSelected()"-->
            <!--                                  [aria-label]="checkboxLabel()">-->
            <!--                    </mat-checkbox>-->
            <!--                </th>-->
            <!--                <td mat-cell *matCellDef="let row">-->
            <!--                    <mat-checkbox (click)="$event.stopPropagation()"-->
            <!--                                  (change)="$event ? selection.toggle(row) : null"-->
            <!--                                  [checked]="selection.isSelected(row)"-->
            <!--                                  [aria-label]="checkboxLabel(row)">-->
            <!--                    </mat-checkbox>-->
            <!--                </td>-->
            <!--            </ng-container>-->

            <base-data-table-column
                    (onArchiveItem)="archiveItem($event)"
                    (onClickRow)='rowClick($event)'
                    (onDeleteItem)='deleteItem($event)'
                    (onSelectItem)='selectItem($event)'
                    (onSelectRow)='selectedRow($event)'
                    (onUpdateItem)='updateItem($event)'
                    *ngFor='let feiColumn of feisColumns'
                    [actionsTemplate]="actionsTemplate"
                    [additionalDisplay]='feiColumn.additionalDisplay'
                    [canArchive]='feiColumn.canArchive'
                    [canDelete]='feiColumn.canDelete'
                    [canSelectMultiple]="canSelectMultiple"
                    [canUpdate]='feiColumn.canUpdate'
                    [column]='feiColumn.column'
                    [customColumnTemplate]="customColumnTemplate"
                    [customDisplay]='feiColumn.customDisplay'
                    [dataSource]='dataSource'
                    [label]='feiColumn.label'
                    [maxDecimal]="feiColumn.maxDecimal"
                    [previousDisplay]='feiColumn.previousDisplay'
                    [propertyDisplay]='feiColumn.propertyDisplay'
                    [routerLinkDetailRow]='routerLinkDetailRow'
                    [selectAllDisabled]="selectAllDisabled"
                    [selectedRows]="selectedRows"
                    [showSort]='showSort'
                    [titleCase]='feiColumn.titleCase'
                    [type]='feiColumn.type'
                    [upperCase]='feiColumn.upperCase'
                    [width]='feiColumn.width'
                    [isExpiredOrderContractLine]="isExpiredOrderContractLine"
            >
            </base-data-table-column>


            <!--            <ng-container matColumnDef="expandedDetail" *ngIf='accordeonRef'>-->
            <!--                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">-->
            <!--                    <div class="example-element-detail max-w-full max-w-max w-full"-->
            <!--                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">-->
            <!--                            <ng-container *ngTemplateOutlet="accordeonRef"></ng-container>-->
            <!--                    </div>-->
            <!--                </td>-->
            <!--            </ng-container>-->


            <tr *matHeaderRowDef='displayedColumns' mat-header-row></tr>
            <tr (click)="expandedElement = expandedElement === element ? null : element"
                *matRowDef='let element; columns: displayedColumns; let i = index'
                class='hover:bg-hover trans-ease-out cursor-pointer w-full max-w-full text-secondary font-medium custom-tr'
                [ngStyle]="generateRowStyle(element)"
                mat-row>
            </tr>
            <!--            <ng-container *ngIf='accordeonRef'>-->
            <!--                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
            <!--            </ng-container>-->
        </table>
        <table class="w-full flex justify-center p-1">
            <!--            <tr *ngIf="!dataSource.data.length">-->
            <!--                <td class="center" [attr.colspan]="displayedColumns.length">-->
            <!--                    Aucune donnée trouvée !-->
            <!--                </td>-->
            <!--            </tr>-->
        </table>

    <mat-paginator #paginator (page)='pageEvent($event)' *ngIf='showPaginator' [length]='count'
                   [pageSizeOptions]='pageSizeOptions'
                   [pageSize]='pageSize'
                   showFirstLastButtons></mat-paginator>
</div>
