<ng-container *ngIf="canCreateMode || canUpdateMode">
    <form (ngSubmit)="save()" [formGroup]="form" style="min-width: 750px; width: 750px">
        <base-form-header [mode]='mode' createLabel='Ajouter un N° Client'
                          [updateLabel]='"Modifier un N° Client"'></base-form-header>

        <mat-dialog-content fxLayout="column">
            <base-form-input
                    *ngIf="!isUpdateMode()"
                    label="Etablissement"
                    type="autocomplete"
                    controlName="establishment"
                    displayProperty='label'
                    [items$]='establishment$'
                    (autocompleteChange)='establishmentAutoCompleteChange($event)'
            ></base-form-input>

            <base-form-input
                    label="N° Client"
                    controlName="clientNumber"
            ></base-form-input>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    [loading]="loading"
                    type='submit'
                    [mode]='mode'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
                    (onDelete)="deleteItem()"
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="canDeleteMode">
    <form-modal-delete-base
            [label]="'le N° client ' + data.defaults.clientNumber"
            defaultMessage="Etes-vous sûr de vouloir supprimer ce N° Client ?"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    ></form-modal-delete-base>
</ng-container>

<ng-container *ngIf="canArchiveMode">
    <form-modal-archive-base
            [label]="'le N° client ' + data.defaults.clientNumber"
            defaultMessage="Etes-vous sûr de vouloir archiver ce N° Client ?"
            (onClose)="close()"
            (onArchive)="archiveItem()"
    ></form-modal-archive-base>
</ng-container>
