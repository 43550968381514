import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    async,
    BehaviorSubject,
    concatMap,
    debounce,
    delay,
    from,
    interval,
    Observable,
    of,
    Subscription,
    switchMap, tap
} from 'rxjs';
import {GenericEngagementModel} from '../../../../../../core/models/generic-engagement.model';
import {GenericEngagementService} from '../../../../settings/generic-engagement/generic-engagement.service';
import {debounceTime, map, take} from 'rxjs/operators';
import {SupplierModel} from '../../../../../../core/models/supplier.model';
import {SupplierService} from '../../../supplier/supplier.service';
import {ContractModel} from '../../../../../../core/models/contract.model';
import {MatDialog} from '@angular/material/dialog';
import {SupplierContactModel} from '../../../../../../core/models/supplier-contact.model';
import {BaseContactType, ContactTypeEnum} from '../../../../../../core/enums/contact-type.enum';
import {ContactTypeService} from '../../../../settings/contact-type/contact-type.service';
import {
    SupplierContactCreateUpdateDeleteComponent
} from '../../../supplier/modals/supplier-contact-create-update-delete/supplier-contact-create-update-delete.component';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import moment from 'moment/moment';
import {
    GenericPaymentConditionService
} from '../../../../settings/generic-payment-condition/generic-payment-condition.service';
import {GenericPaymentConditionModel} from '../../../../../../core/models/generic-payment-condition.model';
import {DocumentElement, GedService} from '../../../../admin/playground/docuware-playground/ged.service';
import {GED_CONFIG, GedTypeEnum} from '../../../../../../core/enums/ged-type.enum';
import {FileSaverService} from 'ngx-filesaver';
import {ContractService} from '../../contract.service';
import {IDocument, IDocumentsQueryResult} from '../../../../../../core/ui/ged-ui/interface/docuware.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {ContractTypeEnum, ContractValidityEnum} from '../../../../../../core/enums/contract.enum';
import {DialogConfirmComponent} from '../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import {ContractStatusEnum} from '../../../../../../core/enums/contract-status.enum';
import {
    ContractResumeCreateUpdateDeleteComponent
} from '../../modals/contract-resume-create-update-delete/contract-resume-create-update-delete.component';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {SnackbarService} from '../../../../../../core/services/snackbar.service';
import {
    SupplierCreateUpdateDeleteComponent
} from '../../../supplier/modals/supplier-create-update-delete/supplier-create-update-delete.component';
import {UserProfileModel} from '../../../../../../core/models/user-profile.model';
import {UserProfileService} from '../../../user-profile/user-profile.service';
import {GenericEngagementFormatEnum} from '../../../../../../core/enums/generic-engagement-format.enum';

@Component({
    selector: 'vex-contract-details-resume',
    templateUrl: './contract-details-resume.component.html',
    styleUrls: ['./contract-details-resume.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ],
    encapsulation: ViewEncapsulation.None
})
export class ContractDetailsResumeComponent implements OnInit {

    public form: FormGroup;

    public genericEngagement$: Observable<GenericEngagementModel[]>;
    public genericPaymentCondition$: Observable<GenericPaymentConditionModel[]>;
    public suppliers$: Observable<SupplierModel[]>;
    public supplierContacts$: Observable<SupplierContactModel[]>;

    public contractValidityEnum = Object.values(ContractValidityEnum);

    public loading = false;
    public loadingSave = false;
    public loadingFile = false;

    public files: DocumentElement[] = [];
    documentTypes = ['Contrat', 'Avenant contrat'];
    private nbTotal$ = new BehaviorSubject<number>(0);
    private totalPercentage = 0;
    private currentBar$ = new BehaviorSubject<number>(0);
    private nbErrors$ = new BehaviorSubject<number>(0);
    private gedType: GedTypeEnum;
    readonly contractStatusEnum = ContractStatusEnum;

    private subscription = new Subscription();

    private isInit = true;
    public isQuote: boolean;

    private contract: ContractModel;

    public minNbDay = 0;
    public maxNbDay = 10;
    public currentSupplierName = '';


    private id: string;
    private isCalculatingRenewal = false;

    ContractStatusEnum = ContractStatusEnum;
    ContractValidityEnum = ContractValidityEnum;

    public engagementContacts$: Observable<UserProfileModel[]>;

    // public standardTheoreticalDeliveryDate: string;
    // public urgentTheoreticalDeliveryDate: string;


    constructor(
        private genericEngagementService: GenericEngagementService,
        private supplierService: SupplierService,
        private contactTypeService: ContactTypeService,
        private genericPaymentConditionService: GenericPaymentConditionService,
        private dialog: MatDialog,
        private _gedService: GedService,
        public fileSaver: FileSaverService,
        public contractService: ContractService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBarService: SnackbarService,
        private userProfileService: UserProfileService
    ) {
        const gedConfig = GED_CONFIG.contract;
        this.gedType = gedConfig.gedType;
        this._gedService.setUrl(gedConfig.apiUrl);
        this._gedService.setType(gedConfig.gedType);
    }

    ngOnInit(): void {
        this.initData();
        this.initForm();
        this.checkValidityDates();
    }

    async initData() {
        this.id = this.activatedRoute.snapshot.params?.id;

        this.genericEngagementAutoCompleteChange();
        this.supplierAutocompleteChange();
        this.genericPaymentConditionAutoCompleteChange();

        this.loadingFile = true;
        this.subscription.add(
            this._gedService.allDocumentsByPredikId(this.id)
                .subscribe((doc: IDocumentsQueryResult) => {

                    const filteredItems = doc.Items.filter(item =>
                        item.Fields.some(field => field.FieldName === "DOCUMENT_TYPE" && field.Item === "Contrat")
                    );

                    this.files = doc.Items.length > 0 ? [this.prepareDocument(filteredItems[0])] : [];

                    this.loadingFile = false;
                })
        );
    }

    public addSupplier() {
        const dialogRef = this.dialog.open(SupplierCreateUpdateDeleteComponent, {});

        dialogRef.afterClosed().subscribe((supplier: any) => {
            if (supplier) {
                this.form.get('supplier').setValue(supplier.data, {emitEvent: true});
                this.form.get('supplierContact').setValue(null);
            }
        });
    }

    private prepareDocument(document: IDocument): DocumentElement {
        return {
            id: document.FileCabinetId,
            DCWDOCID: document.Id,
            dateUpload: document.LastModified,
            originalFileName: document.Title,
            size: document.FileSize,
            contentType: document.ContentType,
            status: 'IMPORTING',
            _file: null,
            progress: new BehaviorSubject<number>(100),
            uploadError: '',
            errorType: '',
            type: this.parseDocumentType(document.ContentType)
        };
    }

    parseDocumentType(contentType: string): string {
        switch (contentType) {
            case 'application/pdf':
                return 'PDF';
            case 'image/jpeg':
                return 'JPEG';
            case 'image/jpg':
                return 'JPG';
            case 'image/png':
                return 'PNG';
            default:
                return '';
        }
    }

    private initForm() {
        this.form = new FormGroup({
            _id: new FormControl(),
            genericEngagement: new FormControl(null, Validators.required),
            object: new FormControl(null, Validators.required),
            supplier: new FormControl(null, Validators.required),
            supplierContractReference: new FormControl(null, Validators.required),
            supplierContact: new FormControl(null, Validators.required),
            validity: new FormControl(null, Validators.required),
            startValidityDate: new FormControl(null, Validators.required),
            endValidityDate: new FormControl({value: null, disabled: true}, Validators.required),
            signatureDate: new FormControl({value: null, disabled: true}, Validators.required),
            renewalValidity: new FormControl({value: null, disabled: true}, [Validators.required, Validators.min(0)]),
            minNotice: new FormControl({value: null, disabled: true}, [Validators.required, Validators.min(0)]),
            paymentCondition: new FormControl(null, Validators.required),
            nbDay: new FormControl({value: null, disabled: true}, [Validators.required, Validators.min(0)]),
            renewalNb: new FormControl({value: null, disabled: true}, [Validators.min(0)]),
            renewalDate: new FormControl({value: null, disabled: true}),
            noticePeriod: new FormControl(7),
            engagementContact: new FormControl(null),
        });

        if (this.isInit) {
            this.subscription.add(
                this.contractService.entity$.subscribe((contract: ContractModel) => {
                    this.contract = contract;

                    if (contract) {
                        this.engagementContactsAutoCompleteChange();
                        this.supplierContactsAutocompleteChange();

                        // let contractFormat;
                        // if (contract.quoteId) {
                        //     contractFormat = ContractTypeEnum.QUOTE;
                        // } else {
                        //     contractFormat = ContractTypeEnum.CONTRACT;
                        // }

                        if (contract.paymentCondition) {
                            const paymentCondition = contract.paymentCondition;
                            contract.paymentCondition.displayProperty = paymentCondition.nbDay ? `${paymentCondition.label} (Maximum ${paymentCondition.nbDay} jours)` : `${paymentCondition.label}`;
                            this.maxNbDay = paymentCondition.nbDay;
                            if (contract.creationStep !== 1) {
                                // displayProperty = paymentCondition.label with X replace by nbDay
                                if (paymentCondition.nbDay) {
                                    const replacedLabel = paymentCondition.label.replace('X', contract.nbDay.toString());
                                    contract.paymentCondition.displayProperty = replacedLabel;
                                }
                            }
                        }

                        this.form.patchValue({
                            _id: contract._id,
                            genericEngagement: contract.genericEngagement,
                            object: contract.object,
                            supplier: contract.supplier,
                            supplierContractReference: contract.supplierContractReference,
                            supplierContact: contract.supplierContact,
                            validity: contract.validity,
                            startValidityDate: contract.startValidityDate,
                            endValidityDate: contract.endValidityDate,
                            signatureDate: contract.signatureDate,
                            renewalValidity: contract.renewalValidity,
                            minNotice: contract.minNotice,
                            paymentCondition: contract.paymentCondition,
                            nbDay: contract.nbDay,
                            renewalNb: contract.renewalNb,
                            renewalDate: contract.renewalDate,
                            noticePeriod: contract.noticePeriod ? contract.noticePeriod : 7,
                            engagementContact: contract.engagementContact
                        }, {emitEvent: false});

                    }

                    if (contract.startValidityDate) {
                        this.form.controls.endValidityDate.enable({emitEvent: false});
                    }

                    if (contract.endValidityDate) {
                        this.form.controls.signatureDate.enable({emitEvent: false});
                    }

                    if (contract.validity === ContractValidityEnum.DENIABLE) {
                        this.form.controls.minNotice.enable({emitEvent: false});
                        this.form.controls.renewalValidity.enable({emitEvent: false});
                        this.form.controls.renewalNb.enable({emitEvent: false});
                        this.form.controls.renewalDate.enable({emitEvent: false});
                    }

                    if (contract.paymentCondition && contract.paymentCondition.nbDay) {
                        this.form.controls.nbDay.enable({emitEvent: false});
                    } else {
                        this.form.controls.nbDay.disable({emitEvent: false});
                    }

                    if (contract.genericEngagement?.format === GenericEngagementFormatEnum.QUOTE) {
                        this.form.controls.validity.setValue(ContractValidityEnum.FIRM, {emitEvent: false});
                        this.form.controls.validity.disable({emitEvent: false});
                    } else {
                        this.form.controls.validity.enable({emitEvent: false});
                    }

                    // if (contract.deliveryTime?.standardTheoreticalDeliveryValue) {
                    //     if (contract.deliveryTime.standardTheoreticalDeliveryFinalizedDate && (contract.status === ContractStatusEnum.VALIDATED || contract.status === ContractStatusEnum.EXPIRED)) {
                    //         this.standardTheoreticalDeliveryDate = moment(contract.deliveryTime.standardTheoreticalDeliveryFinalizedDate).format('DD/MM/YYYY');
                    //     } else {
                    //         const deliveryTimeValueStandard = contract.deliveryTime.standardTheoreticalDeliveryValue;
                    //         const currentDay = new Date();
                    //         const newDate = moment(currentDay).add(deliveryTimeValueStandard, 'days').format('DD/MM/YYYY');
                    //         this.standardTheoreticalDeliveryDate = newDate;
                    //     }
                    // }
                    //
                    // if (contract.deliveryTime?.urgentTheoreticalDeliveryValue) {
                    //     if (contract.deliveryTime.urgentTheoreticalDeliverFinalizedDate && (contract.status === ContractStatusEnum.VALIDATED || contract.status === ContractStatusEnum.EXPIRED)) {
                    //         this.urgentTheoreticalDeliveryDate = moment(contract.deliveryTime.urgentTheoreticalDeliverFinalizedDate).format('DD/MM/YYYY');
                    //     } else {
                    //         const deliveryTimeValueUrgent = contract.deliveryTime.urgentTheoreticalDeliveryValue;
                    //         const currentDay = new Date();
                    //         const newDate = moment(currentDay).add(deliveryTimeValueUrgent, 'days').format('DD/MM/YYYY');
                    //         this.urgentTheoreticalDeliveryDate = newDate;
                    //     }
                    // }
                })
            );
            this.isInit = false;
        }


        this.form.valueChanges
            .pipe(debounceTime(500))
            .subscribe(value => {
                this.contractService.update(this.form.getRawValue()).subscribe();
            });

        this.subscription.add(
            this.form.controls.validity.valueChanges.subscribe((validity: ContractValidityEnum) => {
                if (validity && validity === ContractValidityEnum.DENIABLE) {
                    this.form.controls.minNotice.enable({emitEvent: false});
                    this.form.controls.renewalValidity.enable({emitEvent: false});
                    this.form.controls.minNotice.setValidators([Validators.required, Validators.min(0)]);
                    this.form.controls.renewalValidity.setValidators([Validators.required, Validators.min(0)]);

                    this.form.controls.renewalNb.enable({emitEvent: false});
                    this.form.controls.renewalDate.enable({emitEvent: false});
                    this.form.controls.renewalNb.setValidators([Validators.min(0)]);

                } else {
                    this.form.controls.minNotice.setValue(null, {emitEvent: false});
                    this.form.controls.renewalValidity.setValue(null, {emitEvent: false});
                    this.form.controls.minNotice.disable({emitEvent: false});
                    this.form.controls.renewalValidity.disable({emitEvent: false});
                    this.form.controls.minNotice.setValidators(null);
                    this.form.controls.renewalValidity.setValidators(null);


                    this.form.controls.renewalNb.setValue(null, {emitEvent: false});
                    this.form.controls.renewalDate.setValue(null, {emitEvent: false});
                    this.form.controls.renewalNb.disable({emitEvent: false});
                    this.form.controls.renewalDate.disable({emitEvent: false});
                    this.form.controls.renewalNb.setValidators(null);
                    this.form.controls.renewalDate.setValidators(null);
                }
            })
        );


        this.subscription.add(
            this.form.controls.supplier.valueChanges.subscribe(async (supplier: SupplierModel) => {
                if (supplier) {
                    try {
                        const result = await this.supplierContactAutoCompleteChange(null, supplier._id);

                        if (result.length > 0) {
                            this.form.controls.supplierContact.setValue(result[0], {emitEvent: false});
                        }
                    } catch (error) {
                        console.error('Error fetching supplier contacts:', error);
                    }
                } else {
                    this.form.controls.supplierContact.setValue(null, {emitEvent: false});
                }
            })
        );

        this.subscription.add(
            this.form.controls.startValidityDate.valueChanges.subscribe((date: Date) => {
                if (date) {
                    this.form.controls.endValidityDate.enable({emitEvent: false});
                } else {
                    this.form.controls.endValidityDate.setValue(null, {emitEvent: false});
                    this.form.controls.endValidityDate.disable();
                }
            })
        );

        this.subscription.add(
            this.form.controls.endValidityDate.valueChanges.subscribe((date: Date) => {
                const startValidityDate = this.form.controls.startValidityDate.value;
                const startDate = moment(startValidityDate).startOf('day').toDate();
                const endDate = moment(date).startOf('day').toDate();


                if (date) {
                    if (endDate >= startDate) {
                        this.form.controls.signatureDate.enable({emitEvent: false});
                        this.resultRenewalNbField();
                        this.resultRenewalNbFromRenewalDate();
                    } else {
                        this.form.controls.signatureDate.setValue(null, {emitEvent: false});
                        this.form.controls.signatureDate.disable({emitEvent: false});
                    }

                } else {
                    this.form.controls.signatureDate.setValue(null, {emitEvent: false});
                    this.form.controls.signatureDate.disable({emitEvent: false});
                }
            })
        );

        this.subscription.add(
            this.form.controls.renewalValidity.valueChanges.subscribe(() => {
                this.resultRenewalNbField();
                this.resultRenewalNbFromRenewalDate();
            })
        );

        this.subscription.add(
            this.form.controls.renewalNb.valueChanges.subscribe(() => {
                this.resultRenewalNbField();
            })
        );

        this.subscription.add(
            this.form.controls.renewalDate.valueChanges.subscribe(() => {
                this.resultRenewalNbFromRenewalDate();
                this.resultRenewalNbField();
            })
        );


        this.subscription.add(
            this.form.controls.paymentCondition.valueChanges.subscribe((paymentCondition: GenericPaymentConditionModel) => {
                if (paymentCondition) {
                    this.maxNbDay = paymentCondition.nbDay;
                    if (paymentCondition.nbDay) {

                        this.form.controls.nbDay.enable({emitEvent: false});
                    } else {
                        this.form.controls.nbDay.disable({emitEvent: false});
                    }
                } else {
                    this.form.controls.nbDay.disable({emitEvent: false});
                }
                this.form.controls.nbDay.setValue(null, {emitEvent: false});
            })
        );

        this.subscription.add(
            this.form.controls.nbDay.valueChanges.subscribe((nbDay: number) => {
                if (nbDay && nbDay > this.maxNbDay) {
                    this.form.controls.nbDay.setValue(this.maxNbDay, {emitEvent: false});
                    this.form.controls.nbDay.setErrors({max: true});
                }
            })
        );


        this.form.controls.genericEngagement.valueChanges.subscribe((value) => {

            this.engagementContacts$ = this.userProfileService.findAll(null, null, null, null, '', {
                isEngagementContacts: value?.engagementCategory?._id
            }).pipe(
                map(userProfile => {
                    return userProfile.data.map(x => x);
                }),

                tap(userProfile => {
                    if (!userProfile.find(x => x._id === this.form.controls.engagementContact.value?._id)) {
                        this.form.controls.engagementContact.setValue(null, {emitEvent: false});
                    }
                })
            );



            if (value.format === ContractTypeEnum.QUOTE) {
                this.form.controls.validity.setValue(ContractValidityEnum.FIRM, {emitEvent: false});
                this.form.controls.validity.disable({emitEvent: false});
            } else {
                this.form.controls.validity.enable({emitEvent: false});
            }

            if ((value.format === ContractTypeEnum.QUOTE && !this.form.controls.supplierContact.value?.contactTypes.map(x => x.label).includes(ContactTypeEnum.DEVIS))
                || (value.format === ContractTypeEnum.CONTRACT && !this.form.controls.supplierContact.value?.contactTypes.map(x => x.label).includes(ContactTypeEnum.CONTRACT))) {
                this.form.controls.supplierContact.setValue(null, {emitEvent: false});
            }


            return new Promise((resolve, reject) => {
                this.supplierContacts$ = this.supplierService.findAllSupplierContactById(null, null, null, null, '', {
                    supplierId: this.form.controls.supplier.value?._id,
                    type: BaseContactType.find(x => x.label === value.format)?._id
                }).pipe(
                    map(x => x.data.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`})))
                );

                this.supplierContacts$.subscribe(
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );

            });

        });
    }

    signatureDateFilter = (d: Date | null): boolean => {
        const endValidityDate = this.form.controls.endValidityDate.value;
        return d <= moment(endValidityDate).toDate();
    }

    addDenunciationDate(): void {
        const sub = this.dialog.open(ContractResumeCreateUpdateDeleteComponent, {
            data: {
                contract: this.contract
            }
        }).afterClosed().subscribe(result => {

        });
        this.subscription.add(sub);
    }


    public genericEngagementAutoCompleteChange(state: any = '', contractFormat?: any) {

        const prefixPattern = /^(C\s*\/\s*|D\s*\/\s*)/i;
        const prefixedLabel: string = prefixPattern.test(state) ? state.match(prefixPattern)[0] : null;

        this.genericEngagement$ = this.genericEngagementService
            .findAll(null, null, null, null, state, {inUserPerimeter: true, prefixedLabel: prefixedLabel})
            .pipe(map(genericEngagement => {

                return genericEngagement.data.map(x => {
                    if (x.format === 'Devis') {
                        x.label = `D / ${x.label}`;
                    }
                    if (x.format === 'Contrat') {
                        x.label = `C / ${x.label}`;
                    }
                    return x;
                });

            }));

    }

    public async engagementContactsAutoCompleteChange(state: any = '') {
        this.engagementContacts$ = this.userProfileService.findAll(null, null, null, null, state, {isEngagementContacts: this.contract?.genericEngagement?.engagementCategory?._id })
            .pipe(map(userProfile => {
                return userProfile.data.map(x => {
                    return x;
                });
            }));
    }

    public async supplierContactsAutocompleteChange(state: any = '') {
        this.supplierContacts$ = this.supplierService.findAllSupplierContactById(null, null, null, null, '', {
            supplierId: this.form.controls.supplier.value?._id,
            type: BaseContactType.find(x => x.label === this.contract.genericEngagement?.format)?._id
        }).pipe(
            map(x => x.data.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`})))
        );
    }

    public genericPaymentConditionAutoCompleteChange(state: any = '') {
        this.genericPaymentCondition$ = this.genericPaymentConditionService
            .findAll(null, null, null, null, state)
            .pipe(map(genericPaymentCondition => {
                return genericPaymentCondition.data.map(x => {
                    if (x.nbDay) {
                        x.displayProperty = `${x.label} (Maximum ${x.nbDay} jours)`;
                    } else {
                        x.displayProperty = `${x.label}`;
                    }
                    return x;
                });
            }));
    }

    public supplierAutocompleteChange(state: any = '') {
        this.suppliers$ = this.supplierService
            .findAll(null, null, null, null, state)
            .pipe(map(x => x.data));
    }

    public supplierSetNewCreated(name: string) {
        this.suppliers$
            .pipe(map(d => d.find(s => s.label === name)), take(1))
            .subscribe((res: any) => {
                this.form.get('supplier').setValue(res);
            });
    }

    // public supplierSetNewCreated(name: string) {
    //     this.suppliers$
    //         .pipe(
    //             tap(data => console.log('Data from suppliers$: ', data.map(x=> x.label))), // Ajout du log
    //             map(d => d.find(s => s.label === name)),
    //             take(1)
    //         )
    //         .subscribe((res: any) => {
    //             console.log('Supplier created:', res);
    //             this.form.get('supplier').setValue(res);
    //         });
    // }

    // public async supplierContactAutoCompleteChange(state: any = '', supplierId: string): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         const sub = this.contractService.entity$.pipe(take(1)).subscribe((contract: ContractModel) => {
    //             const contractGenericEngagementStatus = contract.genericEngagement?.format;
    //
    //             this.supplierContacts$ = this.supplierService
    //                 .findAllSupplierContactById(null, null, null, null, state, {
    //                     supplierId: supplierId,
    //                     type: BaseContactType.find(x => x.label === contractGenericEngagementStatus)._id
    //                 })
    //                 .pipe(
    //                     map(x => {
    //                         console.log('Raw data from service:', x);
    //                         const mappedData = x.data.map(y => ({ ...y, fullName: `${y.firstName} ${y.lastName}` }));
    //                         console.log('Mapped data:', mappedData);
    //                         return mappedData;
    //                     })
    //                 );
    //
    //             this.supplierContacts$.subscribe(
    //                 data => {
    //                     resolve(data);
    //                 },
    //                 error => {
    //                     reject(error);
    //                 }
    //             );
    //         });
    //         this.subscription.add(sub);
    //     });
    // }

    public async supplierContactAutoCompleteChange(state: any = '', supplierId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const sub = this.contractService.entity$.pipe(take(1)).subscribe((contract: ContractModel) => {
                const contractGenericEngagementStatus = contract.genericEngagement?.format;

                this.supplierContacts$ = this.supplierService
                    .findAllSupplierContactById(null, null, null, null, state, {
                        supplierId: supplierId,
                        type: BaseContactType.find(x => x.label === contractGenericEngagementStatus)?._id
                    })
                    .pipe(
                        map(x => {
                            const mappedData = x.data.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`}));
                            return mappedData;
                        })
                    );

                this.supplierContacts$.subscribe(
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
            this.subscription.add(sub);
        });
    }

    updateStep(step: number) {
        this.contractService.updateStep(this.id, {creationStep: step}).subscribe(res => {
            this.router.navigate(['/management/engagement/', this.id, 'perimeter']).then();
        });
    }

    async switchToUpdateMode() {
        this.dialog.open(DialogConfirmComponent, {
            data: {
                description: 'Êtes-vous sûr de vouloir modifier les informations ? Attention cela peut avoir des conséquences sur les autres étapes.',
                cancelText: 'ANNULER',
                title: 'Modifier les informations',
                validText: 'CONFIRMER',
                call$: this.contractService.updateStep(this.id, {creationStep: 1})
            }
        });
    }

    async openSupplierContactModal() {
        this.contractService.entity$.pipe(take(1)).subscribe((contract: ContractModel) => {

            const contractGenericEngagementStatus = contract.genericEngagement?.format ?? this.form.controls.genericEngagement.value?.format

            const sub4 = this.dialog.open(SupplierContactCreateUpdateDeleteComponent, {
                data: {
                    id: this.form.controls.supplier.value._id,
                    mode: CrudModeEnum.Create,
                    isCreateInContract: true,
                    defaults: {
                        // contactTypes: BaseContactType.ticket-filter(x => x.label === ContactTypeEnum.CONTRACT)
                        contactTypes: BaseContactType.filter(x => x.label === contractGenericEngagementStatus)
                    },
                }
            }).afterClosed().subscribe(async (result) => {
                if (result) {
                    const contractGenericEngagementStatus = this.contract.genericEngagement?.format ?? this.form.controls.genericEngagement.value?.format

                    if (result.data.contactTypes.map(x => x.label).includes(contractGenericEngagementStatus)) {
                        await this.supplierService.findAllSupplierContactById(null, null, null, null, null, {supplierId: this.form.controls.supplier.value._id}).subscribe((supplier: any) => {
                            this.form.controls.supplierContact.setValue(supplier.data.find(x => x._id === result.data._id));
                        });
                    }
                }
            });
            this.subscription.add(sub4);
        });
    }

    validForm() {

        this.nbTotal$.next(this.files.length);
        this.totalPercentage = 100 * this.files.length;
        this.currentBar$.next(0);
        this.nbErrors$.next(0);

        this.loadingSave = true;
        from(this.files).pipe(
            concatMap(item => of(item).pipe(delay(500)))
        ).subscribe(document => {
                document.status = 'ON_LOAD';
                const documentElement = this._gedService.uploadDocument(document, {
                    documentType: 'Contrat',
                    predikId: this.id
                }, this.loadingSave);

                documentElement.progress.pipe(debounce(() => interval(100)))
                    .subscribe(value => {
                        if (value === 500) {
                            this.loadingSave = false;
                        }
                        if (this.files[this.files.length - 1].id === document.id && value === 101) {

                            this.initData();
                        }
                    });
            },
            error => console.error('toto'),
        );
        // this._gedService.uploadDocument(this.files[0], this.form.getRawValue())
        //     .subscribe(res => {
        //       this.form.reset({
        //         predikId: this.form.get('predikId').value
        //       });
        //       // this.form.get('predikId').setValue(this.quote._id);
        //       this.loadingSave = false;
        //       this.initData();
        //     });
    }

    fileDropped($event: any) {
        if ($event.length > 1) {
            this.files = [this.files[this.files.length - 1]];
        }
        this.subscription.add(
            this._gedService.allDocumentsByPredikId(this.id)
                .pipe(switchMap((res: IDocumentsQueryResult) => {
                    if (res.Items.length > 0) {
                        const doc: any = this._gedService.mapToDataSource(res);
                        return this._gedService.deleteDocument(doc[0].DWDOCID);
                    }
                    return of('No document');
                }))
                .subscribe((res: IDocumentsQueryResult) => {
                    console.log('RES', res);
                })
        );
        this.validForm();
    }

    downloadDocument(id) {
        this._gedService.downloadDocument(id)
            .subscribe((res: any) => {
                this.fileSaver.save(res.body, res.headers.get('filename'));
            });
    }

    resultRenewalNbField() {
        if (!this.isCalculatingRenewal) {
            this.isCalculatingRenewal = true;
            const endValidityDate = this.form.controls.endValidityDate.value;
            const renewalValidity = this.form.controls.renewalValidity.value;
            const renewalNb = this.form.controls.renewalNb.value;
            if (
                endValidityDate &&
                renewalValidity !== null &&
                renewalNb !== null &&
                renewalValidity !== undefined &&
                renewalNb !== undefined
            ) {
                const renewalDate = moment(endValidityDate).add(renewalValidity * renewalNb, 'months').toDate();
                this.form.controls.renewalDate.setValue(renewalDate);
            }
            this.isCalculatingRenewal = false;
        }
    }

    resultRenewalNbFromRenewalDate() {
        if (!this.isCalculatingRenewal) {
            this.isCalculatingRenewal = true;
            const endValidityDate = moment(this.form.controls.endValidityDate.value);
            const renewalDate = moment(this.form.controls.renewalDate.value);
            const renewalValidity = this.form.controls.renewalValidity.value;
            const renewalNbField = this.form.controls.renewalNb.value;

            if (
                endValidityDate.isValid() &&
                renewalDate.isValid() &&
                renewalValidity !== null &&
                renewalNbField !== undefined &&
                renewalNbField !== null &&
                renewalValidity !== undefined
            ) {
                const monthsDiff = renewalDate.diff(endValidityDate, 'months');
                let renewalNb = monthsDiff / renewalValidity;

                // Pour l'instant, je réalise des arrondis car pas de décimale demandée (carte 1313) : Rob, le 12/01/2024
                // Afficher 0 si la valeur est inférieure à 0.5, pour l'instant
                renewalNb = renewalNb < 0.5 ? 0 : (renewalNb % 1 >= 0.5 ? Math.ceil(renewalNb) : Math.floor(renewalNb));

                this.form.controls.renewalNb.setValue(renewalNb);
            }
            this.isCalculatingRenewal = false;
        }
    }

    private checkValidityDates() {
        this.contractService.findById(this.id).subscribe((contract: any) => {
            const startDate = moment(contract.data.startValidityDate).startOf('day').toDate();
            const endDate = moment(contract.data.endValidityDate).startOf('day').toDate();
            if (endDate < startDate) {
                this.form.get('signatureDate').disable();
            } else {
                this.form.get('signatureDate').enable();
            }
        });
    }

    onDateChanged(event: MatDatepickerInputEvent<Date>) {
        const startValidityDate = this.form.get('startValidityDate').value;
        if (event.value && startValidityDate) {
            const signatureDate = event.value;
            const validityDate = new Date(startValidityDate);

            if (signatureDate > validityDate) {
                this.form.get('signatureDate').setValue(validityDate, {emitEvent: false});
                this.snackBarService.danger('La date de signature ne peut être ultérieure à la date de début de contrat');
            }
        }
    }
}
