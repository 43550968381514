import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {map} from 'rxjs/operators';
import {lastValueFrom} from 'rxjs';
import moment from "moment";
import {PermissionGuardService} from "../../services/permission-guard.service";

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
    private currentPermissions;
    private permissions = [];
    private logicalOp = 'AND';
    private isHidden = true;

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService,
        private permissionGuardService: PermissionGuardService
    ) {
    }

    ngOnInit(): void {
        this.authService.getCurrentUserPermissions$().subscribe(x => {
            this.currentPermissions = x;
            this.updateView();
        })
    }

    @Input()
    set hasPermission(val) {
        this.permissions = val;
        this.updateView();
    }

    @Input()
    set hasPermissionOp(permop) {
        this.logicalOp = permop;
        this.updateView();
    }

    private updateView() {
        if (this.checkPermission()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        let hasPermission = false;
        if (!this.permissions || this.permissions.length === 0) return true;
        if (this.currentPermissions && this.currentPermissions.length > 0) {
            if (this.permissions) {
                for (const checkPermission of this.permissions) {

                    if (this.currentPermissions.includes(checkPermission)) {
                        hasPermission = true;

                        if (this.logicalOp === 'OR') {
                            break;
                        }
                    } else {
                        hasPermission = false;
                        if (this.logicalOp === 'AND') {
                            break;
                        }
                    }
                    hasPermission = true;
                }
            }

        }

        return this.permissions ? hasPermission : true;
    }

}