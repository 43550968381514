export enum ContractValidityEnum {
    FIRM = 'Ferme',
    DENIABLE = 'Dénonciable',
}

export enum ContractTypeEnum {
    QUOTE = 'Devis',
    CONTRACT = 'Contrat',
}

export enum ContractTypeOfRevisionEnum {
    FIXED = 'Contractuel - Fixe',
    VARIABLE = 'Contractuel - A confirmer',
    NOT_DEFINED = 'Non défini au contrat',
}

export enum ContractDeliveryCostEnum {
    FREE_SHIPPING = 'Franco de port',
    FREE_SHIPPING_FROM = 'Franco de port à partir de',
    PACKAGE = 'Forfait',
    NOT_DEFINED = 'Non défini au contrat'
}

export enum ContractDeliveryTimeEnum {
    URGENT_THEORETICAL_DELIVERY_TIME = 'Délai de livraison théorique urgent',
    STANDARD_THEORETICAL_DELIVERY_TIME = 'Délai de livraison théorique standard',
}