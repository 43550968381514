import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FeiColumn } from '../../interfaces/fei-column.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import icDone from "@iconify/icons-ic/twotone-done";
import icPlus from "@iconify/icons-ic/twotone-plus";
import icMinus from "@iconify/icons-ic/twotone-minus";
import icClose from "@iconify/icons-ic/twotone-close";
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'catalog-base',
    template: ''
})
export class CatalogBase implements OnInit, OnDestroy {
    subscription = new Subscription();
    tabs = ['Articles', 'Prestations'];
    tabsVisible = [false, false];
    dataSourceCatalog = new MatTableDataSource();
    displayedColumnsCatalog: Array<string>;
    filters: any = {};
    feisColumnsCatalog: Array<FeiColumn>;
    sortField$ = new BehaviorSubject(null);
    sortOrder$ = new BehaviorSubject('asc');
    pageIndex$ = new BehaviorSubject(1);
    pageSize$ = new BehaviorSubject(10);
    count = 0;
    search: string;
    loading = false;
    selectedIndex = 0;
    icDone = icDone;
    icPlus = icPlus;
    icMinus = icMinus;
    icClose = icClose;
    actions: Array<any> = [];
    loading$ = new BehaviorSubject(false);
    isEditionAllowed: boolean = true;
    entityType: 'product' | 'prestation';
    entityDisplayName: 'Article' | 'Prestation';
    entityService: any;

    constructor() {
        this.displayedColumnsCatalog = this.feisColumnsCatalog?.map(feisColumn => feisColumn.column);
    }
    ngOnInit() {
        this.initData();
    }

    initData() {
        //Override this method
    }

    pageEvent(event: PageEvent): void {
        this.pageIndex$.next(event.pageIndex + 1)
        this.pageSize$.next(event.pageSize)
        this.initData();
    }

    sortEvent(event: Sort): void {
        this.sortField$.next(event.active)
        this.sortOrder$.next(event.direction)
        this.initData();
    }

    initProducts(service: any) {
        this.entityService = service;
        this.entityType = "product";
        this.entityDisplayName = "Article";
        this.displayedColumnsCatalog = this.feisColumnsCatalog.map((feiColumn: FeiColumn) => feiColumn.column);
    }

    initPrestations(service: any) {
        this.entityService = service;
        this.entityType = "prestation";
        this.entityDisplayName = "Prestation";
        this.displayedColumnsCatalog = this.feisColumnsCatalog.map((feiColumn: FeiColumn) => feiColumn.column);
    }

    updateCatalogueQuantity(element, direction: string) {
        this.dataSourceCatalog = new MatTableDataSource(this.dataSourceCatalog.data.map((entity: any) => {
            if (entity._id === element._id) {
                const minQuantity = element.minQuantity ?? 1;
                if (direction === "minus" && entity.quantity === minQuantity) return entity;
                if (direction === "change") {
                    if (entity.quantity < minQuantity) {
                        entity.quantity = minQuantity;
                    }
                    return entity;
                }
                direction === "plus" ? entity.quantity++ : entity.quantity--;
            }
            return entity;
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addToBasket(element) {
        //Override this method
    }

    tabChange(selectedIndex: number) {
        //Override this method
    }

    removeItemFromBasket(item: any) {
        //Override this method
    }

    updateItemBasketQuantity(item: any, direction: string) {
        //Override this method
    }

    onFilterEvent(filtersData: any) {
        //Override this method
    }

    isTabProduct() {
        return this.entityType === 'product';
    }

    isTabPrestation() {
        return this.entityType === 'prestation';
    }
}
