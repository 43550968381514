import { Injectable } from '@angular/core';
import { EstablishmentModel } from 'src/app/core/models/establishment.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { AddressModel } from '../../../../core/models/address.model';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { map, mergeMap } from 'rxjs/operators';
import { SupplierModel } from '../../../../core/models/supplier.model';
import { UserProfileService } from '../user-profile/user-profile.service';
import { SupplierContactModel } from '../../../../core/models/supplier-contact.model';
import { UserProfileModel } from '../../../../core/models/user-profile.model';
import { ActivityModel } from '../../../../core/models/activity.model';
import { controlClassNames } from '@angular/core/schematics/migrations/typed-forms/util';
import {EnergyIdModel} from '../../../../core/models/energy-id.model';

@Injectable({
    providedIn: 'root'
})
export class EstablishmentService extends BaseService<EstablishmentModel>{
    defaultSort = 'createdAt';

    protected _contacts = new BehaviorSubject<UserProfileModel[]>([] as UserProfileModel[]);
    readonly contacts$ = this._contacts.asObservable();
    protected _addresses = new BehaviorSubject<ObjectApiInterface<AddressModel[]>>({} as ObjectApiInterface<AddressModel[]>);
    readonly addresses$ = this._addresses.asObservable();

    constructor(
        public http: HttpClient,
        private userProfileService: UserProfileService
    ) {
        super(http, 'establishment');
    }

    // async getContact(search?: string) {
    //     this.entity$.pipe(
    //         switchMap((establishment: any) => {
    //             if (establishment?._id) {
    //                 console.log('icxic', establishment._id);
    //                 return this.userProfileService.findAll(null, null, null, 'desc', search, { establishment: establishment._id });
    //             }
    //             return new Observable<any>();
    //         })
    //     ).subscribe((contact) => {
    //         if (contact?.data) {
    //             this._contacts.next(contact.data);
    //         }
    //     });
    // }

    deletingValidators(){
        return  this.http.delete<ObjectApiInterface<EstablishmentModel>>(`${this.baseUrlApi}/deleteEmptyValidators`);
    }

    getContact(establishmentId?: string, search= ''): Observable<any> {
        return this.userProfileService.findAll(null, null, null, 'desc', search, { establishment: establishmentId,  }).pipe(map(x => {
            this._contacts.next(x.data);
            return x;
        }));
    }
    findAllAsTreeView(contractId) {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/tree-view/${contractId}`);
    }

    findAllAsSelect(noLimit?: boolean, sortField: string = 'label', sortOrder: string = 'asc', search?: string, filters?: any)  {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/findAllAsSelect`,
            {
                params: new HttpParams()
                    .set('noLimit', noLimit )
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
            );
    }

    findAllAffectedToUser(contractId) {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/user-establishments/${contractId}`);
    }

    findAllAddress(page?: number, perPage?: number, sortField: string = null, sortOrder: string = 'desc', search?: string, filters?: any) {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/address/list`, {
            params: new HttpParams()
                .set('page', page?.toString() ?? '1')
                .set('perPage', perPage?.toString() ?? '30')
                .set('sort', `${sortField}@${sortOrder}`)
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        }).pipe(map(x => {
            this._addresses.next(x);
            return x;
        }));
    }

    importAddress(entity, establishmentId: string): Observable<ObjectApiInterface<ActivityModel>> {
        return this.http.post<ObjectApiInterface<ActivityModel>>(`${this.baseUrlApi}/import-establishment-address/${establishmentId}`, entity).pipe(map((x: any) => {
            this.findAllAddress(null, null, null, 'desc', null, {establishmentId: establishmentId}).subscribe();
            return x;
        }));
    }

    downloadExcelAddressTemplate(): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.get<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/download-excel-establishment-address-template`);
    }

    createAddress(establishmentId: string, address: AddressModel): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.post<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/${establishmentId}/createAddress`, address)
            .pipe(map(x => {
                console.log(x);
                const entity = this._addresses.getValue();
                entity.data.push(x.data);
                this._addresses.next(entity);
                return x;
            }));
    }

    deleteAddress(id: string, addressId: string): Observable<ObjectApiInterface<AddressModel>> {
        return this.http.delete<ObjectApiInterface<AddressModel>>(`${this.baseUrlApi}/${id}/deleteAddress/${addressId}`).pipe(map(x => {
                const entity = this._addresses.getValue();
                const findedIdx = entity.data.findIndex(y => y._id === addressId);
                if (findedIdx !== -1) {
                    entity.data.splice(findedIdx, 1);
                    this._addresses.next(entity);
                }
                return x;
            }
        ));
    }

    updateAddress(id: string, data: any): Observable<ObjectApiInterface<EstablishmentModel>> {
        return this.http.put<ObjectApiInterface<EstablishmentModel>>(`${this.baseUrlApi}/${id}/updateAddress/${data._id}`, data)
            .pipe(map(x => {
                const entity = this._addresses.getValue();
                const findedIdx = entity.data.findIndex(y => y._id === data._id);
                if (findedIdx !== -1) {
                    entity.data[findedIdx] = data;
                    this._addresses.next(entity);
                }
                return x;
            }));
    }

    createActivity(establishmentId: string, activity: ActivityModel): Observable<ObjectApiInterface<ActivityModel>> {
        return this.http.post<ObjectApiInterface<ActivityModel>>(`${this.baseUrlApi}/${establishmentId}/createActivity`, activity)
            .pipe(map(x => {
                const entity = this._entity.getValue();
                entity.activity.push(x.data);
                this._entity.next(entity);
                return x;
            }));
    }

    deleteActivity(id: string, activityId: string): Observable<ObjectApiInterface<ActivityModel>> {
        return this.http.delete<ObjectApiInterface<ActivityModel>>(`${this.baseUrlApi}/${id}/deleteActivity/${activityId}`).pipe(map(x => {
                const entity = this._entity.getValue();
                const findedIdx = entity.activity.findIndex(y => y._id === activityId);
                if (findedIdx !== -1) {
                    entity.activity.splice(findedIdx, 1);
                    this._entity.next(entity);
                }
                return x;
            }
        ));
    }

    updateActivity(id: string, data: any): Observable<ObjectApiInterface<EstablishmentModel>> {
        return this.http.put<ObjectApiInterface<EstablishmentModel>>(`${this.baseUrlApi}/${id}/updateActivity/${data._id}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }


    // Analytics Methods
    createAnalyticsLine(establishmentId: string, data:any){
        return this.http.post<any>(`${this.baseUrlApi}/${establishmentId}/analytics/line/create`, data)
            .pipe(map(x => {
                console.log('test2')
                // this._entity.lines.push(x);
                return x;
            }));
    }

    archiveAnalyticsLine(establishmentId: string, data:any){
        data._id = establishmentId;
        data.isArchive = true;
        return this.http.put<any>(`${this.baseUrlApi}/${establishmentId}/analytics/line/archive`, data)
            .pipe(map(x => {
                return x;
            }));
    }

    updateAnalyticsLine(establishmentId: string, data:any){
        console.log(data);
        return this.http.put<any>(`${this.baseUrlApi}/${establishmentId}/analytics/line/update`, data)
            .pipe(map(x => {
                return x;
            }));
    }

    findAllAnalyticsLines(page?: number, perPage?: number, sortField: string = 'accountNumber', sortOrder: string = 'asc', search?: string, filters?: any) {
        if (!filters.isArchive)
        {
            filters.isArchive = false;
        }
        // TODO : if necessery add autocomplete when this function is call in bill to keep paginition and remove perPage 500
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/analytics/line/list`, {
            params: new HttpParams()
                .set('page', page?.toString() ?? '1')
                .set('perPage', perPage?.toString() ?? '500')
                .set('sort', `${sortField}@${sortOrder}`)
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        });
    }

    downloadExcelAnalyticLinesTemplate() {
        return this.http.get(`${this.baseUrlApi}/analytic/lines/download-analytic-excel-template`);
    }

    importAnalytic(establishmentId, entity) {
        return this.http.post(`${this.baseUrlApi}/${establishmentId}/analytic/line/import`, entity);
    }

    getValidator(establishmentId, cateogryId) {
        return this.http.get<any>(`${this.baseUrlApi}/${establishmentId}/get-validator/${cateogryId}`)
            .pipe(map(x => {
                // if (x.data?.businessReferent) {
                //     x.data.businessReferent.fullName = `${x.data.businessReferent.firstName} ${x.data.businessReferent.lastName}`;
                // }

                if (x.data?.referentN1) {
                    x.data.referentN1.fullName = `${x.data.referentN1.firstName} ${x.data.referentN1.lastName}`;
                }

                if (x.data?.referentN2) {
                    x.data.referentN2.fullName = `${x.data.referentN2.firstName} ${x.data.referentN2.lastName}`;
                }

                if (x.data?.buisnessReferents?.length > 0) {
                    x.data.buisnessReferents = x.data.buisnessReferents.map(b => {
                        b.fullName = `${b.firstName} ${b.lastName}`;
                        return b;
                    });
                }

                if (x.data?.purchasers?.length > 0) {
                    x.data.purchasers = x.data.purchasers.map(purchaser => {
                        purchaser.fullName = `${purchaser.firstName} ${purchaser.lastName}`;
                        return purchaser;
                    });
                }
                return x.data;
            }));
    }
    setValidator(establishmentId, data) {
        return this.http.post(`${this.baseUrlApi}/${establishmentId}/set-validator`, data);
    }

    getThresholdForUserProfile(ids: Array<string>) {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/get-seuil-for-userProfile`, {
            params: new HttpParams()
                .set('ids', ids.join(',')),
        });
    }

    transformEstablishmentData(data: EstablishmentModel[]){
        const transformedData = [];

        for (const establishment of data) {

            const transformedItem = {
                _id: establishment._id,
                label: establishment.label,
                genericLabel: null,
                genericId: null,
                establishmentId: null,
                establishmentLabel: null,
                buildingId: null,
                buildingLabel: null,
            }
            transformedData.push({ ...transformedItem });

        }

        transformedData.sort((a, b) => a.label.localeCompare(b.label));
        return transformedData;
    }

}


