<ng-container>

<!--    <div class='flex flex-row px-gutter border-b py-4 mb-6'>-->
<!--        <h2 class='title m-0'>Elements intégrés au contrat</h2>-->
<!--&lt;!&ndash;        <h2 class='title m-0'> Elements intégré à la prestation</h2>&ndash;&gt;-->
<!--    </div>-->

    <ng-container *ngIf="!prestationToPLan; else prestationTemplate">
        <div class='flex flex-row px-gutter border-b py-4 mb-6'>
            <h2 class='title m-0'>Elements intégrés au contrat</h2>
        </div>
    </ng-container>

    <ng-template #prestationTemplate>
        <div class='flex flex-row px-gutter border-b py-4 mb-6'>
            <h2 class='title m-0'> Elements intégrés à la prestation</h2>
        </div>
    </ng-template>

<!--    <mat-progress-bar mode="indeterminate" *ngIf="this.loading"></mat-progress-bar>-->

    <ng-container *ngIf="!readMode; else readOnly">
        <div *ngFor="let item of displayedItemsList">
            <mat-checkbox
                    [value]="item"
                    [checked]="item?.checked"
                    [disabled]="isCheckboxDisabled"
                    (change)="toggleSelectionItem($event.checked, item)"
            >
                {{ item.displayLabel }}
            </mat-checkbox>
        </div>
    </ng-container>

    <ng-template #readOnly>
        <div *ngFor="let item of displayedItemsList"
             class="leading-relaxed">
                {{ item.displayLabel }}
        </div>
    </ng-template>

</ng-container>