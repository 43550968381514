import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormModalBaseComponent} from '../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {ProductModel} from '../../../../../core/models/product.model';
import {Observable} from 'rxjs';
import {GenericProductModel} from '../../../../../core/models/generic-product.model';
import {SupplierModel} from '../../../../../core/models/supplier.model';
import {TvaModel} from '../../../../../core/models/tva.model';
import {GenericCharacteristicModel} from '../../../../../core/models/generic-characteristic.model';
import {CategoryModel} from '../../../../../core/models/category.model';
import {CustomerGroupComptableLine} from '../../../../../core/models/customer-group.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductService} from '../product.service';
import {GenericProductService} from '../../../settings/generic-product/generic-product.service';
import {GenericCharacteristicService} from '../../../settings/generic-characteristic/generic-characteristic.service';
import {SupplierService} from '../../supplier/supplier.service';
import {TvaService} from '../../../settings/tva/tva.service';
import {CustomerGroupService} from '../../../settings/customer-group/customer-group.service';
import {FileSaverService} from 'ngx-filesaver';
import {CategoryService} from '../../../settings/category/category.service';
import {AuthService} from '../../../../../core/services/auth.service';
import {map} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ENUM_PERMISSIONS} from '../../../../../core/enums/permission.enum';
import {ActivatedRoute} from '@angular/router';
import {ContractService} from '../../contract/contract.service';
import {Location} from '@angular/common';
import {ResponseTypeEnum} from '../../../../../core/base/enum/response-type.enum';
import {CrudModeEnum} from '../../../../../core/base/enum/crud-mode.enum';
import {b64ToBlob} from '../../../../../core/helpers/b64ToBlob.helper';
import {ContentTypeEnum} from '../../../../../core/base/enum/content-type.enum';
import {ErrorApi} from '../../../../../core/models/api/error-api';
import {UserProfileModel} from '../../../../../core/models/user-profile.model';
import {ContractModel, ContractPerimeterModel} from '../../../../../core/models/contract.model';
import {node} from '../../../../../core/ui/tree-checkbox/tree-checkbox.component';
import {EstablishmentService} from '../../establishment/establishment.service';
import {ContractTypeOfRevisionEnum} from '../../../../../core/enums/contract.enum';
import {SnackbarService} from '../../../../../core/services/snackbar.service';
import {quillConfig} from '../../../../../core/config/quill.config';

@Component({
    selector: 'vex-product-contract-create-update-delete',
    templateUrl: './product-contract-create-update-delete.component.html',
    styleUrls: ['./product-contract-create-update-delete.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/_quill.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductContractCreateUpdateDeleteComponent extends FormModalBaseComponent<ProductModel> implements OnInit {

    public quillConfig = quillConfig;

    genericProduct$: Observable<GenericProductModel[]>;
    suppliers$: Observable<SupplierModel[]>;
    tva$: Observable<TvaModel[]>;
    genericCharacteristics$: Observable<GenericCharacteristicModel[]>;
    genericCharacteristics: GenericCharacteristicModel[];
    categories$: Observable<CategoryModel[]>;
    tvaAccountingAccounts: CustomerGroupComptableLine[];
    accountingAccounts: CustomerGroupComptableLine[];

    form: FormGroup;
    importForm: FormGroup;
    currentUser: UserProfileModel;

    updateLabel: string;
    createLabel: string;

    purchaseUnits = ['Article', 'Colis', 'Palette'];

    canDownloadTemplateModel = false;
    canStockablePermission = false;
    canAvailableCatalogPermission = false;

    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    contract: ContractModel;

    CrudModeEnum = CrudModeEnum;

    // perimeter
    public node: node[];
    public isLoading = false;
    assignedEstablishments: node[] = [];
    perimeter: ContractPerimeterModel[] = [];


    isRevisionProductAmount = false;

    urlPattern: RegExp = /^(https?:\/\/[^\s/$.?#].[^\s]*|www\.[a-zA-Z0-9-]+\.[a-z]{2,})$/i;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<ProductModel>>,
                public service: ProductService,
                private genericProductService: GenericProductService,
                private genericCharacteristicService: GenericCharacteristicService,
                private supplierService: SupplierService,
                private tvaService: TvaService,
                private customerGroupService: CustomerGroupService,
                private fileSaver: FileSaverService,
                public categoryService: CategoryService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private location: Location,
                private contractService: ContractService,
                private establishmentService: EstablishmentService,
                public snackbarService: SnackbarService
    ) {
        super(data, dialogRef, service);
    }

    async ngOnInit() {
        super.ngOnInit();

        if (this.isUpdateMode()) {
            this.perimeter = this.defaults.perimeter;
        }

        this.contract = this.data.contract;
        if (
            this.contract.sustainabilityAndPriceTrans.typeOfRevision === ContractTypeOfRevisionEnum.FIXED &&
            this.contract.sustainabilityAndPriceTrans.globalRevision === false
        ) {
            this.isRevisionProductAmount = true;
        }

        this.createLabel = 'Ajouter un article au contrat';
        this.updateLabel = this.defaults?.label;

        // this.genericProduct$ = this.genericProductService.findAll().pipe(map(d => d.data));
        // this.suppliers$ = this.supplierService.findAll().pipe(map(d => d.data));
        // this.tva$ = this.tvaService.findAll().pipe(map(d => d.data));
        // this.categories$ = this.categoryService.findAll().pipe(map(d => d.data));
        this.genericProductAutocompleteChange();
        this.supplierAutocompleteChange();
        this.accountLineAutoChange();
        this.tvaAutocompleteChange();

        this.genericCharacteristics$ = this.genericCharacteristicService.findAll().pipe(
            map(d => d.data));


        if (this.defaults.tva) {
            this.tvaAccountingAccounts = this.defaults.tva.accountingAccounts;
            this.tvaAccountingAccounts.map(x => x.display = x.label + ' - ' + x.accountNumber);
            this.defaults.tvaAccountingAccount = this.tvaAccountingAccounts
                .find(x => x.accountNumber === this.defaults.tvaAccountingAccount?.accountNumber);
        }

        if (this.data?.specialMode === 'genericCharacteristic') {
            this.createLabel = 'Ajouter une caractéristique générique';
            this.updateLabel = this.defaults['characteristic']?.characteristic?.label;
            this.form = new FormGroup({
                _id: new FormControl(this.defaults['characteristic']?._id),
                characteristic: new FormControl(this.defaults['characteristic']?.characteristic, [Validators.required]),
                disableCharacteristicLabel: new FormControl(this.defaults['characteristic']?.characteristic?.label),
                disableCharacteristicUnit: new FormControl(this.defaults['characteristic']?.characteristic?.unit),
                value: new FormControl(this.defaults['characteristic']?.value, [Validators.required])
            });
        } else {
            this.form = new FormGroup({
                _id: new FormControl(this.defaults?._id),
                label: new FormControl(this.defaults?.label, [Validators.required]),
                priceHT: new FormControl(this.defaults?.priceHT, [Validators.required]),
                priceTTC: new FormControl({value: this.defaults?.priceTTC, disabled: true}),
                genericProduct: new FormControl(this.defaults?.genericProduct),
                //    supplier: new FormControl(this.defaults?.supplier),
                reference: new FormControl(this.defaults?.reference),
                previousReference: new FormControl(this.defaults?.previousReference),
                manufacturer: new FormControl(this.defaults?.manufacturer),
                model: new FormControl(this.defaults?.model, []),
                tva: new FormControl(this.defaults?.tva, [Validators.required]),
                tvaAccountingAccount: new FormControl({
                    value: this.defaults?.tvaAccountingAccount,
                    //   disabled: !this.defaults.tva // à decommanter pour bloquer le champs
                }),
                accountingAccount: new FormControl({
                    value: this.defaults?.accountingAccount,
                    //   disabled: !this.defaults.tva // à decommanter pour bloquer le champs
                }),
                purchaseUnit: new FormControl(this.defaults?.purchaseUnit, [Validators.required]),
                conditioningUnit: new FormControl(this.defaults?.conditioningUnit),
                // conditioningQuantity: new FormControl(this.defaults?.conditioningQuantity, [Validators.required, Validators.max(9999)]),
                // perishable: new FormControl(this.defaults?.perishable ?? false),
                stockable: new FormControl(this.defaults?.stockable ?? false),
                isAvailable: new FormControl(this.defaults?.isAvailable ?? true),
                isFullPerimeter: new FormControl(this.defaults?.isFullPerimeter ?? true),
                quoteId: new FormControl(this.data?.id ? this.data?.id : this.defaults.quoteId ? this.defaults.quoteId : null),
                minQuantity: new FormControl(1),
                // category: new FormControl(this.isReadMode() ? this.defaults?.category?.link : this.defaults?.category, [Validators.required]),
                revisionAmount: new FormControl(this.defaults?.revisionAmount),
                comment: new FormControl(this.defaults ? this.defaults.comment : null),
                generalSupplierCatalog : new FormControl(this.defaults ? this.defaults.generalSupplierCatalog : null, [Validators.pattern(this.urlPattern)]),
                technicalSheet : new FormControl(this.defaults ? this.defaults.technicalSheet : null, [Validators.pattern(this.urlPattern)]),
                safetyUsageProtocol : new FormControl(this.defaults ? this.defaults.safetyUsageProtocol : null, [Validators.pattern(this.urlPattern)]),
            });

            // Watch genericProduct value changes so we can enable/disable stockable checkbox based on it
            this.subscription.add(
                this.form.controls.genericProduct.valueChanges.subscribe((e) => {
                    if (e.stockable) {
                        this.canStockablePermission = true;
                    } else {
                        this.canStockablePermission = false;
                        this.form.controls.stockable.setValue(false);
                    }
                })
            );

            if (this.isImportMode()) {
                this.importForm = new FormGroup({
                    file: new FormControl('', [Validators.required])
                });
            }


        }

        this.subscription.add(
            this.form.controls.tva.valueChanges.subscribe((e) => {
                if (e) {
                    // this.form.controls.priceTTC.enable();
                    this.computePriceTTC();
                    this.tvaAccountingAccounts = e?.accountingAccounts?.map(x => {
                        x.display = x.accountNumber + ' - ' + x.label;
                        return x;
                    });
                    if (this.tvaAccountingAccounts?.length > 0) {
                        this.form.controls.tvaAccountingAccount.enable();
                    } else {
                        this.form.controls.tvaAccountingAccount.disable();
                    }
                }
            })
        );

        this.subscription.add(
            this.form.controls.priceHT.valueChanges.subscribe(e => {
                this.computePriceTTC();
            })
        );


        // this.form.controls.priceTTC.valueChanges.subscribe(e => {
        //     if (e) {
        //         const tva = this.form.getRawValue()?.tva?.historical[this.form.getRawValue()?.tva?.historical.length - 1].taux;
        //         this.form.controls.priceHT.disable();
        //         let number = e / (1 + (tva / 100));
        //         this.form.controls.priceHT.setValue(Math.round(number * 100) / 100);
        //     } else {
        //         this.form.controls.priceHT.enable();
        //         this.form.controls.priceHT.setValue(null);
        //     }
        // })

        const subPermissions = this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadTemplateModel = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_FM_REF_PURCHASE);
            this.canStockablePermission = permissions.includes(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_INFO_STOCKABLE) &&
                this.defaults?.genericProduct?.stockable !== false;
            this.canAvailableCatalogPermission = permissions.includes(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_INFO);
        });
        this.subscription.add(subPermissions);

        this.currentUser = this.authService.currentUserValue();


        // await this.service.getGenProductByGenEngagement(this.contract._id).subscribe((x: any) => {
        //     this.genericProduct$ = x.data;
        //
        // });
    }

    beforeCreateItem() {
        // @ts-ignore
        this.defaults.tva = this.defaults.tva?._id;
        this.defaults.contractId = this.contract._id;
        this.defaults.supplier = this.contract.supplier;
        this.defaults.perimeter = this.perimeter;
        // @ts-ignore
        // this.defaults.category = this.defaults.category?._id;
    }

    computePriceTTC() {
        const tva = this.tvaService.getRateByDate(this.form.getRawValue()?.tva).rate;
        const number = this.form.getRawValue()?.priceHT * (1 + (tva / 100));
        this.form.controls.priceTTC.setValue(Math.round(number * 100) / 100);
    }

    getTvaRate($event: TvaModel){
        return `${this.tvaService.getRateByDate($event).rate.toFixed(2)}%`;
    }

    createItem() {
        this.defaults.contractId = this.data?.contractId;
        if (this.data?.specialMode === 'genericCharacteristic') {
            this.loading = true;
            this.defaults = {...this.form.getRawValue().characteristic};
            if (this.form.valid) {
                const sub = this.service.addCharacteristic(this.data.product._id, this.defaults).subscribe(
                    result => {
                        this.afterCreateItem(result, null);
                    }, error => {
                        this.afterCreateItem(null, error);
                        this.setErrorsMessages(error.error.errors);
                        this.loading = false;
                    }
                );
                this.subscription.add(sub);
            } else {
                this.onFormInvalid();
                this.form.markAllAsTouched();
                this.loading = false;
            }
        } else {
            this.loading = true;
            this.defaults = this.form.getRawValue();
            this.beforeCreateItem();
            if (this.form.valid) {
                const sub = this.service.create(this.defaults).subscribe(
                    result => {
                        this.afterCreateItem(result, null);
                        this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, this.data.messageResult);
                    }, error => {
                        console.log(error);
                        this.showErrorUniquenessReference(error);
                        this.afterCreateItem(null, error);
                        this.setErrorsMessages(error.error.errors);
                        this.loading = false;
                    }
                );
                this.subscription.add(sub);
            } else {
                this.onFormInvalid();
                this.form.markAllAsTouched();
                this.loading = false;
            }
        }
    }

    updateItem() {
        this.loading = true;
        if (this.form.valid) {
            if (this.data?.specialMode === 'genericCharacteristic') {
                const data = this.form.getRawValue();
                delete data.disableCharacteristicLabel;
                delete data.disableCharacteristicUnit;

                const sub = this.service.updateCharacteristic(this.data.product._id, this.defaults['characteristic']._id, data).subscribe(
                    result => this.afterUpdateItem(result, null),
                    error => {
                        this.afterUpdateItem(null, error);
                        this.setErrorsMessages(error.error.errors);
                        this.loading = false;
                    }
                );
                this.subscription.add(sub);
            } else {
                this.defaults = this.form.getRawValue();
                this.beforeUpdateItem();

                const sub = this.service.update(this.defaults).subscribe(
                    result => {
                        this.afterUpdateItem(result, null);
                        this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Update, this.data.messageResultUpdate);
                    },
                    error => {
                        this.showErrorUniquenessReference(error);
                        this.afterUpdateItem(null, error);
                        this.setErrorsMessages(error.error.errors);
                        this.loading = false;
                    }
                );
                this.subscription.add(sub);
            }
        } else {
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterDeleteItem(result?: any, error?: any) {
        if (result) {
            this.contractService.findById(this.defaults.contractId).subscribe();
            this.close(result);
        }
    }

    beforeUpdateItem() {
        // @ts-ignore
        this.defaults.tva = this.defaults.tva?._id;
        this.defaults.supplier = this.contract.supplier;
        this.defaults.perimeter = this.perimeter;
        this.defaults.contractId = this.contract._id;
        // @ts-ignore
        // this.defaults.category = this.defaults.category?._id;
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    deleteItem() {
        if (this.data?.specialMode === 'genericCharacteristic') {
            this.loading = true;
            const sub = this.service.deleteCharacteristic(this.data.product._id, this.defaults['characteristic']._id).subscribe(
                result => {
                    this.afterDeleteItem(result, null);
                }, error => {
                    this.afterDeleteItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            super.deleteItem();
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    categoriesChange(state: any = '') {
        this.categories$ = this.categoryService.findAll(null, null, null, null, state).pipe(map(d => d.data));
    }


    accountLineAutoChange(state = '') {
        this.customerGroupService.findFilteredComptableLines(state, this.defaults).subscribe(res => {
            this.accountingAccounts = res.data.map(x => {
                x.display = x.label + ' - ' + x.accountNumber;
                return x;
            });

            if (this.defaults?.accountingAccount && this.form.controls.accountingAccount) {
                const accountingAccount = this.accountingAccounts
                    .find(x => x.accountNumber === this.defaults?.accountingAccount?.accountNumber);
                this.form.controls.accountingAccount.setValue(accountingAccount);
            }


        });
    }

    async genericProductAutocompleteChange(state: any = '') {
        // this.genericProduct$ = await this.genericProductService.findAll(null, 30, "label", "asc", state, {getGenItemByGenEngagement: this.contract._id}, this.contract._id).pipe(map(d => d.data));
        this.genericProduct$ = this.genericProductService.findAllGenItemByGenEngagement([this.contract._id], state);
    }

    supplierAutocompleteChange(state: any = '') {
        this.suppliers$ = this.supplierService.findAll(null, null, 'label', 'asc', state).pipe(map(d => d.data));
    }

    tvaAutocompleteChange(state?: any) {
        this.tva$ = this.tvaService.findAll(null, null, 'label', 'asc', state).pipe(map(d => d.data));
    }

    importItems() {
        this.defaults.supplier = this.contract?.supplier;

        this.importDefaults = this.importForm.value;

        const importStartTime = Date.now();

        this.beforeImportItem();

        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.service.importProductContractItems(this.importDefaults, this.contract._id).subscribe(
                result => {
                    // this.close(result);
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    //   this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });

            setTimeout(() => {
                if (!sub.closed) {
                    const importDuration = Date.now() - importStartTime;
                    if (importDuration >= 2000) {
                        this.snackbarService.warning('L\'importation peut prendre quelques secondes. Veuillez patienter.', 15000);
                    }
                }
            }, 2000);

            this.subscription.add(sub);
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    setImportErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.importForm.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.importForm.controls[error.property].setErrors(err);
            console.log(err);
        });
        this.importForm.markAllAsTouched();
    }


    exportProductContractTemplate() {
        this.service.downloadExcelTemplateProductContract(this.contract._id).subscribe((x: any) => {
            const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel);
            this.fileSaver.save(b, x.data.fileName);
        });
    }

    archiveItem(): void {
        this.loading = true;
        this.beforeArchiveItem();
        // @ts-ignore
        const call$ = this.defaults?.isArchive ? this.service.unarchive(this.defaults) : this.service.archive(this.defaults);
        const sub = call$.subscribe(
            result => {
                this.afterArchiveItem(result, null);
                this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Delete, result.message);
            }, error => {
                this.afterArchiveItem(null, error);
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    onSelectedItemsChange(selectedItems: []) {
        this.perimeter = selectedItems;
    }

    showErrorUniquenessReference(error: any) {
        const errors = error.error.errors.filter(x => x.statusCode === 5503);
        if (errors) {
            this.snackbarService.warning('Enregistrement impossible : cette référence existe déjà au contrat au même prix et sur le même périmètre.');
        }
    }
}
