import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import {CustomerGroupComptableLine, CustomerGroupModel} from '../../../../core/models/customer-group.model';
import { map } from 'rxjs/operators';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import {
    UserProfileValidatorLevel,
    UserProfileValidatorSeuil
} from '../../../../core/enums/user-profile-validator-seuil.enum';
import { UserProfileValidator } from '../../../../core/models/user-profile.model';
import { CategoryModel } from '../../../../core/models/category.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupService extends BaseService<CustomerGroupModel> {
    defaultSort = 'name';

    constructor(public http: HttpClient) {
        super(http, 'customer-group');
    }

    deletingValidators() {
        return  this.http.delete<ObjectApiInterface<CustomerGroupModel>>(`${this.baseUrlApi}/deleteEmptyValidators`);
    }

    setValidatorsChecked(checked: boolean) {
        this._entity.value.checkValidator = checked;
    }

    getCurrent() {
        return this.http.get<ObjectApiInterface<CustomerGroupModel>>(`${this.baseUrlApi}/getCurrent`).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }


    getValidators(matriceLevel?: UserProfileValidatorLevel) {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/getValidators?matriceLevel=${matriceLevel}`).pipe(map(x => {
            if (this._entity.value?.validators) {
                this._entity.value.validators = x.data;
            }
            return x;
        }));
    }

    setValidator(category: CategoryModel) {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/setValidator`, category)
            .pipe(map(x => {

                let exist = this._entity.value?.validators?.find(y => y.category === x.data.category);


                x.data.children = x.data.children.map(z => {
                    if (z?._id) {
                        z.category = z._id;
                    }
                    return z;
                });

                if (exist) {
                    this._entity.value.validators.map(y => {
                        if (y.category === x.data.category) {
                            y.seuilA = x.data.seuilA;
                            y.seuilB = x.data.seuilB;
                            y.seuilC = x.data.seuilC;
                            y.standardCommand = x.data.standardCommand;
                            y.urgentCommand = x.data.urgentCommand;
                            y.children = x.data.children;
                        }
                        return y;
                    });
                } else {
                    this._entity.value.validators?.push(x.data);
                }
                return x;
            }));
    }



    updateComptableDetails(data) {
        return this.http.put<ObjectApiInterface<any>>(`${this.baseUrlApi}/comptable/update`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    findAllAccountantLines(page?: number, perPage?: number, sortField: string = 'accountNumber', sortOrder: string = 'asc', search?: string, filters?: any) {
        // BUG TVA filters is null
        if (filters && !filters.isArchive)
        {
            filters.isArchive = false;
        }
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/comptable/line/list`, {
            params: new HttpParams()
                .set('page', page?.toString() ?? '1')
                .set('perPage', perPage?.toString() ?? '30')
                .set('sort', `${sortField}@${sortOrder}`)
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        });
    }

    findFilteredComptableLines(state: string = '',  data: any) {
        // BUG TVA filters is null
        let code: any;
        if (data?.genericProduct?.genericAccountingAccount && !data?.genericPrestation?.genericAccountingAccount) code = null;
        if (data?.genericProduct?.genericAccountingAccount) {
            code = data?.genericProduct?.genericAccountingAccount?.code;
        } else if (data?.genericPrestation?.genericAccountingAccount) {
            code = data?.genericPrestation?.genericAccountingAccount?.code;
        } else {
            code = null;
        }
        if (state !== '') { code = null; }

        return this.findAllAccountantLines(null, null, 'accountNumber', 'asc', state, {accountNumber: code})
    }

    createComptableLine(data) {
        return this.http.post<any>(`${this.baseUrlApi}/comptable/line/create`, data)
            .pipe(map(x => {
                this._entity.value.comptable.lines.push(x);
                return x;
            }));
    }

    updateComptableLine(data) {
        return this.http.put<ObjectApiInterface<any>>(`${this.baseUrlApi}/comptable/line/update`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    importComptable(entity) {
        return this.http.post(`${this.baseUrlApi}/comptable/line/import`, entity);
    }

    downloadExcelComptableTemplate() {
        return this.http.get(`${this.baseUrlApi}/comptable/line/download-excel-template`);
    }

    getGedUrl(): Observable<any> {
        return this.http.get(`${this.baseUrlApi}/getGedUrl`);
    }

    setGedUrl(gedUrls: any): Observable<any> {
        return this.http.put<ObjectApiInterface<any>>(`${this.baseUrlApi}/setGedUrl`, {gedUrls}).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    validateStatus(): Observable<any> {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/validateStatus`).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }
}

