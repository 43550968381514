import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FeiColumn } from '../../../../../../core/base/interfaces/fei-column.interface';
import {
  CorrectiveInterventionService
} from '../../../../technique/corrective-intervention/corrective-intervention.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentService } from '../../equipment.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LitigationStatusEnum } from '../../../../../../core/enums/litigation-status.enum';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

@Component({
  selector: 'vex-equipment-details-service-book',
  templateUrl: './equipment-details-service-book.component.html',
  styleUrls: ['./equipment-details-service-book.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'DD/MM/YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'DD/MM/YYYY'
        },
      }
    }
  ],
})
export class EquipmentDetailsServiceBookComponent implements OnInit {

  dataSourceIntervention: MatTableDataSource<any>;
  displayedColumnsIntervention = [];
  feisColumnsIntervention: Array<FeiColumn> = [
    {label: 'Identifiant technique', column: 'technicalIdentifier'},
    {label: "Type d'intervention", column: 'intervention', previousDisplay: 'intervention '},
    {label: 'Date planifiée', column: 'plannedDateAndTime', type: 'dateTime'},
    {label: "Date de l'intervention", column: 'startDateAndTime', type: 'dateTime'},
    {label: 'Intervenant', column: 'contributor', propertyDisplay: 'fullName'},
    {label: 'Description', column: 'description'},
    {label: 'Statut', column: 'status'}
  ];

  dataSourceHistory: MatTableDataSource<any>;
  displayedColumnsHistory = [];
  feisColumnsHistory: Array<FeiColumn> = [
    {label: 'Identifiant technique', column: 'technicalIdentifier'},
  ];
  sortField: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
  count = 0;

  filters: any = {};
  search: string;

  popupForm: FormGroup;
  countPopupFilter = 0;

  popupMonth: any[] = [
    {value: 1, viewValue: 'Depuis le 1er janvier'},
    {value: 12, viewValue: '12 dernier mois'},
    {value: 6, viewValue: '6 dernier mois'},
    {value: 3, viewValue: '3 dernier mois'},
  ];

  popupRange: FormGroup;
  ENUM_PERMISSION = ENUM_PERMISSIONS;

  constructor(
      public interventionService: CorrectiveInterventionService,
      public equipmentService: EquipmentService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private dialog: MatDialog
  ) {
    this.displayedColumnsIntervention = this.feisColumnsIntervention.map(x => x.column);
    this.displayedColumnsHistory = this.feisColumnsHistory.map(x => x.column);
  }

  ngOnInit(): void {
    this.popupRange = new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    });

    this.popupForm = new FormGroup({
      //modifier suite a la demande dans l'excel retours utilisateur ligne 13
      lastMonth: new FormControl(null),
      equipment: new FormControl(null),
      range: this.popupRange,
    });

    this.popupForm.get('lastMonth').valueChanges.subscribe(x => {
      if (x) {
            this.popupForm.get('range.start').setValue(null, {emitEvent: false});
            this.popupForm.get('range.end').setValue(null, {emitEvent: false});
      };
    })

    this.popupForm.get('range').valueChanges.subscribe(x => {
      if (x?.start && x?.end) {
            this.popupForm.get('lastMonth').reset(null, { onlySelf: true, emitEvent: false });
      };
    })

    this.validatePopupFilter();
  }

  init() {
    this.equipmentService.entity$.subscribe(x => {
      this.filters.equipment = x?._id;
      this.interventionService.findAll(this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, this.filters).subscribe(res => {
        console.log(res);
        res.data = res.data.map(x => {
          if (x.contributor) x.fullName = `${x?.contributor?.firstName} ${x?.contributor?.lastName}`;
          if (x.firstName && x.lastName) x.fullName = `${x?.firstName} ${x?.lastName}`;
          return x;
        })
        this.count = res.totalData;
        this.dataSourceIntervention = new MatTableDataSource<any>(res.data);

        this.equipmentService.getEquipmentDetails(x._id)
            .subscribe((result) => {
              console.log('result', result)
              const { equipment, history, contracts  } = result.data;
              this.dataSourceHistory = new MatTableDataSource<any>(history);

            });

      })
    });
  }

  pageEvent(event: PageEvent): void {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.init();
  }

  sortEvent(event: Sort): void {
    this.sortField = event.active;
    this.sortOrder = event.direction;
    this.init();
  }

  onSearchChange(event) {
    this.pageIndex = 1;
    this.search = event;
    this.init();
  }

  clearTextFilter() {
    this.search = '';
    this.init();
  }

  async navigateToIntervention(element: any) {
    if (element.intervention === 'corrective') {
        await this.router.navigate(['/technique/correctiveIntervention', element._id]);
    } else {
        await this.router.navigate(['/technique/preventiveIntervention', element._id]);
    }
  }

  clearPopupFilter(): void {
    //clear popup filter logic
    // console.log(this.popupForm.getRawValue());

    // console.log(Object.values(this.popupForm.getRawValue()).filter(x => !x).length);
    this.popupForm.reset();
    if (Object.keys(this.filters).length > 0) {
      this.filters = {};
      this.countPopupFilter = 0;
      this.init();
    }
  }

  validatePopupFilter(): void {
    //validator popup filter logic
    this.filters = this.popupForm.value;
    this.countPopupFilter = 0;

    if(this.filters.lastMonth !== null || this.filters.range.start || this.filters.range.end){
      this.countPopupFilter = 1;
    }

    // console.log('test', Object.entries(this.popupForm.value));
    // this.pageIndex = 1;
    this.init();
  }
}
