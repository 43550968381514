<mat-form-field [appearance]="appearance" [ngClass]="{'without-padding': withoutPadding}" class="flex-auto w-full" [matMenuTriggerFor]="menu">
<mat-label>{{ label }}</mat-label>

     <input class="flex-auto w-full" type="text" matInput
            [value]="getSelectedItemsText()" readonly>
</mat-form-field>

<mat-menu #menu="matMenu" class="flex-auto w-full">
    <div class="flex-auto w-full select-all-search-container p-4">
        <mat-checkbox class="pl-2 pr-3" [(ngModel)]="selectAllChecked"
                      (change)="toggleSelectAll($event.checked)"
                      (click)="$event.stopPropagation();">
        </mat-checkbox>

        <div class="search-container">
            <mat-icon>search</mat-icon>
            <input class="flex-auto w-full bg-transparent search-input" placeholder="Rechercher..."
                   [formControl]="searchControl" (click)="$event.stopPropagation()">
            <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="EFFACER"
                    (click)="$event.stopPropagation(); clearSearch()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="scrollable-options">
        <mat-checkbox *ngFor="let item of filteredItems" [(ngModel)]="item.selected"
                        (change)="updateSelectedItems(item, $event.checked)"
                      (click)="$event.stopPropagation();">
            <div class="py-1 pl-2">{{ displayFn(item) }}</div>
        </mat-checkbox>
    </div>

</mat-menu>
