import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ObjectApiInterface} from './interfaces/object-api.interface';
import {filter, map, take} from 'rxjs/operators';
import { CleanCollectionFieldsModel } from '../models/clean-collection-fields.model';


@Injectable({
    providedIn: 'root'
})
export class BaseService<T> {
    protected _entity = new BehaviorSubject<T>(null);
    readonly entity$ = this._entity.asObservable().pipe(filter((entity) => !!entity));

    defaultSort = 'createdAt';
    entityApi: string;

    baseUrlApi: string;

    constructor(public http: HttpClient, @Inject('entityApi') entityApi: string = '') {
        this.entityApi = entityApi;
        this.baseUrlApi = `/api/${this.entityApi}`;
    }

    get entity() {
        return lastValueFrom(this.entity$.pipe(take(1)));
    }

    resetEntity() {
        this._entity.next(null);
    }

    setEntity(entity: T) {
        this._entity.next(entity);
    }

    refreshEntity() {
        this._entity.next(this._entity.value);
    }
    deleteFields(data: CleanCollectionFieldsModel): Observable<ObjectApiInterface<T>> {
        return this.http.put<ObjectApiInterface<T>>(`${this.baseUrlApi}/deleteEmptyFields`, data);
    }

    findAll(page?: number,
            perPage?: number,
            sortField: string = this.defaultSort,
            sortOrder: string = 'desc',
            search?: string,
            filters?: T | any): Observable<ObjectApiInterface<T[]>> {
        return this.http.get<ObjectApiInterface<T[]>>(`${this.baseUrlApi}/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '10')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    getOrderProductsPrestations(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: T | any, order?: T | any, typerEntity?: any): Observable<ObjectApiInterface<T[]>> {
        return this.http.get<ObjectApiInterface<T[]>>(`${this.baseUrlApi}/getOrderProductsPrestations/${order}/${typerEntity}`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '500')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    count(search?: string, filters?: T | any): Observable<ObjectApiInterface<any>> {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/count`, {
            params: new HttpParams()
                .set('search', search ?? '')
                .set('filters', JSON.stringify(filters) ?? '')
        });
    }

    findById(id: string): Observable<ObjectApiInterface<T>> {
        return this.http.get<ObjectApiInterface<T>>(`${this.baseUrlApi}/get/${id}`).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    create(entity: T): Observable<ObjectApiInterface<T>> {
        return this.http.post<ObjectApiInterface<T>>(`${this.baseUrlApi}/create`, entity, {
            headers: {'Error-CRUD': 'CREATE'}
        });
    }

    import(entity: T): Observable<ObjectApiInterface<T>> {
        return this.http.post<ObjectApiInterface<T>>(`${this.baseUrlApi}/import`, entity, {
            headers: {'Error-CRUD': 'IMPORT'}
        });
    }

    downloadExcelTemplate(): Observable<ObjectApiInterface<T>> {
        return this.http.get<ObjectApiInterface<T>>(`${this.baseUrlApi}/download-excel-template`);
    }

    synchronizeDocuware(): Observable<ObjectApiInterface<T>> {
        return this.http.get<ObjectApiInterface<T>>(`${this.baseUrlApi}/docuware/synchronize`);
    }

    update(entity: T | any): Observable<ObjectApiInterface<T>> {
        return this.http.put<ObjectApiInterface<T>>(`${this.baseUrlApi}/update/${entity['_id']}`, entity, {
            headers: {'Error-CRUD': 'UPDATE'}
        }).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    updateBillToIsApproved(entity: T | any): Observable<ObjectApiInterface<T>> {
        return this.http.put<ObjectApiInterface<T>>(`${this.baseUrlApi}/updateBillToIsApproved/${entity['_id']}`, entity).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    delete(entity: T): Observable<ObjectApiInterface<T>> {
        return this.http.delete<ObjectApiInterface<T>>(`${this.baseUrlApi}/delete/${entity['_id']}`, {
            headers: {'Error-CRUD': 'DELETE'}
        });
    }

    switchIsActive(entity: T) {
        return this.http.get(`${this.baseUrlApi}/switchIsActive/${entity['_id']}`);
    }

    getResume(id: string): Observable<ObjectApiInterface<T>> {
        return this.http.get<ObjectApiInterface<T>>(`${this.baseUrlApi}/resume/${id}`);
    }

    searchAutocomplete(observableSearch: Observable<T[]>, search?: string): Observable<T[]> {
        observableSearch = this.http.get<ObjectApiInterface<T[]>>(`${this.baseUrlApi}/list`, {
            params: new HttpParams()
                .set('page', '1')
                .set('perPage', '10')
                .set('search', search ?? '')
        }).pipe(map(d => d.data));
        return observableSearch;
    }

    archive(entity: T): Observable<ObjectApiInterface<T>> {
        return this.http.get<ObjectApiInterface<T>>(`${this.baseUrlApi}/archive/${entity['_id']}`);
    }

    unarchive(entity: T): Observable<ObjectApiInterface<T>> {
        return this.http.get<ObjectApiInterface<T>>(`${this.baseUrlApi}/unarchive/${entity['_id']}`);
    }

}


