<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>
            <base-data-table-header
                    [label]="entityName"
                    [loading]='loading'
                    [showArchives]="canShowArchives"
                    (onSearchChange)='onSearchChange($event)'
                    (onClearTextFilter)='clearTextFilter()'
                    (onShowArchived)="onShowArchived($event)"
                    (onCreate)='createItem()'
                    [canImport]="true"
                    (onImport)="importItems()"
                    [createPermission]="[ENUM_PERMISSION.ADD_ENERGY_ID]"
                    [importPermission]="[ENUM_PERMISSION.IMPORT_ENERGY_ID]"
            ></base-data-table-header>

            <base-data-table
                    [count]='count'
                    [pageSize]='pageSize'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [feisColumns]='feisColumns'
                    [routerLinkDetailRow]='routerLinkDetailRow'
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateItem($event)'
                    (onDeleteItem)='deleteItem($event)'
                    (onArchiveItem)='archiveItem($event)'
                    [customColumnTemplate]="templateRef"
            ></base-data-table>

            <ng-template #templateRef let-column let-row="element">
                <mat-chip-list *ngIf="column === 'buildings'" >
                    <mat-chip *ngFor="let building of row.buildings" style="min-height:18px; font-size: 12px;"  >
                        {{ building.label }}
                    </mat-chip>
                </mat-chip-list>
                <mat-chip-list *ngIf="column === 'establishments'">
                    <mat-chip *ngFor="let establishment of row.establishments" style="min-height:18px; font-size: 12px;"  >
                        {{ establishment.label }}
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </div>
    </vex-page-layout-content>
</vex-page-layout>
