import { Component, OnInit } from '@angular/core';
import { SupplierModel } from 'src/app/core/models/supplier.model';
import { SupplierService } from './supplier.service';
import { MatDialog } from '@angular/material/dialog';
import {
    SupplierCreateUpdateDeleteComponent
} from './modals/supplier-create-update-delete/supplier-create-update-delete.component';
import { FeiColumn } from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { CategoryService } from '../../settings/category/category.service';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { ProductService } from '../product/product.service';
import { PrestationService } from '../prestation/prestation.service';
import {
    FormModalConfirmWithListComponent
} from '../../../../core/base/components/form-modal-confirm-with-list/form-modal-confirm-with-list.component';
import { SupplierType } from '../../../../core/enums/supplier-type.enum';
import { AuthService } from '../../../../core/services/auth.service';
import {SnackbarService} from "../../../../core/services/snackbar.service";

@Component({
    selector: 'vex-supplier',
    templateUrl: './supplier.component.html',
    styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent extends DataTableBase<SupplierModel> implements OnInit {
    ENUM_PERMISSION = ENUM_PERMISSIONS;

    feisColumns: Array<FeiColumn> = [
        {label: 'Famille d\'achat', column: 'families', type: 'custom', width: '30%'},
        {label: 'Dénomination', column: 'label', propertyDisplay: null, canUpdate: false, canDelete: false},
        {label: 'Type', column: 'type', propertyDisplay: null, canUpdate: false, canDelete: false},
        //{ label: 'N° siren', column: 'sirenNumber', propertyDisplay: null, canUpdate: false, canDelete: false },
        //{ label: 'N° siret', column: 'siretNumber', propertyDisplay: null, canUpdate: false, canDelete: false },
        //{ label: 'Code APE', column: 'codeApe', propertyDisplay: null, canUpdate: false, canDelete: false },
        // {
        //     label: 'Adresse de siège',
        //     column: 'headquarterAddress',
        //     propertyDisplay: null,
        //     canUpdate: false,
        //     canDelete: false
        // },
        {label: '', column: 'actions', canDelete: true, canUpdate: false, canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_SUPPLIER)}
    ];

    entityName = 'Fournisseurs';

    popupForm: FormGroup;
    //  categories$: Observable<CategoryModel[]>;
    families: Array<any> = [];
    popupType: Array<string>;

    canShowArchivePermission: boolean = false;

    constructor(
        public service: SupplierService,
        private dialog: MatDialog,
        private router: Router,
        private categoryService: CategoryService,
        private productService: ProductService,
        private prestationService: PrestationService,
        private authService: AuthService,
        private snackbar: SnackbarService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.categoryService.searchByLevel(1).subscribe(res => {
            res.sort((a, b) => a.link.localeCompare(b.link));
            this.families = res;
        });

        this.popupType = Object.values(SupplierType);
        this.popupForm = new FormGroup({
            category: new FormControl(''),
            supplierType: new FormControl(''),
        });


        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canShowArchivePermission = permissions.includes(ENUM_PERMISSIONS.READ_FM_REF_PURCHASE_SUPPLIER_ARCHIVE);
        });
    }

    beforeValidatePopupFilter() {
        this.filters.category = this.popupForm.controls.category.value?._id;
    }

    // categoriesChange(state: any) {
    //     this.categories$ = this.categoryService.searchByLevel(0, state);
    // }

    createItem(): void {
        this.dialog.open(SupplierCreateUpdateDeleteComponent).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
                this.snackbar.success('Fournisseur créé')
            }
        });

    }

    updateItem(element: SupplierModel): void {
        this.dialog.open(SupplierCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                if (supplier._id) {
                    this.router.navigateByUrl('/management/supplier/' + supplier._id).then();
                } else {
                    this.initData();
                }
            }
        });

    }

    deleteItem(element: SupplierModel) {
        this.dialog.open(SupplierCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.label}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });

    }

    async archiveItem(element: SupplierModel) {
        const product = await lastValueFrom(this.productService.getsupplierRelation(element._id));
        const prestation = await lastValueFrom(this.prestationService.getsupplierRelation(element._id));
        const productAndPresta = [...product.data.map(x => `Article - ${x.label}`), ...prestation.data.map(x => `Prestation - ${x.label}`)];

        if (productAndPresta.length > 0) {
            this.dialog.open(FormModalConfirmWithListComponent, {
                data: {
                    title: 'Archivage impossible',
                    message: `Vous ne pouvez pas archiver ce fournisseur car il est affecté à des articles/prestation`,
                    userList: productAndPresta
                }
            });

        } else {
            this.dialog.open(SupplierCreateUpdateDeleteComponent, {
                data: {
                    defaults: element,
                    name: `${element.label}`,
                    mode: CrudModeEnum.Archive
                }
            }).afterClosed().subscribe((supplier: SupplierModel) => {
                if (supplier) {
                    this.initData();
                }
            });
        }
    }

    importItem() {
        this.dialog.open(SupplierCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
                this.snackbar.success('Fournisseur créé')
            }
        });

    }
}
