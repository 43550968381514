import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfileModel } from 'src/app/core/models/user-profile.model';
import { UserProfileService } from './user-profile.service';
import { MatDialog } from '@angular/material/dialog';
import {
    UserProfileCreateUpdateDeleteComponent
} from './user-profile-create-update-delete/user-profile-create-update-delete.component';
import { FeiColumn } from 'src/app/core/base/interfaces/fei-column.interface';
import { DataTableBase } from 'src/app/core/base/components/data-table-base/data-table-base';
import { CrudModeEnum } from 'src/app/core/base/enum/crud-mode.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { EstablishmentModel } from '../../../../core/models/establishment.model';
import { EstablishmentService } from '../establishment/establishment.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ENUM_PERMISSIONS } from '../../../../core/enums/permission.enum';
import { GenderWithEntity } from '../../../../core/enums/gender-with-entity.enum';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
    selector: 'vex-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends DataTableBase<UserProfileModel> implements OnInit, OnDestroy {
    permission = ENUM_PERMISSIONS;

    feisColumns: Array<FeiColumn> = [
        {label: 'Civilité', column: 'gender', propertyDisplay: null},
        {label: 'Nom', column: 'lastName', propertyDisplay: null, upperCase: true},
        {label: 'Prénom', column: 'firstName', propertyDisplay: null},
        {label: 'Téléphone', column: 'mobileNumber', propertyDisplay: null},
        {label: 'Email', column: 'email', propertyDisplay: null},
        // { label: 'Rôle', column: 'role', propertyDisplay: 'role.name' },
        {
            label: '',
            column: 'actions',
            canUpdate: false,
            canArchive: this.authService.hasPermission(ENUM_PERMISSIONS.INPUT_CP_INTERNAL_CONTACT_ARCHIVE),
            canDelete: true
        }
    ];

    establishment$: Observable<EstablishmentModel[]>;
    popupGender: Array<string>;

    canShowArchivesPermission = false;

    constructor(
        public service: UserProfileService,
        private dialog: MatDialog,
        private establishmentService: EstablishmentService,
        private router: Router,
        private authService: AuthService,
    ) {
        super();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit() {
        this.filters = {};
        this.filters.dataTable = true;
        super.ngOnInit();
        this.establishmentAutoCompleteChange();
        this.popupGender = Object.values(GenderWithEntity);
        this.popupForm = new FormGroup({
            establishment: new FormControl(null),
            gender: new FormControl(null)
        });

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canShowArchivesPermission = permissions.includes(ENUM_PERMISSIONS.READ_CP_INTERNAL_CONTACT_INFORMATION);
        });
    }

    createItem(): void {
        this.dialog.open(UserProfileCreateUpdateDeleteComponent).afterClosed().subscribe((userProfile: UserProfileModel) => {
            if (userProfile) {
                if (userProfile._id) {
                    this.router.navigateByUrl('/management/user-profile/' + userProfile._id).then();
                } else {
                    this.initData();
                }
            }
        });
    }

    updateItem(element: UserProfileModel): void {
        this.dialog.open(UserProfileCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((userProfile: UserProfileModel) => {
            if (userProfile) {
                this.initData();
            }
        });
    }

    deleteItem(element: UserProfileModel) {


        this.service.getEstablishmentRelation(element._id).subscribe(data => {
            console.log(data);
        });


        this.dialog.open(UserProfileCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.firstName}`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((userProfile: UserProfileModel) => {
            if (userProfile) {
                this.initData();
            }
        });
    }

    archiveItem(element: UserProfileModel) {
        this.dialog.open(UserProfileCreateUpdateDeleteComponent, {
            data: {
                defaults: element,
                name: `${element.firstName}`,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((userProfile: UserProfileModel) => {
            if (userProfile) {
                this.initData();
            }
        });
    }

    beforeValidatePopupFilter() {
        this.filters.establishment = this.popupForm?.controls?.establishment?.value?._id;
    }

    establishmentAutoCompleteChange(state: any = '') {
        this.establishment$ = this.establishmentService.findAll(null, null, 'label', 'asc', state).pipe(map(x => x.data));
    }

    importItem() {
        this.dialog.open(UserProfileCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((userProfile: UserProfileModel) => {
            if (userProfile) {
                this.initData();
            }
        });
    }
}
