import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/core/base/base.service';
import { CategoryModel } from '../../../../core/models/category.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CategoryService extends BaseService<CategoryModel> {
    defaultSort = 'name';

    protected _categories = new BehaviorSubject<CategoryModel[]>([] as CategoryModel[]);
    readonly categories$ = this._categories.asObservable();

    constructor(public http: HttpClient) {
        super(http, 'category');
    }

    findByLevel(level: number, _ids: Array<string> = []): Observable<ObjectApiInterface<CategoryModel[]>> {
        return this.http.post<ObjectApiInterface<CategoryModel[]>>(`${this.baseUrlApi}/level/${level}`, _ids).pipe(map(x => {
            if (level === 0) {
                this._categories.next(x.data);
                return x;
            }

            if (x.data.length > 0) {


                x.data.forEach(category => {
                    const parents = category.parents.sort((a, b) => a.level - b.level);

                    const level0 = this._categories.value.find(y => y._id === parents[0].parent);

                    if (level === 1) {
                        level0.children = x.data;
                    }

                    if (parents.some(y => y.level === 1)) {
                        const level1 = level0.children.find(y => y._id === parents[1].parent);
                        if (level === 2) {
                            level1.children = x.data;
                        }
                        if (parents.some(y => y.level === 2)) {
                            const level2 = level1.children.find(y => y._id === parents[2].parent);
                            if (level === 3) {
                                level2.children = x.data;
                            }
                            if (parents.some(y => y.level === 3)) {
                                const level3 = level2.children.find(y => y._id === parents[3].parent);
                                if (level === 4) {
                                    level3.children = x.data;
                                }
                                if (parents.some(y => y.level === 4)) {
                                    const level4 = level3.children.find(y => y._id === parents[4].parent);
                                    if (level === 5) {
                                        level4.children = x.data;
                                    }
                                    if (parents.some(y => y.level === 5)) {
                                        const level5 = level4.children.find(y => y._id === parents[5].parent);
                                        if (level === 6) {
                                            level5.children = x.data;
                                        }
                                        if (parents.some(y => y.level === 6)) {
                                            const level6 = level5.children.find(y => y._id === parents[6].parent);
                                            if (level === 7) {
                                                level6.children = x.data;
                                            }
                                            if (parents.some(y => y.level === 7)) {
                                                const level7 = level6.children.find(y => y._id === parents[7].parent);
                                                if (level === 8) {
                                                    level7.children = x.data;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            }

            const newData = Object.assign(this._categories.value, []);
            this._categories.next(newData);
            x.data = this._categories.value;
            return x;
        }));
    }

    searchByLevel(level: number, search: string = '') {
        return this.http.get<ObjectApiInterface<CategoryModel[]>>(`${this.baseUrlApi}/list`, {
            params: new HttpParams()
                .set('search', search ?? '')
                .set('perPage', '10000')
                .set('filters', JSON.stringify({ level }) ?? '')
        }).pipe(map(x => x.data));
    }
    getChildren(category: CategoryModel) {
        return this._categories.value.find(x => x._id === category._id).children;
    }

    export() {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/export`);
    }
    findAllByParentLevel0(id: string, deep: number = 1) {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/findAllByParentLevel0/${id}/${deep}`);
    }

    delete(category: CategoryModel){
        return this.http.delete<ObjectApiInterface<any>>(`${this.baseUrlApi}/delete/${category._id}/${category.level}`);
    }

    checkDependencies(category: CategoryModel){
        return this.http.delete<ObjectApiInterface<any>>(`${this.baseUrlApi}/checkDependencies/${category._id}/${category.level}`);
    }
}


