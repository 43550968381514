<div *ngIf="displayRecurringBasketBanner" @fadeInRight class="card border-l-4 border-red text-orange-700 p-4 mt-4" role="alert">
    <div class="flex flex-row w-full justify-between">
        <p class="font-bold text-red">
            <icon-warning class='ml-2' [show]='true'></icon-warning>
            Les paniers contrats se déclencheront uniquement lorsque le contrat sera validé.
        </p>
    </div>
</div>

<div class='flex flex-row items-stretch justify-center gap-6'>
    <!--              Partie gauche -->

    <div class='w-1/4 card overflow-auto mt-6 '>
        <div class='p-4 border-b border-thick' fxLayout='row' fxLayoutAlign='space-between center'>
            <span class='text-xl'>Panier contrat</span>
            <div *ngIf="contract?.status !== ContractStatusEnum.EXPIRED">
                <button color='primary' mat-raised-button type='button' (click)="createRecurringBasket()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>

        <mat-selection-list [multiple]='false' class='pt-0 scrollable-list'>
            <ng-container *ngFor='let reccuringBasket of reccuringBaskets'>
                <mat-list-option (click)="selectRecurringBasket(reccuringBasket)"
                                 *ngIf="!reccuringBasket.prestInterventionId" [value]='reccuringBasket'
                                 class='border-b'
                                 [selected]="isBasketSelected(reccuringBasket)">
                    <div
                            class='text-base' fxLayout='row'
                            fxLayoutAlign='space-between center'>
                        <span>{{ reccuringBasket.label }}</span>
                        <div>
                            <button (click)='deleteRecurringBasket(reccuringBasket)'>
                                <mat-icon [icIcon]='icDelete'></mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-option>
            </ng-container>
        </mat-selection-list>

        <div class='p-4 border-b border-thick' fxLayout='row' fxLayoutAlign='space-between center'>
            <span class='text-xl '>Interventions contrat</span>
        </div>

        <div class='p-4 border-b' fxLayout='row' fxLayoutAlign='center center'>
            <span class='text-lg'>Récurrences logiques</span>
        </div>

        <ng-container *ngFor="let reccuringBasket of reccuringBaskets">
            <ng-container
                    *ngIf="reccuringBasket?.prestInterventionId && (reccuringBasket?.manualPlanification?.length <= 0 || reccuringBasket?.manualPlanification?.length === null || reccuringBasket?.manualPlanification?.length === undefined)">

                <mat-selection-list [multiple]='false' class='pt-0 scrollable-list'>
                    <mat-list-option [value]='reccuringBasket' class='border-b'
                                     [selected]="isBasketSelected(reccuringBasket)">
                        <div (click)="selectRecurringBasket(reccuringBasket)" class='text-base' fxLayout='row'
                             fxLayoutAlign='space-between center'>
                            <span>{{ reccuringBasket.label }}</span>
                            <div>
                                <button (click)='deleteRecurringBasket(reccuringBasket)'>
                                    <mat-icon [icIcon]='icDelete'></mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </ng-container>
        </ng-container>

        <div class='p-4 border-b' fxLayout='row' fxLayoutAlign='center center'>
            <span class='text-lg'>Récurrences manuelles</span>
        </div>

        <ng-container *ngFor="let reccuringBasket of reccuringBaskets">
            <ng-container
                    *ngIf="reccuringBasket?.manualPlanification?.length > 0 && reccuringBasket?.prestInterventionId">

                <mat-selection-list [multiple]='false' class='pt-0 scrollable-list'>
                    <mat-list-option [value]='reccuringBasket' class='border-b'
                                     [selected]="isBasketSelected(reccuringBasket)">
                        <div (click)="selectRecurringBasket(reccuringBasket)" class='text-base' fxLayout='row'
                             fxLayoutAlign='space-between center'>
                            <span>{{ reccuringBasket.label }}</span>
                            <div>
                                <button (click)='deleteRecurringBasket(reccuringBasket)'>
                                    <mat-icon [icIcon]='icDelete'></mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </ng-container>
        </ng-container>
    </div>

    <!--              Partie milieu -->
    <div class="w-3/4 mt-6">
        <ng-container *ngIf='currentRecurringBasket else notSelected'>

            <div class="card p-4 mb-6">
                <div class="flex flex-row justify-between">
                    <div class="text-xl"> {{ currentRecurringBasket.label | uppercase }}</div>
                    <button *ngIf="!currentRecurringBasket?.isIntervention" (click)="editRecurrence()"
                            class="mr-1" color='primary'
                            mat-raised-button
                            type='button'>
                        MODIFIER LA RECCURENCE
                    </button>
                </div>

                <div class="px-gutter py-4" gdColumns="1fr 1fr"
                     gdColumns.xs="1fr" gdGap="16px">
                    <details-line-base [label]='currentRecurringBasket.label | titlecase'
                                       hint='Libellé'></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.recurrence'
                                       [label]='currentRecurringBasket?.recurrence | recurrenceTranslation'
                                       hint='Répétition'></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.repeat'
                                       [label]='getOccurenceText(currentRecurringBasket)'
                                       hint='Occurence'></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.startDate'
                                       [label]='currentRecurringBasket?.startDate | date: dateFormat'
                                       hint='Début de validité'></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.endDate'
                                       [label]='currentRecurringBasket?.endDate | date: dateFormat'
                                       hint='Fin de validité'></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.establishment'
                                       [label]='currentRecurringBasket?.establishment?.label'
                                       hint='Établissement'></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.nextRecurrence'
                                       [label]='currentRecurringBasket?.nextRecurrence | date: dateFormat'
                                       hint='Prochaine occurence'></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.totalRecurrence'
                                       [label]='currentRecurringBasket?.totalRecurrence'
                                       hint="Nombre total d'occurence(s)"></details-line-base>
                    <details-line-base *ngIf='confirmationDelayDate'
                                       [label]='confirmationDelayDate | date: dateFormat'
                                       hint="Délai de confirmation"></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.nbIntervention'
                                       [label]='currentRecurringBasket?.nbIntervention'
                                       hint="Nombre total d'intervention(s)"></details-line-base>
                    <details-line-base *ngIf='currentRecurringBasket?.prestation?.purchaseValueRemaining'
                                       [label]='currentRecurringBasket?.prestation?.purchaseValueRemaining'
                                       hint="Compteur d'intervention(s)"></details-line-base>

                    <details-line-base
                            *ngIf='currentRecurringBasket?.baskets?.length > 0 && currentRecurringBasket.totalHT'
                            [label]='currentRecurringBasket.totalHT'
                            [additionalDisplay]="'€'"
                            hint='Total HT'>
                    </details-line-base>

                    <!-- SI PERIMETRE COMPLET -->
                    <details-line-base *ngIf="currentRecurringBasket?.isFullPerimeter; else notFullPerimeter"
                                       hint="Périmètre complet"
                                       type="boolean"
                                       [label]="currentRecurringBasket?.isFullPerimeter">
                    </details-line-base>

                    <!-- SI PLUSIEURS DATES DE RECURRENCE MANUELLE -->
                    <ng-container *ngIf="currentRecurringBasket?.manualPlanification?.length > 0">
                        <div style="margin-right: 70%">
                            <div style="max-height: 150px; overflow-y: auto; margin-right: auto;">
                                <mat-list role="list">
                                    <mat-list-item *ngFor="let item of currentRecurringBasket?.manualPlanification"
                                                   role="listitem">
                                        {{ item | date: dateFormat }}
                                    </mat-list-item>
                                </mat-list>
                            </div>
                            <span class='m-0 caption text-hint'>Dates de récurrence manuelle</span>
                        </div>
                    </ng-container>

                    <!-- SI PERIMETRE NON COMPLET -->
                    <ng-template #notFullPerimeter>
                        <!--                        <div class="tooltip-container" (mouseenter)="showTooltip(true)"-->
                        <!--                             (mouseleave)="showTooltip(false)">-->
                        <!--&lt;!&ndash;                            <span class="tooltip-trigger">&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <details-line-base hint="Périmètre complet"&ndash;&gt;-->
                        <!--&lt;!&ndash;                                                   type="boolean"&ndash;&gt;-->
                        <!--&lt;!&ndash;                                                   [label]="currentRecurringBasket?.isFullPerimeter">&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </details-line-base>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            </span>&ndash;&gt;-->
                        <!--                            <span>-->
                        <!--                                <details-line-base hint="Périmètre"-->
                        <!--                                                   type="text"-->
                        <!--                                                   [label]="recurringBasketEstablishmentLabel">-->
                        <!--                                </details-line-base>-->
                        <!--                            </span>-->

                        <!--                            <div class="tooltip-box card ml-10 w-1/2"-->
                        <!--                                 *ngIf="tooltip">-->
                        <!--                                <vex-contract-details-user-perimeter-->
                        <!--                                        [entity]="contract"-->
                        <!--                                        [parentItems]="perimeter"-->
                        <!--                                        [isCheckboxDisabled]="true">-->
                        <!--                                </vex-contract-details-user-perimeter>-->
                        <!--                            </div>-->
                        <!--                        </div>-->


                        <details-line-base hint="Périmètre"
                                           type="text"
                                           [label]="recurringBasketEstablishmentLabel">
                        </details-line-base>

                        <ng-container
                                *ngIf="contract.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.BUILDING">
                            <details-line-base hint="Bâtiment du périmètre"
                                               type="text"
                                               [label]="recurringBasketBuildingLabel">
                            </details-line-base>
                        </ng-container>

                        <ng-container
                                *ngIf="contract.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.EQUIPMENT">

                            <details-line-base hint="Référence équipement"
                                               type="text"
                                               [label]="recurringBasketEquipmentLabel">
                            </details-line-base>
                        </ng-container>

                        <ng-container
                                *ngIf="contract.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.ID_ENERGIE">

                            <details-line-base hint="ID Energie"
                                               type="text"
                                               [label]="recurringBasketIdEnergyLabel">
                            </details-line-base>
                        </ng-container>


                    </ng-template>

                </div>

            </div>

            <ng-container *ngIf='currentRecurringBasket.isActivated; else notValidated'>


                <div class="card p-4">

                    <div class="flex flex-row justify-between">
                        <div class="text-xl"> {{ "PANIER" | uppercase }}</div>
                        <button *ngIf="!currentRecurringBasket.prestInterventionId"
                                (click)="validatesRecurringBasket(false)"
                                class="mr-1" color='primary'
                                mat-raised-button
                                type='button'>
                            MODIFIER LE PANIER
                        </button>
                    </div>


                    <ng-container *ngIf="!currentRecurringBasket.prestInterventionId">
                        <div class='flex flex-row p-4 w-full'>
                            <div class='w-3/5 pl-6'>Libellé</div>
                            <div class='w-1/5'>Prix HT</div>
                            <div class='w-1/5'>Quantité</div>
                        </div>
                        <div *ngIf='currentRecurringBasket.products?.length > 0' class='w-full'>
                            <div class='p-4 w-full' style='background-color: #C6E4F3'>
                                <h4 class='title'>Articles</h4>
                            </div>
                            <div *ngFor='let product of currentRecurringBasket.products'
                                 class='flex flex-row p-4 w-full'>
                                <div class='w-3/5 pl-6'>{{ product?.label }}</div>
                                <div class='w-1/5'>{{ product?.priceHT | number: '1.2-2' }} €</div>
                                <div class='w-1/5'>{{ product.quantity }}</div>
                            </div>
                        </div>
                        <div *ngIf='currentRecurringBasket.prestations?.length > 0' class='w-full'>
                            <div class='p-4 w-full' style='background-color: #C6E4F3'>
                                <h4 class='title'>Prestations</h4>
                            </div>
                            <div *ngFor='let prestation of currentRecurringBasket.prestations'
                                 class='flex flex-row p-4 w-full'>
                                <div class='w-3/5 pl-6'>{{ prestation?.label }}</div>
                                <div class='w-1/5'>{{ prestation?.priceHT | number: '1.2-2' }} €</div>
                                <div class='w-1/5'>{{ prestation.quantity }}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentRecurringBasket?.prestInterventionId">
                        <div class='p-4 w-full' style='background-color: #C6E4F3'>
                            <h4 class='title'>Intervention</h4>
                        </div>
                        <div class="px-gutter py-4" gdColumns="1fr 1fr">

                            <details-line-base
                                    [label]='currentRecurringBasket.prestation?.genericPrestation?.label | titlecase'
                                    hint='Prestation générique'></details-line-base>

                            <details-line-base [label]='currentRecurringBasket.prestation?.reference'
                                               hint='Réference'></details-line-base>
                            <details-line-base [label]='currentRecurringBasket.prestation?.label'
                                               hint='Libellé'></details-line-base>

                            <details-line-base [label]='currentRecurringBasket.prestation?.priceHT | number'
                                               hint='Prix HT'></details-line-base>

                            <details-line-base [label]='currentRecurringBasket.prestation?.priceTTC | number'
                                               hint='Prix TTC'></details-line-base>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </ng-container>
        <ng-template #notValidated>
            <div class="flex flex-col md:flex-row gap-4">
                <div class="card p-4 w-full md:w-3/4  ">
                    <div class="flex flex-row justify-between">
                        <div class="text-xl"> {{ currentRecurringBasket.label | uppercase }}</div>
                        <button [disabled]="currentRecurringBasket.baskets?.length === 0"
                                (click)="validatesRecurringBasket()"
                                class="mr-1" color='primary'
                                mat-raised-button
                                type='button'>
                            VALIDER LE PANIER
                        </button>
                    </div>


                    <div class="mt-6">

                        <mat-tab-group #tabGroup (selectedIndexChange)="tabChanges($event)">
                            <mat-tab *ngIf="hasArticle && !articleEmpty" label='Articles'>
                                <ng-container>
                                    <base-data-table

                                            [count]="countProduct"
                                            [pageSize]='pageSize'
                                            [sortField]="sortField"
                                            [sortOrder]='sortOrder'
                                            (onPageEvent)='pageEvent($event)'
                                            (onSortEvent)='sortEvent($event)'

                                            [feisColumns]="feisColumnsArticle"
                                            [dataSource]="dataSourceArticle"
                                            [displayedColumns]="displayedColumnsArticle"
                                            [customColumnTemplate]="dataTableColumnRef"
                                            [showSort]="true"
                                            [showPaginator]="true"
                                    >
                                    </base-data-table>
                                </ng-container>
                            </mat-tab>
                            <mat-tab *ngIf="hasPrestation && !prestationEmpty" label='Prestation'>
                                <ng-container>
                                    <base-data-table

                                            [count]="countPrestation"
                                            [pageSize]='pageSize'
                                            [sortField]="sortField"
                                            [sortOrder]='sortOrder'
                                            (onPageEvent)='pageEvent($event)'
                                            (onSortEvent)='sortEvent($event)'


                                            [feisColumns]="feisColumnsPrestation"
                                            [dataSource]="dataSourcePrestation"
                                            [displayedColumns]="displayedColumnsPrestation"
                                            [customColumnTemplate]="dataTableColumnRef"
                                            [showSort]="true"
                                            [showPaginator]="true"
                                    >
                                    </base-data-table>
                                </ng-container>
                            </mat-tab>
                        </mat-tab-group>

                    </div>


                </div>
                <div class=" w-full md:w-1/4 ">
                    <catalog-basket-base
                            [canDelete]="true"
                            [canChangeQuantity]="true"
                            [showTotalBasketAmount]="true"
                            [showPrice]="true"
                            [items$]="basket$"
                            [displayTitle]="true"
                            (removeItem)="onRemoveItemFromBasketEvent($event)"
                            (updateQuantityItem)="onUpdateItemQuantityFromBasketEvent({item: $event.item, action: $event.action})"
                    >
                    </catalog-basket-base>

                    <!--                    <div class='p-4 border-b flex flex-row justify-between'>-->
                    <!--                        <h2 class='title m-0 mr-2'>Panier</h2>-->
                    <!--                        <ng-container *ngIf="totalBasketAmount > 0">-->
                    <!--                            <h2 class='title text-right'>Total H.T : {{ totalBasketAmount | number: '1.2-2' }}-->
                    <!--                                €</h2>-->
                    <!--                        </ng-container>-->
                    <!--                        <ng-container *ngIf="totalBasketAmount == 0">-->
                    <!--                            <h2 class='title text-right'> Aucun élement dans le panier</h2>-->
                    <!--                        </ng-container>-->
                    <!--                    </div>-->

                    <!--                    <div class="overflow-auto h-full" style="max-height: 57rem">-->
                    <!--                        <div *ngFor='let item of currentRecurringBasket.baskets ' class='card mt-1'>-->
                    <!--                            <div class='p-4 flex flex-col justify-between w-full h-full'>-->
                    <!--                                <mat-icon (click)='removeItemFromBasket(item, currentRecurringBasket)' class='flex self-end'-->
                    <!--                                          [icIcon]="this.iconDelete"-->
                    <!--                                          size="18px"></mat-icon>-->
                    <!--                                <h4>{{ item.label }}</h4>-->
                    <!--                                <span *ngIf="item"-->
                    <!--                                      class='text-gray'>Prix H.T. : {{ item?.priceHT * item?.quantity | number: '1.3-3' }}-->
                    <!--                                    €</span>-->
                    <!--                                <div class='flex flex-row justify-between mt-2'>-->
                    <!--                                    <div class='flex flex-row justify-between w-20 p-0'-->
                    <!--                                         *ngIf="item.quantity">-->
                    <!--                                        <a (click)="updateItemBasketQuantity(item, 'minus', currentRecurringBasket)"-->
                    <!--                                           class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"-->
                    <!--                                           mat-icon-button>-->
                    <!--                                            <mat-icon [icIcon]="this.iconMinus" size="18px"></mat-icon>-->
                    <!--                                        </a>-->
                    <!--                                        <input-->
                    <!--                                                class='number-no-arrows w-1/3 min-w-10 text-center'-->
                    <!--                                                type='number' min='1'-->
                    <!--                                                oninput="this.value = (this.value > 9999 ? 9999 : Math.max(1, this.value))"-->
                    <!--                                                [(ngModel)]='item.quantity'>-->
                    <!--                                        <a (click)="updateItemBasketQuantity(item, 'plus', currentRecurringBasket)"-->
                    <!--                                           class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover hover:text-primary text-white bg-primary"-->
                    <!--                                           mat-icon-button>-->
                    <!--                                            <mat-icon [icIcon]="this.iconPlus" size="18px"></mat-icon>-->
                    <!--                                        </a>-->
                    <!--                                    </div>-->
                    <!--                                    <div class='flex flex-row justify-center w-20 p-0' *ngIf="item.quantity">-->
                    <!--                                        {{ item.quantity }}-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>

        </ng-template>


        <ng-template let-column let-row="element" let-status="status" #dataTableColumnRef>
            <div class='border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <ng-container *ngIf="column === 'addToCart'">
                    <button mat-icon-button color="primary"
                            (click)='addToBasket(row, currentRecurringBasket)'>
                        <mat-icon>shopping_cart</mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="column === 'quantity'">
                    <catalog-quantity-column-base
                            [column]="column"
                            [row]="row"
                            (updateQuantityItem)="updateCatalogueQuantity($event.item, $event.action)"
                            [readonly]="false"
                            [canAddToBasketPermission]="true"
                            [isContractColumnQuantity]="isContractColumnQuantity"
                    >
                    </catalog-quantity-column-base>
                </ng-container>
            </div>
        </ng-template>

        <ng-template #notSelected>
            <div class="p-6 text-center" fxLayout="column" fxLayoutAlign='center' fxLayoutGap="20px">
                <span class="text-2xl">Veuillez sélectionner un panier</span>
                <img alt="waiting" class="w-full max-w-lg mx-auto mb-6 h-64"
                     src="assets/img/illustrations/checklist.svg">
            </div>
        </ng-template>
    </div>
</div>
