export enum CrudModeEnum {
    Create,
    Update,
    Delete,
    Read,
    Import,
    Duplicate,
    Custom,
    Archive,
    Confirm = 8,
    Reject = 9,
}
