<ng-container>
    <form (ngSubmit)='save()' [formGroup]='form'>
        <ng-container
                *ngIf="prestInterventionRequired || (currentRecurringBasket && currentRecurringBasket.prestInterventionId); else notIntervention">
            <base-form-header [mode]='mode' [createLabel]='"Planifier une récurrence"'
                              [updateLabel]="'Modifier la récurrence'"></base-form-header>
        </ng-container>

        <ng-template #notIntervention>
            <base-form-header [mode]='mode' [createLabel]='"Créer un panier contrat"'
                              [updateLabel]='"Modifier un panier contrat"'></base-form-header>
        </ng-template>
        <div class="flex">
            <mat-dialog-content fxLayout='column'>
                <base-form-input
                        label='Libellé'
                        controlName='label'>
                </base-form-input>

                <div class='flex flex-row w-full'>
                    <p>Planifier</p>
                    <mat-divider class='w-full ml-4 self-center border-gray'></mat-divider>
                </div>

                <ng-container
                        *ngIf="prestInterventionRequired || (currentRecurringBasket && currentRecurringBasket.prestInterventionId)">
                    <div class='flex flex-row w-full'>
                        <mat-chip-list aria-label="prestation selection" *ngIf="contract.status === 'DRAFT'">
                            <ng-container *ngFor='let typesOfRecurrence of typesOfRecurrences'>
                                <mat-chip [selected]="isSelected(typesOfRecurrence)"
                                          (click)="selectPrestation(typesOfRecurrence)">
                                    {{ typesOfRecurrence.name === 'LOGIC' ? 'Récurrence logique' : 'Récurrence manuelle' }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isManualPlanification">

                    <div class='flex flex-row w-full mt-4'>
                        <base-form-input
                                class="w-1/2 mr-4"
                                label="À partir de "
                                controlName='startDate'
                                type="date"
                                [dateStartAt]='startDate'
                                [dateEndAt]='endDate'
                        >
                        </base-form-input>
                        <base-form-input
                                class="w-1/2"
                                label="Date de fin d'application"
                                controlName='endDate'
                                type="date"
                                [dateStartAt]='endStartDate'
                                [dateEndAt]='endDate'
                        >
                        </base-form-input>
                    </div>

                    <!--     CREATION D'UN PANIER CONTRAT FIXE   -->

                    <ng-container
                            *ngIf="currentRecurringBasket && currentRecurringBasket.nbIntervention; else recurringBasketWithoutIntervention">
                        <base-form-input
                                [disabled]="isReadMode()"
                                label="Nombre d'intervention(s)"
                                type="number"
                                controlName="nbIntervention">
                        </base-form-input>
                    </ng-container>

                    <!--     CREATION D'UN PANIER CONTRAT  AVEC RECURRENCE   -->

                    <ng-template #recurringBasketWithoutIntervention>
                        <div class="flex flex-col sm:flex-row">
                            <base-form-input
                                    class='sm:mr-3 flex-auto'
                                    label='Répétition'
                                    type='select'
                                    [items]='recurrenceEnum'
                                    displayProperty='label'
                                    controlName='recurrence'>
                            </base-form-input>
                            <ng-container>
                        <span class='flex-auto self-center'>
                            Tou(te)s les
                        </span>
                                <base-form-input
                                        class='sm:ml-3 flex-auto'
                                        [label]='repeatLabel'
                                        type='number'
                                        controlName='repeat'>
                                </base-form-input>
                            </ng-container>
                        </div>

                        <base-form-input
                                *ngIf='autoCompleteIsActive'
                                label='Activé le'
                                [items]='autoCompleteValue'
                                [type]='autoCompleteType'
                                [displayProperty]='autoCompleteDisplayProperty'
                                controlName='activated'>
                        </base-form-input>

                        <base-form-input
                                *ngIf='autocompleteMonth'
                                label='Mois'
                                [items]='monthEnum'
                                type='autocomplete'
                                displayProperty='label'
                                controlName='month'>
                        </base-form-input>

                        <base-form-input
                                class='flex-auto'
                                label='Délai de confirmation'
                                [minNumber]="0"
                                type='number'
                                controlName='confirmationDelay'
                        ></base-form-input>


                        <base-form-input
                                class="sm:mr-3"
                                label="Tout le périmètre"
                                type="checkbox"
                                controlName="isFullPerimeter">
                        </base-form-input>


                    </ng-template>

                </ng-container>

                <ng-container *ngIf="isManualPlanification">

                    <div formArrayName="manualPlanification" class='w-full mt-4'>
                        <div *ngFor="let dateControl of form.get('manualPlanification')?.controls; let i = index"
                             class="flex">
                            <mat-form-field appearance="fill" class="w-full">
                                <mat-label>Choisir une date</mat-label>
                                <input matInput [matDatepicker]="picker" [formControlName]="i" [max]="endDate"
                                       [disabled]="checkIfDateDisabled(dateControl)"
                                       (dateInput)="addDateField()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <button class="mb-6" mat-icon-button
                                    *ngIf="contract.status === 'DRAFT'"
                                    (click)="removeDateField(i)">
                                <mat-icon color="warn">cancel</mat-icon>
                            </button>
                        </div>
                    </div>

                    <!--                    <div class="flex-auto">-->
                    <!--                        <button mat-raised-button color="primary" (click)="addDateField()">Ajouter une date</button>-->
                    <!--                    </div>-->

                    <base-form-input
                            class='flex-auto'
                            label='Délai de confirmation'
                            [minNumber]="0"
                            type='number'
                            controlName='confirmationDelay'
                    ></base-form-input>

                    <base-form-input
                            class="sm:mr-3"
                            label="Tout le périmètre"
                            type="checkbox"
                            controlName="isFullPerimeter">
                    </base-form-input>

                </ng-container>
                <!--    / CREATION D'UN PANIER CONTRAT       -->


                <ng-container *ngIf="prestInterventionRequired && isReccurencePrestation">
                    <base-form-input
                            label="Non Envoi du BC au fournisseur"
                            type='checkbox'
                            controlName='isNotSendBcToSupplier'
                            [disabled]='isReadMode()'
                    >
                    </base-form-input>
                </ng-container>

                <!--            <base-form-input-->
                <!--                    label='Établissement'-->
                <!--                    [items$]='establishment$'-->
                <!--                    type="autocomplete"-->
                <!--                    controlName='establishment'-->
                <!--                    (autocompleteChange)="autocompleteEstablishment($event)"-->
                <!--                    displayProperty="label"-->
                <!--            >-->
                <!--            </base-form-input>-->

                <!--            <div class='flex flex-row w-full'>-->
                <!--                <base-form-input-->
                <!--                    class="w-1/2 mr-4"-->
                <!--                    label='Répéter'-->
                <!--                    controlName='recurrence'-->
                <!--                    type='select'-->
                <!--                    [items]='recurrence'-->
                <!--                    displayProperty='label'-->
                <!--                >-->
                <!--                </base-form-input>-->

                <!--                <base-form-input-->
                <!--                    class="w-1/2"-->
                <!--                    label='Toutes les semaines'-->
                <!--                    controlName='allOf'-->
                <!--                    type='select'-->
                <!--                    [items]='allOf'-->
                <!--                >-->
                <!--                </base-form-input>-->
                <!--            </div>-->

                <!--            <div class='flex flex-row w-full'>-->
                <!--                <p class='self-center mr-4' >Activé : </p>-->
                <!--                <mat-button-toggle-group formControlName='activated' name="ingredients" aria-label="Ingredients" multiple>-->
                <!--                    <mat-button-toggle *ngFor='let day of weeklyDay' value="day">{{ day }}</mat-button-toggle>-->
                <!--                </mat-button-toggle-group>-->
                <!--            </div>-->

                <!--            <base-form-input-->
                <!--                label='Activé (Jours)'-->
                <!--                controlName='activated'-->
                <!--                type='select'-->
                <!--                [items]='monthlyDay'-->
                <!--            >-->
                <!--            </base-form-input>-->


            </mat-dialog-content>

            <ng-container *ngIf="canUpdatePerimeter">
                <div *ngIf="!form.get('isFullPerimeter').value" class="ml-10 w-1/2"
                     style="min-width: 500px; max-height: 800px; overflow-y: auto;">
                    <!--            <vex-tree-checkbox-->
                    <!--                    *ngIf="node?.length > 0"-->
                    <!--                    [node]="node"-->
                    <!--                    [readMode]="false"-->
                    <!--                    (onSelectedItem)="onSelectItem($event)"-->
                    <!--                    (onUnSelectedItem)="onUnSelectItem($event)"-->
                    <!--            ></vex-tree-checkbox>-->

                    <vex-contract-details-user-perimeter
                            [entity]="contract"
                            [parentItems]="perimeter"
                            (selectedItemsChange)="onSelectedItemsChange($event)"
                            [isCompressed]="true"
                            [prestationToPLan]="prestation"
                            class=''>
                    </vex-contract-details-user-perimeter>

                    <!--            <vex-contract-details-user-perimeter *ngIf="(isEnergyId || isEquipment || isBuilding)"-->
                    <!--                                                 [existingItemsList]="existingItemsList"-->
                    <!--                                                 [entity] = "entity"-->
                    <!--                                                 [selectedItemsFromChild] ="selectedItemsFromChild"-->
                    <!--                                                 (selectedItemsChange)="onSelectedItemsChange($event)"-->
                    <!--                                                 class='w-2/4 card overflow-auto p-6'>-->
                    <!--            </vex-contract-details-user-perimeter>-->

                </div>
            </ng-container>


        </div>


        <mat-dialog-actions align='end'>
            <base-form-input
                    [loading]='loading'
                    type='submit'
                    [mode]='mode'
                    entityLabel='CONTRACT'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<!--<ng-container *ngIf='isDeleteMode()'>-->
<!--    <div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>-->
<!--        <div>Supprimer {{ data.name }}</div>-->
<!--        <button type='button' mat-icon-button (click)='close()' tabindex='-1'>-->
<!--            <mat-icon [icIcon]='icClose'></mat-icon>-->
<!--        </button>-->
<!--    </div>-->

<!--    <mat-dialog-content>-->
<!--        <p>Etes-vous sur de vouloir supprimer {{ data.name }} ?</p>-->
<!--    </mat-dialog-content>-->


<!--    <mat-dialog-actions align='end'>-->
<!--        <button mat-button (click)='close()'>NON</button>-->
<!--        <button mat-button color='primary' (click)='deleteItem()'>OUI</button>-->
<!--    </mat-dialog-actions>-->
<!--</ng-container>-->

<!--<ng-container *ngIf='isArchiveMode()'>-->
<!--    <form-modal-delete-base-->
<!--        (onClose)='close()'-->
<!--        (onDelete)='archiveItem()'-->
<!--        title="Archiver le contrat"-->
<!--        defaultMessage='Êtes-vous sûr de vouloir archiver ce contrat ?'-->
<!--    >-->
<!--    </form-modal-delete-base>-->
<!--</ng-container>-->

