import {Component, OnDestroy, OnInit} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {CategoryModel} from '../../../../../../core/models/category.model';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {CategoryService} from '../../../../settings/category/category.service';
import {SnackbarService} from '../../../../../../core/services/snackbar.service';
import {Tab} from '../../../../../../core/base/interfaces/tab.interface';
import {Observable, Subject, Subscription} from 'rxjs';
import icSearch from '@iconify/icons-ic/twotone-search';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserProfileService} from '../../../user-profile/user-profile.service';
import {map} from 'rxjs/operators';
import {EstablishmentService} from '../../establishment.service';
import {ActivatedRoute} from '@angular/router';
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {AuthService} from '../../../../../../core/services/auth.service';

@Component({
    selector: 'establishment-details-validators-component',
    templateUrl: 'establishment-details-validators.component.html',
    styleUrls: ['establishment-details-validators.component.scss']
})

export class EstablishmentDetailsValidatorsComponent implements OnInit, OnDestroy {
    get purchasers() {
        return this.form.get('purchasers') as FormArray;
    }

    get buisnessReferents() {
        return this.form.get('buisnessReferents') as FormArray;
    }

    constructor(
        private establishmentService: EstablishmentService,
        public service: CategoryService,
        private snackbarService: SnackbarService,
        private userProfileService: UserProfileService,
        private route: ActivatedRoute,
        private authService: AuthService
        ) {
    }
    private subscription = new Subscription();
    treeControl: NestedTreeControl<CategoryModel>;
    dataSource: MatTreeNestedDataSource<CategoryModel>;

    loading = false;
    loadingExport = false;
    canUpdateActorsPermission = false;


    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    selectedCategory: CategoryModel = null;
    tabs: Array<Tab> = [
        {
            value: 'validations',
            filters: [''],
            label: 'Validateurs',
            number: 0,
            icon: icSearch
        }
    ];
    activeTab: Tab = this.tabs[0];
    activeTab$ = new Subject<Tab>();

    validator: any;
    form: FormGroup;

    referents$: Observable<any>;
    refN1$: Observable<any>;
    refN2$: Observable<any>;
    purchasers$: Observable<any>;
    approver$: Observable<any>;
    applyToChildren = true;

    affectedUsers$: Observable<any>;

    establishmentId: any;
    establishmentLabel: any;

    getLevel = (node: CategoryModel) => node.level;
    isExpandable = (node: CategoryModel) => node.children?.length > 0;
    hasChild = (_: number, _nodeData: CategoryModel) => _nodeData.children?.length > 0 && _nodeData.level < 2;

    ngOnInit(): void {
        this.initData();
        this.initForm();


        // à partir d'ici : récuperéatio nde l'id de l établissemzent
        // récupération de l'etablissement par le biais de son id
        // récupération de tous les utilsiateurs ayant pour etbalissement affecté ce dernier
        // et renvoit d'une lsite déroulante possiblement pour les contact interne à ajotuer en


        // console.log(this.route.snapshot);
        //
        // this.route.parent.paramMap.subscribe((parentParams: any) => {
        //     this.establishmentId = this.route.snapshot.paramMap.get('id');
        // });
        //
        // this.establishmentService.entity$.subscribe(entity => {
        //     this.establishmentLabel = entity.label;
        // });

        // const sub = this.establishmentService.findById(this.establishmentId).subscribe(label=> {
        //     console.log("label", label)
        //     if (label.data) {
        //         this.establishmentLabel = label.data.label;
        //     } else {
        //         console.error('No data found for establishment ID:', this.establishmentId);
        //     }
        // }, error => {
        //     console.error('Error while fetching establishment by ID:', error);
        // });

        // this.affectedUsers$ = this.userProfileService.findAll(null, null, 'fullName', 'asc', null).pipe(
        //     map(users => users.data.ticket-filter(user => user.affectations.some(aff => aff.establishment.label === this.establishmentLabel.toString())))
        // );

        // this.subscription.add(sub);

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            if (!permissions.includes(ENUM_PERMISSIONS.UPDATE_CP_ESTABLISHMENT_PURCHASE_ACTOR)) {
                this.canUpdateActorsPermission = true;
            }
        });
    }

    initData(): void {
        this.loading = true;
        this.treeControl = new NestedTreeControl<CategoryModel>(node => node.children);
        this.dataSource = new MatTreeNestedDataSource<CategoryModel>();
        this.service.findByLevel(0).subscribe(res => {
            this.dataSource.data = res.data;
            this.loading = false;

            // if we have the id of the category in the url, we select it by default
            if (this.route.snapshot.params?.id) {
                this.service.findById(this.route.snapshot.params?.id).subscribe(category => {
                    this.selectCategory(category.data);
                });
            }
        });
        this.establishmentService.entity$.subscribe(entity => {
            this.establishmentId = entity._id;
            this.establishmentLabel = entity.label;
            this.loadAutoComplete();
        });
    }

    initForm(): void {
        this.form = new FormGroup({
            buisnessReferents: new FormArray([]),
            referentN1: new FormControl(this.validator?.referentN1, [Validators.required]),
            referentN2: new FormControl(this.validator?.referentN2, [Validators.required]),
            approver: new FormControl(this.validator?.approver, [Validators.required]),
            purchasers: new FormArray([]),
        //    applyToChildren: new FormControl(this.validator?.applyToChildren ?? this.applyToChildren, [Validators.required]),
            applyToChildren: new FormControl(true, [Validators.required]),
            autorizationOrder: new FormControl(this.validator?.autorizationOrder, [Validators.required]),
        });

        this.validator?.buisnessReferents?.forEach(buisnessReferent => {
            this.addBuisnessReferent(buisnessReferent);
        });

        this.validator?.purchasers?.forEach(purchaser => {
            this.addPurchaser(purchaser);
        });

        const subApprobator = this.form.controls.approver.valueChanges.subscribe(value => {
            if (value?._id) {
                this.updateValidators();
            }
        });

        const subRefn1 = this.form.controls.referentN1.valueChanges.subscribe(value => {
            if (value?._id) {
                this.updateValidators();
            }
        });

        const subRefn2 = this.form.controls.referentN2.valueChanges.subscribe(value => {
            if (value?._id) {
                this.updateValidators();
            }
        });

        const subAutorizationOrder = this.form.controls.autorizationOrder.valueChanges.subscribe(value => {
            this.updateValidators();
        });

        this.subscription.add(subRefn1);
        this.subscription.add(subRefn2);
        this.subscription.add(subAutorizationOrder);
        this.subscription.add(subApprobator);
    }

    openLevel(node) {
        if (this.treeControl.isExpanded(node) && !node.expandedOnce) {
            node.isLoading = true;
            node.expandedOnce = true;
            this.service.findByLevel(node.level + 1, node.children.map(x => x._id)).subscribe(res => {
                this.dataSource.data = [];
                this.dataSource.data = res.data;
                node.isLoading = false;
            });
        }
    }

    addBuisnessReferent(defaultValue = null) {
        const buisnessReferentForm = new FormGroup({
            buisnessReferent: new FormControl(defaultValue, [Validators.required]),
        });

        const sub = buisnessReferentForm.controls.buisnessReferent.valueChanges.subscribe(value => {
            if (value?._id) {
                this.updateValidators();
            }
        });
        this.subscription.add(sub);

        const buisnessReferents = this.form.controls.buisnessReferents as FormArray;
        buisnessReferents.push(buisnessReferentForm);
    }

    removeBuisnessReferent(index) {
        const buisnessReferents = this.form.controls.buisnessReferents as FormArray;
        buisnessReferents.removeAt(index);
        this.updateValidators();
    }

    addPurchaser(defaultValue = null) {
        const purchaserForm = new FormGroup({
            purchaser: new FormControl(defaultValue, [Validators.required]),
        });

        const sub = purchaserForm.controls.purchaser.valueChanges.subscribe(value => {
            if (value?._id) {
                this.updateValidators();
            }
        });
        this.subscription.add(sub);

        const purchasers = this.form.controls.purchasers as FormArray;
        purchasers.push(purchaserForm);
    }

    removePurchaser(index) {
        const purchasers = this.form.controls.purchasers as FormArray;
        purchasers.removeAt(index);
        this.updateValidators();
    }

    async selectCategory(node) {
        console.log('selectCategory', node);
        const entity = await this.establishmentService.entity;
        this.selectedCategory = node;
        this.establishmentService.getValidator(entity._id, node._id)
            .subscribe((validator) => {

            // @ts-ignore
            this.validator = validator;

            if (this.validator?.approver)
            {
                this.validator.approver.fullName = this.validator.approver.firstName + ' ' + this.validator.approver.lastName;
            }
            this.initForm();
        });
    }

    switchTab(tab: any) {
        if (this.activeTab !== tab) {
            this.activeTab$.next(tab);
        }
    }

    loadAutoComplete() {
        this.autocompleteApprover();
        this.autocompleteReferent();
        this.autocompleteRefN1();
        this.autocompleteRefN2();
        this.autocompletePurchaser();
        this.affectedUsers$ = this.userProfileService.findAll(null, null, 'fullName', 'asc', null, { establishment: this.establishmentId }).pipe(
            map(users => users.data)
        );
    }

    autocompleteApprover(search = '') {
        this.approver$ = this.userProfileService.findAll(null, null, null, null, search, { isApprover: true, isPredik: false, establishment: this.establishmentId })
           // .pipe(map(x => x.data))
            .pipe(
                map(users => users.data)
            );

    }

    autocompleteReferent(search = '') {
        this.referents$ = this.userProfileService.findAll(null, null, null, null, search, { isBuisnessReferent: true, isPredik: false, establishment: this.establishmentId })
         //   .pipe(map(x => x.data))
            .pipe(
                map(users => users.data)
            );
    }

    autocompleteRefN1(search = '') {
        // OLD WITH FILTER ON ALREADY AFFECTED USERS
        // this.refN1$ = this.userProfileService.findAll(null, null, null, null, search, { isValidator: true, isBill : true })
        //     .pipe(map(x => x.data.ticket-filter(y => {
        //     const ids = [];
        //
        //             if (this.buisnessReferents?.length > 0) {
        //                 ids.push(...this.buisnessReferents.value.map(z => z.buisnessReferent._id));
        //             }
        //
        //             if (this.purchasers?.length > 0) {
        //                 ids.push(...this.purchasers.value.map(z => z.purchaser._id));
        //             }
        // |||
        //     return !ids.includes(y._id) &&
        //         y.affectations.some(aff => aff.establishment.label === this.establishmentLabel.toString());
        // })))

        this.refN1$ =  this.userProfileService.findAll(null, null, null, null, search, { establishment: this.establishmentId, isPredik: false, isValidator: true })
            .pipe(
                map(users => users.data)
            );
    }

    autocompleteRefN2(search = '') {
        // OLD WITH FILTER ON ALREADY AFFECTED USERS
        // this.refN2$ = this.userProfileService.findAll(null, null, null, null, search, { isValidator: true, isBill : true })
        //     .pipe(map(x => x.data.ticket-filter(y => {
        //         const ids = [];
        //
        //         if (this.buisnessReferents?.length > 0) {
        //             ids.push(...this.buisnessReferents.value.map(z => z.buisnessReferent._id));
        //         }
        //
        //         if (this.purchasers?.length > 0) {
        //             ids.push(...this.purchasers.value.map(z => z.purchaser._id));
        //         }
        //
        //         return !ids.includes(y._id)  &&
        //             y.affectations.some(aff => aff.establishment.label === this.establishmentLabel.toString());
        //     })))
        this.refN2$ =  this.userProfileService.findAll(null, null, null, null, search, { establishment: this.establishmentId, isPredik: false, isValidator: true })
            .pipe(
                map(users => users.data)
            );
    }

    autocompletePurchaser(search = '') {
        this.purchasers$ = this.userProfileService.findAll(null, null, null, null, search, { isPurchaser: true, isPredik: false, establishment: this.establishmentId })
            .pipe(
                map(users => users.data)
            );
    }

    async updateValidators() {
        const entity = await this.establishmentService.entity;
        const data = {
            ...this.form.getRawValue(),
            category: this.selectedCategory._id
        };

        data.purchasers = data.purchasers
            .filter(x => x?.purchaser?._id)
            .map(x => {
                return x.purchaser ? {
                    _id: x.purchaser._id,
                    fullName: x.purchaser.fullName,
                    firstName: x.purchaser.firstName,
                    lastName: x.purchaser.lastName,
                    email: x.purchaser.email,
                    mobileNumber: x.purchaser.mobileNumber,
                } : null;
            });
        data.buisnessReferents = data.buisnessReferents
            .filter(x => x?.buisnessReferent?._id)
            .map(x => {
                return x.buisnessReferent ? {
                    _id: x.buisnessReferent._id,
                    fullName: x.buisnessReferent.fullName,
                    firstName: x.buisnessReferent.firstName,
                    lastName: x.buisnessReferent.lastName,
                    email: x.buisnessReferent.email,
                    mobileNumber: x.buisnessReferent.mobileNumber,
                } : null;
            });

        data.approver = data.approver ? {
            _id: data.approver?._id,
            fullName: data.approver?.fullName,
            firstName: data.approver?.firstName,
            lastName: data.approver?.lastName,
            email: data.approver?.email,
            mobileNumber: data.approver?.mobileNumber,
        } : null;

        data.referentN1 = data.referentN1 ? {
            _id: data.referentN1._id,
            fullName: data.referentN1.fullName,
            firstName: data.referentN1.firstName,
            lastName: data.referentN1.lastName,
            email: data.referentN1.email,
            mobileNumber: data.referentN1.mobileNumber,
        } : null;

        data.referentN2 = data.referentN2 ? {
            _id: data.referentN2._id,
            fullName: data.referentN2.fullName,
            firstName: data.referentN2.firstName,
            lastName: data.referentN2.lastName,
            email: data.referentN2.email,
            mobileNumber: data.referentN2.mobileNumber,
        } : null;

        const sub = this.establishmentService.setValidator(entity._id, data).subscribe(res => {
            // this.snackbarService.open('Les validateurs ont été mis à jour');
        });
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
