<div class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
        <div class="card">
            <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Détails</h2>

                <button color='primary' mat-raised-button type='button'
                        *hasPermission="[ENUM_PERMISSION.UPDATE_CP_INTERNAL_CONTACT_INFO]" (click)='updateItem()'>
                    MODIFIER
                </button>
            </div>

            <div *ngIf='(userProfileService.entity$ | async)?._id; else loading' class="px-gutter py-4"
                 gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icPerson" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (userProfileService.entity$ | async).lastName | uppercase }}</p>
                        <p class="m-0 caption text-hint">Nom</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icPersonOutline" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (userProfileService.entity$ | async).firstName | titlecase }}</p>
                        <p class="m-0 caption text-hint">Prénom</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icPhone" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (userProfileService.entity$ | async).mobileNumber }}</p>
                        <p class="m-0 caption text-hint">Téléphone</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icMail" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (userProfileService.entity$ | async).email }}</p>
                        <p class="m-0 caption text-hint">Email</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icMail" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (userProfileService.entity$ | async).mcEmail }}</p>
                        <p class="m-0 caption text-hint">Email Microsoft</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngIf="(userProfileService.entity$ | async).affectAllEstablishments; else affectation">
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icCheck" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <p class="m-0 body-1">Oui</p>
                        <p class="m-0 caption text-hint">Affectation à tous les établissements</p>
                    </div>
                </div>

                <ng-template #affectation>
                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                        <div @scaleIn
                             class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                            <ic-icon [icon]="icClose" size="20px"></ic-icon>
                        </div>

                        <div @fadeInRight>
                            <p class="m-0 body-1">Non</p>
                            <p class="m-0 caption text-hint">Affectation à tous les établissements</p>
                        </div>
                    </div>
                </ng-template>

                <!--                       TODO: Delete this part if role work for predik

                    <div class="py-3" fxLayout="row" fxLayoutAlign="start center">-->
                <!--                    <div @scaleIn-->
                <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                <!--                        <ic-icon [icon]="icAccessTime" size="20px"></ic-icon>-->
                <!--                    </div>-->

                <!--                    <div @fadeInRight>-->
                <!--                        <p class="m-0 body-1">{{ (userProfileService.entity$ | async)?.role?.name | titlecase }}</p>-->
                <!--                        <p class="m-0 caption text-hint">Rôle</p>-->
                <!--                    </div>-->

                <!--                </div>-->

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center"
                     *ngIf='(userProfileService.entity$ | async)?.contactType?.length > 0'>
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icBusiness" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <span class="m-0 body-1"
                              *ngFor='let contactType of (userProfileService.entity$ | async)?.contactType; index as i'>
                            <span *ngIf='i !== 0'>, </span>
                            {{ contactType.label }}
                        </span>
                        <p class="m-0 caption text-hint">Type d'acteur</p>
                    </div>
                </div>

                <!--           TODO: Delete this part if role work for predik
                       <div class="py-3" fxLayout="row" fxLayoutAlign="start center" *ngIf='(userProfileService.entity$ | async)?.contactType?.length > 0'>-->
                <!--                    <div @scaleIn-->
                <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                <!--                        <mat-icon>assignment</mat-icon>-->
                <!--                    </div>-->

                <!--                    <div @fadeInRight>-->
                <!--                        <span class="m-0 body-1" *ngFor='let role of (userProfileService.entity$ | async)?.roles; index as i'>-->
                <!--                            <span *ngIf='i !== 0'>, </span>-->
                <!--                            {{ role }}-->
                <!--                        </span>-->
                <!--                        <p class="m-0 caption text-hint">Rôle</p>-->
                <!--                    </div>-->
                <!--                </div>-->

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center"
                     *ngIf='(userProfileService.entity$ | async)?.isPredik'>
                    <div @scaleIn
                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                        <ic-icon [icon]="icBusiness" size="20px"></ic-icon>
                    </div>

                    <div @fadeInRight>
                        <p class="m-0 body-1">Oui</p>
                        <p class="m-0 caption text-hint">Contact Predik</p>
                    </div>
                </div>

<!--                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">-->
<!--                    <div @scaleIn-->
<!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
<!--                        <ic-icon [icon]="icWork" size="20px"></ic-icon>-->
<!--                    </div>-->

<!--                    <div @fadeInRight>-->
<!--                        <p class="m-0 body-1">{{ translateValidatorMatriceLevel }}</p>-->
<!--                        <p class="m-0 caption text-hint">Niveau de validation</p>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>

        <div class='card mt-6'>
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <div fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class='title m-0 mr-2'>Affectations</h2>
                    <icon-warning
                            [show]='displayAffectationWarning'
                            toolTip='Le contact doit avoir au moins 1 affection'>
                    </icon-warning>
                </div>

                <button (click)='createItemAffectation()'
                        *hasPermission="[ENUM_PERMISSION.ADD_CP_INTERNAL_CONTACT_INFO]" color='primary'
                        mat-raised-button type='button'>AJOUTER
                </button>
            </div>

            <div @stagger
                 class='px-gutter py-4'
                 gdGap='24px'>
                <!-- TODO PERMISSION[updatePermission]="[ENUM_PERMISSION.UPDATE_CP_INTERNAL_CONTACT_ASSIGNMENT]"
                [deletePermission]="[ENUM_PERMISSION.DELETE_CP_INTERNAL_CONTACT_ASSIGNMENT]"-->
                <base-data-table
                        [showPaginator]='false'
                        [sortField]='sortFieldAffectation'
                        [sortOrder]='sortOrderAffectation'
                        [dataSource]='dataSourceAffectation'
                        [displayedColumns]='displayedColumnsAffectation'
                        [feisColumns]='feisColumnsAffectation'
                        [updatePermission]="[ENUM_PERMISSION.TEST]"
                        [deletePermission]="[ENUM_PERMISSION.TEST]"
                        (onPageEvent)='pageEventAffectation($event)'
                        (onSortEvent)='sortEventAffectation($event)'
                        (onUpdateItem)='updateItemAffectation($event)'
                        (onDeleteItem)='deleteItemAffectation($event)'

                ></base-data-table>
            </div>
        </div>
        <div class="card mt-4" *ngIf="loading">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Informations de connexion Docuware</h2>
            </div>
            <div class="mt-4 p-4" fxLayout="column" [formGroup]="gedForm">
                <base-form-input
                        label="Nom d'utilisateur"
                        controlName="username"
                ></base-form-input>
                <base-form-input
                        type="password"
                        [obscure]="true"
                        label="Mot de passe"
                        controlName="password"
                ></base-form-input>

                <button mat-raised-button color="primary" class="mt-4" fxFlexAlign="end" (click)="validGedForm()" [disabled]="gedForm.invalid">ENREGISTRER</button>
            </div>
        </div>
    </div>

    <div class="flex-none max-w-unset md:max-w-md w-full md:ltr:ml-6 md:rtl:mr-6 mt-6 md:mt-0">
        <div class="card">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <div fxLayout='row' fxLayoutAlign='space-between center'>
                    <h2 class='title m-0 mr-2'>Postes</h2>
                    <icon-warning
                            [show]='displayPostePercentWarning'
                            toolTip='Le pourcentage doit être égal à 100%'>
                    </icon-warning>
                </div>

                <button (click)='createItemEmployeePosition()'
                        *hasPermission="[ENUM_PERMISSION.ADD_CP_INTERNAL_CONTACT_INFO_JOB]" color='primary'
                        mat-raised-button type='button'>AJOUTER
                </button>
            </div>

            <div class="px-gutter py-3">
                <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_CP_INTERNAL_CONTACT_POST]"
                [deletePermission]="[ENUM_PERMISSION.DELETE_CP_INTERNAL_CONTACT_INFO]"-->
                <base-data-table
                        [showPaginator]='false'
                        [sortField]='sortFieldEmployeePosition'
                        [sortOrder]='sortOrderEmployeePosition'
                        [dataSource]='dataSourceEmployeePosition'
                        [displayedColumns]='displayedColumnsEmployeePosition'
                        [feisColumns]='feisColumnsEmployeePosition'
                        [updatePermission]="[ENUM_PERMISSION.test]"
                        [deletePermission]="[]"
                        (onSortEvent)='sortEventEmployeePosition($event)'
                        (onUpdateItem)='updateItemEmployeePosition($event)'
                        (onDeleteItem)='deleteItemEmployeePosition($event)'
                ></base-data-table>
            </div>
        </div>

        <!--        <div class="card mt-6">-->
        <!--            <div class="px-6 py-4 border-b">-->
        <!--                <h2 class="title m-0">Friend Suggestions</h2>-->
        <!--            </div>-->

        <!--            &lt;!&ndash;            <div @stagger class="px-6 py-4" fxLayout="column" fxLayoutGap="16px">&ndash;&gt;-->
        <!--            &lt;!&ndash;                <div *ngFor="let friend of suggestions; trackBy: trackByName" fxLayout="row" fxLayoutAlign="start center">&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <img @scaleIn [src]="friend.imageSrc" alt="Profile Picture" class="avatar ltr:mr-3 rtl:ml-3" fxFlex="none"/>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <div @fadeInRight fxFlex="auto">&ndash;&gt;-->
        <!--            &lt;!&ndash;                        <h4 class="body-2 m-0 leading-snug">{{ friend.name }}</h4>&ndash;&gt;-->
        <!--            &lt;!&ndash;                        <h5 class="text-secondary m-0 caption leading-none">{{ friend.friends }} mutual friends</h5>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <button (click)="addFriend(friend)" *ngIf="!friend.added" color="primary" mat-icon-button type="button">&ndash;&gt;-->
        <!--            &lt;!&ndash;                        <mat-icon [icIcon]="icPersonAdd"></mat-icon>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    </button>&ndash;&gt;-->

        <!--            &lt;!&ndash;                    <button (click)="removeFriend(friend)" *ngIf="friend.added" color="primary" mat-icon-button type="button">&ndash;&gt;-->
        <!--            &lt;!&ndash;                        <mat-icon [icIcon]="icCheck"></mat-icon>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    </button>&ndash;&gt;-->
        <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--        </div>-->
    </div>
</div>

<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>
    </div>
</ng-template>
