import { TicketStatusEnum } from './ticketStatusEnum';

export enum TicketTypeEnum {
    INCIDENT = 'INCIDENT',
    REQUIREMENT = 'REQUIREMENT'
}

export const ticketTypeTranslation = (status: TicketTypeEnum) => {
    switch (status) {
        case TicketTypeEnum.INCIDENT.toString():
            return 'Incident';
        case TicketTypeEnum.REQUIREMENT.toString():
            return 'Besoin';
        default:
            return null;
    }
};
