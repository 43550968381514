<ng-content></ng-content>

<mat-form-field [appearance]="appearance"
                [ngStyle]="inputTextWidthAuto ? {'width.ch': inputText.value.length, 'min-width.ch': 10} : false"
                color='primary' *ngIf='fcd.form && (type === "text" || type === "password") ' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [type]='type' [formControlName]='controlName' [placeholder]="placeholder" (blur)="onLostFocus.emit()"
           #inputText matInput>
    <button *ngIf='obscure' (click)="toggleInput()" mat-icon-button matSuffix type="button">
        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
    </button>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.pattern">{{ invalidPatternMessage }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.customError">{{ customErrorMessage }}</mat-error>

</mat-form-field>

<mat-form-field [appearance]="appearance" color='primary' *ngIf='fcd.form && type === "number" '
                class="flex-auto w-full" [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [min]='minNumber' [max]='maxNumber' type='number' [formControlName]='controlName' (blur)="onLostFocus.emit()"
           matInput>
    <button *ngIf='obscure' (click)="toggleInput()" mat-icon-button matSuffix type="button">
        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
    </button>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>

    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + maxNumber }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.isInteger" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.isInteger }}
    </mat-error>
</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "select"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControlName]='controlName' [disabled]="readonly">
        <ng-container *ngIf='!loading'>
            <mat-option *ngFor='let item of items'
                        [value]='item'>{{  displayProperty ? getProperties(item) : item  }}
            </mat-option>
        </ng-container>
    </mat-select>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "multiple"' class="flex-auto w-full"
                [formGroup]='fcd.form'>

    <mat-label>{{ label }}</mat-label>
    <!--    <input [type]='type' [formControlName]='controlName' matInput>-->
    <mat-select [formControlName]='controlName' multiple>
        <mat-select-trigger *ngIf="customMultipleSelectedTemplate">
            <ng-container
                    *ngTemplateOutlet="customMultipleSelectedTemplate; context: {element: fcd.form.controls[controlName].value}"></ng-container>
        </mat-select-trigger>
        <ng-container *ngIf='!loading'>
            <mat-option *ngFor='let item of items' [value]='item' class="wrap-text">
                {{  displayProperty ? getProperties(item) : item  }}
            </mat-option>
        </ng-container>
    </mat-select>

    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
</mat-form-field>


<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "autocomplete-multiple"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
        <mat-chip-list #chipList>
            <mat-chip
                    *ngFor="let item of chipsValues"
                    (removed)="clearChip(item)">
                {{  isStringArray ? item : item.label }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
    <input [matAutocompleteDisabled]='disabled || readonly' [readonly]="readonly" [formControlName]='controlName'
           [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (keydown)='keydownAutocomplete($event)' (blur)="onLostFocus.emit()" matInput>
        </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)"
                      [displayWith]='_displayFn.bind(this)' [panelWidth]="autocompletePanelWidthAuto ? 'auto' : false">
        <mat-option *ngFor="let state of filteredStates$ | async" [value]="state" [disabled]="readonly">
            <span class="body-1">{{ displayProperty ? getProperties(state) : state }}</span>
        </mat-option>
    </mat-autocomplete>
    <!--    <div *ngIf="isDuplicate" style="color: #ff0000;">Ce fournisseur a déja été sélectionné</div>-->
    <!-- Boutons désactivés ou cachés en mode lecture seule -->
    <button *ngIf='fcd.form.controls[controlName].value && !(disabled || readonly) && !fcd.form.controls[controlName].disabled'
            class="text-secondary" mat-icon-button matSuffix
            type="button" (click)='resetAutoComplete()'>
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
    <button *ngIf='!fcd.form.controls[controlName].value && !readonly' class="text-secondary" mat-icon-button matSuffix
            type="button">
        <mat-icon style='font-size: 25px; padding-left: 15px' [icIcon]="icArrowDropDown"></mat-icon>
    </button>

    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.incorrect" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.incorrect }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "autocomplete"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [matAutocompleteDisabled]='disabled || readonly' [readonly]="readonly" [formControlName]='controlName'
           [matAutocomplete]="auto"
           (keydown)='keydownAutocomplete($event)' (blur)="onLostFocus.emit()" matInput>
    <mat-autocomplete (optionSelected)="optionSelected($event)" #auto="matAutocomplete"
                      [displayWith]='_displayFn.bind(this)' [panelWidth]="autocompletePanelWidthAuto ? 'auto' : false">
        <mat-option *ngFor="let state of filteredStates$ | async" [value]="state" [disabled]="readonly">
            <span class="body-1">{{ displayProperty ? getProperties(state) : state}}</span>
        </mat-option>
    </mat-autocomplete>

    <!-- Boutons désactivés ou cachés en mode lecture seule -->
    <button *ngIf='fcd.form.controls[controlName].value && !(disabled || readonly) && !fcd.form.controls[controlName].disabled'
            class="text-secondary" mat-icon-button matSuffix
            type="button" (click)='resetAutoComplete()'>
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
    <button *ngIf='!fcd.form.controls[controlName].value && !readonly' class="text-secondary" mat-icon-button matSuffix
            type="button">
        <mat-icon style='font-size: 25px; padding-left: 15px' [icIcon]="icArrowDropDown"></mat-icon>
    </button>

    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.incorrect" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.incorrect }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "date"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [formControlName]='controlName' [matDatepicker]="datepickerRef" [matDatepickerFilter]="dateFilter"
           [min]="minDate" [max]="maxDate" (blur)="onLostFocus.emit()" matInput (dateChange)="signatureDateChanged($event)">
    <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #datepickerRef></mat-datepicker>
    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
</mat-form-field>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "time"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <mat-label>{{ label }}</mat-label>
    <input [formControlName]='controlName' (blur)="onLostFocus.emit()" format="24" [ngxMatTimepicker]="pickerB"
           matInput>
    <mat-icon matSuffix
              *ngIf="!fcd.form.disabled"
              (click)="pickerB.open()">
        watch_later
    </mat-icon>
    <ngx-mat-timepicker [confirmBtnTmpl]='okBtn' [cancelBtnTmpl]='cancelBtn' #pickerB></ngx-mat-timepicker>
    <ng-template #cancelBtn>
        <button mat-button type="button">ANNULER</button>
    </ng-template>
    <ng-template #okBtn>
        <button mat-raised-button color='primary' type="button">OK</button>
    </ng-template>
    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
</mat-form-field>

<ng-container *ngIf='fcd.form && type === "checkbox"' class="flex-auto w-full" [formGroup]='fcd.form'>
    <mat-checkbox [formControlName]="controlName"> {{ label }}</mat-checkbox>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
    <mat-hint>{{ hint }}</mat-hint>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.email" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.email }}</mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.min" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.min + " " + fcd.form.controls[controlName].errors?.min.min }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.max" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.max + " " + fcd.form.controls[controlName].errors?.max.max }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.minlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.minLength + " " + fcd.form.controls[controlName].errors?.minlength.requiredLength }}
    </mat-error>
    <mat-error
            *ngIf="fcd.form.controls[controlName].errors?.maxlength" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.maxLength + " " + fcd.form.controls[controlName].errors?.maxlength.requiredLength }}
    </mat-error>
</ng-container>

<mat-form-field [appearance]="appearance" *ngIf='fcd.form && type === "file"' class="flex-auto w-full"
                [formGroup]='fcd.form'>
    <ngx-mat-file-input [formControlName]="controlName" placeholder="Required input"
                        valuePlaceholder="Sélectionner un fichier .xlsx" required></ngx-mat-file-input>
    <mat-icon class="mr-3" matSuffix>folder</mat-icon>
    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required" [attr.data-mat-error-name]="controlName + '-error'">{{ defaultErrorMessage.required }}</mat-error>
    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>
</mat-form-field>


<ng-container *ngIf='fcd.form && type === "slide-toggle"' class="flex-auto w-full mb-4" [formGroup]='fcd.form'>
    <mat-slide-toggle [formControlName]='controlName'>{{ label }}</mat-slide-toggle>
    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
</ng-container>

<!--<mat-form-field *ngIf='fcd.form && type === "slide-toggle"' class="flex-auto w-full" [formGroup]='fcd.form'>-->
<!--    <mat-slide-toggle [formControlName]='controlName'>{{ label }}</mat-slide-toggle>-->

<!--    <mat-icon *ngIf='icon' [icIcon]="icon" class="mr-3" matPrefix></mat-icon>-->
<!--    <mat-error *ngFor="let message of fcd.form.controls[controlName].errors?.messages">{{ message }}</mat-error>-->
<!--    <mat-error *ngIf="fcd.form.controls[controlName].errors?.required">mobileNumber is required</mat-error>-->
<!--</mat-form-field>-->

<!--<mat-dialog-actions *ngIf='fcd.form && type === "submit"' align="end">-->
<!--    <button mat-button mat-dialog-close type="button">{{ cancelLabel }}</button>-->
<!--    <button *ngIf="mode === CrudModeEnum.Create" color="primary" mat-button type="submit">CREATE {{ entityLabel }}</button>-->
<!--    <button *ngIf="mode === CrudModeEnum.Update" color="primary" mat-button type="submit">UPDATE {{ entityLabel }}</button>-->
<!--</mat-dialog-actions>-->

<ng-container *ngIf='fcd.form && type === "submit"'>
    <button *ngIf='showCancelButton' mat-button mat-dialog-close type="button">{{ cancelLabel }}</button>
    <button *ngIf="mode === CrudModeEnum.Create" (click)='onCreate.emit()' [loading]='loading' color="primary"
            mat-button
            type="button">{{ (customValidateButtonLabel ? customValidateButtonLabel : saveButtonLabel) }}
    </button>
    <button *ngIf="mode === CrudModeEnum.Update" (click)='onUpdate.emit()' [loading]='loading' color="primary"
            mat-button
            type="button">{{ (customValidateButtonLabel ? customValidateButtonLabel : updateButtonLabel) }}
    </button>
    <button *ngIf="mode === CrudModeEnum.Import" (click)='onImport.emit()' [loading]='loading' color="primary"
            mat-button type="button">IMPORTER
    </button>
</ng-container>
