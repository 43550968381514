<ng-container>
    <div *ngIf="isLoading" class="flex items-center justify-center h-screen">
        <mat-spinner color="primary"></mat-spinner>
    </div>
    <div *ngIf="!isLoading" class="flex flex-col w-full gap-6 mt-6">
        <div class="flex flex-row-reverse justify-between gap-2">
            <span class="flex gap-2">
            <button *ngIf="entity.creationStep === 2 && entity.perimeter.length > 0" color='primary'
                    mat-raised-button type='button'
                    (click)="updateStep(3)">ENREGISTRER</button>
            <button *ngIf="entity.creationStep !== 2 && ( !(entity.status===ContractStatusEnum.VALIDATED) && !(entity.status===ContractStatusEnum.EXPIRED)) " color='primary' mat-raised-button type='button'
                    (click)="switchToUpdateMode()">MODIFIER</button>
            </span>
        </div>

        <div class="flex flex-row items-stretch justify-center gap-6">

            <div *ngIf="entity.creationStep === 2"  class='w-2/4 card overflow-auto p-6'>

                <div class="w-full">
                    <button class="mb-4 w-full" mat-raised-button color="primary"
                            (click)="updateCheckboxSelection(!isAllSelected())">
                        {{ selectAllButtonLabel }}
                    </button>
                </div>

                <form *ngIf="!isReference"
                        [formGroup]="searchForm" class="flex -mb-3">
                    <div class="w-1/3 md:pr-2">
                        <base-form-input
                                [items]="removeDuplicatesAndSortByKey(itemsListNoDuplicate, 'genericId')"
                                controlName='generics'
                                displayProperty="genericLabel"
                                label='Générique'
                                disableAutoSelect="true"
                                type='select'
                        >
                        </base-form-input>
                    </div>

                    <div class="w-1/3 md:pr-2">
                        <base-form-input
                                [items]="removeDuplicatesAndSortByKey(itemsListNoDuplicate, 'establishmentId')"
                                controlName='establishments'
                                displayProperty="establishmentLabel"
                                label='Etablissement'
                                disableAutoSelect="true"
                                type='select'>
                        </base-form-input>
                    </div>
                    <div class="w-1/3">
                        <base-form-input
                                [items]="removeDuplicatesAndSortByKey(itemsListNoDuplicate, 'buildingId')"
                                controlName='buildings'
                                displayProperty="buildingLabel"
                                label='Bâtiment'
                                disableAutoSelect="true"
                                type='select'>
                        </base-form-input>
                    </div>
                </form>

                <div *ngIf="!isReference" class="w-full">
                    <button class="mb-2 w-full" mat-stroked-button color="primary"
                            (click)="clearFilter()">
                        Effacer filtres
                    </button>
                </div>

                <div class='bg-card flex flew-row items-center rounded-full border mb-4 px-4 ltr:pr-4 rtl:pl-4 ltr:border-r rtl:border-l'>
                    <ic-icon [icIcon]='icSearch' size='20px'></ic-icon>
                    <input
                            class='px-4 py-3 border-0 outline-none w-full bg-transparent'
                            placeholder='Recherche...'
                            [formControl]="searchControl"
                            #search
                    >
                </div>

                <form [formGroup]="itemPerimeterForm">
                    <div *ngFor="let item of itemsListNoDuplicate">
                        <mat-checkbox
                                [id]="item._id"
                                [checked]="isSelected(item._id)"
                                [disabled]="isNotAvailable(item._id)"
                                [value]="item._id"
                                (change)="toggleSelection($event.checked, item._id)">
                            {{ item.label }}
                            <span *ngIf="!isReference && (item.buildingId === null)" style="color: red;">{{ messageItemNotAvailable }}</span>
                        </mat-checkbox>
                    </div>
                </form>

            </div>

            <vex-contract-details-user-perimeter
                    [entity] = "entity"
                    (selectedItemsChange)="onSelectedItemsChange($event)"
                    [readMode]="entity.creationStep !== 2"
                    [ngClass]="{'w-full': entity.creationStep !== 2, 'w-2/4': entity.creationStep === 2}"
                    class='card overflow-auto p-6'>
            </vex-contract-details-user-perimeter>

        </div>

        <div class="flex flex-row items-stretch justify-center gap-6"
             *ngIf="entity.creationStep === 2">
        <button  color='primary'
                mat-raised-button type='button'
                (click)="addItem()">AJOUTER <mat-icon size="20" [icIcon]="faAngleRight"></mat-icon></button>
        <button color='primary'
                mat-raised-button type='button'
                (click)="deleteItem()"> <mat-icon size="20" [icIcon]="faAngleLeft"></mat-icon> RETIRER</button>
           </div>
    </div>
</ng-container>