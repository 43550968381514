import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, finalize, Subject, Subscription} from "rxjs";
import {ProductService} from "../../../pages/application/management/product/product.service";
import {PrestationService} from "../../../pages/application/management/prestation/prestation.service";
import {EquipmentService} from "../../../pages/application/management/equipment/equipment.service";
import {MatTableDataSource} from "@angular/material/table";
import {FeiColumn} from "../../base/interfaces/fei-column.interface";
import {ICatalogAction} from "./component/catalog-actions/catalog-actions.component";
import icDone from "@iconify/icons-ic/twotone-done";
import icPlus from "@iconify/icons-ic/twotone-plus";
import icMinus from "@iconify/icons-ic/twotone-minus";
import icClose from "@iconify/icons-ic/twotone-close";
import {takeUntil} from "rxjs/operators";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {AuthService} from "../../services/auth.service";
import {ContactTypeEnum} from "../../enums/contact-type.enum";

@Component({
    selector: 'catalog-component',
    template: ''
})

export class CatalogComponent implements OnInit {
    tabs = ["Articles", "Prestations"];
    tabsVisible = [false, false];
    subscription = new Subscription();
    entityService: ProductService | PrestationService;
    entityType: 'product' | 'prestation';
    dataSourceCatalog = new MatTableDataSource();
    displayedColumnsCatalog: Array<string>;
    filters: any = {};
    feisColumnsCatalog: Array<FeiColumn> = [
        {label: 'Type', column: 'quoteId', type: 'custom'},
        {label: 'Libellé', column: 'label', type: 'custom'},
        {label: 'Réference', column: 'reference', type: 'custom'},
        {label: 'Fabricant/Marque', column: 'manufacturer', canSort: true},
        {label: 'Fournisseur', column: 'supplier', propertyDisplay: 'supplier.label'},
        {label: 'Prix H.T.', column: 'priceHT', type: 'number', additionalDisplay: ' €'},
        // { label: 'Prix T.T.C', column: 'priceTTC', type: 'number', additionalDisplay: ' €' },
        {label: 'Stocks', column: 'stockable', type: 'boolean'},
        {label: 'Quantité', column: 'quantity', type: 'custom'},
        {label: 'Action', column: 'addToCart', type: 'custom'},

    ];
    sortField$ = new BehaviorSubject(null);
    sortOrder$ = new BehaviorSubject('asc');
    pageIndex$ = new BehaviorSubject(1);
    pageSize$ = new BehaviorSubject(10);
    count = 0;
    search: string;
    loading = false;
    showPriceAnyway: boolean = false;
    selectedIndex = 0;
    icDone = icDone;
    icPlus = icPlus;
    icMinus = icMinus;
    icClose = icClose;
    protected destroy$ = new Subject<void>();

    actions: Array<ICatalogAction> = [
        {
            label: 'Hors catalogue',
            click: null
        },
        {
            label: 'Séléctionner un favoris',
            click: null
        },
    ];

    loading$ = new BehaviorSubject(false);

    constructor(
        public equipmentService: EquipmentService,
        public productService: ProductService,
        public prestationService: PrestationService,
        public authService: AuthService,
    ) {
        this.displayedColumnsCatalog = this.feisColumnsCatalog.map((feiColumn: FeiColumn) => feiColumn.column);
    }

    ngOnInit() {
        this.initProducts();
        this.initData();
    }

    initData() {
        this.loading$.next(true);
        // this.filters.requirementCatalog = true;
        this.filters = {
            isAvailable: true,
            isInUserPerimeter: true,
            isContractValid: true,
            isExpired: false,
            isArchive: false,
        };

        this.entityService
            .findAll(this.pageIndex$.value, this.pageSize$.value, this.sortField$.value, this.sortOrder$.value, this.search, this.filters)
            .pipe(takeUntil(this.destroy$), finalize(() => this.loading$.next(false)))
            .subscribe((result: any) => {
                result.data = result.data.map((entity: any) => {
                    entity.inStock = true;
                    entity.quantity = 1;

                    if (entity.quoteId){
                        entity.quoteId = true;
                    }

                    return entity;
                });


                this.dataSourceCatalog = new MatTableDataSource(result.data);
                this.count = result.totalData;
            });

        const user = this.authService.currentUserValue();
        if (!this.showPriceAnyway) {
            if (!(user.contactType?.some(x => x.label === ContactTypeEnum.APPROVISIONNEUR))) {
                this.feisColumnsCatalog = this.feisColumnsCatalog.filter(x => x.column !== 'priceHT' && x.column !== 'priceTTC');
                this.displayedColumnsCatalog = this.feisColumnsCatalog.map((feiColumn: FeiColumn) => feiColumn.column);
            }
        }

        if (this.selectedIndex === 1) {
            this.displayedColumnsCatalog = this.feisColumnsCatalog.filter((feiColumn: FeiColumn) => feiColumn.column !== 'mark').map((feiColumn: FeiColumn) => feiColumn.column);
        }
    }

    initProducts() {
        this.entityService = this.productService;
        this.entityType = 'product';
        this.displayedColumnsCatalog = this.feisColumnsCatalog.map((feiColumn: FeiColumn) => feiColumn.column);
    }

    initPrestations() {
        this.entityService = this.prestationService;
        this.entityType = 'prestation';
        this.displayedColumnsCatalog = this.feisColumnsCatalog.map((feiColumn: FeiColumn) => feiColumn.column);
    }


    // initEquipments() {
    //     this.entityService = this.equipmentService;
    //     this.entityType = "equipment";
    //     this.feisColumnsCatalog[0].column = 'reference';
    //     this.displayedColumnsCatalog = this.feisColumnsCatalog.map((feiColumn: FeiColumn) => feiColumn.column);
    // }

    pageEvent(event: PageEvent): void {
        this.pageIndex$.next(event.pageIndex + 1);
        this.pageSize$.next(event.pageSize);

        this.initData();
    }

    sortEvent(event: Sort): void {
        this.sortField$.next(event.active);
        this.sortOrder$.next(event.direction);
        this.initData();
    }

    updateCatalogueQuantity(element, direction: string) {
        this.dataSourceCatalog = new MatTableDataSource(this.dataSourceCatalog.data.map((entity: any) => {
            if (entity._id === element._id) {
                if (direction === "minus" && entity.quantity === 1) return entity;
                if (direction === "change") return entity;
                if (direction === "plus" && entity.quantity === 9999) return entity;
                direction === "plus" ? entity.quantity++ : entity.quantity--;
            }
            return entity;
        }));
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
