import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ContractModel, ContractPerimeterModel} from '../../../../../../core/models/contract.model';
import {GenericEngagementDataPatrimonyEnum} from "../../../../../../core/enums/generic-engagement-data-patrimony.enum";
import {EstablishmentService} from "../../../establishment/establishment.service";
import {EquipmentService} from "../../../equipment/equipment.service";
import {EnergyIdService} from "../../../energy-id/energy-id.service";
import {BuildingService} from "../../../building/building.service";
import {ContractService} from "../../contract.service";
import {Subscription} from "rxjs";
import {PrestationModel} from "../../../../../../core/models/prestation.model";
import {PrestationService} from "../../../prestation/prestation.service";

@Component({
    selector: 'vex-contract-details-user-perimeter',
    templateUrl: './contract-details-user-perimeter.component.html',
    styleUrls: ['./contract-details-user-perimeter.component.scss']
})
export class ContractDetailsUserPerimeterComponent implements OnInit, OnDestroy {


    @Input() entity?: ContractModel;
    @Input() parentItems: any[] = [];
    @Input() isDefaultSelected: boolean;
    @Input() isCheckboxDisabled = false;
    @Input() readMode = false;
    @Input() isCompressed = false;
    @Input() prestationToPLan?: PrestationModel;
    @Output() selectedItemsChange = new EventEmitter<ContractPerimeterModel[]>();
    itemsList: any[] = [];
    displayedItemsList: any[] = [];
    selectedPerimeters: ContractPerimeterModel[];
    selectedItems: any[] = [];

    subscription = new Subscription();

    //use in case you want to stock the perimeter and not over call the back
    @Input() noReloadData: boolean = false;
    @Input() stockedData = []; // data stocked in the parent
    @Output() stockData = new EventEmitter<any>(); // to send the stocked data to parent


    constructor(
        private establishmentService: EstablishmentService,
        private energyIdService: EnergyIdService,
        private equipmentService: EquipmentService,
        private buildingService: BuildingService,
        private contractService: ContractService,
        private prestationService: PrestationService
    ) {

    }

    ngOnInit(): void {
        this.initData();
    }


    initData() {
        this.initExistingPerimeter();
    }

    toggleSelectionItem(checked: boolean, item) {

        let groupedItems = [];

        if (!this.prestationToPLan) {

            if (checked) {
                switch (this.entity.genericEngagement.dataPatrimony) {

                    case GenericEngagementDataPatrimonyEnum.EQUIPMENT && GenericEngagementDataPatrimonyEnum.ID_ENERGIE:
                        // if not already in the selectedItems list

                        if (this.isCompressed) {
                            groupedItems = this.itemsList.filter(x => x._id === item._id);
                            groupedItems.forEach(groupedItem => {
                                this.selectedItems.push(groupedItem);
                            });

                        } else {
                            groupedItems = this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id);

                            groupedItems.forEach(groupedItem => {
                                this.selectedItems.push(groupedItem);

                            });
                        }


                        break;

                    case GenericEngagementDataPatrimonyEnum.BUILDING:
                        if (this.isCompressed) {
                            groupedItems = this.itemsList.filter(x => x.buildingId === item.buildingId);
                            groupedItems.forEach(groupedItem => {
                                this.selectedItems.push(groupedItem);
                            });
                        } else {
                            this.selectedItems.push(item);
                        }


                    default:

                        this.selectedItems.push(item);
                        break;
                }

            } else {
                // remove from selectedItems item that have the same _id and establishmentId
                this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem._id !== item._id || selectedItem.establishmentId !== item.establishmentId);
            }


        } else {

            if (checked) {
                this.selectedItems.push(item);
            } else {
                this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem._id !== item._id || selectedItem.establishmentId !== item.establishmentId);
            }

        }

        //update perimeters list


        if (!this.prestationToPLan) {
            this.convertToContractPerimeterModel(this.selectedItems);
        } else {
            this.convertToContractPerimeterModel(this.selectedItems);
        }

        this.selectedItemsChange.emit(this.selectedPerimeters);
    }


    convertToContractPerimeterModel(selectedItems): ContractPerimeterModel[] {
        const result: ContractPerimeterModel[] = [];
        let contractPerimeter: ContractPerimeterModel;
        for (const item of selectedItems) {
            if (!item) return null;

            contractPerimeter = {
                establishmentId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.REFERENCE ? item._id : item.establishmentId,
                buildingId: item.buildingId ? item.buildingId : null,
                equipmentId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.EQUIPMENT ? item._id : null,
                energyIdId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.ID_ENERGIE ? item._id : null,
            };

            if (!(this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.REFERENCE)) {
                contractPerimeter = {
                    establishmentId: item.establishmentId ? item.establishmentId : null,
                    buildingId: item.buildingId ? item.buildingId : null,
                    equipmentId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.EQUIPMENT ? item._id : null,
                    energyIdId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.ID_ENERGIE ? item._id : null,
                };
            }

            result.push(contractPerimeter);

        }
        this.selectedPerimeters = result;
    }

    async initExistingPerimeter() {

        if (!this.prestationToPLan) {
            const sub = this.contractService.entity$.subscribe(async entity => {
                this.entity = entity;
                this.selectedItems = [];

                if (entity.perimeter) {
                    const filteredContractPerimeter = entity.perimeter?.filter((item, index, array) =>
                        array.findIndex(obj =>
                            obj.establishmentId === item.establishmentId
                        ) === index
                    );

                    console.log(entity.genericEngagement.dataPatrimony, "DATA PATRIMONY");

                    await this.handleDataPatrimony(entity, filteredContractPerimeter);
                }
            });
            this.subscription.add(sub);

        } else {

            if (this.prestationToPLan.isFullPerimeter) {

                const filteredContractPerimeter = this.entity.perimeter?.filter((item, index, array) =>
                    array.findIndex(obj =>
                        obj.establishmentId === item.establishmentId
                    ) === index
                );
                await this.handleDataPatrimony(this.entity, filteredContractPerimeter);

            } else {
                const sub = this.prestationService.entity$.subscribe(entity => {

                    this.selectedItems = [];
                    if (entity.perimeter) {

                        const filteredContractPerimeter = entity.perimeter?.filter((item, index, array) =>
                            array.findIndex(obj =>
                                obj.establishmentId === item.establishmentId
                            ) === index
                        );

                        this.handleDataPatrimony(this.entity, filteredContractPerimeter);

                    } else {
                        this.displayedItemsList = this.stockedData;
                    }

                });
                this.subscription.add(sub);

            }
        }

    }


    async handleDataPatrimony(entity: ContractModel, filteredContractPerimeter: any[]) {

        switch (entity.genericEngagement.dataPatrimony) {
            case GenericEngagementDataPatrimonyEnum.REFERENCE:
                await this.handleReferenceData(filteredContractPerimeter);
                break;
            case GenericEngagementDataPatrimonyEnum.EQUIPMENT:
                await this.handleEquipmentData(filteredContractPerimeter);
                break;
            case GenericEngagementDataPatrimonyEnum.BUILDING:
                await this.handleBuildingData(filteredContractPerimeter);
                break;
            case GenericEngagementDataPatrimonyEnum.ID_ENERGIE:
                await this.handleEnergyIdData(filteredContractPerimeter, entity);
                break;
        }
    }

    async handleReferenceData(filteredContractPerimeter: any[]) {
        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {
            const result = await this.establishmentService.findAllAsSelect(true, null, 'label', 'asc', {
                includeIds: filteredContractPerimeter.map(x => x.establishmentId),
                withArchive: true,
                isContractPerimeter: true
            }).toPromise();

            this.itemsList = result.data.map(item => {
                item.displayLabel = item.label;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.establishmentId === item._id)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });

            this.displayedItemsList = this.itemsList;

            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);
        } else {
            this.displayedItemsList = this.stockedData;
        }
    }

    async handleEquipmentData(filteredContractPerimeter: any[]) {
        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {
            const result = await this.equipmentService.findPerimetersByEstablishments(this.entity, filteredContractPerimeter.map(x => x.establishmentId)).toPromise();
            this.itemsList = this.equipmentService.transformEquipmentData(result);
            this.itemsList = this.itemsList.filter(item => this.entity.perimeter.some(perimeter => perimeter.equipmentId === item._id));
            this.itemsList = this.itemsList.map(item => {
                item.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel}`;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.equipmentId === item._id)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });

            this.displayedItemsList = [];

            this.itemsList.forEach(item => {
                if (!this.displayedItemsList.some(x => x.establishmentId === item.establishmentId && x._id === item._id)) {
                    this.displayedItemsList.push(item);
                } else {
                    const existingItem = this.displayedItemsList.find(x => x.establishmentId === item.establishmentId && x._id === item._id);
                    existingItem.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel} (${this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id).length} Bâtiments)`
                }
            });

            if (this.readMode || this.isCompressed) {
                this.displayedItemsList.forEach(item => {
                    const existingItems = this.displayedItemsList.filter(x => x._id === item._id);
                    if (existingItems.length > 1) {
                        item.displayLabel = `${item.genericLabel} / ${item.label} (${existingItems.length} Établissements) (${this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id).length} Bâtiments) `
                        this.displayedItemsList = this.displayedItemsList.filter(x => x._id !== item._id);
                        this.displayedItemsList.unshift(item);
                    }
                });
            }

            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);
        } else {
            this.displayedItemsList = this.stockedData;
        }
    }

    async handleBuildingData(filteredContractPerimeter: any[]) {
        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {
            const result = await this.buildingService.findPerimetersByEstablishments(this.entity, filteredContractPerimeter.map(x => x.establishmentId)).toPromise();
            this.itemsList = this.buildingService.transformBuildingData(result);
            this.itemsList = this.itemsList.filter(item => this.entity.perimeter.some(perimeter => perimeter.buildingId === item._id));
            this.itemsList = this.itemsList.map(item => {
                item.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel}`;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.buildingId === item._id && parentItem.establishmentId === item.establishmentId)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });
            this.displayedItemsList = this.itemsList;

            if (this.readMode || this.isCompressed) {
                this.displayedItemsList.forEach(item => {
                    const existingItems = this.displayedItemsList.filter(x => x.buildingId === item.buildingId);
                    if (existingItems.length > 1) {
                        item.displayLabel = `${item.genericLabel} / ${item.label}  (${existingItems.length} Établissements) `
                        this.displayedItemsList = this.displayedItemsList.filter(x => x.buildingId !== item.buildingId);
                        this.displayedItemsList.unshift(item);
                    }
                });
            }

            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);
        } else {
            this.displayedItemsList = this.stockedData;
        }
    }

    async handleEnergyIdData(filteredContractPerimeter: any[], entity: any) {

        console.log(filteredContractPerimeter, "FILTERED CONTRACT PERIMETER")
        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {
            const result = await this.energyIdService.findPerimetersByEstablishments(this.entity, filteredContractPerimeter.map(x => x.establishmentId)).toPromise();
            this.itemsList = this.energyIdService.transformEnergyIdData(result, entity.perimeter);
            console.log(this.itemsList, "ITEMS LIST")
            this.itemsList = this.itemsList.filter(item => this.entity.perimeter.some(perimeter => perimeter.energyIdId === item._id));
            this.itemsList = this.itemsList.map(item => {
                item.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel}`;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.energyIdId === item._id && parentItem.establishmentId === item.establishmentId)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });

            this.displayedItemsList = [];

            this.itemsList.forEach(item => {
                if (!this.displayedItemsList.some(x => x.establishmentId === item.establishmentId && x._id === item._id)) {
                    this.displayedItemsList.push(item);
                } else {
                    const existingItem = this.displayedItemsList.find(x => x.establishmentId === item.establishmentId && x._id === item._id);
                    existingItem.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel} (${this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id).length} Bâtiments)`
                }
            });

            if (this.readMode || this.isCompressed) {
                this.displayedItemsList.forEach(item => {
                    const existingItems = this.displayedItemsList.filter(x => x._id === item._id);
                    if (existingItems.length > 1) {
                        item.displayLabel = `${item.genericLabel} / ${item.label}  (${existingItems.length} Établissements) (${this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id).length} Bâtiments)`
                        this.displayedItemsList = this.displayedItemsList.filter(x => x._id !== item._id);
                        this.displayedItemsList.unshift(item);
                    }
                });
            }

            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);
        } else {
            this.displayedItemsList = this.stockedData;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
