import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

interface MyObject {
    _id: number;
    label: string;
    selected?: boolean;
}

@Component({
    selector: 'form-multi-select-search',
    templateUrl: './form-search-multiple.component.html',
    styleUrls: ['./form-search-multiple.component.scss']
})
export class FormMultiSelectSearchComponent implements OnInit, OnDestroy {
    @Input() formGroup!: FormGroup;
    @Input() controlName!: string;
    @Input() label = 'Recherche et sélectionnez...';
    @Input() appearance: 'outline' | 'fill' = 'outline';
    @Input() displayProperty = '';
    @Input() withoutPadding = false;
    @Input() placeholder = 'Rechercher...';
    @Input() displayFn!: (data: any) => string;
    @Input() filteredStates$: Observable<MyObject[]>;

    searchControl = new FormControl('');
    selectAllChecked = false;

    items: MyObject[] = [];
    filteredItems: MyObject[] = [];
    formGroupSubscription!: Subscription;

    ngOnInit() {
        this.filteredStates$.subscribe(items => {
            this.items = items.map(item => ({ ...item, selected: item.selected || false }));
            this.filteredItems = this.getSortedItems(this.items);
            this.updateFormControl();
        });

        this.searchControl.valueChanges.pipe(
            startWith(''),
            map(searchTerm => {
                this.filteredItems = this.filterItem(this.getSortedItems(this.items), searchTerm);
            })
        ).subscribe();

        this.formGroupSubscription = this.formGroup.valueChanges.subscribe(() => {
            this.resetSelectionsIfFormReset();
        });
    }

    ngOnDestroy() {
        if (this.formGroupSubscription) {
            this.formGroupSubscription.unsubscribe();
        }
    }

    filterItem(items: MyObject[], searchTerm: string): MyObject[] {
        if (!searchTerm) {
            return items;
        }

        const lowerSearchTerm = this.normalizeString(searchTerm);
        return items.filter(item => this.normalizeString(item[this.displayProperty]).includes(lowerSearchTerm));
    }

    private normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    getSelectedItemsText(): string {
        const selectedItems = this.getSortedItems(this.items).filter(item => item.selected).map(item => item[this.displayProperty]);
        return selectedItems.length > 0 ? selectedItems.join(', ') : null;
    }

    updateFormControl() {
        const selectedItems = this.items.filter(item => item.selected);
        const formControl = this.formGroup.get(this.controlName);
        if (formControl) {
            formControl.setValue(selectedItems, { emitEvent: false });
        }
    }

    updateSelectedItems(item: MyObject, checked: boolean) {
        item.selected = checked;
        this.items = this.items.map(i => i._id === item._id ? item : i);
        this.selectAllChecked = this.items.every(i => i.selected);
        this.updateFormControl();
    }

    toggleSelectAll(checked: boolean) {
        this.items.forEach(item => (item.selected = checked));
        this.filteredItems = this.getSortedItems(this.items);
        this.selectAllChecked = checked;
        this.updateFormControl();
    }

    clearSearch() {
        this.searchControl.setValue('');
        this.filteredItems = this.getSortedItems(this.items);
    }

    getSortedItems(items: any[]) {
        return items.sort((a, b) => a[this.displayProperty].localeCompare(b[this.displayProperty]));
    }

    resetSelectionsIfFormReset() {
        const control = this.formGroup.get(this.controlName);
        if (control && !control.value) {
            this.resetSelections();
        }
    }

    resetSelections() {
        this.items.forEach(item => item.selected = false);
        this.selectAllChecked = false;
        this.clearSearch();
        this.updateFormControl();
    }
}
