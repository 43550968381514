import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    GenericProductService
} from '../../../../../../pages/application/settings/generic-product/generic-product.service';
import {debounceTime, distinctUntilChanged, filter, startWith} from 'rxjs/operators';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import {AuthService} from '../../../../../services/auth.service';

export interface AllGenerics {
    type: string;
    items: any[];
}

@Component({
    selector: 'catalog-big-search-bar-base',
    templateUrl: './catalog-big-search-bar-base.component.html',
    styleUrls: ['./catalog-big-search-bar-base.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CatalogBigSearchBarBaseComponent implements OnInit {
    @Output() fillterEvent = new EventEmitter<any>();
    @Input() showFilterButton = true;
    @Input() showOnlyPerimeterCategories = false;
    @Input() showCatalogPermission = true;
    @Input() canSearchPermission = true;

    // popupFilter
    @Input() popupFilter = false;
    @Input() contentPopupTemplate: TemplateRef<any>;
    @Input() countPopupFilter = 0;
    @Input() popupWidth = 400;
    @Input() popupTitle = 'Filtres avancés';
    @Input() popupForm: FormGroup = new FormGroup({});


    @ViewChild('defaultPopupTemplate') defaultPopupTemplateRef: TemplateRef<any>;


    icSearch = icSearch;
    popupStatus: Array<string>;
    icClose = icClose;
    genericControl = new FormControl();
    search = new FormControl();
    searchSupplier = new FormControl();


    generics: AllGenerics[] = [
        {type: 'Prestation', items: []},
        {type: 'Article', items: []},
        // {type: 'Equipement', items: []},
    ];

    filteredGenericsSubject: BehaviorSubject<AllGenerics[]> = new BehaviorSubject([]);
    filteredGenerics: Observable<AllGenerics[]> = this.filteredGenericsSubject.asObservable();

    // matchips
    count = 0;


    constructor(
        private genericProductService: GenericProductService,
        private authService: AuthService,
    ) {
        this.genericControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged()).subscribe((value) => {
            this._filterGenerics(value);
        });
    }

    ngAfterViewInit() {
        if (!this.contentPopupTemplate) {
            console.log('this.defaultPopupTemplateRef', this.defaultPopupTemplateRef);
            this.contentPopupTemplate = this.defaultPopupTemplateRef;
        }
    }

    ngOnInit() {

        this.search.valueChanges
            .pipe(debounceTime(300))
            .subscribe(searchText => {
                    if (this.searchSupplier.value !== null) {
                        this.searchSupplier.setValue(null, {emitEvent: false});
                    }
                    this.emitSearchData();
                }
            );

        this.searchSupplier.valueChanges
            .pipe(debounceTime(300))
            .subscribe(searchText => {
                    if (this.search.value !== null) {
                        this.search.setValue(null, {emitEvent: false});
                    }
                    this.emitSearchData();
                }
            );

        this.authService.refreshCurrentUser().subscribe((newCurrentUser) => {
            this.genericProductService.findAllShopGenerics().subscribe((data) => {
                const userTechnicalCategoryIds: any = newCurrentUser.technicalCategories?.map(category => category._id);

                const filter = (item) => {
                    // Vérifier si le category._id du produit est présent dans les technicalCategories de l'utilisateur
                    if (userTechnicalCategoryIds.includes(item.category)) {
                        return true;
                    }

                    // Vérifier si un des parents du produit est présent dans les technicalCategories de l'utilisateur
                    if (item.category?.parents?.some(parent => userTechnicalCategoryIds.includes(parent.parent))) {
                        return true;
                    }

                    // Si aucune des conditions ci-dessus n'est vraie, le produit est exclu du nouveau tableau
                    return false;
                };

                this.generics.find(x => x.type === 'Prestation').items = data[0].prestations?.filter(this.showOnlyPerimeterCategories ? filter : () => true);
                this.generics.find(x => x.type === 'Article').items = data[0].articles?.filter(this.showOnlyPerimeterCategories ? filter : () => true);
                // this.generics.find(x => x.type === 'Equipement').items = data[0].equipments;
                this._filterGenerics('');
            });
        });

    }

    emitSearchData() {

        let search: any;

        if (this.searchSupplier.value !== null && this.searchSupplier.value !== '' && this.searchSupplier.value !== undefined) {
            search = this.searchSupplier.value;
            console.log('searchSupplier', search);
        } else {
            search = this.search.value;
            console.log('search', search);
        }

        const searchData = {
            genericProduct: this.genericControl.value?.type === 'product' ? this.genericControl.value._id : null,
            genericEquipment: this.genericControl.value?.type === 'equipment' ? this.genericControl.value._id : null,
            genericPrestation: this.genericControl.value?.type === 'prestation' ? this.genericControl.value._id : null,
            search,
            supplierSearch: this.searchSupplier.value,
            filters: this.popupForm.value,
        };
        const filteredSearchData = Object.fromEntries(
            Object.entries(searchData).filter(([key, value]) => value !== null)
        );
        this.fillterEvent.emit(filteredSearchData);
    }


    _filterGenerics(value: string): void {

        let filterValue: string;

        const removeAccents = (str: string) => {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        };
        if (value) {

            filterValue = removeAccents(value.trim()).toLowerCase().replace(/\s+/g, '');
        }

        this.generics.forEach(generic => {
            generic.items.sort((a, b) => a.label.localeCompare(b.label));
        });

        if (value) {
            const newFilterdGenerics = this.generics.map(generic => {
                const filteredItems = generic.items.filter(
                    item => item.label.replace(/\s+/g, '').toLowerCase().indexOf(filterValue) !== -1
                );

                return {...generic, items: filteredItems};
            }).filter(group => group.items.length > 0);
            this.filteredGenericsSubject.next(newFilterdGenerics);

        } else {
            this.filteredGenericsSubject.next(this.generics);

        }


    }

    clearTextFilter() {
        this.search.reset();
    }


    displayGeneric(item: any): string {
        return item && item.label ? item.label : '';
    }

    // Ajoutez cette méthode à la classe SearchBarComponent

    clearSelection(): void {
        this.genericControl.setValue(null);
        // this._filterGenerics('');
        // this.filteredGenericsSubject.next(this.generics);
        this.emitSearchData();

    }

    updateCount() {
        this.count = Object.values(this.popupForm.value).filter((value) => value === true).length;

    }
}
