<!--<div class="mt-6 flex flex-col md:flex-row md:items-start">-->
<!--    <div class="flex-auto">-->
        <div class="card mt-6">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>

                    <h2 class='title m-0'>{{ entityName }}
                        <icon-warning
                            class='ml-2'
                            [show]='displayPostePercentWarning'
                            [toolTip]="'Le pourcentage doit être égal à 100%'"
                        >
                        </icon-warning></h2>

                <button color='primary' mat-raised-button type='button' (click)='createActivity()' *hasPermission="[ENUM_PERMISSION.ADD_CP_ESTABLISHMENT_ACTIVITY]">AJOUTER</button>
            </div>

            <div @stagger
                 class='px-gutter py-4'
                 >

                <base-data-table
                    [showPaginator]='true'
                    [count]='count'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [feisColumns]='feisColumns'
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateItem($event)'
                    (onDeleteItem)='deleteItem($event)'
                ></base-data-table>
            </div>
        </div>
<!--    </div>-->
<!--</div>-->