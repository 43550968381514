<base-sidenav (onClose)="close()" (onClear)="clear()" (onValidate)="validate()"
              [standardForms]="standardForms"></base-sidenav>


<ng-template #standardForms>
    <form [formGroup]="getFormGroup('standardForm')">

        <base-form-input
            label="Identifiant technique"
            appearance="outline"
            controlName="technicalIdentifier"
        >
        </base-form-input>
        <ng-container *ngIf="(supplierContractRef$ | async) as supplierContractRefs">
            <base-form-input
                *ngIf="supplierContractRefs.length > 1"
                (autocompleteChange)='autocompleteContractSupplierContractRef($event)'
                [items]="supplierContractRefs"
                appearance="outline"
                label="Référence du contrat fournisseur"
                displayProperty='supplierContractReference'
                controlName='supplierContractRef'
                type='search-multiple'
                withoutPadding="true"
            ></base-form-input>
        </ng-container>


        <ng-container *ngIf="establishments$ | async as establishments">
            <base-form-input
                *ngIf="establishments.length > 1"
                (autocompleteChange)='autocompleteEstablishment($event)'
                [items]="establishments"
                appearance="outline"
                controlName='establishments'
                displayProperty='label'
                label='Etablissements'
                type='search-multiple'
                withoutPadding="true"
            ></base-form-input>
        </ng-container>

        <base-form-input
            [items]="genericEngagementFormat"
            label="Type d'engagement"
            type="select"
            appearance="outline"
            displayProperty="label"
            controlName='genericEngagementFormat'
            withoutPadding="true"
        >
        </base-form-input>

        <ng-container *ngIf="(suppliers$ | async) as suppliers">
            <base-form-input
                *ngIf="suppliers.length > 1"
                (autocompleteChange)='autocompleteSupplier($event)'
                [items]="suppliers"
                appearance="outline"
                controlName='suppliers'
                displayProperty='label'
                label='Fournisseurs'
                type='search-multiple'
                withoutPadding="true"
            ></base-form-input>
        </ng-container>

        <ng-container *ngIf="(businessReferentUsers$ | async) as businessReferentUsers">
            <base-form-input
                *ngIf="businessReferentUsers.length > 1"
                (autocompleteChange)='autocompleteBusinessReferentUsers($event)'
                [items]="businessReferentUsers"
                label="Référents métiers"
                type="search-multiple"
                appearance="outline"
                displayProperty="fullName"
                controlName='businessReferentUsers'
                withoutPadding="true"
            >
            </base-form-input>
        </ng-container>

        <base-form-input
            [items]="contractStatusOptions"
            label="Statut du contrat"
            displayProperty="label"
            appearance="outline"
            withoutPadding="true"
            type="search-multiple"
            controlName="status">
        </base-form-input>
    </form>

    <ng-container [ngTemplateOutlet]="applicationPeriod"></ng-container>
</ng-template>

<ng-template #applicationPeriod>
    <form [formGroup]="getFormGroup('applicationPeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période d'application (Date de début)"
            typeSelection='date'
            selection="between"
            withoutPadding="true"
        ></base-form-input>
    </form>
    <form [formGroup]="getFormGroup('endApplicationPeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période d'application (Date de fin)"
            typeSelection='date'
            selection="between"
            withoutPadding="true"
        ></base-form-input>
    </form>
    <form [formGroup]="getFormGroup('noticePeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période de préavis"
            typeSelection='date'
            selection="lessThen"
            withoutPadding="true"
        ></base-form-input>
    </form>
    <form [formGroup]="getFormGroup('revisionPeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période de révision"
            typeSelection='date'
            selection="lessThen"
            withoutPadding="true"
        ></base-form-input>
    </form>
</ng-template>
