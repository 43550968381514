import { Injectable } from '@angular/core';
import { BaseService } from '../../../../core/base/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CommentActionModel,
  CommentDataModel,
  CommentTicketEnum,
  TicketBasketModel,
  TicketCommentModel,
  TicketModel,
} from '../../../../core/models/ticket.model';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ObjectApiInterface } from '../../../../core/base/interfaces/object-api.interface';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../../../core/services/auth.service';
import { ContactTypeEnum } from '../../../../core/enums/contact-type.enum';
import { CorrectiveInterventionModel } from '../../../../core/models/corrective-intervention.model';
import { SupplierModel } from '../../../../core/models/supplier.model';
import { BasketRowStatusEnum } from '../../../../core/enums/basketRowStatusEnum';
import { BasketPrestation, BasketProduct } from '../../../../core/models/basket.model';
import { TicketStateEnum } from '../../../../core/enums/ticket-state.enum';
import { QuoteModel } from '../../../../core/models/quote-model';
import { SnackbarService } from '../../../../core/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class TicketService extends BaseService<TicketModel> {

  public readonly = true;
  comment_data: CommentDataModel[] = [
    { name: 'Créateur du ticket', type: CommentTicketEnum.TICKET_CREATOR },
    // {name: 'Référent équipement', type: CommentTicketEnum.REFERENT_TECHNIC},
    // {name: 'Référent qualité', type: CommentTicketEnum.REFERENT_QUALITY},
    { name: 'Référent métier', type: CommentTicketEnum.REFERENT_METIER },
    { name: 'Approvisionneur', type: CommentTicketEnum.PURCHASER },
  ];
  comment_default_action: CommentActionModel[] = [
    { type: CommentTicketEnum.TICKET_CREATOR, visible: true, notification: true },
    // {type: CommentTicketEnum.REFERENT_TECHNIC, visible: true, notification: true},
    { type: CommentTicketEnum.PURCHASER, visible: true, notification: true },
    { type: CommentTicketEnum.REFERENT_METIER, visible: true, notification: true },
  ];
  protected _comment = new BehaviorSubject<TicketCommentModel[]>([]);
  readonly comment$ = this._comment.asObservable();
  protected _orderManagement = new BehaviorSubject<any>([]);
  readonly orderManagement$ = this._orderManagement.asObservable();
  protected _quote = new BehaviorSubject<QuoteModel[]>([]);
  readonly quote$ = this._quote.asObservable();
  protected _basket = new BehaviorSubject<TicketBasketModel>({ products: [], prestations: [] });
  readonly basket$ = this._basket.asObservable();

  constructor(
    public http: HttpClient,
    private authService: AuthService,
    private snackbarService: SnackbarService,
  ) {
    super(http, 'ticket');

    this.readonly = this.isTechnicalReferent();

    // this.authService.currentUser.subscribe(user => {
    //     if (user.contactType?.some(x => x.label === ContactTypeEnum.REFERENT_TECHNIC)) {
    //         this.readonly = false;
    //     }
    // })
  }



  get basket(): Promise<TicketBasketModel> {
    return lastValueFrom(this.basket$.pipe(take(1)));
  }

  findAllProductsAndPrestations(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: any): Observable<ObjectApiInterface<(BasketProduct & {
    ticketCreatedAt?: Date
  })[] | (BasketPrestation & { ticketCreatedAt?: Date })[]>> {
    return this.http.get<ObjectApiInterface<(BasketProduct & { ticketCreatedAt?: Date })[] | (BasketPrestation & {
      ticketCreatedAt?: Date
    })[]>>(`${this.baseUrlApi}/list-products-prestations`, {
      params: new HttpParams()
        .set('page', page?.toString() ?? '1')
        .set('perPage', perPage?.toString() ?? '30')
        .set('sort', `${sortField}@${sortOrder}`)
        .set('search', search ?? '')
        .set('filters', JSON.stringify(filters) ?? ''),
    });
  }

  createOutOfCatalogTreatment(_id: string, data: any): Observable<ObjectApiInterface<TicketModel>> {
    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/create-out-of-catalog-treatment/${_id}`, data).pipe(map(res => {
      this._entity.next(res.data);
      return res;
    }));

  }

  validateOutOfCatalogTreatment(_id: string, data: { value: boolean, comment: string } = {
    value: true,
    comment: null,
  }): Observable<ObjectApiInterface<TicketModel>> {

    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/validate-out-of-catalog-treatment/${_id}`, data).pipe(map(res => {
      this._entity.next(res.data);
      return res;
    }));
  }

  deleteOutOfCatalogTreatment(_id: string): Observable<ObjectApiInterface<TicketModel>> {
    return this.http.delete<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/delete-out-of-catalog-treatment/${_id}`).pipe(map(res => {
      this._entity.next(res.data);
      return res;
    }));
  }

  finalize(_id: string, data: any): Observable<ObjectApiInterface<TicketModel>> {

    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/finalize/${_id}`, data).pipe(map(res => {
      this._entity.next(res.data);
      this._basket.next(res.data?.basket);
      this._comment.next(res.data?.comments);
      return res;
    }));
  }



  giveUp(_id: string): Observable<ObjectApiInterface<TicketModel>> {

    return this.http.get<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/give-up/${_id}`).pipe(map(res => {
      this._entity.next(res.data);
      return res;
    }));
  }

  resendBasketToOrderManagement(_id: string): Observable<ObjectApiInterface<TicketModel>> {
    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/resend-basket-to-order-management/${_id}`, {}).pipe(map(res => {
      this._entity.next(res.data);
      return res;
    }));
  }


  proposeOutOfCatalogTreatment(_id: string, data: any): Observable<ObjectApiInterface<TicketModel>> {

    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/propose-out-of-catalog-treatment/${_id}`, data).pipe(map(res => {
      this._entity.next(res.data);
      this._basket.next(res.data?.basket);
      this._comment.next(res.data?.comments);
      return res;
    }));
  }

  refuseOutOfCatalogTreatment(_id: string, data: any): Observable<ObjectApiInterface<TicketModel>> {

    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/refuse-out-of-catalog-treatment/${_id}`, data).pipe(map(res => {
      this._entity.next(res.data);
      this._comment.next(res.data?.comments);
      this._basket.next(res.data?.basket);
      return res;
    }));
  }

  disableBasketReference(_id: string, data: any): Observable<ObjectApiInterface<TicketModel>> {

    data = {
      itemId: data.item._id,
      comment: data.comment,
      action: data.isReplace ? 'replace' : 'disable',
    };

    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/${_id}/disable-basket-reference/`, data).pipe(map(res => {
      res.data.isUpdatingLines = true;
      this._entity.next(res.data);
      this._basket.next(res.data?.basket);
      this._comment.next(res.data?.comments);
      return res;
    }));
  }


  displayPrice(): boolean {
    return this.isReferentMetier() || this.isAppro();
  }


  isApproAndHaveFamilly(ticket: TicketModel) {
    // optional chaining utilisé pour éviter une erreur récente. PRED-1149.
    if (ticket?.categories) {
      return this.authService.currentUserValue().technicalCategories?.map(category => category?._id)?.filter(c => ticket.categories.includes(c)).length > 0 && this.authService.currentUserValue().contactType?.some(x => x.label === ContactTypeEnum.APPROVISIONNEUR);
    }
    return false;
  }

  isReferentMetierAndHaveFamilly(ticket: TicketModel) {
    if (ticket?.categories) {
      return this.authService.currentUserValue().technicalCategories?.map(category => category?._id)?.filter(c => ticket.categories.includes(c)).length > 0 && this.authService.currentUserValue().contactType?.some(x => x.label === ContactTypeEnum.REFERENT_METIER);
    }
    return false;
  }

  isAppro() {
    return this.authService.currentUserValue().contactType?.some(x => x.label === ContactTypeEnum.APPROVISIONNEUR);
  }

  isCreator() {
    return this.authService.currentUserValue()._id === this._entity.value?.createdBy?._id;

  }

  isTechnicalReferent(): boolean {
    return this.authService.currentUserValue().contactType?.some(x => x.label === ContactTypeEnum.REFERENT_TECHNIC);
  }

  isReferentMetier(): boolean {
    return this.authService.currentUserValue().contactType?.some(x => x.label === ContactTypeEnum.REFERENT_METIER);
  }


// Fonction pour vérifier si l'entité a un produit ou une prestation avec le statut 'WAITING_CUSTOMER'
  hasWaitingCustomerReference(entity: any): boolean {
    return (
      entity.basket.products.some(item => item.status === BasketRowStatusEnum.WAITING_CUSTOMER) ||
      entity.basket.prestations.some(item => item.status === BasketRowStatusEnum.WAITING_CUSTOMER) ||
      entity.basket.products.some(item => item.status === BasketRowStatusEnum.HC_RETURN) ||
      entity.basket.prestations.some(item => item.status === BasketRowStatusEnum.HC_RETURN) ||
      entity.basket.products.some(item => item.status === BasketRowStatusEnum.DRAFT) ||
      entity.basket.prestations.some(item => item.status === BasketRowStatusEnum.DRAFT)
    );
  }


  hasExpiredReference(entity: any): boolean {

    return (entity.basket.products.some(item => item.isExpired) || entity.basket.prestations.some(item => item.isExpired));
  }

  hasExpiredAndNotValidatedReference(entity: any): boolean {

    const expiredProducts = entity.basket.products.filter(item => item.isExpired);
    const expiredPrestations = entity.basket.prestations.filter(item => item.isExpired);
    // VALIDATED = 'VALIDATED',
    //   ORDERED = 'ORDERED',
    //   RECEIVED = 'RECEIVED',
    //   CLOSED = 'CLOSED',
    //   CLOSED_BY_APPROVISIONER = 'CLOSED_BY_APPROVISIONER',
    // return true if there is a reference with status different of  'VALIDATED', 'ORDERED', 'RECEIVED', 'CLOSED' or 'CLOSED_BY_APPROVISIONER' and is expired

    console.log(expiredProducts, expiredPrestations);
    return (
      expiredProducts.some(item => item.status !== BasketRowStatusEnum.VALIDATED && item.status !== BasketRowStatusEnum.ORDERED && item.status !== BasketRowStatusEnum.RECEIVED && item.status !== BasketRowStatusEnum.CLOSED && item.status !== BasketRowStatusEnum.CLOSED_BY_APPROVISIONER && item.status !== BasketRowStatusEnum.REPLACED) ||
      expiredPrestations.some(item => item.status !== BasketRowStatusEnum.VALIDATED && item.status !== BasketRowStatusEnum.ORDERED && item.status !== BasketRowStatusEnum.RECEIVED && item.status !== BasketRowStatusEnum.CLOSED && item.status !== BasketRowStatusEnum.CLOSED_BY_APPROVISIONER && item.status !== BasketRowStatusEnum.REPLACED)
    );



  }


  hasWaitingReferentReference(entity: any): boolean {
    // first filters row to keep only rows with allowed = true
    const filteredProducts = entity.basket.products.filter(item => item.isEditable);
    const filteredPrestations = entity.basket.prestations.filter(item => item.isEditable);

    // const filteredProducts = entity.basket.products;
    // const filteredPrestations = entity.basket.prestations;

    return (
      filteredProducts.some(item => item.status === BasketRowStatusEnum.WAITING_REFERENT) ||
      filteredPrestations.some(item => item.status === BasketRowStatusEnum.WAITING_REFERENT)
    );
  }

  addToBasket(id: string, type: 'products' | 'prestations', reference: any, basketEntity?: boolean, addFromFavoriteBasket?: string, entityLabel?: string) {
    const data = {
      _id: reference._id,
      type,
      quantity: reference.quantity,
      quantityBasketType: basketEntity ? reference.quantity : null,
    };

    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/${id}/add-to-basket`, data).pipe(map((res: any) => {
      this._entity.next(res.data);
      this._basket.next(res.data.basket);
      if (addFromFavoriteBasket === 'addOnlyFavorite' && res.statusCode === 201) {
        this.snackbarService.success(`La référence ${entityLabel} a bien été ajoutée`);
      }
      if (addFromFavoriteBasket === 'addEntireFavorite' && res.statusCode === 201) {
        this.snackbarService.success(`La totalité du favori ${entityLabel} a bien été ajoutée`);
      }
    }));
  }

  async removeItemFromBasket(item: any) {
    const entity = await this.entity;
    this.removeFromBasket(entity._id, item).subscribe();
  }

  removeFromBasket(id: string, article: any) {
    const isProduct = this.isProduct(article);
    const basketValue = this._basket.value;

    if (isProduct) {
      basketValue.products = basketValue.products.filter(x => x._id !== article._id);
    } else {
      basketValue.prestations = basketValue.prestations.filter(x => x._id !== article._id);
    }

    const url = isProduct ? 'deleteBasketProduct' : 'deleteBasketPrestation';

    return this.http.delete<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/${id}/${url}/${article._id}`).pipe(map(res => {
      this._entity.next(res.data);
      this._basket.next(res.data.basket);
    }));
  }

  updateBasketQuantity(id: string, article: any, direction: 'minus' | 'plus' | 'change', basketEntity?: boolean) {
    const basketValue = this._basket.value;
    const isProduct = this.isProduct(article);

    let finded: any;

    if (isProduct) {
      finded = basketValue.products.find(x => x._id === article._id);
    } else {
      finded = basketValue.prestations.find(x => x._id === article._id);
    }

    if (finded) {
      if (direction === 'minus' && finded.quantity === 1) {
        finded.quantity = 1;
        finded.quantityBasketType = 1;
      }
      if (direction === 'change') {
        finded.quantity = article.quantity;
      } else {
        direction === 'minus' ? finded.quantity-- : finded.quantity++;
        if (direction === 'minus') {
            finded.quantityBasketType === 0 ? finded.quantityBasketType = 0 : finded.quantityBasketType--;
        }
        if (direction === 'plus' && finded.quantityBasketType !== null) {
            finded.quantityBasketType++;
        }
      }
    }

    finded.stock = article.stock;

    const url = isProduct ? 'updateBasketProduct' : 'updateBasketPrestation';

    return this.http.put<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/${id}/${url}`, finded).pipe(map(res => {
      if (isProduct) {
        basketValue.prestations.map(prestation => {
          const foundPrestation = res.data?.basket?.prestations.find(x => x._id === prestation._id);
          return foundPrestation ? foundPrestation : prestation;
        });
      } else {
        basketValue.products.map(product => {
          const foundPrestation = res.data?.basket?.products.find(x => x._id === product._id);
          return foundPrestation ? foundPrestation : product;
        });
      }
      this._basket.next(basketValue);
    }));


  }

  findById(id: string): Observable<ObjectApiInterface<TicketModel>> {
    return this.http.get<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/get/${id}`).pipe(map(x => {
      this._basket.next(x.data.basket);
      this._comment.next(x.data.comments);
      this._quote.next(x.data.quotes);
      this._entity.next(x.data);
      return x;
    }));
  }

  clearComment() {
    this._comment.next([]);
  }


  addIntervention(intervention: CorrectiveInterventionModel) {
    const value = this._entity.value;
    value.correctiveInterventions.push(intervention);
    this._entity.next(value);
  }

  checkInterventionConfirmation(data: TicketModel) {
    if (!data.genericEquipment || !data.description) {
      return false;
    }

    if (!data.establishment && !data.locationDescription) {
      return false;
    }

    if (!data.establishment && !data.equipment && !data.locationDescription) {
      return false;
    }
    return true;
  }

  openCommentUpdateForm(_id: string) {
    let values = this._comment.value;
    values = values.map(x => {
      if (x._id !== _id) {
        x.isUpdateFormOpen = false;
      } else {
        x.isUpdateFormOpen = !x.isUpdateFormOpen;
      }
      return x;
    });
    this._comment.next(values);
    //   this._customerComment.next(values);
  }

  findAllComment(_id: string): Observable<ObjectApiInterface<TicketCommentModel[]>> {
    return this.http.get<ObjectApiInterface<TicketCommentModel[]>>(`${this.baseUrlApi}/comments/${_id}`).pipe(map((res: ObjectApiInterface<TicketCommentModel[]>) => {
      res.data = res.data.map(x => {
        x.isUpdateFormOpen = false;
        return x;
      });
      this._comment.next(res.data);
      return res;
    }));
  }



  addComment(_id: string, entity: TicketCommentModel): Observable<ObjectApiInterface<TicketCommentModel>> {
    return this.http.post<ObjectApiInterface<TicketCommentModel>>(`${this.baseUrlApi}/add-comment/${_id}`, entity).pipe(map(res => {
      const value: TicketCommentModel[] = this._comment.value;

      console.log(res);


      // @ts-ignore
      //    res.data.isUpdateFormOpen = false;

      value.unshift(res.data);
      this._comment.next(value);
      return res;
    }));
  }


  changeRowStatus(_id: string, item: any, status: BasketRowStatusEnum): Observable<ObjectApiInterface<TicketModel>> {
    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/change-row-status/${status}/${_id}`, item).pipe(map(res => {
      this._entity.next(res.data);
      this._basket.next(res.data?.basket);
      this._comment.next(res.data?.comments);
      return res;
    }));
  }

  sendTicketToSupplier(_id: string, supplier: SupplierModel): Observable<ObjectApiInterface<TicketModel>> {
    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/send-supplier/${_id}`, { supplier }).pipe(map(res => {
      this._entity.next(res.data);
      return res;
    }));
  }

  updateComment(_id: string, entity: TicketCommentModel): Observable<ObjectApiInterface<TicketCommentModel>> {
    return this.http.post<ObjectApiInterface<TicketCommentModel>>(`${this.baseUrlApi}/update-comment/${_id}`, entity).pipe(map(res => {
      const values: TicketCommentModel[] = this._comment.value;
      res.data.isUpdateFormOpen = false;
      const findedComm = values.findIndex(x => x._id === _id);
      if (findedComm !== -1) {
        values[findedComm] = res.data;
      }
      this._comment.next(values);
      return res;
    }));
  }


  deleteComment(id: string, _idComment: string): Observable<ObjectApiInterface<TicketCommentModel>> {
    return this.http.delete<ObjectApiInterface<TicketCommentModel>>(`${this.baseUrlApi}/${id}/delete-comment/${_idComment}`).pipe(map(x => {
      let values = this._comment.value;
      values = values.filter(x => x._id !== _idComment);
      this._comment.next(values);
      return x;
    }));
  }


  createTicketQuote(id: string, data): Observable<ObjectApiInterface<QuoteModel[]>> {
    return this.http.post<ObjectApiInterface<QuoteModel[]>>(`${this.baseUrlApi}/${id}/create-ticket-quote`, data).pipe(map((res: ObjectApiInterface<QuoteModel[]>) => {
      let value = this._quote.value;
      value = [...value, ...res.data];
      this._quote.next(value);
      return res;
    }));
  }


  updateBasketLineEmergency(_id: string, type: 'product' | 'prestation', article: any) {
    const value = this._basket.value;
    let finded: any;
    if (type === 'product') {
      finded = value.products.find(x => x._id === article._id);
    } else {
      finded = value.prestations.find(x => x._id === article._id);
    }

    if (finded) {
      finded.isUrgent = article.isUrgent;
      const url = type === 'product' ? 'updateEmergencyProduct' : 'updateEmergencyPrestation';
      this.http.put(`${this.baseUrlApi}/${_id}/${url}`, finded).subscribe();
    }


    this._basket.next(value);
  }


  findAllServicesToOrder(establishmentsIds = []) {
    return this.http.post<ObjectApiInterface<TicketModel[]>>(`${this.baseUrlApi}/find-all-services-to-order`, { establishmentsIds }).pipe(map(res => {
      this._orderManagement.next(res.data);
      console.log(res.data);
      return res;
    }));
  }

  getEquipmentDetailsByTicketId(ticketId: string): Observable<ObjectApiInterface<any>> {
    return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/equipment-details/${ticketId}`).pipe(map(res => {
      return res;
    }));
  }

  duplicate(_id, state?: TicketStateEnum) {
    const options = state ? {
      params: new HttpParams().set('filters', JSON.stringify({ filters: state })),
    } : {};

    return this.http.post<ObjectApiInterface<TicketModel>>(`${this.baseUrlApi}/${_id}/duplicate`, {}, options);
  }

  changeSelectedTicketsStatus(selectedIds: any, service: any, status: any) {

    return this.http.post<ObjectApiInterface<TicketModel[]>>(`${this.baseUrlApi}/hold-selected-tickets`, {
      ticketIds: selectedIds,
      service,
      status,
    }).pipe(map(res => {
      this._orderManagement.next(res.data);
      return res;
    }));
  }


  hasBasketReference(): boolean {


    return this._basket?.value?.products?.filter(x => !x.isOutOfCatalog)?.length > 0 || this._basket?.value?.prestations?.filter(x => !x.isOutOfCatalog)?.length > 0;
  }

  generateExcludedReference(ticket) {
    // get an array on products and prestations ids merged in one array. products and prestations are added to the array only if they have value isAllowed = false
    const excludedReferences = ticket.basket.products.filter(x => !x.isEditable).map(x => x._id).concat(ticket.basket.prestations.filter(x => !x.isEditable).map(x => x._id));

    console.log(excludedReferences);

    return excludedReferences;
  }

  isProduct(article: any): boolean {
    const basketValue = this._basket.value;
    const foundProduct = basketValue.products.find(x => x._id === article._id);

    return !!foundProduct;
  }

}
