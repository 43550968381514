<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
        <!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
        <!--            <div>-->
        <!--                <h1 class='title mt-0 mb-1'>{{ entityName }}</h1>-->
        <!--                <vex-breadcrumbs [crumbs]="[entityName]"></vex-breadcrumbs>-->
        <!--            </div>-->
        <!--        </div>-->
    </vex-page-layout-header>

    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>
            <base-data-table-header
                    [label]='entityName'
                    [loading]='loading'
                    (onSearchChange)='onSearchChange($event)'
                    (onClearTextFilter)='clearTextFilter()'
                    (onCreate)='createItem()'
                    [contentPopupTemplate]='contentPopupTemplate'
                    (validatePopupFilter)='validatePopupFilter()'
                    (clearPopupFilter)='clearPopupFilter()'
                    [countPopupFilter]='countPopupFilter'
                    [popupFilter]='true'
                    headerPopupTitle='Filtres avancés'
            ></base-data-table-header>

            <base-data-table
                    [count]='count'
                    [pageSize]='pageSize'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    [dataSource]='dataSource'
                    [displayedColumns]='displayedColumns'
                    [feisColumns]='feisColumns'
                    [routerLinkDetailRow]='routerLinkDetailRow'
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateItem($event)'
                    (onDeleteItem)='deleteItem($event)'
            ></base-data-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm' class='pb-4'>
        <base-form-input
                [items$]="establishments$"
                controlName='establishment'
                label='Établissement'
                type='autocomplete'
                displayProperty='label'
                (autocompleteChange)='establishmentsAutocompleteChange($event)'
        >
        </base-form-input>
        <base-form-input
                [items$]="buildings$"
                controlName='building'
                label='Bâtiment'
                type='autocomplete'
                displayProperty='label'
                (autocompleteChange)='buildingsAutocompleteChange($event)'
        >
        </base-form-input>
        <base-form-input
                [items$]="genericEquipments$"
                controlName='genericEquipment'
                label='Equipement'
                type='autocomplete'
                displayProperty='label'
                (autocompleteChange)='equipmentsAutocompleteChange($event)'
        >
        </base-form-input>
        <base-form-input
                [items]="contractVersion"
                controlName='contractVersion'
                label='Version'
                type='number'
        >
        </base-form-input>
    </form>
</ng-template>

