<ng-container *ngIf="(contractService.entity$ | async) as contract">
    <div *ngIf="contract?.creationStep === 1; else contractValidatedTemplate" class="flex flex-col w-full gap-6 mt-6"
         [formGroup]="form">
        <div class="flex flex-row-reverse gap-2">
            <button [disabled]="form.invalid || (files.length === 0)" color='primary' mat-raised-button type='button'
                    (click)="updateStep(2)">ENREGISTRER
            </button>
        </div>
        <div class="flex flex-row w-full gap-6">
            <div class="card w-full">
                <div class='flex flex-row px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Engagement générique</h2>
                </div>
                <div class="px-gutter py-4 flex flex-col gap-4">
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type='autocomplete'
                            [items$]='genericEngagement$'
                            (autocompleteChange)='genericEngagementAutoCompleteChange($event)'
                            displayProperty='label'
                            label='Engagement générique'
                            controlName='genericEngagement'>
                    </base-form-input>
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            label='Objet du contrat'
                            controlName='object'>
                    </base-form-input>
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            label='Contact engagement'
                            type='autocomplete'
                            [items$]='engagementContacts$'
                            (autocompleteChange)='engagementContactsAutoCompleteChange($event)'
                            displayProperty="fullName"
                            controlName='engagementContact'>
                    </base-form-input>
                </div>
            </div>
            <div class="card w-full">
                <div class='flex flex-row w-full gap-4 px-gutter py-4 border-b'>
                    <h2 class='title m-0 w-full'>Fournisseur</h2>
                </div>
                <div class="px-gutter py-4 flex flex-col gap-4">
                    <div class="flex flex-row w-full gap-4 items-center">
                        <base-form-input
                                @fadeInRight
                                appearance="outline"
                                class="w-full"
                                label='Fournisseur'
                                type='autocomplete'
                                [items$]='suppliers$'
                                (autocompleteChange)='supplierAutocompleteChange($event)'
                                displayProperty='label'
                                controlName='supplier'>
                        </base-form-input>
                        <button @fadeInRight mat-mini-fab color="primary"
                                class="ml-3 mb-6" (click)="addSupplier()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            label='Référence contrat fournisseurs'
                            controlName='supplierContractReference'>
                    </base-form-input>
                    <div class="flex flex-row w-full gap-4 items-center">
                        <base-form-input
                                @fadeInRight
                                appearance="outline"
                                class="w-full"
                                type='autocomplete'
                                label='Contact fournisseur'
                                [items$]='supplierContacts$'
                                [disabled]="!form.controls?.supplier?.value"
                                (autocompleteChange)='supplierContactsAutocompleteChange($event)'
                                displayProperty="fullName"
                                controlName='supplierContact'>
                        </base-form-input>
                        <button @fadeInRight [disabled]="!form.controls?.supplier?.value" mat-mini-fab color="primary"
                                class="ml-3 mb-6" (click)="openSupplierContactModal()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card w-full">
            <div class='flex flex-row px-gutter py-4 border-b'>
                <h2 class='title m-0'>Validité</h2>
            </div>
            <div class="px-gutter py-4 flex flex-col w-full gap-4">
                <div class="flex flex-row w-full gap-4">
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type="select"
                            [items]="contractValidityEnum"
                            label='Validité du contrat'
                            controlName='validity'>
                    </base-form-input>
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type="date"
                            label='Date de début de validité'
                            controlName='startValidityDate'>
                    </base-form-input>
                </div>
                <div class="flex flex-row w-full gap-4">
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type="date"
                            label='Date de fin de validité'
                            [minDate]="form.controls?.startValidityDate?.value"
                            controlName='endValidityDate'>
                    </base-form-input>
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type="number"
                            label='Délai de prévenance (jours)'
                            [defaultValue]="this.form.controls.noticePeriod.value"
                            [minNumber]="0"
                            controlName='noticePeriod'>
                    </base-form-input>
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type="date"
                            label='Date de signature'
                            [dateFilter]="signatureDateFilter"
                            controlName='signatureDate'
                            (dateChanged)="onDateChanged($event)"
                            [isFromContract]="true">
                    </base-form-input>
                </div>

                <div class="flex flex-row w-full gap-4"
                     *ngIf="this.contract.validity === ContractValidityEnum.DENIABLE">
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type='number'
                            label='Préavis minimum (en mois)'
                            controlName='minNotice'>
                    </base-form-input>
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type='number'
                            label='Reconduction validité (en mois)'
                            controlName='renewalValidity'>
                    </base-form-input>

                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type='number'
                            label='Nombre de reconductions validé'
                            controlName='renewalNb'
                    >
                    </base-form-input>

                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type='date'
                            label='Fin de reconduction ferme'
                            controlName='renewalDate'
                    >
                    </base-form-input>

                </div>
            </div>
        </div>
        <div class="flex flex-row w-full gap-6">
            <div class="card w-full">
                <div class='flex flex-row px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Conditions de règlement</h2>
                </div>
                <div class="px-gutter py-4 flex flex-col gap-4">
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            label='Conditions de règlement'
                            type='autocomplete'
                            [items$]='genericPaymentCondition$'
                            (autocompleteChange)='genericPaymentConditionAutoCompleteChange($event)'
                            displayProperty='displayProperty'
                            controlName='paymentCondition'>
                    </base-form-input>
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            label='Nombre de jours'
                            type="number"
                            [minNumber]="minNbDay"
                            [maxNumber]="maxNbDay"
                            controlName='nbDay'
                    >
                    </base-form-input>
                </div>
            </div>
            <div id="engagement-details-resume-signature-card" class="card w-full">
                <div class='flex flex-row px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Signature</h2>
                </div>
                <div @fadeInRight class="px-gutter py-4 flex flex-col gap-4">
                    <p class="italic text-warn">Le contrat doit impérativement être signé</p>
                    <div @dragFormZone>
                        <vex-drop-zone (onFileDropped)="fileDropped($event)" [files]="files"></vex-drop-zone>
                    </div>
                </div>
            </div>
        </div>
        <vex-contract-details-amendment-ged>
        </vex-contract-details-amendment-ged>
    </div>
    <ng-template #contractValidatedTemplate>
        <div class="flex flex-col w-full gap-6 mt-6">
            <div class="flex flex-row-reverse gap-2">
                <button *ngIf="!(contract?.status===ContractStatusEnum.VALIDATED) && !(contract?.status===ContractStatusEnum.EXPIRED)"
                        color='primary' mat-raised-button type='button' (click)="switchToUpdateMode()">MODIFIER
                </button>
            </div>
            <div class="flex flex-row w-full gap-6">
                <div class="card w-full">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Engagement générique</h2>
                    </div>
                    <div class="px-gutter py-4 flex flex-col gap-4">
                        <details-line-base
                                hint='Engagement générique'
                                [label]='contract?.genericEngagement?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Objet du contrat'
                                [label]='contract?.object'
                        >
                        </details-line-base>
                        <details-line-base
                                hint='Contact engagement'
                                [label]='contract?.engagementContact?.fullName'
                        >
                        </details-line-base>
                    </div>
                </div>
                <div class="card w-full">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Fournisseur</h2>
                    </div>
                    <div class="px-gutter py-4 flex flex-col gap-4">
                        <details-line-base
                                hint='Fournisseur'
                                [label]='contract?.supplier?.label'
                        >
                        </details-line-base>

                        <details-line-base
                                hint="Référence contrat fournisseurs"
                                [label]='contract?.supplierContractReference'
                        >
                        </details-line-base>

                        <details-line-base
                                hint='Contact fournisseur'
                                [label]="contract?.supplierContact?.firstName + ' ' + contract?.supplierContact?.lastName"
                        >
                        </details-line-base>
                        <details-line-base
                                *ngIf='contract?.referenceInterne' [label]='contract?.referenceInterne'
                                hint='Identifiant GED'
                        >
                        </details-line-base>
                    </div>
                </div>
            </div>
            <div class="card w-full">
                <div class='flex flex-row justify-between px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Validité</h2>
                    <button *ngIf="contract?.status === contractStatusEnum.VALIDATED" color='primary' mat-raised-button
                            type='button' (click)="addDenunciationDate()">AJOUTER UNE DENONCIATION
                    </button>
                </div>
                <div class="px-gutter py-4 flex flex-row justify-between w-full gap-4">
                    <details-line-base
                            hint='Validité du contrat'
                            [label]='contract?.validity'
                    >
                    </details-line-base>
                    <details-line-base
                            hint='Date de début de validité'
                            [label]='contract?.startValidityDate'
                            type='date'
                    >
                    </details-line-base>
                    <details-line-base
                            hint='Délai de prévenance (jours)'
                            [label]='contract?.noticePeriod'
                            type='text'
                    >
                    </details-line-base>
                    <details-line-base
                            hint='Date de dénonciation'
                            [label]='contract?.denunciationDate'
                            type='date'
                    >
                    </details-line-base>
                    <details-line-base
                            hint='Date de fin de validité'
                            [label]='contract?.endValidityDate'
                            type='date'
                            [ngClass]="{ 'end-date-disabled': contract.denunciationDate }"
                    >
                    </details-line-base>
                    <details-line-base
                            hint='Date de signature'
                            [label]='contract?.signatureDate'
                            type='date'
                    >
                    </details-line-base>
                    <details-line-base
                            hint='Préavis minimum (en mois)'
                            [label]='contract?.minNotice'
                    >
                    </details-line-base>
                    <details-line-base
                            hint='Reconduction validité  (en mois)'
                            [label]='contract?.renewalValidity'
                    >
                    </details-line-base>

                    <details-line-base
                            hint='Fin de reconduction ferme'
                            [label]='contract?.renewalDate'
                            type='date'
                    >
                    </details-line-base>
                </div>
            </div>
            <div class="flex flex-row w-full gap-6">
                <div class="card w-full">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Conditions de règlement</h2>
                    </div>
                    <div class="px-gutter py-4 flex flex-col gap-4">
                        <details-line-base
                                hint='Conditions de règlement'
                                [label]='contract?.paymentCondition?.displayProperty'
                        >
                        </details-line-base>
<!--                        <details-line-base-->
<!--                                hint='Délai de livraison standard'-->
<!--                                [label]="this.standardTheoreticalDeliveryDate">-->
<!--                        </details-line-base>-->
<!--                        <details-line-base-->
<!--                                hint='Délai de livraison urgent'-->
<!--                                [label]="this.urgentTheoreticalDeliveryDate">-->
<!--                        </details-line-base>-->
                    </div>
                </div>
                <div class="card w-full">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Signature</h2>
                    </div>
                    <div @fadeInRight class="px-gutter py-4 flex flex-col text-center w-full gap-4 cursor-pointer"
                         (click)="downloadDocument(files[0].DCWDOCID)">
                        <div class="container ">
                            <mat-icon color="primary" class="icon">insert_drive_file</mat-icon>
                            <span *ngIf="!loadingFile" class="center">{{ files[0]?.type }}</span>
                            <div *ngIf="loadingFile" class="center-spinner w-full flex flex-row justify-center">
                                <mat-spinner diameter="30"></mat-spinner>
                            </div>
                        </div>
                        <p *ngIf="contract.status === ContractStatusEnum.VALIDATED"
                           class="text-primary">{{ contract.referenceInterne }}</p>
                    </div>
                </div>
            </div>
            <vex-contract-details-amendment-ged
                    [canAddDoc]="false"
            >
            </vex-contract-details-amendment-ged>
        </div>
    </ng-template>
</ng-container>
