import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, takeUntil, tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {UserModel} from '../models/user.model';
import {InterceptorSkipHeader} from '../helpers/http-ignore.helper';
import {CustomerGroupModel} from '../models/customer-group.model';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {AccountInfo, EventMessage, EventType} from '@azure/msal-browser';
import {UserProfileModel} from '../models/user-profile.model';
import {ContactTypeEnum} from '../enums/contact-type.enum';
import {RequirementModel} from '../models/requirement.model';
import {ConfigService} from '../../../@vex/services/config.service';
import moment from 'moment';
import {ENUM_PERMISSIONS} from '../enums/permission.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public currentUser: Observable<UserProfileModel>;
    public currentUserCore: Observable<UserModel>;
    public currentRoute: Observable<string>;
    loggedIn = false;
    private currentUserSubject: BehaviorSubject<UserProfileModel>;
    private currentUserCoreSubject: BehaviorSubject<UserModel>;
    private currentRouteSubject: BehaviorSubject<string>;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private http: HttpClient,
        private router: Router,
        private activedtedRoute: ActivatedRoute,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private configService: ConfigService,
        private route: ActivatedRoute,
    ) {
        this.currentUserSubject = new BehaviorSubject<UserProfileModel>(null);
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentUserCoreSubject = new BehaviorSubject<UserModel>(null);
        this.currentUserCore = this.currentUserCoreSubject.asObservable();
        this.currentRouteSubject = new BehaviorSubject<string>(window.location.href.replace(window.location.origin, ''));
        this.currentRoute = this.currentRouteSubject.asObservable();
    }

    public currentUser$() {
        return this.currentUserSubject;
    }

    public currentUserCore$() {
        return this.currentUserCoreSubject;
    }

    public currentUserValue() {
        return this.currentUserSubject.value;
    }

    public currentUserValue$(): Observable<UserProfileModel> {
        if (this.currentUserSubject.value == null) {
            return this.http.get<any>(`/api/auth/me`, {headers: InterceptorSkipHeader})
                .pipe(map(response => {
                    // console.info("Pushing user  = ", response.data);
                    this.currentUserSubject.next(response.data);
                    return response.data;
                }));
        } else {
            return this.currentUser;
        }
    }

    // refresh current user
    refreshCurrentUser() {
        return this.http.get<any>(`/api/auth/me`, {headers: InterceptorSkipHeader})
            .pipe(map(response => {
                // console.info("Pushing user  = ", response.data);
                this.currentUserSubject.next(response.data);
                return response.data;
            }));
    }

    public currentUserCoreValue(): Observable<UserModel> {
        if (this.currentUserCoreSubject.value == null) {
            return this.http.get<any>(`/api/auth/meCore`, {headers: InterceptorSkipHeader})
                .pipe(map(response => {
                    // console.info("Pushing user  = ", response.data);
                    this.currentUserCoreSubject.next(response.data);
                    return response.data;
                }));
        } else {
            return this.currentUserCore;
        }
    }

    public getCurrentUserPermissions(): ENUM_PERMISSIONS[] {

        const permissionsSet = new Set<string>();
        this.currentUserValue()?.roles?.forEach(role => {
            role._id.permissions.forEach(permission => {
                permissionsSet.add(permission.permission);
            });
        });
        const permissions = Array.from(permissionsSet);

        return permissions as ENUM_PERMISSIONS[];

    }

    public getCurrentUserPermissions$() {
        return this.currentUserValue$().pipe(map((user: UserProfileModel) => {
            const permission = new Set();
            const currentDate = moment().toDate();
            const userPermission = user.roles?.filter(role => {
                return ((moment(role.start).startOf('day').toDate() < currentDate) &&
                        (moment(role.end).endOf('day').toDate() > currentDate)) ||
                    (!role.start && !role.end);
            }).map(x => x._id.permissions.map((permission) => permission.permission));
            userPermission?.forEach(p => {
                p?.forEach(x => {
                    permission.add(x);
                });
            });
            return [...permission];
        }));
    }

    updateGetCredential(username: string) {
        const currentUser = this.currentUserSubject.value;
        currentUser.gedCredential.username = username;
        this.currentUserSubject.next(currentUser);
    }

    switchTenant(tenant: CustomerGroupModel) {
        this.http.post<any>(`/api/auth/switchTenant`, {
            tenantId: tenant._id,
        })
            .pipe(map(response => {
                window.location.reload();
            }))
            .subscribe();
    }

    login(email: string, password: string, rememberMe?: boolean) {

        return this.http
            .post<any>(`/api/auth/login`, {email, password, rememberMe})
            .pipe(
                map(response => {
                    return this.currentUserValue$().subscribe();
                })
            );
    }

    logout() {
        // remove user from local storage to log user out
        this.msalService.logout({
            postLogoutRedirectUri: this.configService.env.apiUrl.replace('/api', ''),
        });
        // window.location.reload();
        // return this.http
        //     .get<any>(`/api/auth/logout`, { headers : InterceptorSkipHeader }) //${environment.apiUrl}
        //     .pipe(
        //         map(() => {
        //             this.currentUserSubject.next(null);
        //         })
        //     )
        //     .subscribe(response => {
        //         console.info(response);
        //         //this.router.navigate(['/login']);
        //         // window.location.replace('/login');
        //     });
    }

    updateLoggedInStatus() {
        // handleRedirect
        // startup
        // none
        return new Promise((res, rej) => {
            // this.msalBroadcastService.inProgress$
            //     .pipe(
            //         filter((status: InteractionStatus) => {
            //             console.log('status', status);
            //             return status === InteractionStatus.None;
            //         }),
            //         takeUntil(this._destroying$),
            //         tap(() => {
            //             this.setLoggedIn();
            //             this.checkAndSetActiveAccount();
            //         }),
            //     ).subscribe((data: any) => {
            //     console.log('currentRoute', this.currentRoute);
            //         if (this.router.url !== '/') {
            //             // this.currentRoute = this.router.url;
            //         }
            //         console.log('in progress', this.router.url);
            //         // this.router.navigate(['/']).then();
            //         res(true);
            //     }, () => {
            //         if (this.getActiveAccount().username) {
            //             this.msalService.logout();
            //             rej();
            //         }
            //     }
            // );


            this.msalBroadcastService.msalSubject$
                .pipe(
                    filter((msg: EventMessage) => {
                        return msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS;
                    }),
                    takeUntil(this._destroying$),
                    tap(() => {
                        this.setLoggedIn();
                        this.checkAndSetActiveAccount();
                    })
                ).subscribe((msg: EventMessage) => {
                    if (this.router.url !== '/') {
                        this.currentRouteSubject.next(this.router.url);
                    }
                    if (msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                        this.currentRoute.subscribe(route => {
                            if (route.split('#')[0] !== '/redirect') { this.router.navigate([route]).then(); } else {
                                this.router.navigate(['/']).then();
                            }
                        });
                    }

                    res(true);
                }, () => {
                    if (this.getActiveAccount().username) {
                        this.msalService.logout();
                        rej();
                    }
                }
            );
        });
    }

    getActiveAccount(): AccountInfo | null {
        return this.msalService.instance.getActiveAccount();
    }

    getUserCore(): Observable<UserModel> {
        return this.http.get<any>('/api/auth/meCore');
    }

    switchMode() {
        return this.http.get('/api/userProfile/switchMode');
    }

    isRequirementProvider(requirement: RequirementModel): boolean {
        // Si il n'y a pas d'approvisionneur, pour garder le fonctionnement selon la logique predik on retourne true
        if (!requirement.provider) {
            return false;
        }
        return requirement?.provider?._id === this.currentUserValue()._id;
    }

    checkCurrentContactType(contactType: ContactTypeEnum) {
        const findType = this.currentUserValue()?.contactType?.findIndex(type => type.label === contactType);
        return findType !== -1;
    }

    checkCurrentIsPredik() {
        return this.currentUserValue()?.isPredik;
    }

    isAccountant() {
        return this.checkCurrentContactType(ContactTypeEnum.ACCOUNTANT);
    }

    isAppro() {
        return this.checkCurrentContactType(ContactTypeEnum.APPROVISIONNEUR);
    }

    isPredik() {
        return this.checkCurrentIsPredik();
    }

    isReferentQuality() {
        return this.checkCurrentContactType(ContactTypeEnum.REFERENT_QUALITY);
    }

    isReferentMetier() {
        return this.checkCurrentContactType(ContactTypeEnum.REFERENT_METIER);
    }

    hasOnlyThisRole(role: ContactTypeEnum) {
        return this.currentUserValue()?.contactType?.length === 1 && this.checkCurrentContactType(role);
    }

    hasPermission(...permissions: ENUM_PERMISSIONS[]): boolean {
        const currentUserPermissions = this.getCurrentUserPermissions();
        return permissions.some(permission => currentUserPermissions.includes(permission));
    }

    private setLoggedIn() {
        this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
    }

    private checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            const accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }


    // for the ssoSilent method ?
    private getLoginHint() {
        const accounts = this.msalService.instance.getAllAccounts();
        return accounts.length > 0 ? accounts[0].username : '';
    }

    private redirectIfNeeded() {
        const redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
        if (this.loggedIn && redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
        }
    }
}
