<div class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
        <div class="card">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Détails</h2>

                <button color='primary' mat-raised-button type='button'
                        (click)='updateItem()' *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING_INFO]">MODIFIER
                </button>
                <!--                <button color='accent' mat-raised-button type='button'>MODIFIER</button>-->
                <!--                <button color='warn' mat-raised-button type='button' (click)='updateItem()'><mat-icon><mat-progress-spinner diameter="20" mode="indeterminate" strokeWidth="11%">-->
                <!--                </mat-progress-spinner></mat-icon></button>-->
            </div>
            <div *ngIf='(buildingService.entity$ | async)?._id; else loading' class="px-gutter py-4" gdColumns="1fr 1fr"
                 gdColumns.xs="1fr" gdGap="16px">

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (buildingService.entity$ | async).genericBuilding?.label }}</p>
                        <p class="m-0 caption text-hint">Bâtiment générique</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <!--                    <div @scaleIn-->
                    <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                    <!--                        <ic-icon [icon]="icPhone" size="20px"></ic-icon>-->
                    <!--                    </div>-->

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (buildingService.entity$ | async).technicalIdentifier }}</p>
                        <p class="m-0 caption text-hint">Identifiant Technique</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <!--                    <div @scaleIn-->
                    <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                    <!--                        <ic-icon [icon]="icWork" size="20px"></ic-icon>-->
                    <!--                    </div>-->

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (buildingService.entity$ | async).label | capitalize }}</p>
                        <p class="m-0 caption text-hint">Libellé</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <!--                    <div @scaleIn-->
                    <!--                         class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">-->
                    <!--                        <ic-icon [icon]="icMail" size="20px"></ic-icon>-->
                    <!--                    </div>-->

                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (buildingService.entity$ | async)?.surface }} m²</p>
                        <p class="m-0 caption text-hint">Surface Totale (m²)</p>
                    </div>
                </div>

                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                    <div @fadeInRight>
                        <p class="m-0 body-1">{{ (buildingService.entity$ | async)?.type === 'main' ? 'Principal' : 'Autre' }}</p>
                        <p class="m-0 caption text-hint">Type de bâtiment</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-6">
            <div class="px-6 py-4 border-b flex flex-row justify-between">
                <h2 class="title m-0">Etablissement
                    <icon-warning
                            [show]='displayEstablishmentSurfaceWarning'
                            [toolTip]="'La surface des établissements saisie ne correspond pas à la surface totale saisie du bâtiment. (Surface totale : ' + (buildingService.entity$ | async)?.surface + ' m²)'"
                            class='ml-2'
                    >
                    </icon-warning>
                    <icon-warning
                            [show]='displayFavoriteWarning && !isMainEstablishmentPresent'
                            [toolTip]="'Il y a plus de deux établissements. Assignez un établissement comme contact achats'"
                            class='ml-2'
                    >
                    </icon-warning>
                </h2>

                <button *hasPermission="[ENUM_PERMISSION.ADD_BUILDING_INFO_ESTABLISHMENT]"
                        (click)='addItemEstablishment()'
                        class='ml-4'
                        color='primary'
                        fxFlex='none'
                        mat-mini-fab
                        matTooltip='Ajouter un établissement'
                        type='button'>
                    <mat-icon [icIcon]='icAdd'></mat-icon>
                </button>
            </div>

            <!-- TODO PERMISSION [deletePermission]="[ENUM_PERMISSION.DELETE_HM_BUILDING_INFO_ESTABLISHMENT]"-->
            <!--                    (onFavoriteItem)='favoriteItemEstablishment($event)'-->

            <base-data-table
                    (onDeleteItem)='deleteItemEstablishment($event)'
                    (onUpdateItem)='updateItemEstablishment($event)'
                    (onSelectedRow)='favoriteItemEstablishment($event)'
                    [dataSource]='dataSourceEstablishment'
                    [displayedColumns]='displayedColumnsEstablishment'
                    [feisColumns]='feisColumnsEstablishment'
                    [routerLinkDetailRow]='routerLinkEstablishment'
                    [showPaginator]='false'
            ></base-data-table>
        </div>


        <div class="card mt-6">
            <div class="px-gutter py-4 border-b" fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class="title m-0">Activités
                    <icon-warning
                            [show]='displayActivityWarning'
                            [toolTip]="'La surface des activités saisies ne correspond pas à la surface totale saisie du bâtiment. (Surface total : ' + (buildingService.entity$ | async)?.surface + ' m²)'"
                            class='ml-2'
                    >
                    </icon-warning>
                </h2>

                <button *hasPermission="[ENUM_PERMISSION.ADD_BUILDING_INFO_ACTIVITY]"
                        (click)='createActivity()'
                        class='ml-4'
                        color='primary'
                        fxFlex='none'
                        mat-mini-fab
                        matTooltip='Ajouter une activité'
                        type='button'>
                    <mat-icon [icIcon]='icAdd'></mat-icon>
                </button>
            </div>

            <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_HM_BUILDING_INFO_ACTIVITY]"
                    [deletePermission]="[ENUM_PERMISSION.DELETE_HM_BUILDING_INFO_ACTIVITY]"-->
            <base-data-table
                    (onDeleteItem)='deleteItemActivity($event)'
                    (onUpdateItem)='updateItemActivity($event)'
                    [dataSource]='dataSourceActivity'
                    [displayedColumns]='displayedColumnsActivity'
                    [feisColumns]='feisColumnsActivity'
                    [showPaginator]='false'
            ></base-data-table>
        </div>
        <div class="card mt-6">
            <div class='px-gutter py-4 border-b' fxLayout='row' fxLayoutAlign='space-between center'>
                <h2 class='title m-0'>Adresses
                    <icon-warning
                            [show]='displayEstablishmentWarning'
                            [toolTip]="'Veuillez ajouter un établissement pour pouvoir ajouter une adresse.'"
                            class='ml-2'
                    >
                    </icon-warning>
                </h2>
                <div>
                    <button *hasPermission="[ENUM_PERMISSION.ADD_BUILDING_INFO_ADDRESS]"
                            (click)='createAddress()'
                            class='ml-4'
                            color='primary'
                            fxFlex='none'
                            mat-mini-fab
                            matTooltip='Ajouter une adresse'
                            type='button'>
                        <mat-icon [icIcon]='icAdd'></mat-icon>
                    </button>
                </div>
            </div>

            <div @stagger
                 class='px-gutter py-4'
                 gdGap='24px'>
                <base-data-table
                        (onDeleteItem)='deleteAddress($event)'
                        (onPageEvent)='pageEventAddress($event)'
                        (onSortEvent)='sortEventAddress($event)'
                        (onUpdateItem)='updateAddress($event)'
                        [count]='countAddress'
                        [dataSource]='dataSourceAddress'
                        [displayedColumns]='displayedColumnsAddress'
                        [feisColumns]='feisColumnsAddress'
                        [pageSize]='pageSizeAddress'
                        [sortField]='sortFieldAddress'
                        [sortOrder]='sortOrderAddress'
                ></base-data-table>
            </div>
        </div>
    </div>

    <div class="flex-none max-w-unset md:max-w-xs w-full md:ltr:ml-6 md:rtl:mr-6 mt-6 md:mt-0">
        <div class="card">
            <div class="px-6 py-4 border-b flex flex-row justify-between">
                <h2 class="title m-0">Étages</h2>

                <button *hasPermission="[ENUM_PERMISSION.ADD_BUILDING_INFO_FLOOR]"
                        (click)='createStage()'
                        class='ml-4'
                        color='primary'
                        fxFlex='none'
                        mat-mini-fab
                        matTooltip='Ajouter un étage'
                        type='button'>
                    <mat-icon [icIcon]='icAdd'></mat-icon>
                </button>
            </div>

            <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_HM_BUILDING_INFO_STAGE]"
                    [deletePermission]="[ENUM_PERMISSION.DELETE_HM_BUILDING_INFO_STAGE]"-->
            <base-data-table
                    (onDeleteItem)='deleteItemStage($event)'
                    (onUpdateItem)='updateItemStage($event)'
                    [dataSource]='dataSourceStage'
                    [displayedColumns]='displayedColumnsStage'
                    [feisColumns]='feisColumnsStage'
                    [showPaginator]='false'
            ></base-data-table>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="px-gutter py-4" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="16px">
        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton [circle]='true' class='w-14 mr-2' heigthTailwind='h-12'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton [circle]='true' class='w-14 mr-2' heigthTailwind='h-12'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>

        <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
            <vex-skeleton [circle]='true' class='w-14 mr-2' heigthTailwind='h-12'></vex-skeleton>
            <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
        </div>
    </div>
</ng-template>
