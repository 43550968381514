import {Component, OnDestroy, OnInit} from '@angular/core';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import icAdd from '@iconify/icons-ic/twotone-add';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {MatDialog} from '@angular/material/dialog';
import {SupplierService} from '../../supplier.service';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {
    SupplierClientNumberCreateUpdateDeleteComponent
} from "../../modals/supplier-client-number-create-update-delete/supplier-client-number-create-update-delete.component";
import {SupplierModel} from "../../../../../../core/models/supplier.model";
import {FeiColumn} from "../../../../../../core/base/interfaces/fei-column.interface";
import {MatTableDataSource} from "@angular/material/table";
import {DataTableBase} from "../../../../../../core/base/components/data-table-base/data-table-base";
import {AuthService} from "../../../../../../core/services/auth.service";

@Component({
    selector: 'vex-supplier-details-contact',
    templateUrl: './supplier-details-client-number.component.html',
    styleUrls: ['./supplier-details-client-number.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class SupplierDetailsClientNumberComponent extends DataTableBase<SupplierModel> implements OnInit, OnDestroy {
    ENUM_PERMISSION = ENUM_PERMISSIONS;

    loadingData = false;
    entityName = 'N° Client';
    icAdd = icAdd;
    loading = false;

    supplierId: string;
    dataSourceCode: any;
    displayedColumnsCode: any;
    feisColumns: Array<FeiColumn> = [
        {label: 'Etablissement', column: 'establishment', propertyDisplay: 'establishment.label'},
        {label: 'N° Client', column: 'clientNumber', propertyDisplay: null},
        {label: '', column: 'actions', canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE), canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_SUPPLIER_CUSTOMER_CODE)},
    ];

    constructor(private dialog: MatDialog, public supplierService: SupplierService, private authService: AuthService) {
        super();
    }

    ngOnInit(): void {
        this.initData();
    }


    initData() {
        if (!this.isArchive) {
            this.feisColumns = this.feisColumns.map(x => {
                if (x.column === 'actions') {
                    x.canArchive = false;
                }
                return x;
            });
        } else {
            this.feisColumns = this.feisColumns.map(x => {
                if (x.column === 'actions') {
                    x.canArchive = true;
                }
                return x;
            });
        }


        let isArchive = this.filters.isArchive;

        const sub = this.supplierService.entity$.subscribe((entity) => {
            if (entity?._id) {
                this.loadingData = true;
                this.filters = {
                    isArchive
                };

                this.supplierService.findAllClientNumbers(entity?._id, this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search, this.filters).subscribe(res => {
                    this.dataSourceCode = new MatTableDataSource<any>(res.data);
                    console.log(res.data)
                    this.count = res.totalData;
                    this.loadingData = false;
                });
            }
        });
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onSearchChange(event) {
        this.pageIndex = 1;
        this.search = event;
        this.initData();
    }

    onShowArchived(event) {
        this.isArchive = event;
        this.filters = {...this.filters, isArchive: event};
        this.initData();
    }

    async addClientNumber() {
        const entity = await this.supplierService.entity;
        const sub = this.dialog.open(SupplierClientNumberCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                entity,
                mode: CrudModeEnum.Create,
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
        this.subscription.add(sub);
    }

    async updateClientNumber(element: any) {
        const entity = await this.supplierService.entity;
        const sub = this.dialog.open(SupplierClientNumberCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: element,
                mode: CrudModeEnum.Update,
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
        this.subscription.add(sub);
    }

    async deleteClientNumber(element: any) {
        const entity = await this.supplierService.entity;
        const sub = this.dialog.open(SupplierClientNumberCreateUpdateDeleteComponent, {
            data: {
                id: entity?._id,
                defaults: element,
                name: element,
                mode: CrudModeEnum.Delete,
            }
        }).afterClosed().subscribe((supplier: any) => {
            if (supplier) {
                this.initData();
            }
        });
        this.subscription.add(sub);
    }

    async archiveItem(element: any): Promise<void> {
        const entity = await this.supplierService.entity;
        const sub = this.dialog.open(SupplierClientNumberCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: element,
                mode: CrudModeEnum.Archive
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
        this.subscription.add(sub);
    }

    importItem() {
        const sub = this.dialog.open(SupplierClientNumberCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
        this.subscription.add(sub);
    }
}
