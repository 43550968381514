import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ContractModel, ContractPerimeterModel} from '../../../../../../core/models/contract.model';
import {GenericEngagementDataPatrimonyEnum} from "../../../../../../core/enums/generic-engagement-data-patrimony.enum";
import {EstablishmentService} from "../../../establishment/establishment.service";
import {EquipmentService} from "../../../equipment/equipment.service";
import {EnergyIdService} from "../../../energy-id/energy-id.service";
import {BuildingService} from "../../../building/building.service";
import {ContractService} from "../../contract.service";
import {Subscription} from "rxjs";
import {PrestationModel} from "../../../../../../core/models/prestation.model";
import {PrestationService} from "../../../prestation/prestation.service";

@Component({
    selector: 'vex-contract-details-user-perimeter',
    templateUrl: './contract-details-user-perimeter.component.html',
    styleUrls: ['./contract-details-user-perimeter.component.scss']
})
export class ContractDetailsUserPerimeterComponent implements OnInit, OnDestroy {

    @Input() itemChecked: any = null;

    @Input() entity?: ContractModel;
    @Input() parentItems: any[] = [];
    @Input() isDefaultSelected: boolean;
    @Input() isCheckboxDisabled = false;
    @Input() readMode = false;
    @Input() isCompressed = false;
    @Input() prestationToPLan?: PrestationModel;
    @Output() selectedItemsChange = new EventEmitter<ContractPerimeterModel[]>();
    itemsList: any[] = [];
    displayedItemsList: any[] = [];
    selectedPerimeters: ContractPerimeterModel[];
    selectedItems: any[] = [];

    subscription = new Subscription();

    //use in case you want to stock the perimeter and not over call the back
    @Input() noReloadData: boolean = false;
    @Input() stockedData = []; // data stocked in the parent
    @Output() stockData = new EventEmitter<any>(); // to send the stocked data to parent


    constructor(
        private establishmentService: EstablishmentService,
        private energyIdService: EnergyIdService,
        private equipmentService: EquipmentService,
        private buildingService: BuildingService,
        private contractService: ContractService,
        private prestationService: PrestationService
    ) {

    }

    async ngOnInit(): Promise<void> {
        await this.initData();
    }

    async initData() {
        await this.initExistingPerimeter();
        console.log(this.displayedItemsList, 'voici la liste');
    }

    toggleSelectionItem(checked: boolean, item) {

        let groupedItems = [];

        if (!this.prestationToPLan) {

            if (checked) {
                switch (this.entity.genericEngagement.dataPatrimony) {

                    case GenericEngagementDataPatrimonyEnum.EQUIPMENT && GenericEngagementDataPatrimonyEnum.ID_ENERGIE:
                        // if not already in the selectedItems list

                        if (this.isCompressed) {
                            groupedItems = this.itemsList.filter(x => x._id === item._id);
                            groupedItems.forEach(groupedItem => {
                                this.selectedItems.push(groupedItem);
                            });

                        } else {
                            groupedItems = this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id);

                            groupedItems.forEach(groupedItem => {
                                this.selectedItems.push(groupedItem);

                            });
                        }


                        break;

                    case GenericEngagementDataPatrimonyEnum.BUILDING:
                        if (this.isCompressed) {
                            groupedItems = this.itemsList.filter(x => x.buildingId === item.buildingId);
                            groupedItems.forEach(groupedItem => {
                                this.selectedItems.push(groupedItem);
                            });
                        } else {
                            this.selectedItems.push(item);
                        }


                    default:

                        this.selectedItems.push(item);
                        break;
                }

            } else {
                // remove from selectedItems item that have the same _id and establishmentId
                this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem._id !== item._id || selectedItem.establishmentId !== item.establishmentId);
            }


        } else {

            if (checked) {
                this.selectedItems.push(item);
            } else {
                this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem._id !== item._id || selectedItem.establishmentId !== item.establishmentId);
            }

        }

        //update perimeters list
        this.convertToContractPerimeterModel(this.selectedItems);

        this.selectedItemsChange.emit(this.selectedPerimeters);
    }

    convertToContractPerimeterModel(selectedItems): ContractPerimeterModel[] {
        const result: ContractPerimeterModel[] = [];
        let contractPerimeter: ContractPerimeterModel;
        for (const item of selectedItems) {
            if (!item) return null;

            contractPerimeter = {
                establishmentId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.REFERENCE ? item._id : item.establishmentId,
                buildingId: item.buildingId ? item.buildingId : null,
                equipmentId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.EQUIPMENT ? item._id : null,
                energyIdId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.ID_ENERGIE ? item._id : null,
            };

            if (!(this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.REFERENCE)) {
                contractPerimeter = {
                    establishmentId: item.establishmentId ? item.establishmentId : null,
                    buildingId: item.buildingId ? item.buildingId : null,
                    equipmentId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.EQUIPMENT ? item._id : null,
                    energyIdId: this.entity.genericEngagement.dataPatrimony === GenericEngagementDataPatrimonyEnum.ID_ENERGIE ? item._id : null,
                };
            }
            result.push(contractPerimeter);
        }
        this.selectedPerimeters = result;

        if (this.prestationToPLan) {
            this.selectedItems.forEach(item => {
                this.selectedPerimeters.map(selectedPerimeter => {
                    selectedPerimeter.labelPerimeter = item.label;
                });
            });
        }

    }

    async initExistingPerimeter() {

        if (!this.prestationToPLan) {
            const sub = this.contractService.entity$.subscribe(async entity => {
                this.entity = entity;
                this.selectedItems = [];

                if (entity.perimeter) {
                    const filteredContractPerimeter = entity.perimeter?.filter((item, index, array) =>
                        array.findIndex(obj =>
                            obj.establishmentId === item.establishmentId
                        ) === index
                    );

                    await this.handleDataPatrimony(entity, filteredContractPerimeter);
                }
            });
            this.subscription.add(sub);

        } else {
            const sub = this.prestationService.entity$.subscribe(async entity => {
                this.selectedItems = [];
                if (entity.perimeter) {
                    const filteredContractPerimeter = entity.perimeter?.filter((item, index, array) =>
                        array.findIndex(obj =>
                            obj.establishmentId === item.establishmentId
                        ) === index
                    );

                    await this.handleDataPatrimony(this.entity, filteredContractPerimeter, entity);

                } else {
                    this.displayedItemsList = this.stockedData;
                }

            });
            this.subscription.add(sub);

        }

    }


    async handleDataPatrimony(entity: ContractModel, filteredContractPerimeter: any[], entityPrestation?: PrestationModel) {

        switch (entity.genericEngagement.dataPatrimony) {
            case GenericEngagementDataPatrimonyEnum.REFERENCE:
                await this.handleReferenceData(filteredContractPerimeter, entityPrestation);
                break;
            case GenericEngagementDataPatrimonyEnum.EQUIPMENT:
                await this.handleEquipmentData(filteredContractPerimeter, entityPrestation);
                break;
            case GenericEngagementDataPatrimonyEnum.BUILDING:
                await this.handleBuildingData(filteredContractPerimeter, entityPrestation);
                break;
            case GenericEngagementDataPatrimonyEnum.ID_ENERGIE:
                await this.handleEnergyIdData(filteredContractPerimeter, entity, entityPrestation);
                break;
        }

        this.checkIfOneItemInDisplayedItemsList();
    }

    async handleReferenceData(filteredContractPerimeter: any[], entityPrestation?: PrestationModel) {
        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {
            const result = await this.establishmentService.findAllAsSelect(true, null, 'label', 'asc', {
                includeIds: filteredContractPerimeter.map(x => x.establishmentId),
                isContractPerimeter: true
            }).toPromise();

            this.itemsList = result.data.map(item => {
                item.displayLabel = item.label;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.establishmentId === item._id)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });

            this.displayedItemsList = this.itemsList;
            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.REFERENCE);

            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);
        } else {
            this.displayedItemsList = this.stockedData;
            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.REFERENCE);
        }
     //   this.checkIfOneItemInDisplayedItemsList();
    }

    async handleEquipmentData(filteredContractPerimeter: any[], entityPrestation?: PrestationModel) {
        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {

            let result;
            if (this.prestationToPLan) {
                result = await this.equipmentService.findEquipmentsByPrestation(this.prestationToPLan._id).toPromise();
                this.itemsList = this.equipmentService.transformEquipmentData(result);
            } else {
                result = await this.equipmentService.findPerimetersByEstablishments(this.entity, filteredContractPerimeter.map(x => x.establishmentId)).toPromise();
                this.itemsList = this.equipmentService.transformEquipmentData(result);
            }

            this.itemsList = this.itemsList.filter(item => this.entity.perimeter.some(perimeter => perimeter.equipmentId === item._id));
            this.itemsList = this.itemsList.map(item => {
                item.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel}`;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.equipmentId === item._id)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });

            this.displayedItemsList = [];

            this.itemsList.forEach(item => {
                if (!this.displayedItemsList.some(x => x.establishmentId === item.establishmentId && x._id === item._id)) {
                    this.displayedItemsList.push(item);
                } else {
                    const existingItem = this.displayedItemsList.find(x => x.establishmentId === item.establishmentId && x._id === item._id);
                    existingItem.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel} (${this.itemsList.filter(x => x.buildingId === item.buildingId && x._id === item._id).length} Bâtiments)`;
                }
            });

            if (this.readMode || this.isCompressed) {
                this.displayedItemsList.forEach(item => {
                    const existingItems = this.displayedItemsList.filter(x => x._id === item._id);
                    if (existingItems.length > 1) {
                        item.displayLabel = `${item.genericLabel} / ${item.label} (${existingItems.length} Établissements) (${this.itemsList.filter(x => x.buildingId === item.buildingId && x._id === item._id).length} Bâtiments) `;
                        this.displayedItemsList = this.displayedItemsList.filter(x => x._id !== item._id);
                        this.displayedItemsList.unshift(item);
                    }
                });
            }

            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.EQUIPMENT);

            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);

        } else {
            this.displayedItemsList = this.stockedData;
            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.EQUIPMENT);
        }
    }

    async handleBuildingData(filteredContractPerimeter: any[], entityPrestation) {
        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {

            let result;
            if (this.prestationToPLan) {
                result = await this.buildingService.findBuildingsByPrestation(this.prestationToPLan._id).toPromise();
            } else {
                result = await this.buildingService.findPerimetersByEstablishments(this.entity, filteredContractPerimeter.map(x => x.establishmentId)).toPromise();
            }

            this.itemsList = this.buildingService.transformBuildingData(result);
            this.itemsList = this.itemsList.filter(item => this.entity.perimeter.some(perimeter => perimeter.buildingId === item._id));

            // Mapping des items avec labels et gestion de la sélection
            this.itemsList = this.itemsList.map(item => {
                item.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel}`;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.buildingId === item._id && parentItem.establishmentId === item.establishmentId)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });

            this.displayedItemsList = [];


            // Comportement par défaut : regrouper par établissement
            this.itemsList.forEach(item => {
                if (!this.displayedItemsList.some(x => x.establishmentId === item.establishmentId && x._id === item._id)) {
                    this.displayedItemsList.push(item);
                } else {
                    const existingItem = this.displayedItemsList.find(x => x.establishmentId === item.establishmentId && x._id === item._id);
                    existingItem.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel} (${this.itemsList.filter(x => x.buildingId === item.buildingId && x._id === item._id).length} Bâtiments)`;
                }
            });

            if (this.readMode || this.isCompressed) {
                this.displayedItemsList.forEach(item => {
                    const existingItems = this.displayedItemsList.filter(x => x.buildingId === item.buildingId);
                    if (existingItems.length > 1) {
                        item.displayLabel = `${item.genericLabel} / ${item.label}  (${existingItems.length} Établissements)`;
                        this.displayedItemsList = this.displayedItemsList.filter(x => x.buildingId !== item.buildingId);
                        this.displayedItemsList.unshift(item);
                    }
                });
            }


            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.BUILDING);
            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);

        } else {
            this.displayedItemsList = this.stockedData;
            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.BUILDING);
        }
    }


    async handleEnergyIdData(filteredContractPerimeter: any[], entity: any, entityPrestation: PrestationModel) {

        if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {
            let result;

            if (this.prestationToPLan) {
                result = await this.energyIdService.findEnergyIdsByPrestationId(this.prestationToPLan._id).toPromise();
            } else {
                result = await this.energyIdService.findPerimetersByEstablishments(this.entity, filteredContractPerimeter.map(x => x.establishmentId)).toPromise();
            }

            this.itemsList = this.energyIdService.transformEnergyIdData(result, entity.perimeter);
            this.itemsList = this.itemsList.filter(item => this.entity.perimeter.some(perimeter => perimeter.energyIdId === item._id));

            this.itemsList = this.itemsList.map(item => {
                item.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel}`;

                if (this.parentItems && this.parentItems.length > 0) {
                    if (this.parentItems.some(parentItem => parentItem.energyIdId === item._id && parentItem.establishmentId === item.establishmentId)) {
                        item.checked = true;
                        if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
                            this.selectedItems.push(item);
                        }
                    }
                }
                return item;
            });

            this.displayedItemsList = [];


            this.itemsList.forEach(item => {
                if (!this.displayedItemsList.some(x => x.establishmentId === item.establishmentId && x._id === item._id)) {
                    this.displayedItemsList.push(item);
                } else {
                    const existingItem = this.displayedItemsList.find(x => x.establishmentId === item.establishmentId && x._id === item._id);
                    existingItem.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel} (${this.itemsList.filter(x => x.buildingId === item.buildingId && x._id === item._id).length} Bâtiments)`;
                }
            });

            if (this.readMode || this.isCompressed) {
                this.displayedItemsList.forEach(item => {
                    const existingItems = this.displayedItemsList.filter(x => x._id === item._id);
                    if (existingItems.length > 1) {
                        item.displayLabel = `${item.genericLabel} / ${item.label}  (${existingItems.length} Établissements) (${this.itemsList.filter(x => x.buildingId === item.buildingId && x._id === item._id).length} Bâtiments)`; // buildingID
                        this.displayedItemsList = this.displayedItemsList.filter(x => x._id !== item._id);
                        this.displayedItemsList.unshift(item);
                    }
                });
            }


            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.ID_ENERGIE);
            if (this.noReloadData) this.stockData.emit(this.displayedItemsList);

        } else {
            this.displayedItemsList = this.stockedData;
            this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.ID_ENERGIE);
        }
    }


    toggleSelectionItemRadioButton(item) {

        let groupedItems = [];

        if (!this.prestationToPLan) {

            this.selectedItems = [];

            switch (this.entity.genericEngagement.dataPatrimony) {
                case GenericEngagementDataPatrimonyEnum.EQUIPMENT && GenericEngagementDataPatrimonyEnum.ID_ENERGIE:
                    if (this.isCompressed) {
                        groupedItems = this.itemsList.filter(x => x._id === item._id);
                        groupedItems.forEach(groupedItem => {
                            this.selectedItems.push(groupedItem);
                        });
                    } else {
                        groupedItems = this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id);
                        groupedItems.forEach(groupedItem => {
                            this.selectedItems.push(groupedItem);
                        });
                    }
                    break;

                case GenericEngagementDataPatrimonyEnum.BUILDING:
                    if (this.isCompressed) {
                        groupedItems = this.itemsList.filter(x => x.buildingId === item.buildingId);
                        groupedItems.forEach(groupedItem => {
                            this.selectedItems.push(groupedItem);
                        });
                    } else {
                        this.selectedItems.push(item);
                    }
                    break;

                default:
                    this.selectedItems.push(item);
                    break;
            }
        } else {
            // Même logique si prestationToPLan est définie
            this.selectedItems = [];
            this.selectedItems.push(item);
        }

        this.convertToContractPerimeterModel(this.selectedItems);
        this.selectedItemsChange.emit(this.selectedPerimeters);
        this.itemChecked = item;
    }

    // function to disable radio button is the item is already planned (in recurring basket)
    matchPerimeterToDisable(entityPrestation: PrestationModel, genericEngagementDataPatrimonyEnum?: GenericEngagementDataPatrimonyEnum) {

        if (entityPrestation) {

            switch (genericEngagementDataPatrimonyEnum) {
                case GenericEngagementDataPatrimonyEnum.REFERENCE:
                    this.displayedItemsList = this.displayedItemsList.map(displayedItem => {
                        const matchingPerimeter = entityPrestation?.perimeter.find(perimeterItem => perimeterItem.establishmentId === displayedItem._id);
                        if (matchingPerimeter) {
                            displayedItem.isPlanned = matchingPerimeter.isPlanned;
                        }

                        this.displayedItemsList.forEach(item => item.checked = false);
                        return displayedItem;
                    });
                    break;

                case GenericEngagementDataPatrimonyEnum.EQUIPMENT:
                    this.displayedItemsList = this.displayedItemsList.map(displayedItem => {
                        const matchingPerimeter = entityPrestation?.perimeter.find(perimeterItem => perimeterItem.equipmentId === displayedItem.equipmentId &&
                            perimeterItem.establishmentId === displayedItem.establishmentId);

                        if (matchingPerimeter) {
                            displayedItem.isPlanned = matchingPerimeter.isPlanned;
                        }

                        this.displayedItemsList.forEach(item => item.checked = false);
                        return displayedItem;
                    });
                    break;

                case GenericEngagementDataPatrimonyEnum.BUILDING:
                    this.displayedItemsList = this.displayedItemsList.map(displayedItem => {
                        const matchingPerimeter = entityPrestation?.perimeter.find(perimeterItem => perimeterItem.buildingId === displayedItem.buildingId &&
                            perimeterItem.establishmentId === displayedItem.establishmentId);
                        if (matchingPerimeter) {
                            displayedItem.isPlanned = matchingPerimeter.isPlanned;
                        }

                        this.displayedItemsList.forEach(item => item.checked = false);
                        return displayedItem;
                    });
                    break;

                case GenericEngagementDataPatrimonyEnum.ID_ENERGIE:
                    this.displayedItemsList = this.displayedItemsList.map(displayedItem => {
                        // Recherche dans les périmètres correspondants à la fois par energyIdId et establishmentId
                        const matchingPerimeter = entityPrestation?.perimeter.find(perimeterItem =>
                            perimeterItem.energyIdId === displayedItem._id &&
                            perimeterItem.establishmentId === displayedItem.establishmentId
                        );

                        if (matchingPerimeter) {
                            displayedItem.isPlanned = matchingPerimeter.isPlanned;
                        }

                        displayedItem.checked = false;

                        return displayedItem;
                    });
                    break;

                default:
                    break;
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }


    // ancienne fonction exemple pour séparer les data patrimoines des établissements ->

    // async handleBuildingData(filteredContractPerimeter: any[], entityPrestation) {
    //     if (!this.noReloadData || !(this.noReloadData && this.stockedData.length > 0 && this.readMode)) {
    //
    //         let result;
    //
    //         //    Récupérer les bâtiments par prestation si prestationToPlan est défini
    //         if (this.prestationToPLan) {
    //             result = await this.buildingService.findBuildingsByPrestation(this.prestationToPLan._id).toPromise();
    //         } else {
    //             // Récupérer les périmètres par établissements sinon
    //             result = await this.buildingService.findPerimetersByEstablishments(this.entity, filteredContractPerimeter.map(x => x.establishmentId)).toPromise();
    //         }
    //
    //         // Transformer les données reçues
    //         this.itemsList = this.buildingService.transformBuildingData(result);
    //
    //         // Filtrer les bâtiments correspondants aux périmètres de l'entité
    //         this.itemsList = this.itemsList.filter(item => this.entity.perimeter.some(perimeter => perimeter.buildingId === item._id));
    //
    //         // Mapping des items avec labels et gestion de la sélection
    //         this.itemsList = this.itemsList.map(item => {
    //             item.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel}`;
    //
    //             if (this.parentItems && this.parentItems.length > 0) {
    //                 if (this.parentItems.some(parentItem => parentItem.buildingId === item._id && parentItem.establishmentId === item.establishmentId)) {
    //                     item.checked = true;
    //                     if (!this.selectedItems.some(selectedItem => selectedItem._id === item._id)) {
    //                         this.selectedItems.push(item);
    //                     }
    //                 }
    //             }
    //             return item;
    //         });
    //
    //         this.displayedItemsList = [];
    //
    //         // Cas où prestationToPlan est présent : chaque bâtiment est affiché individuellement
    //         if (this.prestationToPLan) {
    //             this.itemsList.forEach(item => {
    //                 this.displayedItemsList.push(item);
    //             });
    //         } else {
    //             // Comportement par défaut : regrouper par établissement
    //             this.itemsList.forEach(item => {
    //                 if (!this.displayedItemsList.some(x => x.establishmentId === item.establishmentId && x._id === item._id)) {
    //                     this.displayedItemsList.push(item);
    //                 } else {
    //                     const existingItem = this.displayedItemsList.find(x => x.establishmentId === item.establishmentId && x._id === item._id);
    //                     existingItem.displayLabel = `${item.genericLabel} / ${item.label} / ${item.establishmentLabel} (${this.itemsList.filter(x => x.establishmentId === item.establishmentId && x._id === item._id).length} Bâtiments)`;
    //                 }
    //             });
    //
    //             if (this.readMode || this.isCompressed) {
    //                 this.displayedItemsList.forEach(item => {
    //                     const existingItems = this.displayedItemsList.filter(x => x.buildingId === item.buildingId);
    //                     if (existingItems.length > 1) {
    //                         item.displayLabel = `${item.genericLabel} / ${item.label}  (${existingItems.length} Établissements)`;
    //                         this.displayedItemsList = this.displayedItemsList.filter(x => x.buildingId !== item.buildingId);
    //                         this.displayedItemsList.unshift(item);
    //                     }
    //                 });
    //             }
    //         }
    //
    //         // Désactiver certains périmètres si nécessaire
    //         this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.BUILDING);
    //
    //         // Émettre les données stockées si nécessaire
    //         if (this.noReloadData) this.stockData.emit(this.displayedItemsList);
    //
    //     } else {
    //         // Utiliser les données stockées si noReloadData est activé
    //         this.displayedItemsList = this.stockedData;
    //         this.matchPerimeterToDisable(entityPrestation, GenericEngagementDataPatrimonyEnum.BUILDING);
    //     }
    // }

    checkIfOneItemInDisplayedItemsList() {
        if (this.displayedItemsList.length > 0 && this.prestationToPLan) {
            for (const item of this.displayedItemsList) {
                if (!item.isPlanned) {
                    item.checked = true;
                    this.toggleSelectionItemRadioButton(item);
                    break;
                }
            }
        }
    }


}
