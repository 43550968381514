import {BaseService} from '../../../../core/base/base.service';
import {HttpClient} from '@angular/common/http';
import {ContractModel, ContractPerimeterModel, RecurringBasketModel} from '../../../../core/models/contract.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {catchError, map} from 'rxjs/operators';
import {TicketCommentModel} from '../../../../core/models/ticket.model';
import {node} from '../../../../core/ui/tree-checkbox/tree-checkbox.component';
import {
    GenericBuildingCondENum,
    GenericEngagementDataPatrimonyEnum,
    GenericEngagementEquipmentCondEnum,
    GenericEngagementReferenceCondEnum
} from "../../../../core/enums/generic-engagement-data-patrimony.enum";
import {ProductModel} from "../../../../core/models/product.model";
import {PrestationModel} from "../../../../core/models/prestation.model";
import {BaseBasketItemModel} from "../../../../core/base/models/base-basket-item.model";
import {DayEnum} from "../../../../core/enums/day.enum";
import {RecurrenceEnum} from "../../../../core/enums/recurrence.enum";
import {MonthEnum, monthEnumTranslation} from "../../../../core/enums/month.enum";
import {GenericEquipmentService} from "../../settings/generic-equipment/generic-equipment.service";
import moment from "moment";
import {ContractStatusEnum} from "../../../../core/enums/contract-status.enum";

@Injectable({
    providedIn: 'root'
})
export class ContractService extends BaseService<ContractModel> {

    protected _perimeter = new BehaviorSubject<TicketCommentModel[]>([]);
    readonly perimeter$ = this._perimeter.asObservable();

    protected _currentRecurringBasket = new BehaviorSubject<RecurringBasketModel>(null);
    readonly currentRecurringBasket$ = this._currentRecurringBasket.asObservable();

    protected _reccuringBaskets = new BehaviorSubject<RecurringBasketModel[]>([]);
    readonly reccuringBaskets$ = this._reccuringBaskets.asObservable();

    private _isValidable = new BehaviorSubject<boolean>(false);
    readonly isValidable$ = this._isValidable.asObservable();

    defaultSort = 'createdAt';

    constructor(public http: HttpClient,
                private readonly genericEquipmentService: GenericEquipmentService) {
        super(http, 'contract');
    }

    findById(id: string): Observable<ObjectApiInterface<ContractModel>> {
        // Appelle la méthode findById de BaseService et met à jour _reccuringBasket
        return super.findById(id).pipe(
            tap((response: ObjectApiInterface<ContractModel>) => {
                this._reccuringBaskets.next(response.data.recurringBasket);
                //this._currentRecurringBasket.next(response.data.recurringBasket[0]);
            })
        );
    }

    create(entity: ContractModel): Observable<ObjectApiInterface<ContractModel>> {
        return this.http.post<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/create`, entity, {
            headers: {'Error-CRUD': 'CREATE'}
        });
    }


    fetchActiveContractByCondition(condition: any): any {
        return this.http.post<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/fetchActiveContractByCondition`, condition).pipe(map(x => {
            return x.data;
        }));
    }


    update(entity: ContractModel): Observable<ObjectApiInterface<ContractModel>> {
        if (entity.genericEngagement?._id){
            entity.genericEngagementId = entity.genericEngagement?._id;
            entity.genericEngagement = null;
        }

        console.log("update ocntract : ", entity)
        return this.http.put<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/update/${entity._id}`, entity).pipe(map(x => {
            //this._entity.next(x.data);
            return x;
        }));
    }

    addDenunciationDate(id: string, data: any) {
        return this.http.put<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/addDenunciationDate/${id}`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    delete(entity: ContractModel): Observable<ObjectApiInterface<ContractModel>> {
        entity.isDelete = true;
        return this.http.put<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/update/${entity._id}`, entity).pipe(map(x => {
            // this._entity.next(x.data);
            return x;
        }));
    }

    // updatePerimeter(id: string, data: node, isEquipmentId: boolean) {
    //     const perimeter: ContractPerimeterModel[] = this.convertToContractPerimeterModel(data, isEquipmentId);
    //     return this.http.put<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/updatePerimeter/${id}/${data._id}`, {data: perimeter}).pipe(map(x => {
    //         //this._perimeter.next(x);
    //         return x;
    //     }));
    // }

    addAllPerimeter(id: string, data) {
        return this.http.put<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/addAllPerimeter/${id}`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    deleteAllPerimeter(id: string, data) {
        return this.http.put<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/deleteAllPerimeter/${id}`, {perimeters: data}).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    convertToContractPerimeterModel(root: node, isEquipmentId): ContractPerimeterModel[] {
        const result: ContractPerimeterModel[] = [];
        this.traverseNode(root, null, null, 1, result, isEquipmentId);
        return result;
    }

    traverseNode(currentNode: node, establishmentId: string | null, buildingId: string | null, level: number, result: ContractPerimeterModel[], isEquipmentId) {
        if (!currentNode) {
            return;
        }

        // Déterminer le type de node basé sur le niveau
        switch (level) {
            case 1: // Niveau Établissement
                establishmentId = currentNode._id;
                if (currentNode.children.length === 0 && currentNode.selected) {
                    // Créer et ajouter un nouveau ContractPerimeterModel
                    result.push(new ContractPerimeterModel(currentNode._id, null, null, null));
                }
                break;
            case 2: // Niveau Bâtiment
                buildingId = currentNode._id;
                if (currentNode.children.length === 0 && currentNode.selected) {
                    // Créer et ajouter un nouveau ContractPerimeterModel
                    result.push(new ContractPerimeterModel(establishmentId, currentNode._id, null, null));
                }
                break;
            case 3: // Niveau Équipement
                if (establishmentId) {
                    if (currentNode.selected) {
                        if (isEquipmentId) {
                            result.push(new ContractPerimeterModel(establishmentId, buildingId, currentNode._id, null));
                        } else {
                            result.push(new ContractPerimeterModel(establishmentId, buildingId, null, currentNode._id));
                        }
                    }
                }
                break;
        }

        // Traiter les enfants récursivement avec le niveau augmenté
        if ((currentNode.selected || currentNode.indeterminate) && currentNode.children && currentNode.children.length) {
            currentNode.children.forEach(childNode => {
                this.traverseNode(childNode, establishmentId, buildingId, level + 1, result, isEquipmentId);
            });
        }
    }

    updateStep(id: string, data: { creationStep: number }) {
        return this.http.post<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/updateStep/${id}`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    validatesContract(id: string, data: ContractModel) {
        if (data.genericEngagement?._id){
            data.genericEngagementId = data.genericEngagement?._id;
            data.genericEngagement = null;
        }
        return this.http.post(`${this.baseUrlApi}/validatesContract/${id}`, data).pipe(map((res: any) => {
            this._entity.next(res.data);
            return res;
        }));
    }

    invalidateContract(id: string) {
        return this.http.put<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/invalidateContract/${id}`, {}).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    updateCga(id: string, data: any) {
        // for test remove additionalCosts, deliveryCost, priceRevision

        if (data.priceRevision && data.priceRevision.recurrence) {
            data.priceRevision = this.parseRecurrenceData(data.priceRevision);
        }

        if (data.additionalCosts && data.additionalCosts.length > 0) {
            // remove additionalCosts if data.additionalCost.additionalCost is null
            data.additionalCosts = data.additionalCosts.filter((x: any) => x.additionalCost && x.additionalCost?._id);
        }

        if (data.additionalCosts.length === 0) {
            delete data.additionalCosts;
        }


        return this.http.post<ObjectApiInterface<ContractModel>>(`${this.baseUrlApi}/updateCga/${id}`, data).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    parseRecurrenceData(data: any) {
        const monthEnum = Object.values(MonthEnum).map(month => monthEnumTranslation(month));
        data.recurrence = data.recurrence.value;
        if (data.recurrence === RecurrenceEnum.Weekly) {
            data.activated = data.activated?.map((value) => {
                return Object.values(DayEnum).findIndex((y: any) => y === value.value);
            });
        }
        if (!Array.isArray(data.activated)) {
            data.activated = [data.activated];
        }

        if (data.month) {
            data.month = data.month.value;
        }
        // if (data.month) {
        //     // change month to number with january = 1
        //     data.month = monthEnum.findIndex((y: any) => y === data.month) + 1;
        // }

        return data;
    }


    removeRecurringBasket(contractId: string, recurringBasketId: string) {
        return this.http.delete(`${this.baseUrlApi}/${contractId}/removeRecurringBasket/${recurringBasketId}`).pipe(map((res: any) => {
            this._entity.next(res.data);
            this._reccuringBaskets.next(res.data.recurringBasket);
            if (this._currentRecurringBasket?.value?._id === recurringBasketId) {
                this._currentRecurringBasket.next(null);
            }
        }));
    }

    createRecurringBasket(id: string, data: any) {
        return this.http.post(`${this.baseUrlApi}/${id}/createRecurringBasket`, data).pipe(map((res: any) => {
            this._entity.next(res.data.contract);
            this._reccuringBaskets.next(res.data.contract.recurringBasket);
            return res;
            // this._basket.next(res.data.basket);
        }));
    }

    confirmCreateRecurringBasket(id: string) {
        return this.http.post(`${this.baseUrlApi}/${id}/confirmCreateRecurringBasket`, {}).pipe(map((res: any) => {
            this._entity.next(res.data.contract);
            this._reccuringBaskets.next(res.data.contract.recurringBasket);
            return res;
        }));
    }

    updateRecurringBasket(id: string, recurringBasketId: string, data: any) {
        return this.http.patch(`${this.baseUrlApi}/${id}/updateRecurringBasket/${recurringBasketId}`, data).pipe(map((res: any) => {
            this._entity.next(res.data.contract);
            this._reccuringBaskets.next(res.data.contract.recurringBasket);
            this._currentRecurringBasket.next(res.data.contract.recurringBasket.find((x: any) => x._id === recurringBasketId));
            return res;
        }));
    }

    confirmUpdateRecurringBasket(id: string, recurringBasketId: string) {
        return this.http.post(`${this.baseUrlApi}/${id}/confirmUpdateRecurringBasket/${recurringBasketId}`, {}).pipe(map((res: any) => {
            this._entity.next(res.data.contract);
            this._reccuringBaskets.next(res.data.contract.recurringBasket);
            return res;
        }));
    }

    addToBasket(id: string, reccuringBasketLineId: any, type: 'products' | 'prestations', element: ProductModel | PrestationModel) {


        return this.http.put(`${this.baseUrlApi}/${id}/addToBasket/${reccuringBasketLineId}`, {
            type,
            item: element._id,
            quantity: element.quantity,

        }).pipe(map((res: any) => {
            this._currentRecurringBasket.next(res.data.find(r => r._id === reccuringBasketLineId));
            this._reccuringBaskets.next(res.data);
        }));


    }

    updateBasketQuantity(id: string, reccuringBasketLineId: string, item: any) {


        return this.http.put(`${this.baseUrlApi}/${id}/updateBasket/${reccuringBasketLineId}`, {
            type: item.type,
            item: item._id,
            quantity: item.quantity
        }).pipe(map((res: any) => {
            this._currentRecurringBasket.next(res.data);
        }));
    }

    removeFromBasket(contractId: string, reccuringBasketLineId: string, item: BaseBasketItemModel) {

        return this.http.post(`${this.baseUrlApi}/${contractId}/removeFromBasket/${reccuringBasketLineId}`, {
            type: item.type,
            _id: item._id,
        }).pipe(map((currentBasket: any) => {
            this._currentRecurringBasket.next(currentBasket.data);

        }));
    }

    // Recurring Basket
    selectRecurringBasket(reccuringBasketId: string) {
        const reccuringBasket = this._reccuringBaskets.value.find(x => x._id === reccuringBasketId);
        this._currentRecurringBasket.next(reccuringBasket);
    }

    validateRecurringBasket(contractId: string, reccuringBasketId: string, value: boolean, optionUrgencyDegree?: string) {
        return this.http.post(`${this.baseUrlApi}/${contractId}/validateRecurringBasket/${reccuringBasketId}`, {value, optionUrgencyDegree}).pipe(map((res: any) => {
            const reccuringBasket = res.data.find(x => x._id === reccuringBasketId);
            this._reccuringBaskets.next(res.data);
            this._currentRecurringBasket.next(reccuringBasket);

            return res.data;
        }));
    }


    mapBasket(contract: ContractModel, data: RecurringBasketModel): BaseBasketItemModel[] {
        const mappedBasket: BaseBasketItemModel[] = [];


        if (data?.products && data?.products.length > 0) {


            for (const item of data?.products) {

                const product = item.item as ProductModel;

                if (!product) {
                    continue;
                }

                mappedBasket.push({
                    _id: product._id,
                    type: 'products',
                    label: product.label,
                    subLabel: product.reference,
                    priceHT: product.priceHT,
                    quantity: item.quantity,
                    isAllowed: true
                });
            }

        }

        if (data?.prestations && data?.prestations.length > 0) {
            for (const item of data?.prestations) {

                const prestation = item.item as PrestationModel;

                if (!prestation) {
                    continue;
                }

                mappedBasket.push({
                    _id: prestation._id,
                    type: 'prestations',
                    label: prestation.label,
                    subLabel: prestation.reference,
                    priceHT: prestation.priceHT,
                    quantity: item.quantity,
                    isAllowed: true
                });

            }

        }

        return mappedBasket;

    }


    generateTabs(contract: ContractModel) {
        let tabs = [];

        console.log('contract.genericEngagement', contract.genericEngagement);


        switch (contract.genericEngagement.dataPatrimony) {
            case GenericEngagementDataPatrimonyEnum.REFERENCE:
                if (contract.genericEngagement.referenceCond.includes(GenericEngagementReferenceCondEnum.ARTICLES)) {
                    tabs.push('Articles');
                }

                if (contract.genericEngagement.referenceCond.includes(GenericEngagementReferenceCondEnum.PRESTATIONS)) {
                    tabs.push('Prestations');
                }
                break;

            case GenericEngagementDataPatrimonyEnum.BUILDING:
                if (contract.genericEngagement.buildingCond.includes(GenericBuildingCondENum.MAINTENANCE_BAT)) {
                    tabs.push('Prestations');
                }
                if (contract.genericEngagement.buildingCond.includes(GenericBuildingCondENum.MAINTENANCE_BAT_AC)) {
                    tabs.push('Articles');
                }
                if (contract.genericEngagement.buildingCond.includes(GenericBuildingCondENum.MAINTENANCE_REG)) {
                    tabs.push('Prestations');
                }
                break;

            case GenericEngagementDataPatrimonyEnum.ID_ENERGIE:
                tabs = ['Articles', 'Prestations'];
                break;

            case GenericEngagementDataPatrimonyEnum.EQUIPMENT:

                const equipmentCondArray = contract?.genericEngagement?.equipmentCond;

                if (equipmentCondArray === null) {
                    tabs = ['Articles', 'Prestations'];
                    break;
                }

                for (const equipmentCond of equipmentCondArray) {
                    switch (equipmentCond) {
                        case GenericEngagementEquipmentCondEnum.MAINTENANCE_EQ:
                        case GenericEngagementEquipmentCondEnum.MAINTENANCE_REG:
                        case GenericEngagementEquipmentCondEnum.LOCATION_EQ:
                            if (tabs.indexOf('Prestations') === -1) {
                                tabs.push('Prestations');
                            }

                            break;

                        case GenericEngagementEquipmentCondEnum.MAINTENANCE_EQ_AC:
                            tabs.push('Articles');
                            break;
                        default:
                            tabs = ['Articles', 'Prestations'];
                            break;
                    }
                }
                break;

            default:
                tabs = ['Articles', 'Prestations'];
                break;
        }


        // sort tabs
        if (tabs.indexOf('Articles') !== -1) {
            tabs.splice(tabs.indexOf('Articles'), 1);
            tabs.unshift('Articles');
        }
        return tabs;
    }

    setIsValid(value: boolean): void {
        this._isValidable.next(value);
    }


    checkIsEditableCatalog(contract: ContractModel): boolean {


        const currentDate = moment();
        const startValidityDate = moment(contract?.startValidityDate);

        return currentDate.isBefore(startValidityDate) || contract?.status === ContractStatusEnum.DRAFT;
    }


    contractExpiringSoon(contract) {
        if(contract){
            const endDate = contract?.denunciationDate ? contract?.denunciationDate : contract?.endValidityDate;
            const currentDate = moment();
            const endValidityDate = moment(endDate);
            const daysBeforeExpiration = endValidityDate.diff(currentDate, 'days');
            return daysBeforeExpiration >= 0 && daysBeforeExpiration <= 7;
        } else {
            return false;
        }
    }

}
