<div class="card overflow-hidden">
<!--    <div class="w-full">-->
<!--    <div class="flex justify-end mt-2 mr-2">-->
<!--        <button (click)="onEdit.emit()">-->
<!--            <mat-icon [icIcon]="icEdit"></mat-icon>-->
<!--        </button>-->
<!--        <button (click)="onDelete.emit()">-->
<!--            <mat-icon [icIcon]="icDelete"></mat-icon>-->
<!--        </button>-->

<!--    </div>-->

  <div (click)="linkToContact()"
       class="p-4 text-center hover:bg-hover trans-ease-out cursor-pointer relative"
       matRipple>
<!--    <img [src]="contact?.imageSrc" class="avatar h-24 w-24 mx-auto">-->
    <div class="avatar h-24 w-24 mx-auto bg-primary flex justify-center items-center font-sm text-white text-xl tracking-wide">
      {{ contactInitial }}
    </div>

    <h2 class="title mb-1 mt-3">{{ contact?.fullName | titlecase }}</h2>
    <div class="body-2 text-secondary">
      <ic-icon [icon]="icBusiness" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
      <span>{{ contact?.email }}</span>
    </div>

    <div class="body-2 text-secondary">
      <ic-icon [icon]="icPhone" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
      <span>{{ contact?.mobileNumber }}</span>
    </div>
    <div class="body-2 text-secondary" *ngIf="contact?.contactTypes?.length > 0">
        <ic-icon [icon]="icContacts" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
        <span *ngFor='let type of contact?.contactTypes; let i = index; let first = first ;let last = last;'>{{ type.label }}<span *ngIf="!last">, </span> </span>
    </div>
    <ng-content></ng-content>
<!--    <button (click)="emitToggleStar($event, contact?.id)" class="absolute top-2 right-2" mat-icon-button type="button">-->
<!--      <mat-icon *ngIf="contact?.starred" [icIcon]="icStar" class="text-amber"></mat-icon>-->
<!--      <mat-icon *ngIf="!contact?.starred" [icIcon]="icStarBorder"></mat-icon>-->
<!--    </button>-->
  </div>

  <div *hasPermission='allPermissions; op "OR"' class="bg-app-bar p-2" fxLayout="row" fxLayoutAlign="space-around center">
    <a *hasPermission="callPermission" href="tel:{{contact?.mobileNumber}}" class="text-secondary" mat-icon-button type="button">
      <mat-icon [icIcon]="icPhone"></mat-icon>
    </a>

    <a *hasPermission="sendPermission" href="mailto:{{contact?.email}}" class="text-secondary" mat-icon-button type="button">
      <mat-icon [icIcon]="icMail"></mat-icon>
    </a>

      <ng-container *ngIf='showAction' matColumnDef='actions'>
              <div style='text-align: right;'>
                  <div>
                      <button [matMenuTriggerFor]="columnActionMenu"
                              mat-icon-button type='button'>
                          <mat-icon [icIcon]='icMoreVert'
                                    matTooltip='actions'
                                    [matTooltipShowDelay]='1500'
                          ></mat-icon>
                      </button>
                  </div>
              </div>
      </ng-container>

      <mat-menu #columnActionMenu="matMenu" xPosition="before" yPosition="below">
          <ng-container *hasPermission="updatePermission">
              <button  *ngIf='canUpdate' class="mat-menu-item" (click)='onEdit.emit()'>
                  <mat-icon [icIcon]="icEdit"></mat-icon>
                  Modifier
              </button>
          </ng-container>
          <ng-container *hasPermission="deletePermission">
              <button *ngIf='canDelete' class="mat-menu-item" (click)="onDelete.emit()">
                  <mat-icon [icIcon]="icDelete"></mat-icon>
                  Supprimer
              </button>
          </ng-container>
          <!--    <button class="mat-menu-item" (click)="deleteItem()">-->
          <!--        <mat-icon [icIcon]="icDelete"></mat-icon>-->
          <!--        Switch isActive-->
          <!--    </button>-->
      </mat-menu>

<!--    <button class="text-secondary" mat-icon-button type="button">-->
<!--      <mat-icon [icIcon]="icChat"></mat-icon>-->
<!--    </button>-->
  </div>
</div>
