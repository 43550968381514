import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EstablishmentModel} from 'src/app/core/models/establishment.model';
import {EstablishmentService} from '../../establishment.service';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {Country} from '../../../../../../core/enums/countries.enum';
import {FrenchTerritoryHelper} from '../../../../../../core/helpers/frenchTerritory.helper';
import {Observable} from 'rxjs';
import {CpomModel} from '../../../../../../core/models/cpom.model';
import {CpomService} from '../../../../settings/cpom/cpom.service';
import {map} from 'rxjs/operators';
import {UserProfileService} from "../../../user-profile/user-profile.service";
import {UserProfileModel} from "../../../../../../core/models/user-profile.model";
import {
    UserProfileValidatorLevel,
    userProfileValidatorLevelTransalation
} from "../../../../../../core/enums/user-profile-validator-seuil.enum";
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";
import {AuthService} from "../../../../../../core/services/auth.service";


@Component({
    selector: 'vex-establishment-create-update',
    templateUrl: './establishment-create-update-delete.component.html',
    styleUrls: ['./establishment-create-update-delete.component.scss']
})
export class EstablishmentCreateUpdateDeleteComponent extends FormModalBaseComponent<EstablishmentModel> implements OnInit {

    addressForm: FormGroup;
    countryEnum = Country;
    countries: Array<any>;
    levels = Object.keys(UserProfileValidatorLevel);

    isFrench?: boolean = true;

    userProfiles$: Observable<UserProfileModel[]>;

//    userProfiles$: Array<UserProfileModel[]>
    cpom$: Observable<CpomModel[]>;
    statusTva = ['Soumis', 'Non soumis'];

    canDownloadModelPermissions: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<EstablishmentModel>>,
                public service: EstablishmentService,
                private cpomService: CpomService,
                private userProfileService: UserProfileService,
                private authService: AuthService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {

        super.ngOnInit();
        this.customerReferentAutocompleteChange();
        this.countries = Object.values(this.countryEnum)

        // this.cpom$ = this.cpomService.findAll().pipe(map(x => x.data))
        this.initForm();

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadModelPermissions = permissions.includes(ENUM_PERMISSIONS.DOWNLOAD_CP_ESTABLISHMENT);
        });

    }

    initForm() {
        this.addressForm = new FormGroup({
            street: new FormControl(this.defaults?.headquarterAddress?.street, [Validators.required]),
            additionalAddress: new FormControl(this.defaults?.headquarterAddress?.additionalAddress),
            postalCode: new FormControl(this.defaults?.headquarterAddress?.postalCode, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]),
            city: new FormControl(this.defaults?.headquarterAddress?.city, [Validators.required]),
            country: new FormControl(this.isUpdateMode() ? this.defaults?.headquarterAddress?.country : Country.FR, [Validators.required]),
        });

        if (this.defaults?.attachedAccountant) {
            this.defaults.attachedAccountant.fullName = this.defaults?.attachedAccountant?.firstName +
                ' ' + this.defaults?.attachedAccountant?.lastName;
        }

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            label: new FormControl(this.defaults?.label, [Validators.required]),
            headquarterAddress: this.addressForm,
            sirenNumber: new FormControl(this.defaults?.sirenNumber, [Validators.required]),
            siretNumber: new FormControl(this.defaults?.siretNumber, [Validators.required]),
            dunsNumber: new FormControl(this.defaults?.dunsNumber, null),
            // cpom: new FormControl(this.defaults?.cpom, [Validators.required]),
            tva: new FormControl(this.defaults?.tva, [Validators.required]),
            ape: new FormControl(this.defaults?.ape, [Validators.required]),
            vatStatus: new FormControl(this.defaults?.vatStatus, [Validators.required]),
            attachedAccountant: new FormControl(this.defaults?.attachedAccountant),
            accountingFile: new FormControl(this.defaults?.accountingFile),
            validatorMatriceLevel: new FormControl(this.defaults?.validatorMatriceLevel, [Validators.required]),
        });

        if (this.isUpdateMode()) {
            this.form.controls.vatStatus.setValue(this.data?.defaults?.vatStatus ? this.statusTva[0] : this.statusTva[1]);
        }

        this.setValidatorByCountry(this.addressForm.controls.country.value);

        this.addressForm.controls['country'].valueChanges.subscribe(value => {
            this.setValidatorByCountry(value);
        });
    }

    setValidatorByCountry(value: any) {
        this.isFrench = value ? FrenchTerritoryHelper(value) : true;

        if (this.isFrench) {
            this.form.controls['siretNumber'].setValidators([Validators.required]);
            this.form.controls['sirenNumber'].setValidators([Validators.required]);
            this.form.controls['ape'].setValidators([Validators.required]);
            this.form.controls['dunsNumber'].setValidators(null);
        } else {
            this.form.controls['sirenNumber'].setValidators(null);
            this.form.controls['siretNumber'].setValidators(null);
            this.form.controls['ape'].setValidators(null);
            this.form.controls['dunsNumber'].setValidators([Validators.required]);
        }
        this.form.controls['sirenNumber'].updateValueAndValidity();
        this.form.controls['siretNumber'].updateValueAndValidity();
        this.form.controls['ape'].updateValueAndValidity();
        this.form.controls['dunsNumber'].updateValueAndValidity();
    }

    beforeCreateItem() {
        this.defaults.vatStatus = this.form.controls.vatStatus.value === this.statusTva[0];
        if (!this.countries.includes(this.addressForm.controls['country'].value)) {
            this.addressForm.controls['country'].reset();
        }
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    beforeUpdateItem() {
        this.defaults.vatStatus = this.form.controls.vatStatus.value === this.statusTva[0];
        if (!this.countries.includes(this.addressForm.controls['country'].value)) {
            this.addressForm.controls['country'].reset();
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    cpomAutoCompleteChange(state: any = '') {
        this.cpom$ = this.cpomService.findAll(null, null, 'label', 'asc', state).pipe(map(x => x.data))
    }


    // userProfileChange(state: any = '') {
    //     this.userProfiles$ = this.userProfileService.findAll(null, null, null, null, state, this.filters).pipe(map(x => x.data));
    // }

    customerReferentAutocompleteChange() {
        // @ts-ignore
        this.userProfiles$ = this.userProfileService.findAll(null, null, 'fullName', 'asc', null, {
            isAccountant: true,
            establishment: this.defaults?._id
        }).pipe(map(x => x.data));
    }

    levelTranslation(level: string) {
        return userProfileValidatorLevelTransalation(UserProfileValidatorLevel[level]);
    }

}
