import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormModalBaseComponent} from './components/form-modal-base/form-modal-base.component';
import {DataTableBaseComponent} from './components/data-table-base/data-table-base.component';
import {FormBaseComponent} from './components/form-base/form-base.component';
import {DataTableHeaderBaseComponent} from './components/data-table-header-base/data-table-header-base.component';
import {ExtendedModule, FlexModule, GridModule} from '@angular/flex-layout';
import {IconModule} from '@visurel/iconify-angular';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {DataTableColumnBaseComponent} from './components/data-table-column-base/data-table-column-base.component';
import {MatMenuModule} from '@angular/material/menu';
import {DataTableBase} from './components/data-table-base/data-table-base';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormInputBaseComponent} from './components/form-input-base/form-input-base.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormHeaderBaseComponent} from './components/form-header-base/form-header-base.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {
    DataTableDropdownTemplateComponent
} from './components/data-table-dropdown/content/data-table-dropdown-template.component';
import {DataTableDropdownIconComponent} from './components/data-table-dropdown/data-table-dropdown-icon.component';
import {MatBadgeModule} from '@angular/material/badge';
import {RouterModule} from '@angular/router';
import {DetailsBaseComponent} from './components/details-base/details-base.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {FormImportBaseComponent} from './components/form-import-base/form-import-base.component';
import {MatButtonLoadingModule} from '../directives/mat-button-loading.module';
import {DetailsLineBaseComponent} from './components/details-line-base/details-line-base.component';
import {DetailsSkeletonBaseComponent} from './components/details-skeleton-base/details-skeleton-base.component';
import {
    DetailsSkeletonLineBaseComponent
} from './components/details-skeleton-line-base/details-skeleton-line-base.component';
import {FormModalDeleteBaseComponent} from './components/form-modal-delete-base/form-modal-delete-base.component';
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {GedBaseComponent} from './components/ged-base/ged-base.component';
import {FormModalConfirmeBaseComponent} from './components/form-modal-confirme-base/form-modal-confirme-base.component';
import {HasPermissionModule} from "../directives/has-permission/has-permission.module";
import {
    FormModalConfirmWithListComponent
} from "./components/form-modal-confirm-with-list/form-modal-confirm-with-list.component";
import {MatListModule} from "@angular/material/list";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatTabsModule} from "@angular/material/tabs";
import {MatExpansionModule} from "@angular/material/expansion";
import {CatalogBaseComponent} from './components/catalog-base/catalog-base.component';
import {CatalogBase} from './components/catalog-base/catalog-base';
import {
    CatalogActionsBaseComponent
} from './components/catalog-base/components/catalog-actions-base/catalog-actions-base.component';
import {
    CatalogBasketBaseComponent
} from './components/catalog-base/components/catalog-basket-base/catalog-basket-base.component';
import {
    CatalogQuantityColumnBaseComponent
} from './components/catalog-base/components/catalog-quantity-column-base/catalog-quantity-column-base.component';
import {
    CatalogSearchBarBaseComponent
} from './components/catalog-base/components/catalog-search-bar-base/catalog-search-bar-base.component';
import {FormModalArchiveBaseComponent} from "./components/form-modal-archive-base/form-modal-delete-base.component";
import {MatChipsModule} from "@angular/material/chips";
import {ListServicesBaseComponent} from "./components/list-services-base/list-services-base.component";
import {
    CatalogBigSearchBarBaseComponent
} from "./components/catalog-base/components/catalog-big-search-bar-base/catalog-big-search-bar-base.component";
import {
    CatalogBasketMinifiedBaseComponent
} from "./components/catalog-base/components/catalog-basket-minified-base/catalog-basket-minified-base.component";


@NgModule({
    declarations: [
        DataTableBase,
        DataTableBaseComponent,
        DataTableHeaderBaseComponent,
        DataTableColumnBaseComponent,
        FormBaseComponent,
        FormModalBaseComponent,
        FormHeaderBaseComponent,
        FormInputBaseComponent,
        DataTableDropdownIconComponent,
        DataTableDropdownTemplateComponent,
        DetailsBaseComponent,
        FormImportBaseComponent,
        DetailsLineBaseComponent,
        DetailsSkeletonBaseComponent,
        DetailsSkeletonLineBaseComponent,
        FormModalDeleteBaseComponent,
        FormModalArchiveBaseComponent,
        FormModalConfirmeBaseComponent,
        GedBaseComponent,
        FormModalConfirmWithListComponent,
        CatalogBaseComponent,
        CatalogBase,
        CatalogActionsBaseComponent,
        CatalogBasketBaseComponent,
        CatalogBasketMinifiedBaseComponent,
        CatalogQuantityColumnBaseComponent,
        CatalogSearchBarBaseComponent,
        CatalogBigSearchBarBaseComponent,
        ListServicesBaseComponent

    ],
    exports: [
        DataTableHeaderBaseComponent,
        DataTableColumnBaseComponent,
        DataTableBaseComponent,
        FormInputBaseComponent,
        FormHeaderBaseComponent,
        FormImportBaseComponent,
        DetailsLineBaseComponent,
        DetailsSkeletonBaseComponent,
        DetailsSkeletonLineBaseComponent,
        FormModalDeleteBaseComponent,
        FormModalArchiveBaseComponent,
        GedBaseComponent,
        FormModalConfirmeBaseComponent,
        CatalogBaseComponent,
        CatalogQuantityColumnBaseComponent,
        CatalogSearchBarBaseComponent,
        CatalogBigSearchBarBaseComponent,
        CatalogBase,
        CatalogActionsBaseComponent,
        DetailsBaseComponent,
        CatalogBasketBaseComponent,
        CatalogBasketMinifiedBaseComponent,
        ListServicesBaseComponent

    ],
    imports: [
        CommonModule,
        FlexModule,
        IconModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        ExtendedModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatDividerModule,
        MatAutocompleteModule,
        MatBadgeModule,
        RouterModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MaterialFileInputModule,
        MatButtonLoadingModule,
        // UiModule,
        GridModule,
        MatMomentDateModule,
        FormsModule,
        NgxMatTimepickerModule.setLocale('fr-FR'),
        NgxMatTimepickerModule,
        HasPermissionModule,
        MatListModule,
        ScrollingModule,
        MatTabsModule,
        MatExpansionModule,
        MatChipsModule,
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class BaseModule {
}
