<div class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
        <div class="card">
            <base-data-table-header
                [backgroundColorWhite]='true'
                label='Interventions'
                [canCreate]='false'
                [popupFilter]='true'
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                (clearPopupFilter)='clearPopupFilter()'
                (validatePopupFilter)='validatePopupFilter()'
                [countPopupFilter]='countPopupFilter'
                headerPopupTitle='Filtres avancés'
                [contentPopupTemplate]='contentPopupTemplate'
            ></base-data-table-header>

            <base-data-table
                [dataSource]='dataSourceIntervention'
                [displayedColumns]='displayedColumnsIntervention'
                [feisColumns]='feisColumnsIntervention'
                [showPaginator]='true'
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onRowClick)='navigateToIntervention($event)'
                [rowClickPermission]="ENUM_PERMISSION.ACCESS_EQUIPMENT_MAINTENANCE_BOOK_INTERVENTION"
            ></base-data-table>
        </div>
    </div>
</div>

<ng-template #contentPopupTemplate>
    <form [formGroup]='popupForm'>

        <mat-form-field class='flex-auto w-full'>
            <mat-label>Filtre</mat-label>
            <mat-select formControlName='lastMonth'>
                <mat-option *ngFor="let month of popupMonth" [value]="month.value">
                    {{month.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class='flex-auto w-full'>
            <mat-label>Filtrer par intervalle de temps</mat-label>
            <mat-date-range-input formGroupName='range'  [rangePicker]="picker">
                <input formControlName='start' matStartDate placeholder="Date de début">
                <input formControlName='end' matEndDate placeholder="Date de fin">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="popupRange.controls.start.errors?.required">Date de début obligatoire.</mat-error>
            <mat-error *ngIf="popupRange.controls.end.errors?.required">Date de fin obligatoire</mat-error>
        </mat-form-field>
    </form>
</ng-template>
<div class="flex-auto mt-4" *hasPermission="[ENUM_PERMISSION.READ_EQUIPMENT_MAINTENANCE_BOOK]">
    <div class="card">
        <base-data-table-header
                [backgroundColorWhite]='true'
                label='Tickets incident'
                [canCreate]='false'
                [popupFilter]='false'
                (onSearchChange)='onSearchChange($event)'
                (onClearTextFilter)='clearTextFilter()'
                (clearPopupFilter)='clearPopupFilter()'
                (validatePopupFilter)='validatePopupFilter()'
                [countPopupFilter]='countPopupFilter'
                headerPopupTitle='Filtres avancés'
                [contentPopupTemplate]='contentPopupTemplate'
        ></base-data-table-header>
        <base-data-table
                [dataSource]='dataSourceHistory'
                [displayedColumns]='displayedColumnsHistory'
                [feisColumns]='feisColumnsHistory'
                [showPaginator]='true'
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                routerLinkDetailRow="/administrative/ticket/:id"
                [rowClickPermission]="ENUM_PERMISSION.ACCESS_EQUIPMENT_MAINTENANCE_BOOK"
        ></base-data-table>
    </div>
</div>
