import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ContractModel } from '../../../../../../core/models/contract.model';
import { ContractService } from '../../contract.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProductModel } from '../../../../../../core/models/product.model';
import { PrestationModel } from '../../../../../../core/models/prestation.model';
import { FeiColumn } from '../../../../../../core/base/interfaces/fei-column.interface';
import { first } from 'rxjs/operators';
import { ContractTypeOfRevisionEnum } from '../../../../../../core/enums/contract.enum';
import { ProductService } from '../../../product/product.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { PrestationService } from '../../../prestation/prestation.service';
import { ContractStatusEnum } from '../../../../../../core/enums/contract-status.enum';

@Component({
  selector: 'vex-contract-base',
  templateUrl: './contract-base.component.html',
  styleUrls: ['./contract-base.component.scss'],
})
export class ContractBaseComponent implements OnInit, OnDestroy {

  loading = false;
  subscription = new Subscription();
  tabs: Array<string> = ['Articles', 'Prestations'];
  entityType: 'products' | 'prestations';
  popupForm: FormGroup = new FormGroup({});
  contract: ContractModel;
  products: ProductModel[];
  prestations: PrestationModel[];
  isEditableCatalog = false;


  dataSourceArticle: MatTableDataSource<ProductModel>;
  dataSourcePrestation: MatTableDataSource<PrestationModel>;

  feisColumnsArticle: Array<FeiColumn> = [];
  feisColumnsPrestation: Array<FeiColumn> = [];


  displayedColumnsArticle = this.feisColumnsArticle.map((feiColumn: FeiColumn) => feiColumn.column);
  displayedColumnsPrestation = this.feisColumnsPrestation.map((feiColumn: FeiColumn) => feiColumn.column);

  countProduct = 0;
  countPrestation = 0;

  pageSize = 10;
  pageIndex: number;
  sortField: string;
  sortOrder: string;
  feisColumns: Array<FeiColumn>;


  hasArticle = false;
  prestationEmpty = false;
  hasPrestation = false;
  articleEmpty = false;

  constructor(
    public contractService: ContractService,
    public productService: ProductService,
    public prestationService: PrestationService,
  ) {
  }

  ngOnInit(): void {
    this.popupForm = new FormGroup({
      stockable: new FormControl(null),
      isAvailable: new FormControl(null),
    });


  }

  initData() {
    const sub1 = this.contractService.entity$.pipe().subscribe((contract: ContractModel) => {
      this.contract = contract;

      this.initDataSources();
    });


    const sub2 = this.contractService.entity$.pipe(
      first(), // Assure que l'observable émet une seule valeur
    ).subscribe((contract: ContractModel) => {
      this.contract = contract;

      this.initDataSources();

      this.manageTabs();

      this.updateColumnsBasedOnSustainabilityAndPrice();

      this.displayedColumnsArticle = this.feisColumnsArticle.map((feiColumn: FeiColumn) => feiColumn.column);
      this.displayedColumnsPrestation = this.feisColumnsPrestation.map((feiColumn: FeiColumn) => feiColumn.column);

    });

    this.subscription.add(sub1);
    this.subscription.add(sub2);


  }

  initDataSources() {
    this.loading = true;

    // isArchive : we display archives products and prestations when the contract is expired

    const itemsPrpoduct = this.productService.findAll(this.pageIndex, this.pageSize, 'label', 'asc', null, {
      contractId: this.contract._id,
      isAllEstablishment: true,
      isWithExpired: true,
    }).subscribe(res => {
      this.countProduct = res.totalData;
      this.products = res.data;
      this.dataSourceArticle = new MatTableDataSource<ProductModel>(res.data);
      this.loading = false;
    });

    const itemsPrestation = this.prestationService.findAll(this.pageIndex, this.pageSize, 'label', 'asc', null, {
      contractId: this.contract._id,
      isAllEstablishment: true,
      isPackageService: true,
      isWithExpired: true,
      isWithRecurrence: true,
    }).subscribe(res => {
      this.countPrestation = res.totalData;
      this.prestations = res.data;
      this.dataSourcePrestation = new MatTableDataSource<PrestationModel>(res.data);
      this.loading = false;
    });

    this.subscription.add(itemsPrpoduct);
    this.subscription.add(itemsPrestation);

  }

  manageTabs() {
    this.tabs = this.contractService.generateTabs(this.contract);
    this.hasArticle = this.tabs.includes('Articles');
    this.hasPrestation = this.tabs.includes('Prestations');
    this.tabChanges(this.hasArticle ? 0 : 1);
  }


  updateColumnsBasedOnSustainabilityAndPrice() {
    const { typeOfRevision } = this.contract.sustainabilityAndPriceTrans;

    if (typeOfRevision !== ContractTypeOfRevisionEnum.FIXED) {
      this.feisColumnsArticle = this.feisColumnsArticle.filter((feiColumn: FeiColumn) => feiColumn.column !== 'revisionAmount');
      this.feisColumnsPrestation = this.feisColumnsPrestation.filter((feiColumn: FeiColumn) => feiColumn.column !== 'revisionAmount');

    }
  }

  tabChanges(event: number): void {
    this.entityType = event === 1 ? 'prestations' : 'products';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  pageEvent(event: PageEvent): void {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.initDataSources();

  }

  sortEvent(event: Sort): void {
    const propertyDisplay = this.feisColumns.find(x => x.column === event.active);
    this.sortField = propertyDisplay ? propertyDisplay.propertyDisplay : event.active;
    this.sortField = propertyDisplay.propertyDisplay ? propertyDisplay.propertyDisplay : event.active;
    this.sortOrder = event.direction;
    this.initDataSources();
  }


}
