import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContractService } from '../../contract.service';
import { FeiColumn } from '../../../../../../core/base/interfaces/fei-column.interface';
import { ENUM_PERMISSIONS } from '../../../../../../core/enums/permission.enum';
import { AuthService } from '../../../../../../core/services/auth.service';
import {take, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ProductModel } from '../../../../../../core/models/product.model';
import {
  ProductContractCreateUpdateDeleteComponent,
} from '../../../product/product-contract-create-update-delete/product-contract-create-update-delete.component';
import {
  PrestationContractCreateUpdateDeleteComponent,
} from '../../../prestation/prestation-contract-create-update-delete/prestation-contract-create-update-delete.component';
import { PrestationModel } from '../../../../../../core/models/prestation.model';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import { MatTableDataSource } from '@angular/material/table';
import { ContractBaseComponent } from '../contract-base/contract-base.component';
import {
  ContractBasketCreateUpdateDeleteComponent,
} from '../../modals/contract-basket-create-update-delete/contract-basket-create-update-delete.component';
import { ContractModel, ContractPerimeterModel } from '../../../../../../core/models/contract.model';
import { PrestationService } from '../../../prestation/prestation.service';
import { ProductService } from '../../../product/product.service';
import { SnackbarService } from '../../../../../../core/services/snackbar.service';
import icDone from '@iconify/icons-ic/twotone-done';
import icClose from '@iconify/icons-ic/twotone-close';
import { ContractStatusEnum } from '../../../../../../core/enums/contract-status.enum';
import { InterventionTypeEnum } from '../../../../../../core/enums/intervention.enum';
import { TvaService } from '../../../../settings/tva/tva.service';
import { TvaModel } from '../../../../../../core/models/tva.model';
import {EmployeePositionModel} from '../../../../../../core/models/employee-position.model';
import {
  FormModalConfirmWithListComponent
} from '../../../../../../core/base/components/form-modal-confirm-with-list/form-modal-confirm-with-list.component';
import {
  EmployeePositionCreateUpdateDeleteComponent
} from '../../../../settings/employee-position/employee-position-create-update-delete/employee-position-create-update-delete.component';
import {Subscription} from "rxjs";


@Component({
  selector: 'vex-contract-details-catalog',
  templateUrl: './contract-details-catalog.component.html',
  styleUrls: ['./contract-details-catalog.component.scss'],
})
export class ContractDetailsCatalogComponent extends ContractBaseComponent implements OnInit, OnDestroy {

// Datatable

  feisColumnsArticle: Array<FeiColumn> = [
    { label: 'Référence', column: 'reference', type: 'custom' },
    { label: 'Libellé', column: 'label' },
    { label: 'Périmètre complet', column: 'isFullPerimeter', type: 'custom' },
    // {label: 'Article', column: 'genericProduct', propertyDisplay: 'genericProduct.label'},
    { label: 'Quantité Minimum', column: 'minQuantity' },
    { label: 'Coût article contrat HT (Prix HT)', column: 'priceHT', type: 'number', additionalDisplay: '€' },
    {
      label: 'Taux TVA',
      column: 'tva',
      type: 'custom'
    },
    { label: 'Coût article contrat TTC (Prix TTC)', column: 'priceTTC', type: 'number', additionalDisplay: '€' },
    {
      label: 'Condition de révision (en %)',
      type: 'number',
      column: 'revisionAmount',
      additionalDisplay: ' %',
      maxDecimal: 2,
    },
    { label: 'Visibilité eShop', column: 'isAvailable', type: 'boolean' },
    { label: 'Action', column: 'actions', canUpdate: true, canArchive: true, canDelete: false },
  ];

  feisColumnsPrestation: Array<FeiColumn> = [
    { label: 'Référence', column: 'reference', type: 'custom' },
    { label: 'Libellé', column: 'label' },
    { label: 'Périmètre', column: 'isFullPerimeter', type: 'custom' },
    // {label: 'Prestation', column: 'genericPrestation', propertyDisplay: 'genericPrestation.label'},
    { label: 'Quantité Minimum', column: 'minQuantity' },
    { label: 'Coût prestation contrat HT (Prix HT)', column: 'priceHT', type: 'number', additionalDisplay: '€' },
    {
      label: 'Taux TVA',
      column: 'tva',
      type: 'custom'
    },
    { label: 'Coût prestation contrat TTC (Prix TTC)', column: 'priceTTC', type: 'number', additionalDisplay: '€' },
    {
      label: 'Condition de révision (en %)',
      type: 'number',
      column: 'revisionAmount',
      additionalDisplay: ' %',
      maxDecimal: 2,
    },
    { label: 'Nombre Prestations au forfait', column: 'purchasePackageValue', type: 'text' },
    { label: 'Nombre d’interventions initial', column: 'purchaseValueToDisplay', type: 'text' },
    { label: 'Solde de prestations', column: 'purchaseValueRemaining', type: 'number' },
    { label: 'Visibilité eShop', column: 'isAvailable', type: 'boolean' },
    { label: 'Intervention planifiée', column: 'interventionRequired', type: 'custom' },
    { label: 'Action', column: 'actions', canUpdate: true, canArchive: true, canDelete: true },
  ];


  // Pagination

  //  pageIndex: number;
  //  pageSize = 10;
  //   sortField: string;
  //   sortOrder: string;
  feisColumns: Array<FeiColumn>;


  // Boolean
  canUpdateAndAddPermission = false;
  canUpdateQuantityPermission = false;
  canDeletePermission = false;
  canAddPanierContratPermission = false;
  tooltip = false;
  showPreviousReference = false;

  ContractStatusEnum = ContractStatusEnum;


  icClose = icClose;
  icDone = icDone;

  // count = 0;
  prestations;
  previousId;
  perimeter: ContractPerimeterModel[] = [];
  contract: ContractModel;
  isFullPerimter: boolean;
  stockedData: any[] = [];

  genericProduct = null;
  genericPrestation = null;
  // }
  protected readonly InterventionTypeEnum = InterventionTypeEnum;

  hideTooltipTimeout: any;

  constructor(
    public contractService: ContractService,
    public prestationService: PrestationService,
    private authService: AuthService,
    private dialog: MatDialog,
    public productService: ProductService,
    public snackbarService: SnackbarService,
    private tvaService: TvaService,
  ) {
    super(contractService, productService, prestationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();

    //    this.tabChanges(0);
  }

  initData() {
    super.initData();

    this.contractService.entity$.subscribe((contract: ContractModel) => {
      this.isEditableCatalog = this.contractService.checkIsEditableCatalog(this.contract);
      if (this.contract.status === ContractStatusEnum.DRAFT) {
        this.feisColumnsArticle = this.updateActionsColumnDraft(this.feisColumnsArticle);
        this.feisColumnsPrestation = this.updateActionsColumnDraft(this.feisColumnsPrestation);
      }
      if (!this.isEditableCatalog) {
        this.feisColumnsArticle = this.updateActionsColumn(this.feisColumnsArticle);
        this.feisColumnsPrestation = this.updateActionsColumn(this.feisColumnsPrestation);
      }

    });

    const subCheckPermissions = this.checkPermissions().subscribe();
    this.subscription.add(subCheckPermissions);
  }

  // showTooltip(element?) {
  //   if (!element) {
  //     this.tooltip = false;
  //     this.previousId = '';
  //   }
  //
  //   if (element?._id && this.previousId !== element?._id) {
  //     this.tooltip = true;
  //     this.perimeter = element.perimeter;
  //     this.isFullPerimter = element.isFullPerimeter;
  //     this.previousId = element?._id;
  //   }
  // }

  updateActionsColumn(columns: Array<FeiColumn>) {
    return columns.map(column => {
      if (column.column === 'actions') {
        column.canUpdate = false;
        column.canArchive = this.contract?.status !== ContractStatusEnum.EXPIRED;
        column.canDelete = false;
      }
      return column;
    });
  }

  onStockData(data) {
    this.stockedData = data;
  }
  getTvaRate(tva: TvaModel) {
    return `${this.tvaService.getRateByDate(tva).rate.toFixed(2)}%`;
  }

  onFilterEvent(filtersData: any) {

    if (!filtersData.search) {
      filtersData.search = '';
    }

    this.dataSourceArticle.filterPredicate = (data, filter: any) => {

      if (filter.genericPrestation) {
        return false;
      }


      const matchStockable = filter.filters?.stockable ? data.stockable === filter.filters?.stockable : true;
      const matchIsAvailable = filter.filters?.isAvailable ? data.isAvailable === filter.filters?.isAvailable : true;

      // Logique de filtrage personnalisée pour les produits generique
      const matchGenericProduct = filter.genericProduct ?
        data.genericProduct?._id === filter.genericProduct : true;

      // Logique de filtrage personnalisée pour les produits
      const tvaRate = this.tvaService.getRateByDate(data.tva);
      const matchSearch = data.reference?.toLowerCase().includes(filter.search) ||
        data.label?.toLowerCase().includes(filter.search) ||
        data.previousReference?.toLowerCase().includes(filter.search) ||
        data.genericProduct?.label?.toLowerCase().includes(filter.search) ||
        data.minQuantity?.toString().toLowerCase().includes(filter.search) ||
        data.priceHT?.toString().toLowerCase().includes(filter.search) ||
        tvaRate?.rate.toString().toLowerCase().includes(filter.search) ||
        data.priceTTC?.toString().toLowerCase().includes(filter.search);

      return matchGenericProduct && matchSearch && matchStockable && matchIsAvailable;

    };
    this.dataSourceArticle.filter = filtersData;

    this.dataSourcePrestation.filterPredicate = (data, filter: any) => {

      if (filter.genericProduct) {
        return false;
      }
      if (filter.filters.stockable) {
        return false;
      }

      const matchIsAvailable = filter.filters?.isAvailable ? data.isAvailable === filter.filters?.isAvailable : true;

      const matchGenericPrestation = filter.genericPrestation ?
        data.genericPrestation?._id === filter.genericPrestation : true;

      // Logique de filtrage personnalisée pour les prestations
      const tvaRate = this.tvaService.getRateByDate(data.tva);
      const matchSearch = data.reference?.toLowerCase().includes(filter.search) ||
        data.label?.toLowerCase().includes(filter.search) ||
        data.previousReference?.toLowerCase().includes(filter.search) ||
        data.genericPrestation?.label?.toLowerCase().includes(filter.search) ||
        data.minQuantity?.toString().toLowerCase().includes(filter.search) ||
        data.priceHT?.toString().toLowerCase().includes(filter.search) ||
        tvaRate?.rate.toString().toLowerCase().includes(filter.search) ||
        data.priceTTC?.toString().toLowerCase().includes(filter.search);

      return matchGenericPrestation && matchSearch && matchIsAvailable;

      // Ajouter d'autres conditions selon les colonnes de feisColumnsPrestation
    };
    this.dataSourcePrestation.filter = filtersData;
    const filteredPrestationCount = this.countFilteredItems(this.dataSourcePrestation, this.dataSourcePrestation.filter);
    const filteredArticleCount = this.countFilteredItems(this.dataSourceArticle, this.dataSourceArticle.filter);
    this.prestationEmpty = filteredPrestationCount === 0;
    this.articleEmpty = filteredArticleCount === 0;


    if (this.prestationEmpty && !this.articleEmpty) {
      this.tabChanges(0);
    } else if (this.articleEmpty && !this.prestationEmpty) {
      this.tabChanges(1);
    }

    if (filtersData.search === '') {
      this.prestationEmpty = false;
      this.articleEmpty = false;
    }

  }

  countFilteredItems(dataSource: MatTableDataSource<any>, filterValue: any) {
    return dataSource.data.filter(item => dataSource.filterPredicate(item, filterValue)).length;
  }

  checkPermissions() {
    return this.authService.getCurrentUserPermissions$()
      .pipe(
        tap((permissions: any) => {
          this.canUpdateAndAddPermission = permissions.includes(ENUM_PERMISSIONS.INPUT_FM_REF_PURCHASE_COMMITMENT_CATALOG);
          this.canUpdateQuantityPermission = permissions.includes(ENUM_PERMISSIONS.UPDATE_FM_REF_PURCHASE_COMMITMENT_CATALOG);
          this.canDeletePermission = permissions.includes(ENUM_PERMISSIONS.DELETE_FM_REF_PURCHASE_COMMITMENT_CATALOG);
          this.canAddPanierContratPermission = permissions.includes(ENUM_PERMISSIONS.ADD_FM_REF_PURCHASE_COMMITMENT_CATALOG);
        }),
      );
  }

  createPrestIntervention(row) {
    const dialogRef = this.dialog.open(ContractBasketCreateUpdateDeleteComponent, {
      data: {
        mode: CrudModeEnum.Create,
        contract: this.contract,
        prestInterventionId: row._id,
        prestInterventionRequired: row.interventionRequired,
        prestLabel: row.label,
        contractGenericEngagementDataPatrimony: this.contract.genericEngagement.dataPatrimony
      }, // Passer les données ici
    });
    dialogRef.afterClosed().subscribe({});
  }

  createContractItem() {
    const component = this.entityType === 'products' ? ProductContractCreateUpdateDeleteComponent : PrestationContractCreateUpdateDeleteComponent;
    // @ts-ignore

    const sub = this.dialog.open(component, {
      data: {
        mode: CrudModeEnum.Create,
        contract: this.contract,
        messageResult: this.entityType === 'products' ? 'L\'article a été ajouté avec succès' : 'La prestation a été ajoutée avec succès',
      },
    }).afterClosed().subscribe((elements: ProductModel[] | PrestationModel[]) => {
      if (elements) {
        this.initDataSources();
        this.contractService.setIsValid(true);
      }
    });
    this.subscription.add(sub);
  }

  importContractItems() {
    const component = this.entityType === 'products' ? ProductContractCreateUpdateDeleteComponent : PrestationContractCreateUpdateDeleteComponent;

    // @ts-ignore
    const sub = this.dialog.open(component, {
      data: {
        mode: CrudModeEnum.Import,
        contract: this.contract,

      },
    }).afterClosed().subscribe((elements: ProductModel[] | PrestationModel[]) => {
      if (elements) {
        this.initDataSources();
        this.contractService.setIsValid(true);
        const resultMessage: string = this.entityType === 'products' ? 'Les articles ont été importés avec succès' : 'Les prestations ont été importé(e)s avec succès';
        this.snackbarService.success(resultMessage);
      }
    });
    this.subscription.add(sub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  updateItem(element: ProductModel | PrestationModel): void {

    const component = this.entityType === 'products' ? ProductContractCreateUpdateDeleteComponent : PrestationContractCreateUpdateDeleteComponent;
    // @ts-ignore
    this.dialog.open(component, {
      data: {
        defaults: element,
        mode: CrudModeEnum.Update,
        contract: this.contract,
        messageResultUpdate: this.entityType === 'products' ? 'L\'article a été modifié avec succès' : 'La prestation a été modifiée avec succès',
      },
    }).afterClosed().subscribe(element => {
      if (element) {
        this.initDataSources();
      }
    });
  }

  archiveItem(element: ProductModel | PrestationModel) {
    const component = this.entityType === 'products' ? ProductContractCreateUpdateDeleteComponent : PrestationContractCreateUpdateDeleteComponent;

    // @ts-ignore
    this.dialog.open(component, {
      data: {
        defaults: element,
        name: `${element.label}`,
        mode: CrudModeEnum.Archive,
      },
    }).afterClosed().subscribe(element => {
      if (element) {
        this.initDataSources();
      }
    });
  }

  deleteItem(element: ProductModel | PrestationModel) {
    let defaultMessage = `Êtes-vous sûr de vouloir supprimer ${element.label} ?`;

    const component = this.entityType === 'products'
        ? ProductContractCreateUpdateDeleteComponent
        : PrestationContractCreateUpdateDeleteComponent;

    // Check if the reference exist in recurringBasket
    const subscription: Subscription = this.contractService.reccuringBaskets$
        .pipe(take(1))
        .subscribe(recurringBaskets => {
      const exists = recurringBaskets.some(recurringBasket => {
        const items = recurringBasket[this.entityType];
        return items?.some(item => (item.item as ProductModel | PrestationModel)._id === element._id);
      });

      if (exists) {
        defaultMessage = `Cette référence se trouve dans l'un de vos paniers contrat. Êtes-vous sûr de vouloir supprimer ?`;
      }

      // @ts-ignore
      this.dialog.open(component, {
        data: {
          defaults: element,
          name: `${element.label}`,
          mode: CrudModeEnum.Delete,
          defaultMessage,
        },
      }).afterClosed().subscribe(result => {
        if (result) {
          this.initDataSources();

          this.snackbarService.success(
              this.entityType === 'products'
              ? 'Le produit a bien été supprimé'
              : 'La prestation a bien été supprimée'
          );

        }
      }, error => {
        this.snackbarService.warning('Une erreur est survenue');
      });

      subscription.unsubscribe();
    });
  }

  isToPlan(element): boolean {
    if (element.genericPrestation?.interventionRequired && !(element.interventionType === InterventionTypeEnum.FIXE)) {
      return true;
    } else {
      return false;
    }
  }

  isExistingIntervention(element): boolean {
    if (this.contract?.recurringBasket?.find(x => x.prestation?._id === element._id)) {
      return true;
    } else {
      return false;
    }
  }

  showTooltipText(element): boolean {
    if (element.interventionType === InterventionTypeEnum.FIXE && element.isPackageService) {
      return true;
    } else {
      return false;
    }
  }

  getTooltipText(element: any): string {
    return `Compteur d'intervention : ${element.purchaseValueRemaining}\n`;
  }

  updateActionsColumnDraft(columns: Array<FeiColumn>) {
    return columns.map(column => {
      if (column.column === 'actions') {
        column.canArchive = false;
        column.canDelete = true;
      }
      return column;
    });
  }

  showTooltip(state: boolean, element?) {
    if (!state) {
      this.tooltip = false;
      this.previousId = '';
    } else if (element?._id && this.previousId !== element?._id) {
      this.tooltip = true;
      this.perimeter = element.perimeter;
      this.isFullPerimter = element.isFullPerimeter;
      this.previousId = element?._id;
    }
  }

  onMouseEnter(row) {
    this.clearHideTooltipTimeout();
    this.showTooltip(true, row);
  }

  onMouseLeave() {
    this.setHideTooltipTimeout();
  }

  onMouseEnterTooltip() {
    this.clearHideTooltipTimeout();
  }

  onMouseLeaveTooltip() {
    this.setHideTooltipTimeout();
  }

  setHideTooltipTimeout() {
    this.hideTooltipTimeout = setTimeout(() => {
      this.showTooltip(false);
    }, 300);
  }

  clearHideTooltipTimeout() {
    if (this.hideTooltipTimeout) {
      clearTimeout(this.hideTooltipTimeout);
      this.hideTooltipTimeout = null;
    }
  }
}
