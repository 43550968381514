import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContractService } from '../../contract.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ContractModel, ContractPerimeterModel } from '../../../../../../core/models/contract.model';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogConfirmComponent } from '../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import faAngleRight from '@iconify/icons-fa-solid/angle-right';
import faAngleLeft from '@iconify/icons-fa-solid/angle-left';
import { AuthService } from '../../../../../../core/services/auth.service';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import {
    GenericEngagementDataPatrimonyEnum
} from '../../../../../../core/enums/generic-engagement-data-patrimony.enum';
import { EnergyIdService } from '../../../energy-id/energy-id.service';
import { EquipmentService } from '../../../equipment/equipment.service';
import { BuildingService } from '../../../building/building.service';
import { EstablishmentService } from '../../../establishment/establishment.service';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { Subscription } from 'rxjs';
import { ContractStatusEnum } from '../../../../../../core/enums/contract-status.enum';

@Component({
    selector: 'vex-contract-details-perimeter',
    templateUrl: './contract-details-perimeter.component.html',
    styleUrls: ['./contract-details-perimeter.component.scss']
})
export class ContractDetailsPerimeterComponent implements OnInit, OnDestroy {

    constructor(
        private contractService: ContractService,
        private energyIdService: EnergyIdService,
        private equipmentService: EquipmentService,
        private buildingService: BuildingService,
        private establishmentService: EstablishmentService,
        private authService: AuthService,
        private userProfileService: UserProfileService,
        private router: Router,
        private dialog: MatDialog,
    ) {
    }

    public faAngleRight = faAngleRight;
    public faAngleLeft = faAngleLeft;
    public icSearch = icSearch;
    public icClose = icClose;
    public selectAllButtonLabel = 'Tout sélectionner';

    public referencePerimeterForm: FormGroup;
    public itemPerimeterForm: FormGroup;
    public searchForm: FormGroup;
    public searchControl: FormControl = new FormControl();

    public  ContractStatusEnum = ContractStatusEnum;

    establishmentsList: { _id: string; label: string }[];
    itemsList: {
        _id: string;
        label: string,
        genericLabel: string;
        genericId: string,
        establishmentId: string,
        establishmentLabel: string,
        buildingId: string,
        buildingLabel: string
    }[];
    itemsListNoDuplicate: {
        _id: string;
        label: string,
        genericLabel: string;
        genericId: string,
        establishmentId: string,
        establishmentLabel: string,
        buildingId: string,
        buildingLabel: string
    }[];

    selectedItems: any[] = [];
    selectedItemsFromChild: ContractPerimeterModel[] = [];

    entity: ContractModel;
    currentDataPatrimony: GenericEngagementDataPatrimonyEnum;
    isReference: boolean;
    messageItemNotAvailable = '';

    isLoading = true;

    subscription = new Subscription();

    protected readonly GenericEngagementDataPatrimonyEnum = GenericEngagementDataPatrimonyEnum;

    ngOnInit(): void {
        this.initData();
        this.initForm();

        this.searchControl.valueChanges.subscribe((value) => {
            this.filterCheckBox(value);
        });

        this.searchForm.valueChanges.subscribe((value) => {
            this.filterInputs(value);
        });

    }

    async initData() {
        this.isLoading = true;

        this.initAffectations();

        const sub = this.contractService.entity$.subscribe((entity: ContractModel) => {
            this.entity = entity;

            this.currentDataPatrimony = entity.genericEngagement?.dataPatrimony;
            this.isReference = this.currentDataPatrimony === GenericEngagementDataPatrimonyEnum.REFERENCE;

            if (this.establishmentsList) {
                this.initExistingPerimeter();
            }
        });
        this.subscription.add(sub);

        console.log('CONTRACT MODEL', this.entity);

        this.isLoading = false;
    }

    private initForm() {

        this.itemPerimeterForm = new FormGroup({
            selectedItem: new FormControl(),
        });

        this.searchForm = new FormGroup({
            generics: new FormControl(''),
            establishments: new FormControl(''),
            buildings: new FormControl(''),
        });
    }

    initAffectations() {

        const sub = this.userProfileService.findUserAffectation().subscribe((datas) => {

            this.establishmentsList = datas.data.map(x => {

                return {
                    _id: x.establishment._id,
                    label: x.establishment.label
                };
            });
            this.establishmentsList.sort((a, b) => a.label.localeCompare(b.label));
            this.initExistingPerimeter();


        });
        this.subscription.add(sub);


    }

    async initExistingPerimeter() {

        console.log('dataPatrimony', this.entity.genericEngagement.dataPatrimony);

        switch (this.entity.genericEngagement.dataPatrimony) {
            case GenericEngagementDataPatrimonyEnum.REFERENCE:
                this.itemsList = this.establishmentService.transformEstablishmentData(this.establishmentsList);
                this.itemsListNoDuplicate = this.removeDuplicatesAndSortByKey(this.itemsList, '_id');

                // remove the establishement that are already in the contract.perimeter
                this.entity.perimeter?.forEach(perimeter => {

                    this.itemsListNoDuplicate = this.itemsListNoDuplicate.filter(list => list._id !== perimeter.establishmentId);

                });

                break;

            case GenericEngagementDataPatrimonyEnum.EQUIPMENT:
                this.equipmentService.findPerimetersByEstablishments(this.entity, this.establishmentsList
                    .map(x => x._id)).subscribe(result => {
                    this.itemsList = this.equipmentService.transformEquipmentData(result);
                    // check if one item has no location
                    const itemsListNotAvailable = this.itemsList
                        .filter(i => i.buildingId === null)
                        .map(i => i.label);

                    this.messageItemNotAvailable = itemsListNotAvailable.length > 0 ? 'Pas de bâtiment affecté' : '';

                    this.itemsListNoDuplicate = this.removeDuplicatesAndSortByKey(this.itemsList, '_id');

                    // remove the establishement that are already in the contract.perimeter
                    this.entity.perimeter?.forEach(perimeter => {

                        this.itemsListNoDuplicate = this.itemsListNoDuplicate.filter(list => list._id !== perimeter.equipmentId);
                    });
                });
                break;

            case GenericEngagementDataPatrimonyEnum.BUILDING:

                console.log('on rentre dans les batiments');
                this.buildingService.findPerimetersByEstablishments(this.entity, this.establishmentsList
                    .map(x => x._id)).subscribe(result => {
                    this.itemsList = this.buildingService.transformBuildingData(result);
                    this.itemsListNoDuplicate = this.removeDuplicatesAndSortByKey(this.itemsList, '_id');
                    // remove the establishement that are already in the contract.perimeter
                    this.entity.perimeter?.forEach(perimeter => {

                        this.itemsListNoDuplicate = this.itemsListNoDuplicate.filter(list => list._id !== perimeter.buildingId);

                    });
                });
                break;

            case GenericEngagementDataPatrimonyEnum.ID_ENERGIE:


                this.energyIdService
                    .findPerimetersByEstablishments(this.entity, this.establishmentsList.map(x => x._id))
                    .subscribe(result => {
                    this.itemsList = this.energyIdService.transformEnergyIdData(result);
                    this.itemsListNoDuplicate = this.removeDuplicatesAndSortByKey(this.itemsList, '_id');

                    // remove the establishement that are already in the contract.perimeter
                    this.entity.perimeter?.forEach(perimeter => {
                        this.itemsListNoDuplicate = this.itemsListNoDuplicate.filter(list => list._id !== perimeter.energyIdId);
                    });
                });
                break;
        }
    }

    filterCheckBox(value): void {
        // this.initAffectations();
        this.itemsListNoDuplicate = this.removeDuplicatesAndSortByKey(this.itemsList, '_id');

        // if there is something in the inputs search
        if (this.searchForm.value) {
            this.filterInputs(this.searchForm.value);
        }

        if (value) {
            const normalizedValue = value.toLowerCase();

            this.itemsListNoDuplicate = this.itemsListNoDuplicate.filter(list => list.label.toLowerCase().includes(normalizedValue));
            this.selectedItems = this.selectedItems.filter(selectedId =>
                this.itemsListNoDuplicate.some(e => e._id === selectedId._id.toString())
            );
        }

        if (this.selectAllButtonLabel === 'Tout déselectionner') {
            this.selectedItems = this.itemsListNoDuplicate;
        }
    }

    filterInputs(value) {

        if (value.establishments && value.establishments?._id) {
            this.itemsListNoDuplicate = this.itemsListNoDuplicate.
            filter(list => list.establishmentId === value.establishments.establishmentId);
            this.selectedItems = this.selectedItems
                .filter(selectedId => this.itemsListNoDuplicate
                    .some(e => e.establishmentId === selectedId.establishmentId));
        }

        if (value.generics && value.generics?._id) {
            this.itemsListNoDuplicate = this.itemsListNoDuplicate
                .filter(list => list.genericId === value.generics.genericId);
            this.selectedItems = this.selectedItems
                .filter(selectedId => this.itemsListNoDuplicate
                    .some(e => e.genericId === selectedId.genericId));
        }

        if (value.buildings && value.buildings?._id) {
            this.itemsListNoDuplicate = this.itemsListNoDuplicate
                .filter(list => list.buildingId === value.buildings.buildingId);
            this.selectedItems = this.selectedItems
                .filter(selectedId => this.itemsListNoDuplicate
                    .some(e => e.buildingId === selectedId.buildingId));
        }

        if (this.selectAllButtonLabel === 'Tout déselectionner') {
            this.selectedItems = this.itemsListNoDuplicate;
        }
    }

    toggleSelection(checked: boolean, itemId) {

        if (checked) {
            this.selectedItems.push(itemId);
        }

        if (!checked) {
            const index = this.selectedItems.findIndex(item => item === itemId);
            if (index !== -1) {
                this.selectedItems.splice(index, 1);
            }
        }
    }

    updateCheckboxSelection(checked: boolean) {

        if (checked) {
            this.selectedItems = this.itemsListNoDuplicate;

            if (!this.isReference) {
                this.selectedItems = this.selectedItems.filter(i => i.buildingId !== null);
            }

            this.selectAllButtonLabel = 'Tout déselectionner';
        }

        if (!checked) {
            this.selectedItems = [];
            this.selectAllButtonLabel = 'Tout sélectionner';
        }
    }

    isSelected(itemId: any): boolean {
        return this.selectedItems.some(selectedItem => selectedItem._id === itemId);
    }

    isNotAvailable(itemId: any): boolean {
        if (!this.isReference){
        return this.itemsList.some(i => i._id === itemId && i.buildingId === null);
        }

        return false;
    }

    isAllSelected() {
        if (!this.isReference) {
            const filteredItems = this.itemsListNoDuplicate.filter(i => i.buildingId !== null);
            return this.selectedItems.length === filteredItems.length;
        }

        if (this.isReference) {
            return this.selectedItems.length === this.itemsListNoDuplicate.length;
        }
    }

    onSelectedItemsChange(selectedItems: ContractPerimeterModel[]) {

        console.log(selectedItems);

        this.selectedItemsFromChild = selectedItems;
    }

    addItem() {
        const sub = this.contractService.addAllPerimeter(this.entity._id, this.selectedItems).subscribe((datas) => {
        });
        this.reset();
        this.subscription.add(sub);
    }

    deleteItem() {
        const sub = this.contractService.deleteAllPerimeter(this.entity._id, this.selectedItemsFromChild).subscribe((datas) => {
        });
        this.reset();
        this.subscription.add(sub);
    }

    reset() {
        this.selectedItems = [];
        this.selectedItemsFromChild = [];
        this.selectAllButtonLabel = 'Tout sélectionner';
    }

    clearFilter() {
        this.itemsListNoDuplicate = this.removeDuplicatesAndSortByKey(this.itemsList, '_id');
        this.searchForm.reset({}, {emitEvent: false});
        this.selectAllButtonLabel = 'Tout sélectionner';

        // if there is something in the search bar
        if (this.searchControl.value) {
            this.filterCheckBox(this.searchControl.value);
        }
    }

    removeDuplicatesAndSortByKey(items: any[], key: string): any[] {

        if (!items) {
            return [];
        }

        const uniqueItems = [];
        const addedItems = new Set<string>();

        for (const item of items) {
            if (item[key] != null && !addedItems.has(item[key])) {
                uniqueItems.push(item);
                addedItems.add(item[key]);
            }
        }

        if (key === 'genericId'){
            uniqueItems.sort((a, b) => a.genericLabel.localeCompare(b.genericLabel));
        }

        if (key === 'establishmentId'){
            uniqueItems.sort((a, b) => a.establishmentLabel.localeCompare(b.establishmentLabel));
        }

        if (key === 'buildingId'){
            uniqueItems.sort((a, b) => a.buildingLabel.localeCompare(b.buildingLabel));
        }

        return uniqueItems;
    }

    async switchToUpdateMode() {
        const sub = this.dialog.open(DialogConfirmComponent, {
            data: {
                description: 'Êtes-vous sûr de vouloir modifier le perimètre ? Attention cela peut avoir des conséquences sur les autres étapes.',
                cancelText: 'ANNULER',
                title: 'Modifier le perimètre',
                validText: 'CONFIRMER',
                call$: this.contractService.updateStep(this.entity._id, {creationStep: 2})
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.initData();
            }
        });
        this.subscription.add(sub);
    }

    updateStep(step: number) {
        const sub = this.contractService.updateStep(this.entity._id, {creationStep: step}).subscribe(res => {
            if (step === 3) {
                this.router.navigate(['/management/engagement/', this.entity._id, 'cga']).then();
            }
        });
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
